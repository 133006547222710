// Customizable Area Start
import React, { Component } from "react";
import { Dialog, DialogProps } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const DialogWrapper = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: "8px 8px 32px 8px"
    }
});

interface IProps extends DialogProps {
  children: React.ReactNode;
}

export class CustomDialog extends Component<IProps> {
  render() {
    const { children } = this.props;
    return <DialogWrapper {...this.props}>{children}</DialogWrapper>;
  }
}

export default CustomDialog;
// Customizable Area End
