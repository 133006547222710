// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Card,
  Box,
  Modal,
  IconButton,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@material-ui/core";
import {
  EverybrandIcon,
  infoIcon,
  greyCheckIcon,
  socialPhoneDocIcons,
  checkIcon,
} from "./assets";
import SocialPhoneNumberController from "./SocialPhoneNumberController.web";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import MobileLoader from "../../../components/src/MobileLoader";

export class SocialPhoneNumber extends SocialPhoneNumberController {
  renderItem = (item: any) => {
    if (item.isSelected) {
      return <RadioButtonCheckedIcon style={{ color: "#000" }} />;
    } else {
      return <RadioButtonUncheckedIcon style={{ color: "#64748B" }} />;
    }
  };

  getActiveStep = () => {
    return this.state.steps.find((step: any) => step.active);
  };
  renderStep = (step: any, classes: any) => {
    return (
      <>
        {step.completed ? (
          <img src={greyCheckIcon} alt="" style={{ margin: "0 10px" }} />
        ) : (
          <Box
            className={step.active ? classes.activeDot : classes.unreadDot}
          ></Box>
        )}
      </>
    );
  };
  render() {
    const { classes }: any = this.props;

    const activeStep = this.getActiveStep();

    const stepForm = () => {
      return (
        <Box className={classes.dotsRow} sx={{ padding: "0 20px" }}>
          {this.state.steps.map((step: any) => (
            <Box key={step.id}>{this.renderStep(step, classes)}</Box>
          ))}
        </Box>
      );
    };

    return (
      <Container maxWidth={false} className={classes.mainContSocial}>
        <Box className={classes.firstBox}>
          <Typography className={classes.heading}>
            Your Everybrand Number
          </Typography>
          <Box>
            <img src={EverybrandIcon} alt="" />
          </Box>
        </Box>
        <Typography className={classes.numberTExt}>354-695-412</Typography>
        <Typography className={classes.heading}>Trust Center</Typography>
        <Typography className={classes.para}>
          Trust Center provides products that can improve customer engagement by
          increasing throughput and brand recognition. To access the available
          products, please follow these steps.
        </Typography>
        <Box className={classes.secondBox}>
          <img src={checkIcon} alt="" />
          <Typography align="left" className={classes.paramid}>
            For A2P Brand Registration Business Profile setup is not required.
            Directly proceed with A2P Brand Registration
          </Typography>
        </Box>
        <Box className={classes.secondBox}>
          <img src={checkIcon} alt="" />
          <Typography align="left" className={classes.paramid}>
            Business Profile Setup would still be required for SHAKEN/STIR
            trusted calling.
          </Typography>
        </Box>
        <Card className={classes.regCard}>
          <img alt="" src={socialPhoneDocIcons} className={classes.cardIcon} />
          <Typography className={classes.cardHeading}>
            A2P Brand and Campaign Registration (SMS)
          </Typography>
          <Typography className={classes.cardPara}>
            Avoid additional carrier filtering by registering for A2P messaging
            capabilities. This applies to SMS/MMS sent to the US (only) via
            10-digit code numbers.
          </Typography>
          <Button
            data-test-id="registration_button"
            onClick={this.openFormModal}
            variant="contained"
            className={classes.startBtn}
          >
            Start Registration
          </Button>
        </Card>
        <Modal
          open={this.state.formModal}
          onClose={this.closeFormModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div
            ref={this.formTopRef}
            className={
              this.state.activeStep > 2
                ? classes.modalBoxRest
                : classes.modalBox
            }
          >
            <MobileLoader loading={this.state.loader} />
            <IconButton
              data-test-id="close_modal_icon"
              className={classes.crossIconBtn}
              onClick={this.closeFormModal}
            >
              <CloseIcon />
            </IconButton>
            <img
              alt=""
              src={socialPhoneDocIcons}
              className={classes.cardIcon}
            />
            <Box className={classes.dotsRow} sx={{ padding: "30px 0" }}>
              <Typography className={classes.stepText}>
                Step {this.state.activeStep} of 8{" "}
              </Typography>
              {stepForm()}
            </Box>
            {/* STEPS  */}
            {(() => {
              switch (activeStep.id) {
                case 1:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        Determine A2P Brand needs
                      </Typography>
                      <Typography className={classes.modalparafirst}>
                        Does the business you´re registrering have a Tax ID
                        (E.g. EIN, CCN etc.)?
                      </Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={this.state.business_tx_id}
                          onChange={this.hanleStepOnRadio}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio style={{ color: "#000" }} />}
                            label="Yes, the business I´m registering has a Tax ID (E.g. EIN, CCN
              etc.)"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio style={{ color: "#000" }} />}
                            label="No, the business I´m registering does not have a Tax ID (E.g. EIN,
              CCN etc.)"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Box className={classes.InfoBox}>
                        <img
                          src={infoIcon}
                          alt=""
                          className={classes.inofICons}
                        />
                        <Typography className={classes.infoBoxPara}>
                          You can find your US EIN on your IRS or previous tax
                          return documents (W2, W9 or CP 575)
                        </Typography>
                      </Box>
                      <Grid container className={classes.buttonsBox}>
                        <Grid item md={6}>
                          <Button
                            className={classes.modalBtnDark}
                            onClick={this.closeFormModal}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item md={6}>
                          <Button
                            data-test-id="step_one_next"
                            className={classes.modalBtn}
                            onClick={() => this.handleNextStep(1)}
                          >
                            Next
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                case 2:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        Select your Standard Brand Plan
                      </Typography>
                      <Typography className={classes.modalparafirst}>
                        We have the following plans for Standard Brand
                        registrations
                      </Typography>
                      <Grid container>
                        {this.state.stepTwoPlans.map((item: any) => (
                          <Grid key={item.id} item md={6}>
                            <Box
                              className={
                                item.isSelected
                                  ? classes.stepTwoPlansBoxActive
                                  : classes.stepTwoPlansBox
                              }
                            >
                              <Box className={classes.radioHeadingBox}>
                                <Typography className={classes.stepTwoCardH}>
                                  {item.title}
                                </Typography>
                                <IconButton
                                  onClick={() =>
                                    this.handleStepTwoRadioIcon(item)
                                  }
                                >
                                  {this.renderItem(item)}
                                </IconButton>
                              </Box>
                              {item.isRecomended && (
                                <Typography className={classes.stepTwoRecom}>
                                  Recommended
                                </Typography>
                              )}
                              {item.includes.map((x: any) => (
                                <Box
                                  key={x}
                                  className={classes.secondStepPlanBox}
                                >
                                  <FiberManualRecordIcon
                                    className={classes.listDot}
                                  />
                                  <Typography className={classes.radiotext2s}>
                                    {x}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Box className={classes.InfoBox}>
                        <img
                          src={infoIcon}
                          alt=""
                          className={classes.inofICons}
                        />
                        <Typography className={classes.infoBoxPara}>
                          Brand and Campaign registration on Time $20.95 will be
                          charged. Additional campaign fee up to $12 per month
                          will be apply in accordance with TCR rules
                        </Typography>
                      </Box>
                      <Grid container className={classes.buttonsBox}>
                        <Grid item md={6}>
                          <Button
                            data-test-id="step_two_back"
                            className={classes.modalBtnDark}
                            onClick={() => this.handleBackStep(2)}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item md={6}>
                          <Button
                            data-test-id="step_two_next"
                            className={classes.modalBtn}
                            onClick={() => this.handleNextStep(2)}
                          >
                            Next
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                case 3:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        Business Details
                      </Typography>
                      <Box>
                        <Typography className={classes.textFiledLable}>
                          Legal Business Name*
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label="Your Business Name"
                          variant="outlined"
                          className={classes.inputFieldOne}
                          data-test-id="legal_business_name"
                          value={this.state.legal_business_name}
                          onChange={(e) => {
                            this.setState({
                              legal_business_name: e.target.value,
                            });
                          }}
                        />
                        <Typography className={classes.infotextS2}>
                          Use either legal name of the organization which is
                          sending the messages, of your own name if this brand
                          is not for an organization
                        </Typography>
                        <Typography className={classes.textFiledLable}>
                          Contact Information
                        </Typography>
                        <Typography className={classes.infotextS2}>
                          This can be any person that can answer questions about
                          this brand, Please note that you cannot reuse contact
                          information between brands. Each brand you create must
                          have unique contact information.
                        </Typography>
                        <Typography className={classes.textFiledLable}>
                          First Name*
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label="Your First Name"
                          variant="outlined"
                          className={classes.inputFieldOne}
                          data-test-id="first_name"
                          value={this.state.first_name}
                          onChange={(e) => {
                            this.setState({ first_name: e.target.value });
                          }}
                        />
                        <Typography className={classes.textFiledLable}>
                          Last Name*
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label="Your Last Name"
                          variant="outlined"
                          className={classes.inputFieldOne}
                          data-test-id="last_name"
                          value={this.state.last_name}
                          onChange={(e) => {
                            this.setState({ last_name: e.target.value });
                          }}
                        />
                        <Typography className={classes.textFiledLable}>
                          Email*
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label="Your Business Email"
                          variant="outlined"
                          className={classes.inputFieldOne}
                          data-test-id="email"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                        />
                        {this.state.emailError && (
                          <Typography className={classes.errorMsg}>
                            {this.state.emailError}{" "}
                          </Typography>
                        )}
                        <Typography className={classes.textFiledLable}>
                          Your Mobile Number
                        </Typography>
                        <PhoneInput
                          data-test-id="fullNumber"
                          country={"us"}
                          containerStyle={{ margin: "10px 0" }}
                          inputStyle={{
                            width: "100%",
                            padding: "25px 60px",
                            borderRadius: "8px",
                          }}
                          placeholder="Enter phone number"
                          inputProps={{
                            name: "Enter Phone",
                            required: true,
                            autoFocus: true,
                          }}
                          value={this.state.phoneNumber}
                          onChange={(e) => {
                            this.setState({ phoneNumber: e });
                          }}
                        />
                        <Typography className={classes.infotextS2}>
                          Don´t use the same phone number for more than 5
                          brands.
                        </Typography>
                        <Button
                          data-test-id="step_three_next"
                          onClick={() => this.handleStepThree()}
                          className={classes.modalBtn2}
                        >
                          Next
                        </Button>
                        <Button
                          data-test-id="step_three_back"
                          onClick={() => this.handleBackStep(3)}
                          className={classes.modalBtnDark2}
                        >
                          Back
                        </Button>
                      </Box>
                    </Box>
                  );
                case 4:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        Business Address
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        Street Address*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your Street Address"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="street_address"
                        value={this.state.streetAddress}
                        onChange={(e) => {
                          this.setState({ streetAddress: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Must be a valid address linked to your bussiness
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        City
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your City"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="city"
                        value={this.state.city}
                        onChange={(e) => {
                          this.setState({ city: e.target.value });
                        }}
                      />
                      <Typography className={classes.textFiledLable}>
                        Postal/Zip Code*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your Zip Code"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="zip_code"
                        value={this.state.zipCode}
                        onChange={(e) => {
                          this.setState({ zipCode: e.target.value });
                        }}
                      />
                      <Typography className={classes.textFiledLable}>
                        Country
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your Country"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="country"
                        value={this.state.country}
                        onChange={(e) => {
                          this.setState({ country: e.target.value });
                        }}
                      />
                      <Typography className={classes.textFiledLable}>
                        State/Prov/Region*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your State"
                        variant="outlined"
                        data-test-id="region"
                        className={classes.inputFieldOne}
                        value={this.state.region}
                        onChange={(e) => {
                          this.setState({ region: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        This can be any person that can answer questions about
                        this brand, Please note that you cannot reuse contact
                        information between brands. Each brand you create must
                        have unique contact information.{" "}
                      </Typography>
                      <Button
                        data-test-id="step_four_next"
                        onClick={() => this.handleNextStep(4)}
                        className={classes.modalBtn2}
                      >
                        Next
                      </Button>
                      <Button
                        data-test-id="step_four_back"
                        onClick={() => this.handleBackStep(4)}
                        className={classes.modalBtnDark2}
                      >
                        Back
                      </Button>
                    </Box>
                  );
                case 5:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        Brand Details{" "}
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        Brand Name*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your Brand name"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="brand_name"
                        value={this.state.brandName}
                        onChange={(e) => {
                          this.setState({ brandName: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Use either legal name of the organization which is
                        sending the messages, of your own name if this brand is
                        not for an organization
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        Business Industry*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Select a Bussiness Industry"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="business_industry"
                        value={this.state.businessIndustry}
                        onChange={(e) => {
                          this.setState({ businessIndustry: e.target.value });
                        }}
                      />
                      <Typography className={classes.textFiledLable}>
                        Mobile Number for OTP Verification <br />
                        (with country code)*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your Mobile Number*"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="mobile_number_for_otp"
                        value={this.state.mobileForOtp}
                        onChange={(e) => {
                          this.setState({ mobileForOtp: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Please provide a mobile number that you have access to
                        so you can confirm OTP Verification. Only US/Canadian
                        nubers will be accepted. You may not use a CPaaS phone
                        number such as one obtained from LeadConnector You
                        cannot use the same mobile nummber to register more than
                        3 sole Proprietor Brands. This is mmanaged by TCR.
                      </Typography>
                      <Button
                        data-test-id="step_five_next"
                        onClick={() => this.handleNextStep(5)}
                        className={classes.modalBtn2}
                      >
                        Next
                      </Button>
                      <Button
                        data-test-id="step_five_back"
                        onClick={() => this.handleBackStep(5)}
                        className={classes.modalBtnDark2}
                      >
                        Back
                      </Button>
                    </Box>
                  );
                case 6:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        Enter campaign details{" "}
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        Campaign Use Case*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Your Use Case"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        data-test-id="campaign_use_case"
                        value={this.state.campaignUseCase}
                        onChange={(e) => {
                          this.setState({ campaignUseCase: e.target.value });
                        }}
                      />
                      <Typography className={classes.textFiledLable}>
                        Campaign Use Case Description <br />
                        (please explain in detail)*{" "}
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Example: This campaign sends appointment information - confirmation & reminder messages to our customers "
                        variant="outlined"
                        className={classes.inputFieldOne}
                        minRows={4}
                        multiline={true}
                        data-test-id="campaign_use_case_description"
                        value={this.state.campaignUseCaseDescription}
                        onChange={(e) => {
                          this.setState({
                            campaignUseCaseDescription: e.target.value,
                          });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Min length: 40 characters. Max length: 4096 characters
                      </Typography>
                      <Button
                        data-test-id="step_six_next"
                        onClick={() => this.handleNextStep(6)}
                        className={classes.modalBtn2}
                      >
                        Next
                      </Button>
                      <Button
                        data-test-id="step_six_back"
                        onClick={() => this.handleBackStep(6)}
                        className={classes.modalBtnDark2}
                      >
                        Back
                      </Button>
                    </Box>
                  );
                case 7:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        Enter campaign details
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        Sample Message #1 (Must include lead name, your name,
                        business name &opt-out language)*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Example: Hi John! This is Jane fromm Dallas-MedSpa Our appointment for July 20 11:00AMM is confirmed: Please reach out to +1(312)154-568 in case you need to reschedule. Reply STOP to unsuscribe."
                        variant="outlined"
                        className={classes.inputFieldOne}
                        multiline={true}
                        minRows={4}
                        data-test-id="sample_message_one"
                        value={this.state.sampleMessageOne}
                        onChange={(e) => {
                          this.setState({ sampleMessageOne: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Min length: 20 characters. Max length: 1024 characters
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        Sample Message #2 (Must include lead name, your name,
                        business name &opt-out language)*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Example: This campaign sends appointment information - confirmation & reminder messages to our customers "
                        variant="outlined"
                        className={classes.inputFieldOne}
                        minRows={4}
                        multiline={true}
                        data-test-id="sample_message_two"
                        value={this.state.sampleMessageTwo}
                        onChange={(e) => {
                          this.setState({ sampleMessageTwo: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Min length: 20 characters. Max length: 1024 characters
                      </Typography>
                      <Button
                        data-test-id="step_seven_next"
                        onClick={() => this.handleNextStep(7)}
                        className={classes.modalBtn2}
                      >
                        Next
                      </Button>
                      <Button
                        data-test-id="step_seven_back"
                        onClick={() => this.handleBackStep(7)}
                        className={classes.modalBtnDark2}
                      >
                        Back
                      </Button>
                    </Box>
                  );
                case 8:
                  return (
                    <Box>
                      <Typography className={classes.modalHeading}>
                        End user Consent
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        How do end-users consent to receive messages?*{" "}
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Example: Hi John! This is Jane fromm Dallas-MedSpa Our appointment for July 20 11:00AMM is confirmed: Please reach out to +1(312)154-568 in case you need to reschedule. Reply STOP to unsuscribe."
                        variant="outlined"
                        className={classes.inputFieldOne}
                        multiline={true}
                        minRows={4}
                        data-test-id="consent_message"
                        value={this.state.endUserCnsent}
                        onChange={(e) => {
                          this.setState({ endUserCnsent: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Min length: 40 characters. Max length: 4096 characters
                      </Typography>
                      <Typography className={classes.textFiledLable}>
                        OPT-in Keywords*{" "}
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Keywords separated by comma. Example: SUBSCRIBE_START"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        minRows={4}
                        multiline={true}
                        data-test-id="otp_in_keywords"
                        value={this.state.otpInKeywords}
                        onChange={(e) => {
                          this.setState({ otpInKeywords: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Max length: 225 characters{" "}
                      </Typography>

                      <Typography className={classes.textFiledLable}>
                        OPT-in Message(Must contain business name and opt-out
                        keyword)*
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        label="Example: You have succesfully opted-in to receive notification and promotional SMS from Dallas MedSpa. Please reply STOP if you need to Opt-out in the future"
                        variant="outlined"
                        className={classes.inputFieldOne}
                        minRows={4}
                        multiline={true}
                        data-test-id="otp_in_message"
                        value={this.state.otpInMessages}
                        onChange={(e) => {
                          this.setState({ otpInMessages: e.target.value });
                        }}
                      />
                      <Typography className={classes.infotextS2}>
                        Min length: 20 characters. Max length: 320 characters{" "}
                      </Typography>
                      <Button
                        data-test-id="step_eight_next"
                        onClick={() => {
                          this.callSocialPostAPI(this.state);
                          this.closeFormModal();
                        }}
                        className={classes.modalBtn2}
                      >
                        Next
                      </Button>
                      <Button
                        data-test-id="step_eight_back"
                        onClick={() => this.handleBackStep(8)}
                        className={classes.modalBtnDark2}
                      >
                        Back
                      </Button>
                    </Box>
                  );
                default:
                  return <div>No active step</div>;
              }
            })()}
          </div>
        </Modal>
      </Container>
    );
  }
}

export const commonStyles: any = {
  planscards2: {
    borderRadius: "20px",
    border: "3px solid #000",
    padding: "20px 25px",
    height: "234px",
    margin: "10px",
  },
  text: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  flexDRS: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    backgroundColor: "#FFC629",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    padding: "5px 40px",
  },
  dotts: {
    height: "22px",
    width: "22px",
    borderRadius: "50%",
    margin: "0 10px",
  },
  modalBox: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "32px 48px 24px",
    width: "950px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "0px 0px 40px 0",
  },
};

const styles = (theme: Theme) =>
  createStyles({
    errorMsg: {
      fontSize: "11px",
      color: "red",
      padding: "2px 8px",
    },
    flagsDropdown: {
      border: "none !important",
      backgroundColor: "transparent !important",
      height: "50px !important",
      fontSize: "18px !important",
    },
    phoneInput: {
      borderRadius: "8px",
      backgroundColor: "#e8fafe !important",
      border: "none",
      width: "100%",
      marginTop: "5px",
      marginBottom: "5px",
      borderBottom: "4px solid",
      borderImageSource: "linear-gradient(45deg, pink, white 50%)",
      borderImageSlice: 1,
    },
    phoneInputInput: {
      border: "none !important",
      backgroundColor: "transparent !important",
      height: "50px !important",
      fontSize: "18px !important",
      paddingLeft: "50px !important",
      width: "100%",
    },
    selectedFlag: {
      backgroundColor: "transparent !important",
    },
    countryList: {
      zIndex: 999999,
    },
    formControl: {
      borderRadius: "8px",
      height: "60px",
      border: "none",
      color: "#000",
      fontSize: "20px",
      lineHeight: "30px",
      width: "100% !importent",
    },
    inputFieldOne: {
      borderRadius: "8px",
      width: "100%",
      margin: "5px",
    },
    infotextS2: {
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "22px",
    },
    textFiledLable: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      padding: "20px 0 5px 0",
    },
    listDot: {
      fontSize: "10px",
      color: "#475569",
      margin: "auto 0",
    },
    radiotext2s: {
      color: "#475569",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "19.5px",
      padding: "0 10px",
    },
    secondStepPlanBox: {
      ...commonStyles.flexDRS,
      justifyContent: "flex-start",
      padding: "10px 0",
    },
    stepTwoRecom: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      backgroundColor: "#FFC629",
      borderRadius: "5px",
      width: "130px",
      padding: "5px",
    },
    radioHeadingBox: {
      ...commonStyles.flexDRS,
    },
    stepTwoCardH: {
      ...commonStyles.text,
    },
    stepTwoPlansBoxActive: {
      ...commonStyles.planscards2,
    },
    stepTwoPlansBox: {
      ...commonStyles.planscards2,
      border: "1px solid #ABB0BC",
    },
    inofICons: {
      height: "28px",
      width: "28px",
    },
    activeDot: {
      ...commonStyles.dotts,
      backgroundColor: "#FFC629",
    },
    completedDot: {
      ...commonStyles.dotts,
      backgroundColor: "pink",
    },
    unreadDot: {
      ...commonStyles.dotts,
      backgroundColor: "#E2E8F0",
    },
    buttonsBox: {
      ...commonStyles.flexRow,
      justifyContent: "space-evenly",
    },
    modalBtn: {
      ...commonStyles.button,
      padding: "10px 80px",
      width: "80%",
    },
    modalBtn2: {
      ...commonStyles.button,
      padding: "20px 80px",
      width: "100%",
      margin: "10px 0",
    },
    modalBtnDark: {
      ...commonStyles.button,
      backgroundColor: "#000",
      color: "#FFFFFF",
      padding: "10px 80px",
      width: "80%",
      "&:hover": {
        color: "#000",
      },
    },
    modalBtnDark2: {
      ...commonStyles.button,
      backgroundColor: "#000",
      color: "#FFFFFF",
      padding: "20px 80px",
      margin: "10px 0",
      width: "100%",
      "&:hover": {
        color: "#000",
      },
    },
    infoBoxPara: {
      color: "#64748B",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      padding: "0 20px",
    },
    InfoBox: {
      display: "flex",
      flexDirection: "row",
      borderRadius: "7px",
      border: "1px solid #ABB0BC",
      padding: "20px",
      margin: "20px 0",
    },
    radioText: {
      margin: "auto 0px",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
    },
    radioIcons: {
      marginRight: "20px",
    },
    stepOneRadioBox: {
      ...commonStyles.flexRow,
    },
    modalparafirst: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "30px 0",
    },
    modalHeading: {
      fontSize: "25px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
    },
    stepText: {
      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#64748B",
    },
    dotsRow: {
      ...commonStyles.flexRow,
    },
    dots: {
      height: "22px",
      width: "22px",
      borderRadius: "50%",
      backgroundColor: "#FFC629",
      margin: "0 10px",
    },
    modalBox: {
      ...commonStyles.modalBox,
    },
    modalBoxRest: {
      ...commonStyles.modalBox,
      width: "564px",
      maxHeight: "80vh",
      overflowY: "auto",
      padding: "30px 30px",
      // Custom scrollbar styles
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
        border: "3px solid transparent",
        backgroundClip: "content-box",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      "&::-webkit-scrollbar-corner": {
        backgroundColor: "#f2f2f2",
      },
      animation: "resetScroll 0.1s",
    },
    crossIconBtn: {
      position: "absolute",
      top: "10px",
      right: "15px",
    },
    startBtn: {
      ...commonStyles.button,
    },
    cardHeading: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      padding: "20px 0 10px 0",
    },
    cardPara: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#64748B",
      padding: "10px 0 20px 0",
    },
    regCard: {
      padding: "20px 40px 50px 40px",
      borderRadius: "11px",
    },
    cardIcon: {
      height: "60px",
      width: "60px",
      backgroundColor: "#FFC629",
      borderRadius: "50%",
    },
    paramid: {
      fontSize: "14px",
      fontWeight: 400,
      paddingLeft: "30px",
      lineHeight: "22px",
    },
    tickBox: {
      height: "27px",
      width: "27px",
      backgroundColor: "#FFC629",
      borderRadius: "50%",
    },
    firstBox: {
      ...commonStyles.flexDRS,
    },
    secondBox: {
      ...commonStyles.flexDRS,
      padding: "20px 0",
      justifyContent: "flex-start",
    },
    para: {
      ...commonStyles.text,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "20px 0",
    },
    numberTExt: {
      ...commonStyles.text,
      fontSize: "30px",
      padding: "20px 0",
    },
    mainContSocial: {
      padding: "40px",
    },
    heading: {
      ...commonStyles.text,
    },
  });

export default withStyles(styles)(SocialPhoneNumber);
// Customizable Area End
