// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { NewEveryreelSteps, PostTypes } from "./enums";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  setStep: (step: NewEveryreelSteps) => void;
  closeModal: () => void;
  setPostType: (postType: PostTypes) => void;
};

interface S {}

interface SS {
  navigation: any;
  stepType: NewEveryreelSteps;
}

export default class CreateEveryreelInitialController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  createNewEveryreel = configJSON.createNewEveryreel;
  socialScroll = configJSON.socialScroll;
  socialScrollText = configJSON.socialScrollText;
  interactiveFunnel = configJSON.interactiveFunnel;
  interactiveFunnelText = configJSON.interactiveFunnelText;
}
// Customizable Area End
