import React from "react";

// Customizable Area Start
import { Box, Button, Typography, TextField } from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import BusinessPhoneVerificationController, {
  configJSON,
} from "./BusinessPhoneVerificationController.web";
import EmailAccountLoginErrorBlock from "./EmailAccountRegistrationErrorBlock.web";

const styles = (theme: Theme) =>
  createStyles({
    Otpfield: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "15px",
      alignItems: "center",
      width: "100%",
      margin: "10px 0px",
    },

    otpFieldErrorContainer: {
      height: "38px"
    },

    OtpfieldError: {
      fontFamily: "sans-serif",
      color: "#f44336",
      fontSize: "12px",
    },
    nextHomePage: {
      textTransform: "capitalize" as "capitalize",
      height: "56",
      fontSize: "16px",
      backgroundColor: "#FFC629",
      marginTop: "25px",
      width: "100%",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#FFC629",
        color: "black",
      },
    },
    BackHomePage: {
      fontSize: "16px",
      fontWeight: 700,
      backgroundColor: "#0F172A",
      width: "100%",
      color: "#fff",
      marginTop: "45px",
      textTransform: "capitalize" as "capitalize",
      height: "56px",
      "&:hover": {
        backgroundColor: "#0F172A",
        color: "#fff",
      },
    },
    fontWeight: {
      fontWeight: 700,
      marginBottom: "30px",
      fontSize: "24px",
    },
    formContainer: {
      display: "flex",
      maxWidth: "350px",
      justifyContent: "center",
      alignItems: "center",
    },
    BelowCommunityFlex: {
      display: "flex",
      marginTop: "25px",
      alignItems: "center",
    },
    verificationNumber: {
      fontSize: "15px",
      fontWeight: 400,
    },
    otpInput: {
      width: "52px",
      height: "52px",
      marginRight: "4px",

      '& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFC629'
      },
    },
    verificationNumbers: {
      marginTop: "35px",
    },
  });

class BusinessPhoneVerification extends BusinessPhoneVerificationController {
  render() {
    const { classes }: any = this.props;
    const {
      mobileNumber,
      formError,
      otp,
      formGlobalError,
      currentStep,
    } = this.state;
    return (
      <Box className={classes.formContainer}>
        <Box>
          {formGlobalError[currentStep] && (
            <EmailAccountLoginErrorBlock
              errorMsg={formGlobalError[currentStep]}
            />
          )}
          <Typography variant="h5" gutterBottom className={classes.fontWeight}>
            {configJSON.verification}
          </Typography>

          <Typography
            className={classes.verificationNumber}
            gutterBottom
            variant="caption"
          >
            {configJSON.sixDigitCode}
            <br /> +{mobileNumber}
          </Typography>

          <Box className={classes.Otpfield}>
            {this.inputRefs.map((inputRef: any, index: number) => (
              <TextField
                size="small"
                className={classes.otpInput}
                data-test-id={`otpinput${index}`}
                autoFocus={index === 0}
                inputProps={{
                  maxLength: 1,
                  style: {
                    height: "15px",
                    textAlign: "center",
                    alignItems: "center",
                    width: "100%",
                    margin: "10px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                  },
                }}
                key={index}
                variant="outlined"
                value={otp[index] || ""}
                error={Boolean(formError.otp)}
                onChange={(event) => this.handleInputChange(event, index)}
                inputRef={inputRef}
                onKeyDown={(event) => this.handleKeyDown(event, index)}
              />
            ))}
          </Box>
          <div className={classes.otpFieldErrorContainer}>
            {formError && (
              <p className={classes.OtpfieldError}>{formError.otp}</p>
            )}
          </div>
          <Box>
            <Typography
              className={classes.verificationNumbers}
              style={{ fontSize: "16px", fontWeight: 400 }}
              gutterBottom
              variant="caption"
            >
              {configJSON.validCode}
            </Typography>
          </Box>
          <Box>
            <Button
              className={classes.nextHomePage}
              onClick={this.handleNextClick}
              data-test-id="nextButton"
              variant="contained"
            >
              {configJSON.next}
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              className={classes.BackHomePage}
              onClick={this.props.handleGoBackClick}
            >
              {configJSON.changeNumber}
            </Button>
          </Box>
          <Box className={classes.BelowCommunityFlex}>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.resendCode}
            >
              {configJSON.otpCode}{" "}
              <b>
                <a
                  href=""
                  onClick={(e) => this.resendOTP(e)}
                  style={{ textDecoration: "none", color: "#0F172A" }}
                  data-test-id="resendOtp"
                >
                  {configJSON.resendOtp}
                </a>
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
}
export { BusinessPhoneVerification };
export default withStyles(styles)(BusinessPhoneVerification);
// Customizable Area End
