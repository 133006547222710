export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const firststepimg1 = require("../assets/firststepimg1.png");
export const firststepimgwomen = require("../assets/image_woman_step1.png");
export const groupStarbar = require("../assets/group_Starbar.png");
export const groupStarbar2 = require("../assets/step2.png");
export const groupStarbar3 = require("../assets/step3.png");
export const groupStarbar4 = require("../assets/step4.png");
export const titleImg1 = require("../assets/textlabel_Step 1.png");
export const titleImg2 = require("../assets/textlabel_Step 02.png");
export const titleImg3 = require("../assets/textLabel_step3.png");
export const titleImg4 = require("../assets/textlabel_Step 4.png");
export const flags = require("../assets/Flags.png");
export const everybrandLogo = require("../assets/everybrand_logo.svg");
export const womanPicture = require("../assets/woman.png");
export const everybrandIcon = require("../assets/everybrand_icon.svg");
export const backArrow = require("../assets/Arro_Back.png");
export const lockIconSignIn = require("../assets/lock.png");




