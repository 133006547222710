// Customizable Area Start
import React from "react";
import {
  Button,

  Typography,

  Box,

  Grid,

  Divider,
  Modal,
  IconButton,
  Snackbar,
  
} from "@material-ui/core";

import {
  withStyles,
  createStyles,
  Theme,

} from "@material-ui/core/styles";
import { womanImage, radioButtonOn, radioButtonOff, CloseIcon } from "./assets";

import WaitListController, { stripePromise } from "./WaitListController.web";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PaymentIcon from '@material-ui/icons/Payment';
import { Elements } from "@stripe/react-stripe-js";
import MuiAlert from "@material-ui/lab/Alert";
import StripeFoeWaitListPayment from "./StripeForWaitListPayment.web";




export class WaitListSubscription extends WaitListController {
  render() {
    const { classes }: any = this.props;
    
   

   
    console.log(this.state.clientSecretData,"clientSecret")
    return (
      <>
        {/* <Container maxWidth="xl" className={classes.root}> */}
        <Grid container>
        <Snackbar
                        open={this.state.isShowAlert}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            severity={this.state.errorAlertMessage ? "error" : "success"}
                        >
                            {this.state.showSucess}

                        </MuiAlert>

                    </Snackbar>
        {this.props.subscriptionListData.map((item:any)=>{
              return(
                <>
                 <Grid
            item
            container
            md={6}
            justifyContent="center"

          >
           
            <div >

              <Box style={{ padding: "0", margin: "48px 57px 0 65px" }}>

                <Box className={classes.buttonsBox}>

                  <Button
                    data-test-id="annualButton"
                    onClick={this.pricingChangeFunction}

                    className={
                      !this.state.pricingRadioButton
                        ? classes.activeSelecetBtn
                        : classes.btnsSelect
                    }
                    startIcon={
                      !this.state.pricingRadioButton ? (
                        <img src={radioButtonOn} alt="on" />
                      ) : (
                        <img src={radioButtonOff} alt="off" />
                      )
                    }

                  >
                    Annual Pricing
                  </Button>
                  <Divider orientation="vertical" className={classes.btnDivider} />{" "}
                  <Button
                    data-test-id="monthlyButton"
                    onClick={this.pricingChangeFunction}
                    className={
                      this.state.pricingRadioButton
                        ? classes.activeSelecetBtn
                        : classes.btnsSelect
                    }
                    startIcon={
                      this.state.pricingRadioButton ? (
                        <img src={radioButtonOn} alt="on" />
                      ) : (
                        <img src={radioButtonOff} alt="off" />
                      )
                    }

                  >
                    Monthly Pricing
                  </Button>

                </Box>
                {this.state.pricingRadioButton ? <Box style={{
                  display: "flex",
                  width: "440px",
                  minHeight: "469px",
                  padding: "40px",
                  flexDirection: "column",


                  gap: "40px",

                  borderRadius: "8px 8px 32px 8px",
                  border: "1px solid #CBD5E1",

                  background: "#FFF"
                }}>


                  <h4 className={classes.indPlanRates}>
                    <div className={classes.price}>
                      <span className={classes.curSign}>$</span>
                      <span>{item.attributes.plans[0].price}</span>
                    </div>
                    <div className={classes.currencyType}>
                      <span className={classes.currencyTypeSpanFirst}>{item.attributes.plans[0].currency}</span>
                      <span className={classes.currencyTypeSpanLast}>/{item.attributes.plans[0].interval}</span>
                    </div>
                  </h4>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography className={classes.fontsOfBiled}>Month-to month</Typography>
                    <Typography className={classes.fontsOfBiled}>Billed Monthly</Typography>

                  </div>
                  <div>
                    <Typography className={classes.fontsOfBiled}>
                      <span className={classes.bolledFonts}>Fill spots</span>
                       within minutes of a cancellation.
                      </Typography>
                    <Typography className={classes.fontsOfBiled}>
                      <span className={classes.bolledFonts}>
                        Add additional renevue</span> to your business
                        </Typography>

                  </div>

                  <Button
                    variant="contained"
                    className={classes.addFolderButton}
                    type="submit"
                    fullWidth
                    disableElevation
                    data-test-id="addPayemntScreen"
                    onClick={this.paymentModal}
                  >
                    Add New Folder
                  </Button>

                  <Button
                    variant="contained"
                    className={classes.backButton}
                    fullWidth
                    disableElevation
                    onClick={this.props.addFolderModalFunction}
                    type="submit"
                  >
                    Back
                  </Button>
                </Box> : <Box
                  data-test-id="monthlyBox"
                  style={{
                    display: "flex",
                    width: "440px",
                    minHeight: "469px",
                    padding: "40px",
                    flexDirection: "column",


                    gap: "40px",

                    borderRadius: "8px 8px 32px 8px",
                    border: "1px solid #CBD5E1",

                    background: "#FFF"
                  }}>


                  <h4 className={classes.indPlanRates}>
                    <div className={classes.price}>
                      <span className={classes.curSign}>$</span>
                      <span>{item.attributes.plans[1].price}</span>
                    </div>
                    <div className={classes.currencyType}>
                      <span className={classes.currencyTypeSpanFirst}>{item.attributes.plans[1].currency}</span>
                      <span data-test-id="waitlistPlanInterval" className={classes.currencyTypeSpanLast}>/{"Month"}</span>
                    </div>
                  </h4>
                  <div className="agreementDiv" style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography id="aggreement" className={classes.fontsOfBiled}>12 Month Agreement</Typography>
                    <Typography className={classes.fontsOfBiled}>Billed Annually</Typography>

                  </div>
                  <div data-test-id="featuresForSubscription">
                    <Typography id="first" className={classes.fontsOfBiled}><span className={classes.bolledFonts}>Fill spots</span> within minutes of a cancellation.</Typography>
                    <Typography id="second" className={classes.fontsOfBiled}><span className={classes.bolledFonts}>Add additional renevue</span> to your business</Typography>

                  </div>

                  <Button
                    variant="contained"
                    id="addNewFolder"
                    className={classes.addFolderButton}
                    fullWidth
                    disableElevation
                    type="submit"
                    onClick={this.paymentModal}
                    
                  >
                    Add New Folder
                  </Button>

                  <Button
                    variant="contained"
                    id="back"
                    className={classes.backButton}
                    fullWidth
                    disableElevation
                    onClick={this.props.addFolderModalFunction}
                    type="submit"
                  >
                    Back
                  </Button>
                </Box>}



              </Box>
            </div>
          </Grid>
          <Grid
            item
            container
            md={6}
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.sidebar}
          >
            <Grid item>

              <Typography variant="h4" className={classes.heading}>
                98% Open Rates
              </Typography>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <FiberManualRecordIcon
                  className={classes.bulletSmall
                  }
                />
                <Typography className={classes.subHeading}>

                  Stop Fumbling to fill empty spots
                </Typography>
              </Box>
              <br />
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <FiberManualRecordIcon
                  className={classes.bulletSmall
                  }
                />
                <Typography className={classes.subHeading}>
                  Stop wasting time leaving voicemails, and <br />
                  waiting hours for callbacks
                </Typography>
              </Box>
              <br />
              {this.state.pricingRadioButton ? <Box style={{ display: "flex", flexDirection: "row" }}>

                <FiberManualRecordIcon
                  className={classes.bulletSmall
                  }
                />
                <Typography className={classes.subHeading}>
                  Priced to ensure even minimal use pays for <br /> itself each month

                </Typography>
              </Box> : <></>
              }
            </Grid>
            <Grid item>
              <img src={womanImage} alt="woman picture" />
            </Grid>
          </Grid>
          <Modal
          open={this.state.openPaymentModal}
          onClose={this.paymentModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="templateEditModal"
        >
          <Box className={classes.modalBox}>

            <>

              <Typography className={classes.modalHeading}>
                Add Your Payment Information
              </Typography>
              <IconButton
                onClick={this.paymentModal}
                className={classes.crossIcon}
              >
                <img src={CloseIcon} alt="" />
              </IconButton>
              <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div>
                  <Typography className={classes.typographyFonts}>Plan Standard</Typography>
                </div>
                <div>
                <Typography
                data-test-id="planPrice"
                variant="h6"
                className={classes.boldText}
              >
               $
                <span className={classes.priceText}>{this.state.pricingRadioButton ? item.attributes.plans[0].price:item.attributes.plans[1].price}</span>
                /Month
              </Typography>
                 
                </div>
              </Box>
              <Box style={{ display: "flex",paddingRight:"240.5px" }}>
              <img src={radioButtonOn} alt="" />
                <PaymentIcon/>
                <div>
                  <Typography className={classes.newCardFonts}>New Card</Typography>
                </div>
              </Box>
             

               <Elements stripe={stripePromise} options={{mode:"payment",amount:100,currency:"usd",setup_future_usage:"off_session",payment_method_types: ['card']}}>
            <StripeFoeWaitListPayment
            data-test-id="stripeForm"
              handleBackButton={this.paymentModal}
              handleSubmit={this.handleSubmit}
            />
          </Elements>
            </>


          </Box>
        </Modal>
                </>
              )
            })}
         
        </Grid>
        
      </>
    );
  }
}


const styles = (theme: Theme) =>
  createStyles({
    boldText: {
      color: "#000",

textAlign: "center",
fontFamily: "Inter",
fontSize: "20px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "24px", /* 220% */
letterSpacing: "-0.36px",
    },
  
    priceText: {
      color: "#000",

      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px", /* 220% */
      letterSpacing: "-0.36px",
    },
    newCardFonts: {
      color: "#0F172A",
      /* text-base/font-normal */
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */

    },
    typographyFonts: {
      color: "#0F172A",

      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px", /* 133.333% */
      letterSpacing: "-0.12px",
    },
    modalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "40px 38px",
      width: "100%",
      maxWidth: "440px",
      borderRadius: "0px 0px 40px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      display: "flex",


      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "25px"

    },
    crossIcon: {
      top: "10px",
      right: "10px",
      position: "absolute",
    },
    modalHeading: {
      fontFamily: "Inter",

      fontStyle: "normal",
      color: "#000",
      textAlign: "center",
      fontSize: "24px",
      fontWeight: 700, // Note: Numeric values for font weight don't need quotes
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
    fontsOfBiled: {
      color: "#334155",

      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    bolledFonts: {
      color: "#334155",

      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
    buttonsBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "20px 0",
    },
    btnDivider: {
      height: "auto",
      alignSelf: "stretch",
      border: "1px solid #C0C4CD",
      margin: "0 15px",
    },

    btnsSelect: {
      color: "#0F172A",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      padding: "20px",
    },
    activeSelecetBtn: {
      color: "#0F172A",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      padding: "20px",
      borderRadius: "5px",
      backgroundColor: "#D9D9D9",
      "&:hover": {
        backgroundColor: "#D9D9D9",
      },
    },
    bulletSmall: {
      fontSize: "12px",
      margin: "6px 5px 0 0",
      color: "#000",
    },


    backButton: {
      color: "#fff",
      width: "360px",
      backgroundColor: "#0F172A",
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      textTransform: "none",
      padding: theme.spacing(2, 0),
      "&:hover": {
        backgroundColor: "#0F172A",
      },
    },
    addFolderButton: {
      color: "#000",
      width: "360px",
      backgroundColor: "#FFC629",
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      textTransform: "none",
      padding: theme.spacing(2, 0),
      "&:hover": {
        backgroundColor: "#FFC629",
      },
    },
    fullHeight: {
      height: "100vh",
    },
    sidebar: {
      gap: theme.spacing(5),
      backgroundColor: "#FFC629",
      borderBottomLeftRadius: theme.spacing(8),
    },
    heading: {
      color: "#000",
      marginTop: "79px",
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "44px", /* 122.222% */
      letterSpacing: "-0.36px",
      marginBottom: "35px"

    },
    subHeading: {

      color: "#334155",

      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px"
    },
    indPlanRates: {
      display: "flex",
      alignItems: "center",
      maxWidth: "100%",
    },
    price: {
      color: "#0F172A",

      fontFamily: "Inter",
      fontSize: "48px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",/* 66.667% */
      letterSpacing: " -0.12px",
      display: "inline-flex",
      alignItems: "center",
    },
    curSign: {
      color: "#0F172A",

      fontFamily: "Inter",
      fontSize: "48px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",/* 66.667% */
      letterSpacing: " -0.12px"
    },
    currencyType: {
      display: "inline-flex",
      flexDirection: "column",
    },
    currencyTypeSpanFirst: {

      textTransform: "capitalize",
      color: "#0F172A",

      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "6px", /* 228.571% */
      letterSpacing: "-0.12px"
    },
    currencyTypeSpanLast: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,

      letterSpacing: "-0.12px",

      textTransform: "capitalize"
    },

  });

export default withStyles(styles)(WaitListSubscription);
// Customizable Area End
