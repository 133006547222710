// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import { HeartIcon, ChatBubbleLargeIcon, StarIcon } from './assets';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DashboardController from './DashboardController.web';

class Reactions extends DashboardController {

  render() {
    const {
      reactionCount
    } = this.state
    return (
      <Paper square elevation={0}>
      <Grid
        container
        justifyContent="center"
        style={{padding: "8px",
          gap: "40px"}}
      >
        <Grid item>
          <img src={HeartIcon} 
          style={{width: '45px',
            height: '40px'}}
          />
          <Typography
            data-test-id="likeCount"
            variant="subtitle2"
            align="center"
            style={{color: '#FF0000',
              fontSize:"14px",
              fontFamily:"Inter",
              fontStyle:"normal",
              fontWeight: 600}}
          >
            {reactionCount.likes}
          </Typography>
        </Grid>
        <Grid item>
          <img src={ChatBubbleLargeIcon}
          style={{
            width: '45px',
            height: '40px'
          }}
          />
          <Typography
            data-test-id="commentCount"
            variant="subtitle2"
            align="center"
            style={{color: '#FF0000',
              fontSize:"14px",
              fontStyle:"normal",
              fontFamily:"Inter",
              fontWeight: 600}}
          >
            {reactionCount.comments}
          </Typography>
        </Grid>
        <Grid item>
          <img src={StarIcon} 
          style={{
            height: '40px',
            width: '45px'
          }}
          />
          <Typography
            data-test-id="reviewCount"
            variant="subtitle2"
            align="center"
            style={{color: '#FF0000',
              fontStyle:"normal",
              fontSize:"14px",
              fontWeight: 600,
              fontFamily:"Inter"
            }}
          >
            {reactionCount.reviews}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
    );
  }
}
export { Reactions }
// Customizable Area End
