// Customizable Area Start
import { isValidPhoneNumber } from "libphonenumber-js";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import * as yup from "yup";
import { ContactsListViews, InviteFriendsStepType } from "./enums";
import { Contact } from "./InviteFriendsController.web";
import { getToken } from "../../../components/src/AuthService";

export const configJSON = require("./config");

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  fullPhoneNumber: string;
}

interface FormErrors extends Partial<FormValues> {}

export interface Props {
  currentStep?: InviteFriendsStepType | ContactsListViews;
  contactData?: {
    id: number,
    firstName: string,
    lastName: string,
    email?: string,
    fullPhoneNumber?: string,
  };
  addContact?: (contact: Contact) => void;
  setStep: (params?: any) => void;
  classes?: any;
  hideBackBtn?: boolean
}

interface S {
  apiError: string;
  apiSuccess: string;
}

interface SS {
  navigation: any;
}

export default class AddContactModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiForgotPasswordCallId: string = "";
  apiResetPasswordCallId: string = "";

  labelEverybrand = configJSON.labelEverybrand;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      apiError: "",
      apiSuccess: "",
    };
  }

  addContactApiCallId: string = "";

  createContactDescription = configJSON.createContactDescription;
  addContactModalTitle = configJSON.addContactModalTitle;
  createContactTitle = configJSON.createContactTitle;
  firstName = configJSON.firstName;
  lastName = configJSON.lastName;
  email = configJSON.email;
  mobileNumber = configJSON.mobileNumber;

  showModalTitle = () => {
    let title = this.props.currentStep === InviteFriendsStepType.ADD_CONTACT ||
      this.props.currentStep === ContactsListViews.ADD_CONTACT
        ? this.addContactModalTitle
        : this.createContactTitle    

    if(this.props.contactData) {
      title = "Edit Contact"
    }

    return title;
  }

  getInitialFormValues = () => {
    return {
      firstName: this.props.contactData?.firstName || "",
      lastName: this.props.contactData?.lastName || "",
      email: this.props.contactData?.email || "",
      fullPhoneNumber: this.props.contactData?.fullPhoneNumber || "",
    }
  }

  validationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup.string().required("Email is required."),
    fullPhoneNumber: yup.string().required("Phone number is required."),
  });

  onCloseSnackbar = () => {
    const {apiError} = this.state;

    if (!apiError) {      
      this.props.setStep();
    }
  };

  validate = (values: FormValues) => {
    const errors: FormErrors = {};

    if (!values.firstName) {
      errors.firstName = "First name is required.";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required.";
    }
    if (
      values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.fullPhoneNumber) {
      errors.fullPhoneNumber = "Phone number is required.";
    } else if (!isValidPhoneNumber(values.fullPhoneNumber)) {
      errors.fullPhoneNumber = "Mobile number is not valid";
    }
    return errors;
  };

  async createContact(values: FormValues, contactId?: number) {
    this.setState({ apiSuccess: "", apiError: "" });

    const contact = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      full_phone_number: values.fullPhoneNumber,
    };

    const httpBody = {
      contact,
    };

    const headerData = {
      "Content-Type": configJSON.dashboarContentType,
      token: getToken(),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addContactApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.props?.contactData ? configJSON.contactsEndPoint + `/${contactId}` : configJSON.contactsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.props?.contactData ? configJSON.httpPutMethod : configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleAddContactAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.error?.length) {
      this.setState({ apiError: Array.isArray(responseJson.error) ? responseJson.error[0] : responseJson.error });
      setTimeout(() => {
        this.setState({ apiError: "" });
      }, 3000)
    }

    if (responseJson?.data) {
      this.setState({ apiSuccess: this.props.contactData ? "Contact updated" : "Contact is added successfully" });

      if(this.props.addContact) {
        this.props.addContact(responseJson.data);
      }
    }
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.addContactApiCallId) {
      this.handleAddContactAPIResponse(message);
    }
  }
}
// Customizable Area End
