// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { configJSON } from './Settings2Controller';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { CustomSwitch } from '../../../components/src/CustomSwitch.web';
import CustomizePostingFeedController,{Props} from './CustomizePostingFeedController.web';
import MuiAlert from "@material-ui/lab/Alert"
import Snackbar from '@material-ui/core/Snackbar';
import { EverybrandIcon } from './assets';


const useStyles = ((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5, 5, 5, 3),
      },
    },
    pageHeading: {
      color: "#000",

      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",/* 133.333% */
      letterSpacing: "-0.12px",
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
      },
    },
    typography: {
      fontSize: '14px',
      color: '#0F172A',
    },
    contentTypography: {
      color: "#0F172A",

      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
    fontBold: {
      fontWeight: 700,
    },
    divider: {
      height: '1px',
      border: 'none',
      backgroundColor: '#DADADA',
      margin: "33px 72px 32px 62px",
    },
    textField: {
      backgroundColor: '#FFFFFF',
      width: "150.759px",
      '& fieldset': {
        border: '1px solid #DADADA',
        borderRadius: '6px',
      },
      "& .Mui-focused fieldset": {
        borderColor: "#FFCA29 !important",
      }
    },
    saveButton: {
      color: '#0F172A',
      backgroundColor: '#FFC629',
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      textTransform: 'none',

      display: "flex",
      width: "358px",
      height: "56px",
      padding: "16px 163px 16px 156px",
      alignItems: "center",
      flexShrink: 0,
      '&:hover': {
        backgroundColor: '#FFC629',
      },
    },
    marginBottom_40: {
      marginBottom: '40px',
    },
    formContainer: {
      padding: "32px 56px 22.29px 61px",
    },
    input: {
      '&::placeholder': {
        color: '#0F172A',
        opacity: '0.50',
      },
    },
    formHelperText: {
      position: 'absolute',
      bottom: '-40px',
    },
    switchContainer: {
      padding: "86px 89px 57px 61px",
    },
  })
);
class CustomizePostingFeed extends CustomizePostingFeedController{
  constructor(props: Props) {
    super(props);
  }

   switchOnOff=(postEnabled:any)=>{
       return postEnabled? configJSON.switchOn
       : configJSON.switchOff
   }
  render() {
   
    const { classes } = this.props;
    const {
      color_option_1 ,
      color_option_2 ,
      comments_enabled = false,
      post_enabled = false,
      review_enabled = false,
      approve_audience_post_enabled = false,
      photo_video_enabled = false,
    } = this.state.dashboardData;
    return (
      <>
    <Snackbar
            open={this.state.isShowAlert}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={"success"}
            >
              {this.state.showSucess}
            </MuiAlert>
          </Snackbar>
      <div className={classes.root}>
        
       
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={3}
          style={{ margin: "66px 80.47px 33px 55px" }}
        >
          <Typography className={classes.pageHeading} data-test-id="pageHeading">
            {configJSON.customizePostingFeed}
          </Typography>
          <img src={EverybrandIcon} />
        </Box>
        <hr className={classes.divider} />
       
        <Formik
          enableReinitialize={true}
          initialValues={{
            color_option_1:color_option_1 || "#FFC629",
            color_option_2:color_option_2 || "#FFF",
            comments_enabled,
            post_enabled,
            review_enabled,
            approve_audience_post_enabled,
            photo_video_enabled

          }}
          validationSchema={this.validationSchemaCustomisePostingFeed}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting }) => {
            this.handleSubmit(values);
            setSubmitting(false);
            
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="column"
                className={classes.formContainer}
              >
                <Box mb={6}>
                  <Typography
                    className={clsx(classes.typography, classes.fontBold)}
                  >
                    {configJSON.appearance}
                  </Typography>
                </Box>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.marginBottom_40}
                >
                  <Grid item>
                    <Typography variant="body2" className={classes.contentTypography}>
                      {configJSON.postThemeColor1}
                    </Typography>
                  </Grid>
                  <Box display={"flex"} style={{ gap: "22.48px" }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      data-testId="color_option_1"
                      name="color_option_1"
                      placeholder={configJSON.postThemeColorPlaceholder}
                      fullWidth
                      className={classes.textField}
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                      
                      value={this.state.dashboardData.color_option_1}
                      onChange={(e)=>this.setState({dashboardData:{...this.state.dashboardData,color_option_1:e.target.value}})}
                      onBlur={handleBlur}
                      error={
                        touched.color_option_1 && Boolean(errors.color_option_1)
                      }
                      helperText={touched.color_option_1 && errors.color_option_1}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                   <div style={{width: "150.759px",background:this.state.dashboardData.color_option_1 || "#FFC629", border: '1px solid #DADADA',
        borderRadius: '6px',}}></div>

                  </Box>
                  <>

                  </>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.marginBottom_40}

                >
                  <Typography variant="body2" className={classes.contentTypography}>
                    {configJSON.postThemeColor2}
                  </Typography>
                  <Box display={"flex"} style={{ gap: "22.48px" }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="color_option_2"
                      data-testId="color_option_2"
                      placeholder={configJSON.postThemeColorSecondPlaceholder}
                      fullWidth
                      className={classes.textField}
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                      value={this.state.dashboardData.color_option_2}
                      onChange={(e)=>this.setState({dashboardData:{...this.state.dashboardData,color_option_2:e.target.value}})}
                      onBlur={handleBlur}
                      error={
                        touched.color_option_2 && Boolean(errors.color_option_2)
                      }
                      helperText={touched.color_option_2 && errors.color_option_2}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                      <div style={{width: "150.759px",background:this.state.dashboardData.color_option_2 || "#FFF", border: '1px solid #DADADA',
        borderRadius: '6px',  }}></div>
                  </Box>



                </Grid>
              </Grid>
              <hr className={classes.divider} />
              <Grid container spacing={5} justifyContent="flex-end">
                <Grid style={{ padding:"32px 56px 69px 0" }} item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    type="submit"
                    className={classes.saveButton}
                    data-test-id="saveButton"
                    
                  >
                    {configJSON.save}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
  
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
       
        >
          <Grid item style={{ paddingLeft: "62px", paddingRight: "89px", paddingBottom: "57px" }}>
            <Typography variant="body2" className={classes.contentTypography}>
              {configJSON.switchOne}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "57px" }}>
            <FormControlLabel
              style={{ marginRight: "89px" }}
              data-testId="post_enabled"
              control={
                <CustomSwitch
                  color="primary"
                  name="post_enabled"
                checked={this.state.dashboardData.post_enabled || false}
                onChange={(e:any) => this.handleSwitchChange("post_enabled", e.target.checked)}
                />
              }
              label={
                this.switchOnOff(this.state.dashboardData.post_enabled)
              
              }
            />
          </Grid>

        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
        
        >
          <Grid item style={{ paddingLeft: "62px", paddingRight: "89px", paddingBottom: "57px" }}>
            <Typography variant="body2" className={classes.contentTypography}>
              {configJSON.switchTwo}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "57px" }}>
            <FormControlLabel
              style={{ marginRight: "89px" }}
              data-testId="approve_audience_post_enabled"
              control={
                <CustomSwitch
                  color="primary"
                  name="approve_audience_post_enabled"
                checked={this.state.dashboardData.approve_audience_post_enabled || false}              
                onChange={(e:any) => this.handleSwitchChange("approve_audience_post_enabled", e.target.checked)}
                
                />
              }
              label={
                this.switchOnOff( this.state.dashboardData.approve_audience_post_enabled)

                
              }
            />
          </Grid>

        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
        
        >
          <Grid item style={{ paddingLeft: "62px", paddingRight: "89px", paddingBottom: "57px" }}>
            <Typography variant="body2" className={classes.contentTypography}>
              {configJSON.switchThree}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "57px" }}>
            <FormControlLabel
              style={{ marginRight: "89px" }}
              data-testId="review_enabled"
              control={
                <CustomSwitch
                  color="primary"
                  name="review_enabled"
                  checked={this.state.dashboardData.review_enabled || false}
                onChange={(e:any) => this.handleSwitchChange("review_enabled", e.target.checked)}
                  
                  
                />
              }
              label={
                this.switchOnOff(this.state.dashboardData.review_enabled)

              
              }
            />
          </Grid>

        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
        
        >
          <Grid item style={{ paddingLeft: "62px", paddingRight: "89px", paddingBottom: "57px" }}>
            <Typography variant="body2" className={classes.contentTypography}>
              {configJSON.switchFour}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "57px" }}>
            <FormControlLabel
              style={{ marginRight: "89px" }}
              data-testId="comments_enabled"
              control={
                <CustomSwitch
                  color="primary"
                  name="comments_enabled"
                  checked={this.state.dashboardData.comments_enabled || false}
                onChange={(e:any) => this.handleSwitchChange("comments_enabled", e.target.checked)}
                 
                  
                />
              }
              label={
                this.switchOnOff(this.state.dashboardData.comments_enabled)

             
              }
            />
          </Grid>

        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
       
        >
          <Grid item style={{ paddingLeft: "62px", paddingRight: "89px", paddingBottom: "57px" }}>
            <Typography variant="body2" className={classes.contentTypography}>
              {configJSON.switchFive}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "57px" }}>
            <FormControlLabel
              style={{ marginRight: "89px" }}
              data-testId="photo_video_enabled"
              control={
                <CustomSwitch
                  color="primary"
                  name="photo_video_enabled"
                  checked={this.state.dashboardData.photo_video_enabled || false}
                onChange={(e:any) => this.handleSwitchChange("photo_video_enabled", e.target.checked)}
                  
                />
              }
              label={
                this.switchOnOff(this.state.dashboardData.photo_video_enabled)

              }
            />
          </Grid>

        </Grid>

      </div>
      </>
    );
  }
}
export default withStyles(useStyles)(CustomizePostingFeed);
export { CustomizePostingFeed }
// Customizable Area End
