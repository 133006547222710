// Customizable Area Start
import React, { Component } from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import clsx from "clsx";

const MuiButton = styled(Button)({
  width: "100%",
  textTransform: "none",
  fontFamily: "Inter, 'sans-serif'",
  minWidth: "132px",
  padding: "8px 16px",
  fontSize: "16px",
  fontWeight: 700,
  borderRadius: "8px",
  border: "1px solid transparent",
  lineHeight: "32px",
  "&.primary": {
    backgroundColor: "#FFC629",
    borderColor: "#FFC629",
    color: "#000",
    "&:hover": {
      backgroundColor: "#eebe40",
      borderColor: "#eebe40",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFD77E",
      color: "#666",
      borderColor: "#FFD77E",
    },
  },
  "&.secondary": {
    backgroundColor: "#000",
    borderColor: "#000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#222",
      borderColor: "#222",
    },
    "&.Mui-disabled": {
      backgroundColor: "#666",
      color: "#ccc",
      borderColor: "#666",
    },
  },
  "&.primary-outlined": {
    borderColor: "#FFC629",
    color: "#000",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    "&.Mui-disabled": {
      borderColor: "#FFD77E",
      color: "#666",
      backgroundColor: "transparent",
    },
  },
  "&.secondary-outlined": {
    borderColor: "#000",
    color: "#000",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    "&.Mui-disabled": {
      borderColor: "#666",
      color: "#666",
    },
  },
});

interface ICustomButton extends ButtonProps {
  children: React.ReactNode;
  btnType?: "primary" | "secondary" | "primary-outlined" | "secondary-outlined";
}

export class CustomButton extends Component<ICustomButton> {
  static defaultProps: Partial<ICustomButton> = {
    btnType: "primary",
  };

  render() {
    const { children, btnType, className, hidden, ...rest } = this.props;
    return hidden ? null : (
      <MuiButton className={clsx(btnType, className)} {...rest}>
        {children}
      </MuiButton>
    );
  }
}

export default CustomButton;
// Customizable Area End
