// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getToken } from "../../../components/src/AuthService";
import { NewPostCreationSteps } from "./enums";
export const configJSON = require("./config");

interface IPayloadConfig {
  payloadEnum: string;
  payload: {
    postType: NewPostCreationSteps;
  };
}

export interface Props {
  id: string;
  navigation: any;
}

interface S {
  isLoading: boolean;
  alertType: "error" | "success" | undefined;
  alertMessage: string;
}

interface SS {
  navigation: any;
}

export default class InstagramAuthRedirectController extends BlockComponent<
  Props,
  S,
  SS
> {
  instagramCodeSendApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      alertMessage: "",
      alertType: undefined,
    };
  }

  async componentDidMount() {
    this.handleInstagramAuthRedirection();
  }

  handleErrorAlert = (error: string) => {
    this.setState({ alertMessage: error, alertType: "error" });
    setTimeout(() => {
      this.redirectToPage("Dashboard");
    }, 3000);
  };

  handleInstagramAuthRedirection = () => {
    this.setState({ isLoading: true });
    const params = new URLSearchParams(window.location.search);
    const instagramAuthCode = params.get(configJSON.INSTAGRAM_AUTH_CODE_PARAM);
    const instagramAuthError = params.get(
      configJSON.INSTAGRAM_AUTH_ERROR_PARAM
    );
    if (instagramAuthCode) {
      this.sendInstagramAuthCodeApi(instagramAuthCode);
    } else if (instagramAuthError) {
      this.handleErrorAlert(instagramAuthError);
    }
  };

  sendInstagramAuthCodeApi = (instagramAuthCode: string) => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken(),
    };

    const endPoint = `${configJSON.instagramCodeApiEndPoint}?code=${instagramAuthCode}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.instagramCodeSendApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.instagramCodeSendApiCallId) {
      this.handleInstagramCodeSendApiResponse(message);
    }
  }

  redirectToPage = (routeName: string, payloadConfig?: IPayloadConfig) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), routeName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (payloadConfig) {
      const { payload, payloadEnum } = payloadConfig;
      const payloadMsg = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      payloadMsg.addData(payloadEnum, payload);
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMsg);
    }
    this.send(msg);
  };

  redirectToNewPost = () => {
    const payload = {
      postType: NewPostCreationSteps.INSTAGRAM_LIBRARY,
    };
    const payloadConfig = {
      payloadEnum: getName(MessageEnum.NewSocialPostMessage),
      payload,
    };
    this.redirectToPage("NewPost", payloadConfig);
  };

  handleInstagramCodeSendApiResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.error) {
      this.setState({
        alertMessage: configJSON.instagramAccountConnectedMessage,
        alertType: "success",
      });
      setTimeout(() => {
        this.redirectToNewPost();
      }, 2000);
    } else {
      this.handleErrorAlert(responseJson.error || "Something went wrong");
    }
  };
}

// Customizable Area End
