// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { APIRequest, Post, DashboardAccount, IBrandDetails } from "./interfaces";
import { Message } from "../../../framework/src/Message";
import { getToken, setToken } from "../../../components/src/AuthService";
import { getStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";

export const configJSON = require("./config.js");

interface BrandAttributesHome {
  activated: boolean;
  country_code: string;
  email: string;
  full_name: string | null;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  is_first_login: boolean;
  profile_pic: {
    url: string;
  } | null;
  cover_photo: {
    url: string;
  } | null;
  follows: {
    followers: string;
    followings: string;
  };
  subscription_paused: boolean;
}
interface BrandHome {
  id: string;
  type: string;
  attributes: BrandAttributesHome;
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  hidePostApicall?: (values: any) => void;
  banUserApiCall: (values: any) => void | null;
  postCommentApiCall: (values: any) => void;
  showAlert?: boolean;
  showSuccess?: string;
  errorAlertMessage?: boolean;
  dashboardApicall?: Function;
  deleteCommentApiCall?: (id: number) => void;
  archiveCommentApiCall?: (values: any) => void;
  revertArchiveCommentApiCall?: (id: number) => void;
  achiveId?: number;
  disableClass?: boolean;
}
interface S {
  isLoading: boolean;
  account?: DashboardAccount;
  posts: Post[];
  top6: IBrandDetails[];
  showSucess: string;
  isShowAlert: boolean;
  errorAlertMessage: boolean;
  achiveId: any;
  scrlltoPost: number;
  notificationData: any;
  brandsIFollowHome: BrandHome[];
  suggestedBrandsHome: BrandHome[];
  euHomePageNo: number,
  euHomeTotalPages: number,
  isEUHomePostLoading: boolean
  videoRefsHome: React.RefObject<HTMLVideoElement>[];
}
interface SS {
  id: any;
}

export default class EuDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  dashboardApiCallId: string = "";
  tokenUpdateApiCallId: string = "";
  addLikePostApiCallId: string = "";
  euHomeBrandsApiCallId: string = "";
  followBrandApiCallId: string = "";
  observerHome: IntersectionObserver | null = null;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ConnectTOSidebar),
      getName(MessageEnum.DashboardRedirection)
    ];

    this.state = {
      isLoading: false,
      account: undefined,
      posts: [],
      top6: [],
      showSucess: "",
      isShowAlert: false,
      errorAlertMessage: false,
      achiveId: 0,
      scrlltoPost: 0,
      notificationData: {},
      brandsIFollowHome: [],
      suggestedBrandsHome: [],
      euHomePageNo: 1,
      euHomeTotalPages: 0,
      isEUHomePostLoading: true,
      videoRefsHome: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getDashboardData();
    this.getSuggestedAndFollowBrandHome();
  }

  componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): void {
    if (
      this.state.account !== prevState.account &&
      window.localStorage.getItem("rememberMe")
    ) {
      this.updateToken();
    }

    if(prevState.euHomePageNo !== this.state.euHomePageNo && this.state.euHomePageNo <= this.state.euHomeTotalPages) {
      this.getDashboardData()
    }

    if (prevState.posts !== this.state.posts) {
      this.initIntersectionObvEuHome()
      this.setState({videoRefsHome : this.state.posts.map(() => createRef<HTMLVideoElement>())});
    }

  }

  initIntersectionObvEuHome () {
    if (this.observerHome) {
      this.observerHome.disconnect();
    }
    
    setTimeout(() => {
      this.observerHome = new IntersectionObserver(
        (entriesPost) => {
          entriesPost.forEach((entryPost) => {
            const videoPost = entryPost.target as HTMLVideoElement;
            if (entryPost.isIntersecting) {
              videoPost.play();
            } else {
              videoPost.pause();
            }
          });
        },
        { threshold: 0.5 }
      );
      
      this.state.videoRefsHome.forEach((refVid) => {
        if (refVid.current) {
          this.observerHome?.observe(refVid.current);
        }
      });
    }, 0); 
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.dashboardApiCallId && responseJson) {
      // const { posts } = this.state
      const { posts: { data },  pagination } = responseJson
      const {  total_pages } = pagination
      this.setState(prevState =>({
        posts: [...prevState.posts, ...data],
        isEUHomePostLoading: false,
        euHomeTotalPages: total_pages
      }))
    }

    if (apiRequestCallId === this.euHomeBrandsApiCallId) {
      this.setState({
        brandsIFollowHome: responseJson.followings.data,
        suggestedBrandsHome: responseJson.suggested_brands.data,
        top6: responseJson.top6.data
      });
    }

    if (apiRequestCallId === this.tokenUpdateApiCallId) {
      this.handleTokenUpdateApiResponse(responseJson);
    }

    if(responseJson && !responseJson.errors && !responseJson.error) {
      if(apiRequestCallId === this.followBrandApiCallId) {
        this.getSuggestedAndFollowBrandHome()
      }
    }
  }

  updateToken = () => {
    if (this.state.account) {
      this.tokenUpdateApiCallId = this.makeRequest({
        endpoint: `${configJSON.accountApiEndpoint}/${
          this.state.account.id
        }/get_new_token`,
        method: configJSON.dashboarApiMethodType,
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          token: getToken()
        }
      });
    }
  };

  followBrandApi = (brandId: string) => {
    const token = window.localStorage.getItem(configJSON.EU_AUTH_TOKEN) || ""
    const httpBody = {
      data:{
          attributes:{
              followed_user_id: brandId
          }
      }
    }
    this.followBrandApiCallId = this.makeRequest({
      endpoint: configJSON.followBrandApiEndpoint,
      method: configJSON.httpPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token
      },
      body: JSON.stringify(httpBody)
    })
  }

  getDashboardData = () => {
    const token = window.localStorage.getItem("EUAuthToken");
    if (token) {
      this.dashboardApiCallId = this.makeRequest({
        endpoint: `${configJSON.getDashboardData}?page=${this.state.euHomePageNo}&per_page=${configJSON.POST_PER_PAGE}`,
        method: configJSON.dashboarApiMethodType,
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          token
        }
      });
    }
  };

  handleScrollHomePost = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if(this.state.isEUHomePostLoading) return

    if (scrollHeight - scrollTop <= clientHeight + 10 && this.state.euHomePageNo < this.state.euHomeTotalPages) {
      this.setState(prevState => ({
        euHomePageNo: prevState.euHomePageNo + 1,
        isEUHomePostLoading: true
      }));
    }
  };

  getSuggestedAndFollowBrandHome = async () => {
    const EuToken = await getStorageData("EUAuthToken");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euHomeBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetEUTrendingBrands
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUTrendingBrands,
      token: EuToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUTrendingBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePostLikes = (postId: string) => {
    const token = window.localStorage.getItem("EUAuthToken");
    const payload = {
      like: {
        likeable_id: postId,
        likeable_type: "BxBlockPosts::Post"
      }
    };
    if (token) {
      this.addLikePostApiCallId = this.makeRequest({
        endpoint: configJSON.addLikeToPostEndPoint,
        method: configJSON.httpPostMethod,
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          token
        },
        body: JSON.stringify(payload)
      });
    }
  };

  checkIsVideo = (file: {link : string}) => {
    return (
      (typeof file.link === "string" && file.link.toLowerCase().endsWith(".mp4"))
    );
  };

  handleTokenUpdateApiResponse = (response: { token: string }) => {
    setToken(response.token);
  };

  makeRequest = ({ endpoint, method, headers, body }: APIRequest) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
}
// Customizable Area End
