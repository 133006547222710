// Customizable Area Start
import React, { useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Box, Button } from "@material-ui/core";
import { FollowerBack } from "./interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "450px",
      padding: theme.spacing(2, 1)
    },
    fontWeightBold: {
      fontWeight: 700,
      fontSize: "14px",
      fontStyle: "normal",
      fontFamily: "Inter"
    },
    activeUsersContainer: {
      padding: theme.spacing(2, 1)
    },
    heading: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px"
    },
    followerUIContainer: {
      listStyle: "none",
      margin: "0px",
      padding: "15px 10px"
    },
    followerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "15px 10px"
    },
    followerImgContainer: {
      width: "45px",
      height: "45px",
      background: "#FFC629",
      borderRadius: "50%"
    },
    followerInfoContainer: {
      display: "flex",
      gap: "15px",
      alignItems: "center"
    },
    followerName: {
      fontSize: "14px",
      fontWeight: 700
    },
    followBtn: {
      textTransform: "capitalize",
      padding: "6px 8px 2px 8px",
      borderRadius: 8,
      color: "#000000",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      backgroundColor: "#FFC629",
      "&:hover": {
        backgroundColor: "#FFC629",
        opacity: 0.5
      }
    },
    seeMoreLessContainer: {
      display: "flex",
      justifyContent: "end",
      padding: "8px 24px"
    },
    seeMoreLessText: {
      fontSize: "16px",
      fontWeight: 600,
      color: "#3B82F6",
      cursor: "pointer"
    }
  })
);

interface FollowersUsersProps {
  followers: FollowerBack[];
  handleCreateFollower: (id: any) => void;
}

const FollowBackUsers: React.FC<FollowersUsersProps> = ({
  followers = [],
  handleCreateFollower
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleFollow = (id: any) => {
    handleCreateFollower(id);
  };

  const followersToShow = expanded ? followers : followers.slice(0, 5);

  return (
    <Paper square elevation={0} className={classes.root}>
      <Typography
        align="center"
        variant="subtitle2"
        className={clsx(classes.fontWeightBold)}
      >
        Follow Back
      </Typography>
      <Box>
        <ul className={classes.followerUIContainer}>
          {followersToShow.map(follower => (
            <li className={classes.followerContainer} key={follower.id}>
              <Box className={classes.followerInfoContainer}>
                <Box className={classes.followerImgContainer}>
                  <img src={""} alt={""} />
                </Box>
                <Typography className={classes.followerName}>
                  {follower.attributes.full_name}
                </Typography>
              </Box>
              <Button
                className={classes.followBtn}
                onClick={() => handleFollow(follower.id)}
              >
                Follow
              </Button>
            </li>
          ))}
        </ul>
        {followers.length > 5 && 
          <Box className={classes.seeMoreLessContainer}>
            <Typography
              className={classes.seeMoreLessText}
              onClick={handleToggleExpand}
            >
              {expanded ? "See Less" : "See More"}
            </Typography>
          </Box>
        }
      </Box>
    </Paper>
  );
};

export default FollowBackUsers;
// Customizable Area End
