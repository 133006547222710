// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum"
import { getToken } from "../../../components/src/AuthService";
export const configJSON = require("./config");


export type Props = {
  id: string;
  navigation: any;
};

interface S {
}

interface SS {
  navigation: any;
}

export default class HomeController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
    };
  }

  async componentDidMount() {
    this.handlePageLoad()
  }

  handlePageLoad = () => {
    const buLoginToken = getToken()
    const euLoginToken = window.localStorage.getItem(configJSON.EU_AUTH_TOKEN) || ""

    if(!buLoginToken && !euLoginToken) {
        this.handleRedirect("Login")
    }

    if(buLoginToken) {
        this.handleRedirect("Dashboard")
    }
    
    if(euLoginToken) {
        this.handleRedirect("EuDashboard")
    }
  }

  handleRedirect = (RouteName: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        RouteName
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
}
// Customizable Area End
