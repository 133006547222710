// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");

export type Props = {
  isOpen: boolean
  classes?: any;
  closeModal: () => void;
};

interface S {
  isLoading: boolean
  policyContent: string
  error: string;
}

interface SS {
}

export default class PrivacyPolicyModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPrivacyPolicyApiCallId: string = "";
  confirm = configJSON.confirm;
  privacyPolicyTitle = configJSON.privacyPolicyTitle;
  privacyPolicyContentText = configJSON.privacyPolicyContentText;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      error: "",
      policyContent: "",
      isLoading: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getPrivacyPolicy()
  }

  handleConfirm = () => {
    this.props.closeModal()
  }

  getPrivacyPolicy = () => {
    this.setState({ isLoading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPrivacyPolicyApiCallId) {
      if(responseJson.data.length > 0) {
        const { attributes: { description } } = responseJson.data[0]
        this.setState({ policyContent: description, isLoading: false })
      }
    }
  }
}
// Customizable Area End
