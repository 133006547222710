// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  ThemeProvider,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  themeWhite,
  themeYellow,
  commonStyles,
  pallete,
} from "../../../framework/src/enduserTheme";
import EnduserFeedController from "./EnduserFeedController.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { backArrow, speechIcon } from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import MessageIcon from "@material-ui/icons/Message";

export class EnduserFeed extends EnduserFeedController {
  render() {
    const { classes }: any = this.props;

    return (
      <ThemeProvider theme={this.state.themeSwitch ? themeYellow : themeWhite}>
        <CssBaseline />
        <Container
          data-test-id="mainContainerEUFeed"
          maxWidth={false}
          className={classes.mainContainerEUFeed}
        >
          <Box className={classes.upperBox}>
            <IconButton data-test-id="iconButtonBack" onClick={this.navigateToEnduser}>
              <img
                alt="back-arrow"
                src={backArrow}
                className={classes.back_arrorImg}
              />
            </IconButton>
            <Box className={classes.privacyBox}>
              <VisibilityIcon className={classes.privacyIcons} />
              <Typography className={classes.privacytext}>
                Who can view
              </Typography>
              <ExpandMoreIcon className={classes.privacyIcons} />
            </Box>
          </Box>
          <Box>
            <Typography className={classes.postText}>
              Can't believe the new smartphone just launched!{" "}
            </Typography>
          </Box>
          <Grid container className={classes.mainGrid}>
            <Grid item xs={9} className={classes.userBoxMain}>
              <Typography className={classes.userName}>
                {this.state.userDetails.full_name}
              </Typography>
              <Typography className={classes.userText}>
                Congratulations! Your profile has been created. Click the button
                below so your new community can see your post.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.userControles}>
                <AccountCircleIcon className={classes.userImage} />
                <FavoriteBorderIcon className={classes.likesIcon} />
                <Typography className={classes.digits}>35</Typography>
                <img src={speechIcon} alt="" className={classes.likesIcon} />
                <Typography className={classes.digits}>15</Typography>
              </Box>
            </Grid>
            <Button style={{alignSelf: "center",height:"56px"}} className={classes.postBtn}>Post</Button>
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    digits: {
      color: "#FFF",
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
    likesIcon: {
      color: "#FFFFFF",
      padding: "5px 0",
      height: "32px",
      width: "32px",
    },
    userBoxMain: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
    },
    userImage: {
      height: "46px",
      width: "46px",
      color: "#FFFFFF",
    },
    userControles: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignContent: "center",
      textAlign: "center",
      alignItems: "center",
    },
    userText: {
      color: pallete.black,
      fontFamily: "Inter",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    userName: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
    postBtn: {
      ...commonStyles.mobileButton,
      width: "100%",
      margin: "20px 0",
    },
    privacyIcons: {
      margin: "auto",
      height: "16px",
      width: "16px",
    },
    privacytext: {
      color: pallete.black,
      fontFamily: "Inter",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
      margin: "auto 5px",
    },
    postText: {
      color: pallete.black,
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "25px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
    },
    privacyBox: {
      display: "flex",
      flexDirection: "row",
    },
    back_arrorImg: {
      height: "22px",
      width: "22px",
    },
    upperBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "25px 2px",
    },
    mainContainerEUFeed: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
  });

export default withStyles(styles)(EnduserFeed);
