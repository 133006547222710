// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { EveryreelVideo } from "./interfaces";

export const configJSON = require("./config");

export enum VideoViews {
  RESULTS = "RESULTS",
  LEADS = "LEADS",
  LINK_CLICKS = "LINK_CLICKS"
}

type Answer = {
  key: string;
  value: number;
  valuePercentage: number;
};

export type Props = {
  classes: any;
  videos: EveryreelVideo[];
};

interface S {
  currentVideoView: VideoViews;
  selectedVideoId: number;
  videoAnswers: {
    key: string;
    value: number;
    valuePercentage: number;
  }[];
}

interface SS {
  navigation: any;
}

export default class AnalyticsReelsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      currentVideoView: VideoViews.RESULTS,
      selectedVideoId: this.props.videos[0]?.id || -1,
      videoAnswers: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): Promise<void> {
    return new Promise(() => {
      if (this.state.selectedVideoId > -1) {
        this.setState({
          videoAnswers: this.getAnswers(
            this.props.videos.find(
              (video) => video.id === this.state.selectedVideoId
            )!
          ),
        });
      }
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (prevState.selectedVideoId !== this.state.selectedVideoId) {
      const selectedVideo = this.props.videos.find(
        (video) => video.id === this.state.selectedVideoId
      )!;

      this.setState({
        videoAnswers: this.getAnswers(selectedVideo),
      });
    }
  }

  getAnswers = (selectedVideo: EveryreelVideo): Array<Answer> => {
    const videoAnswersKeys = Object.keys(selectedVideo.answers);
    const totalAnswers = videoAnswersKeys.reduce(
      (accumulator, key) => accumulator + selectedVideo.answers[key].count,
      0
    );

    return videoAnswersKeys.map((answerKey) => ({
      key: answerKey,
      value: selectedVideo.answers[answerKey].count,
      valuePercentage: Math.round(
        (selectedVideo.answers[answerKey].count / (totalAnswers || 1)) * 100
      ),
    }));
  };

  setSelectedVideoId = (videoId: number) => {
    this.setState({ selectedVideoId: videoId });
  };

  setVideoView = (view: VideoViews) => {
    this.setState({
      currentVideoView: view,
    })
  }
}
// Customizable Area End
