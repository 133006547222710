// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { InviteFriendsStepType } from "./enums";
import { Contact, NormalizedContact } from "./InviteFriendsController.web";
import { ChangeEvent } from "react";

export const configJSON = require("./config");

export interface Props {
  contacts: NormalizedContact[];
  addContact: (contact: Contact) => void;
  setStep: (step: InviteFriendsStepType) => void;
  checkBulkContact: (contactId: number) => void;
  submitInvites: () => void;
  classes?: any;
}

interface S {
  filterValue: string;
  filteredContacts: NormalizedContact[];
}

interface SS {
  navigation: any;
  stepType: InviteFriendsStepType;
}

export default class BulkInviteFriendsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      filterValue: "",
      filteredContacts: this.props.contacts || [],
    };
  }

  currentContacts = configJSON.currentContacts;
  search = configJSON.search;
  createContactDecription = configJSON.createContactDecription;
  selectedUsers = configJSON.selectedUsers;
  sendBulkInvite = configJSON.sendBulkInvite;
  contactList = configJSON.contactList;

  componentDidUpdate(prevProps: Props) {
    if (this.props.contacts !== prevProps.contacts) {
      this.setState({
        filteredContacts: this.filterByValue(
          this.props.contacts,
          this.state.filterValue
        ),
      });
    }
  }

  searchContact = (event: ChangeEvent<HTMLInputElement>) => {    
    let filteredContacts = this.props.contacts;
    const value = event.target.value.toLocaleLowerCase();
    this.setState({ filterValue: value });

    filteredContacts = this.filterByValue(filteredContacts, value);

    this.setState({
      filteredContacts,
    });
  };

  filterByValue = (contacts: NormalizedContact[], value: string = "") => {
    if (value.length) {
      return contacts.filter(
        (contact) =>
          contact.fullName.toLocaleLowerCase().includes(value) ||
          contact.email.toLocaleLowerCase().includes(value)
      );
    }

    return contacts;
  };
}
// Customizable Area End
