// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  withStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../../components/src/CustomButton.web";
import PrivacyPolicyController, { Props } from "./PrivacyPolicyController.web";
import CustomLoader from "../../../components/src/CustomLoader.web";

const styles = (theme: Theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: "8px 8px 32px 8px",
      maxWidth: "830px",
      padding: "20px",
    },
    dialogTitle: {
      paddingTop: "35px",
      position: "relative",
    },
    dialogContent: {
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "#D9D9D9",
      },
    },
    closeButton: {
      position: "absolute",
      top: "0px",
      right: "0px",
      padding: "8px",
    },
    modalTitle: {
      alignSelf: "flex-start",
      padding: 0,
      color: "#0F172A",
      textAlign: "center",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      fontFamily: "Inter, 'sans-serif'",
    },
    modalDescription: {
      paddingRight: "9px",
      fontFamily: "Inter, 'sans-serif'",
    },
    submitButton: {
      padding: "5px 25px",
      marginTop: "10px",
      maxWidth: "253px",
    },
  });
class PrivacyPolicyModal extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isOpen, classes, closeModal } = this.props;
    const { isLoading, policyContent } = this.state;

    return (
      <Dialog
        fullWidth
        open={isOpen}
        classes={{
          paper: classes.dialogPaper,
        }}
        onClose={closeModal}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="space-between">
            <Typography data-test-id="title" className={classes.modalTitle}>
              {this.privacyPolicyTitle}
            </Typography>
            <IconButton className={classes.closeButton} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CustomLoader
            loaderSize={24}
            isLoading={isLoading}
            wrapperHeight="45vh"
          >
            <Typography
              data-test-id="description"
              dangerouslySetInnerHTML={{ __html: policyContent }}
              className={classes.modalDescription}
            />
          </CustomLoader>
        </DialogContent>
        <DialogActions>
          <CustomButton
            type="submit"
            onClick={this.handleConfirm}
            className={classes.submitButton}
          >
            {this.confirm}
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export { PrivacyPolicyModal };
export default withStyles(styles)(PrivacyPolicyModal);
// Customizable Area End
