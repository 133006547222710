// Customizable Area Start
import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { Color } from "@material-ui/lab/Alert";

interface ICustomAlert {
  alertType: Color | undefined;
  message: string;
}

export class CustomAlert extends Component<ICustomAlert> {
  render() {
    const { alertType, message } = this.props;

    return (
      <Snackbar
        autoHideDuration={6000}
        open={!!message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert elevation={6} variant="filled" severity={alertType}>
          {message}
        </MuiAlert>
      </Snackbar>
    );
  }
}

export default CustomAlert;
// Customizable Area End
