// Customizable Area Start
import { loadStripe } from "@stripe/stripe-js";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React, { MouseEvent } from "react";
import { PlanPricing, ProductData } from "./EmailAccountRegistrationController.web";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export const stripePromise = loadStripe(configJSON.stripeKey);

export interface Props {
  handleGoBackClick: () => void;
  paymentPrize: number;
  platformManagementPaymentPlans: ProductData[];
  attributeName: string;
  stripePaymentData: any;
  platformManagementChecked: boolean;
  selectedPricing: PlanPricing;
}

interface S {
  stripePaymentData: any;
  attributeName: string;
  paymentPrize: number;
  flag: boolean
}
interface SS {
  id: any;
}
export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      attributeName: this.props.attributeName,
      paymentPrize: this.props.paymentPrize,
      stripePaymentData: this.props.stripePaymentData,
      flag: false
    };
  }

  handleSubmit = async (
    event: MouseEvent<HTMLButtonElement>,
    stripe: any,
    elements: any
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const token = sessionStorage.getItem("userToken")!
    await setStorageData("authToken", token)

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          window.location.href.match(/https:\/\/[a-z0-9\:\-.]+\//)?.[0]
        }dashboard`,
      },
    });

    if (result.error) {
      await removeStorageData("authToken")
      console.log(result.error.message);
    }
  };


  getPlatformManagementPrice = () => {    
    return Number(this.props.platformManagementPaymentPlans?.[0]?.attributes.plans.find(plan => plan.interval === "month")?.price || "0")
  }
}
// Customizable Area End
