// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getErrorMessage } from "../../../components/src/CommonFunctions";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  userDetails: any;
  loading: boolean;
  themeSwitch: boolean
  error:string
}
interface SS {
  id: any;
}
export default class EnduserFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserDetailsApiId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
        userDetails:{},
        loading:true,
        themeSwitch:localStorage.getItem("yellowTheme") === "true" ? true : false,
        error:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getUserDetailsApiId) {
          console.log({data: responseJson.data.attributes})
          this.setState({userDetails: responseJson.data.attributes})
        }

      } else if (responseJson.errors) {
        let err = getErrorMessage(responseJson.errors[0]);
        this.setState({ loading: false, error:err });
      }
    }

    
  }

  async componentDidMount(): Promise<void> {
    this.getuserDetails()
  }

  navigateToEnduser = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EndUserEmailSignUp");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserSignupMessage)
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }


  getuserDetails() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: localStorage.getItem("EndUserAuthToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getuserdetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

}
// Customizable Area End
