import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import {flags} from "./assets"

// Customizable Area End

const webStyle = {
    nextHomePage: {
        backgroundColor: "#FFC629",
        marginTop: '25px',
        width: "100%"
    },
    BackHomePage: {
        backgroundColor: "#0F172A",
        color: "#fff",
        marginTop: '25px',
        width: "100%"
    },
    fontWeight: {
        fontWeight: 900
    },
    marginCommon: {
        marginBottom: "7px",
        width: "100%"
    },
    fieldMnumber: {
        marginBottom: "7px",
        width: "100%",
        height: "40px"
    },
    marginTextField: {
        marginTop: "5px",
        fontWeight: 900,
        color: "#334155"
    },
    MainContainerInfo: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column" as "column",
        width: "340px",
        marginTop:"20px"
    },
    boldFontweight: {
        fontWeight: 900
    },
    captionDetail: {
        fontSize: "12px",
        color: "#0F172A",
        marginTop: "10px"
    },
    policyDetailBelow: {
        fontSize: "10px",
        color: "#0F172A",
        marginTop: "10px"
    },
    conditionRatesNote:{
        alignItems:"center",
        paddingTop:"10px",
        marginLeft:"68px",
        fontSize: "10px",

    }



};
const InformationSignup = (props: any) => {
        
        return (
            <Box >
                <Box style={webStyle.MainContainerInfo} >
                    <Typography variant="h6" gutterBottom style={webStyle.boldFontweight} >
                        Complete your information
                    </Typography>

                    <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>
                        First Name</Typography>
                    <TextField
                        style={webStyle.marginCommon}
                        id="firstname"
                        variant="outlined"
                        size="small"
                        placeholder="Your First name"
                        name="firstName"
                        onChange={(e)=> props.handleFieldChange(e)}
                        value={props.firstName}
                        error={Boolean(props.errorMsg["firstName"])}
                        helperText={props.errorMsg["firstName"]}
                        data-test-id="infoFirstname"
                    />
                    <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>Last Name</Typography>
                    <TextField
                        style={webStyle.marginCommon}
                        id="lastname"
                        variant="outlined"
                        size="small"
                        placeholder="Your last name"
                        name="lastName"
                        onChange={(e)=> props.handleFieldChange(e)}
                        value={props.lastName}
                        error={Boolean(props.errorMsg["lastName"])}
                        helperText={props.errorMsg["lastName"]}
                        data-test-id="infoLastname"
                    />
                    <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>Business website</Typography>
                    <TextField
                        style={webStyle.marginCommon}
                        id="businessWebsite"
                        variant="outlined"
                        size="small"
                        placeholder="Your Business Website"
                        name="businessWebsite"
                        onChange={(e)=> props.handleFieldChange(e)}
                        value={props.businessWebsite}
                        error={Boolean(props.errorMsg["businessWebsite"])}
                        helperText={props.errorMsg["businessWebsite"]}
                        data-test-id="infoBusinesswebsite"
                    />
                    <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>Zip code</Typography>
                    <TextField
                        style={webStyle.marginCommon}
                        id="zipcode"
                        variant="outlined"
                        size="small"
                        placeholder="Your Zip Code"
                        name="zipcode"
                        onChange={(e)=> props.handleFieldChange(e)}
                        value={props.zipcode}
                        error={Boolean(props.errorMsg["zipcode"])}
                        helperText={props.errorMsg["zipcode"]}
                        data-test-id="infoZipcode"
                    />
                    <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>Mobile Number</Typography>
                    <TextField
                        id="outlined-adornment-america"
                        style={webStyle.fieldMnumber}
                        size="small"
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start"> <img src={flags} alt=""/>{ " "} +1</InputAdornment>
                        ),
                        }}
                        aria-describedby="outlined-america-helper-text"
                        name="mobileNumber"
                        onChange={(e) => props.handleFieldChange(e)}
                        value={props.mobileNumber}
                        error={Boolean(props.errorMsg['mobileNumber'])}
                        helperText={props.errorMsg['mobileNumber']}
                        variant="outlined"
                        data-test-id="infoMobilenumber"
                    />
                    <Typography variant="caption" display="block" gutterBottom style={webStyle.captionDetail} >
                        We'll verify your number to make sure you are you. We never share this with anyone and it won't be on your public profile
                    </Typography>
                    {/*  variant="outlined" */}

                    <Box><Button variant="contained" onClick={props.handleSubmit} style={webStyle.nextHomePage}>Next</Button></Box>
                    <Box><Button variant="contained" onClick={()=>props.goToBack(2)} style={webStyle.BackHomePage}>Back</Button></Box>
                    <Typography variant="caption" display="block" gutterBottom style={webStyle.policyDetailBelow} >
                        By tapping "Next", you agree to our <b>Privacy Policy and Terms of Service</b> and authorize Everybrand
                        to send text messages to the mobile number provided.
                        

                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom style={webStyle.conditionRatesNote} >Message/data rates may apply
                    </Typography>
                </Box>
            </Box>

        );
    }
export default InformationSignup;
