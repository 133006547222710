import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField,
} from "@material-ui/core";

const webStyle = {
    nextHomePage: {
        backgroundColor: "#FFC629",
        marginTop: '25px',
        width: "100%"
    },
    BackHomePage: {
        backgroundColor: "#0F172A",
        color: "#fff",
        marginTop: '25px',
        width: "100%"
    },
    BelowCommunityFlex: {
        display: "flex",
        marginTop: '25px',
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        maxWidth: "350px",
    },
    fontWeight: {
        fontWeight: 900
    },
    verificationNumber: {
        fontSize: "16px"
    },
    Otpfield: {
        display: 'flex',
        justifyContent: 'space-between' as 'space-between',
        alignItems: 'center' as 'center',
        width: '100%',
        margin: '10px 0px',
    },
    OtpfieldError: {
        fontSize: "0.75rem",
        marginTop: "2px",
        color: "#f44336",
        fontWeight: 400,
        lineHeight: "1.66",
    }
}
const BusinessPhoneVerification = (props: any) => {
    return (
        // Required for all blocks

        <Box style={webStyle.formContainer}>
            <Box>
                <Typography variant="h5" gutterBottom style={webStyle.fontWeight} >
                    Phone Verification
                </Typography>
                <Typography variant="caption" style={webStyle.verificationNumber} gutterBottom>
                    A 6-digit verification code was just sent to +1 {props?.mobileNumber}
                </Typography>
                <Box style={webStyle.Otpfield}>
                    {props.inputRefs.map((inputRef: any, index: number) => (
                        <TextField
                            key={index}
                            variant="outlined"
                            size="small"
                            autoFocus={index === 0}
                            value={props.otp[index] || ''}
                            onChange={(event) => props.handleInputChange(event, index)}
                            inputRef={inputRef}
                            onKeyDown={(event) => props.handleKeyDown(event, index)}
                            inputProps={{
                                maxLength: 1,
                                style: {
                                    flex: 1,
                                    width: '35px',
                                    textAlign: 'center',
                                    padding: '10px 8px',
                                },
                            }}
                            data-test-id={`otpinput${index}`}
                            error={Boolean(props.errorMsg?.otp)}
                        />
                    ))}

                </Box>
                <div>
                    {props.errorMsg && <p style={webStyle.OtpfieldError}>{props.errorMsg?.otp}</p>}
                </div>

                <Box><Button variant="contained" style={webStyle.nextHomePage} onClick={props.handleSubmit}>Next</Button></Box>
                <Box><Button variant="contained" style={webStyle.BackHomePage} onClick={props.goToBack}>Change number</Button></Box>

                <Box style={webStyle.BelowCommunityFlex}>
                    <Typography variant="body2" gutterBottom>
                        Didn’t get code?  <b><a href="" onClick={props.resendOTP} style={{ textDecoration: "none" ,color:"#0F172A"}} data-test-id="resendOtp">resend it!</a></b></Typography>
                </Box>
            </Box>
        </Box>

    );
}
export default BusinessPhoneVerification;
// Customizable Area End
