// Customizable Area Start
import React, { Component } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const LoaderWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Loader = styled(CircularProgress)({
  color: "#ffc629",
});

interface ICustomLoaderProps {
  isLoading: boolean;
  wrapperHeight?: string | number;
  loaderSize?: number | string;
  children?: React.ReactNode;
}

export class CustomLoader extends Component<ICustomLoaderProps> {
  render() {
    const { wrapperHeight, isLoading, loaderSize, children } = this.props;

    return isLoading ? (
      <LoaderWrapper height={wrapperHeight}>
        <Loader size={loaderSize} />
      </LoaderWrapper>
    ) : (
      children || null
    );
  }
}

export default CustomLoader;
// Customizable Area End
