// Customizable Area Start
import React, { createRef } from "react";
import AvatarEditor from "react-avatar-editor";
import { createStyles, StyleRules, Theme } from "@material-ui/core/styles";
import {
  withStyles,
  Box,
  Typography,
  Button,
  WithStyles,
} from "@material-ui/core";

interface Props extends WithStyles<typeof uploadImageStyles> {
  file: File | null;
  setPicture: (file: Blob) => void;
  closeModal: (event: React.SyntheticEvent) => void;
}

interface State {
  imageScale: number;
}

class ImageUploadSet extends React.Component<Props, State> {
  private editorRef: React.RefObject<AvatarEditor>;

  constructor(props: Props) {
    super(props);
    this.state = {
      imageScale: 1,
    };
    this.editorRef = createRef();
  }

  handleNextStep = async () => {
    if (this.editorRef.current) {
      const dataUrl = this.editorRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      this.props.setPicture(blob);
    }
  };

  handleScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ imageScale: Number(e.target.value) });
  };

  render() {
    const { classes, file, closeModal } = this.props;
    const { imageScale } = this.state;

    return (
      <Box className={classes.container} id="uploadImageScreen">
        <Typography className={classes.title}>Upload a Profile Pic</Typography>
        <Typography className={classes.description}>
          Let's make your profile a little more on brand
        </Typography>

        {file && (
          <Box className={classes.imagePreviewContainer}>
            <AvatarEditor
              ref={this.editorRef}
              image={file}
              width={340}
              height={340}
              border={25}
              borderRadius={300}
              color={[0, 0, 0, 0.6]}
              scale={imageScale}
              rotate={0}
            />

            <Box className={classes.scaleContainer}>
              Scale
              <input
                className={classes.scaleInput}
                type="range"
                name="Scale"
                id="scale"
                min="1"
                max="5"
                step="0.2"
                value={imageScale}
                onChange={this.handleScaleChange}
              />
            </Box>
          </Box>
        )}

        <Box className={classes.buttonsContainer}>
          <Typography
            id="skipUploadingButton"
            className={classes.buttonSkip}
            onClick={closeModal}
          >
            Cancel
          </Typography>
          <Button
            id="goToInvitesScreen"
            className={classes.buttonNext}
            onClick={this.handleNextStep}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
}

const uploadImageStyles = (_theme: Theme): StyleRules =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      paddingRight: "20px",
      gap: "22px",
      color: "#0F172A",
      overflow: "auto",

      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: "39px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    title: {
      alignSelf: "flex-start",
      fontSize: "26px",
      fontWeight: 700,
    },

    description: {
      fontSize: "20px",
      fontWeight: 700,
    },

    imagePreviewContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",

      "& > canvas": {
        selfAlign: "center",
        touchAction: "manipulation"
      },
    },

    scaleContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: 700,

      "& input[type='range']": {
        "-webkit-appearance": "none",
        appearance: "none",
        background: "transparent",
        cursor: "pointer",
        width: "20rem",
      },

      "& input[type='range']::-webkit-slider-runnable-track": {
        background: "#FFC629",
        height: "11px",
        borderRadius: "21px",
      },

      "& input[type='range']::-moz-range-track": {
        background: "#FFC629",
        height: "11px",
        borderRadius: "21px",
      },

      "& input[type='range']::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        appearance: "none",
        marginTop: "-8px",
        backgroundColor: "#000",
        height: "26px",
        width: "26px",
        borderRadius: "50%",
      },
    },

    scaleInput: {
      width: "50%",
    },

    buttonsContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },

    buttonSkip: {
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
      width: "100%",
      textAlign: "center",
      fontFamily: "Inter, 'sans-serif'",
    },

    buttonNext: {
      alignSelf: "flex-end",
      width: "100%",
      height: "44px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#FFC629",
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: "Inter, 'sans-serif'",
    },
  });

export default withStyles(uploadImageStyles)(ImageUploadSet);
// Customizable Area End