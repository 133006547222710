Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContactList";
exports.labelBodyText = "ContactList Body";
exports.labelShareVCard = "Share VCard";
exports.labelNoContactText = "No Contact Found";
exports.labelContactPermission = "You have to give permission of contact";
exports.labelError = "SomeThing Went Wrong";
exports.labelFileError = "File Must Be VCF Type";
exports.fileSupport = 'text/x-vcard'
exports.organization = 'Organization'

exports.btnExampleTitle = "CLICK ME";
exports.generateCardButton = "Generate vCard";
exports.uploadFileButton = "Upload File";
exports.btnShare = "SHARE";
exports.btnAllow = "ALLOW";
exports.firstName = "First Name";
exports.middleName = "Middle Name";
exports.lastName = "Last Name";
exports.contactNumber = "Contact Number";
exports.birthDate = "Birth Date";
exports.gender = "Gender";
exports.male = "Male";
exports.female = "Female";
exports.title = "Title";
exports.url = "URL";
exports.note = "Note";

//mobile
exports.btnAdd = "+";
exports.btnTryAgain = "Try Again";
exports.btnCancel = "CANCEL";
exports.btnContinous = "CONTINOUS";
exports.importVcard = 'Import VCard'
exports.newContact = 'New Contact'
exports.name = 'Name'
exports.phone = 'Phone'

exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.validationApiContentType = "application/json";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.btnExampleTitle = "CLICK ME";
exports.labelBodyText = "settings2 Body";
exports.getPaymentDetailsEndPoint="bx_block_stripe_integration/payment_methods"
exports.countryCodesGetEndpoint = "account/accounts/country_code_and_flag";
exports.contentType = "application/json";
exports.postApiMethod = "POST";
exports.updateBusinessContactGetEndpoint = "account_block/business_contacts/current_contact";
exports.updateBusinessContactPutEndpoint = "account_block/business_contacts/update_contact";
exports.createIntentEndPoint ="bx_block_stripe_integration/payment_methods/create_intent"
exports.save = "Save";
exports.stripeKey="pk_test_51NlZ7kBJz0kOzyGJKm1A1VZUEgqZJ7tez3FPTqPmywuJRsotJoKY1Dm99Z6ceaY7OWGbUJ8ZULD2ZKKqileBglMZ00ym8mSgSj"
exports.getApiMethod = "GET";
exports.update = "Save";
exports.updateContactButton="Update"
exports.border = "Border";
exports.setHolidayHoursEndPoint="account_block/business_hours/set_holiday_hours_status"
exports.putApiMethod = "PUT";
exports.websitesEndPoint="account_block/websites"
exports.automationTemplatesApiEndPoint="bx_block_automations/automations"
exports.getHolidayHoursEndPoint="account_block/business_hours/get_holiday_hours_status"
exports.teammatesEndPoint="bx_block_teammates/teammates"
exports.updateCurrentBuPlanEndPoint="customisable_user_subscriptions/update_current_subscription"
exports.contactsInWaitListEndPoint="account_block/contacts"
exports.setUpAutomationEndPoint="account_block/waitlists"
exports.settingsSubscriptionPlansEndPoint="customisable_user_subscriptions/products"
exports.addedToWaitListFolderEndPoint="account_block/contacts/waitlist/add_contacts"
exports.removeWaitListContactEndPoint="account_block/contacts/remove_waitlist_contact"
exports.getCurrentBuPlanEndPoint="customisable_user_subscriptions/current_subscription"
exports.getWaitListFoldersEndPoint1="account_block/waitlists" 
exports.getWaitListFoldersEndPoint2="account_block/waitlists" 
exports.getWaitListFoldersEndPoint3="account_block/waitlists" 
exports.getWaitListFoldersEndPoint4="account_block/waitlists" 
exports.firstNameErrorMessage = "First Name is required.";
exports.lastNameErrorMessage = "Last Name is required.";
exports.phoneNumberErrorMessage = "Phone Number is required.";
exports.getSecretKeyForSubscription="bx_block_stripe_integration/subscriptions"
exports.getCustomizableSubscriptionEndPoint="customisable_user_subscriptions/waitlist_subscriptions"
exports.addBulkContactsInWaitList="account_block/contacts/draft_contacts"
exports.removeBulkContactsInWaitLIst="account_block/contacts/waitlist/remove_contacts"
exports.getAutomationTemplateEndPoint="bx_block_automations/automations"
exports.getCurrentContactsEndPointForAutoFill="account_block/contacts/pending_waitlist_contacts"
// Customizable Area End