// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef } from 'react';


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleSuccessResponse:(data:any)=>void;
    handleGoBackClick:()=>void;
}


   interface  S {
    formError: any;
    currentStep: number;
    mobileNumber :any;
    otp: string[] ;
    EmailPasswordID: number;
    userToken:any;
    showSuccessMessage: boolean;
    formGlobalError: any;
}

interface SS {

    id: any;

}
export default class EmailAccountRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {

    apiEmailSignupVerificationOtpId: any = "";
    apiEmailSignUpOtpSendCallId: any = "";


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            currentStep: 1,
            formError: {},
            formGlobalError: {},
            mobileNumber: sessionStorage.getItem('mobileNumber'),
            showSuccessMessage: false,
            otp: Array(6).fill(''),
            EmailPasswordID:0,
            userToken:this.userTokenValue
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    inputRefs = Array(6).fill(null).map(() => createRef<HTMLInputElement>());
    userTokenValue =   sessionStorage.getItem('userToken')
    handleInputChange = (event: any, index: number) => {
        const value = event.target.value;
        const updatedOtp = [...this.state.otp];
        updatedOtp[index] = value;
        this.setState({ otp: updatedOtp });
        if (value && index < this.inputRefs.length - 1) 
        {
            this.inputRefs[index + 1].current?.focus();
        }
        else  if (!value && index > 0) {
        this.inputRefs[index - 1].current?.focus();
        }
        return false;
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>, index: number) => {
        if (event.key === 'Backspace') {
            if (index < this.inputRefs.length  ) {
                const updatedOtp =  [...this.state.otp];
                updatedOtp[index] = '' ;

                this.setState( { otp: updatedOtp } );
                if (index !== 0) 
                {
                this.inputRefs[index-1].current?.focus();
                
                } else {
                    this.inputRefs[index].current?.focus();
                }
            }
        }
         else if (event.key === 'Delete') {
            if ( index <= this.inputRefs.length - 1) 
            {
                const updatedOtp = [...this.state.otp];

                 updatedOtp[index] = '';

                this.setState ( { otp: updatedOtp } );
                if (index !== this.inputRefs.length - 1) {
                       this.inputRefs[index + 1].current?.focus();
                } 
                else 
                {this.inputRefs[index].current?.focus();
                }
            }
        }
    };

    handleFieldChange = (e:any) => {
        const { name, value }: { name: string; value: string } = e.target;
        this.setState({
            [name]: value,
        } as unknown as Pick<S, keyof S>)

    };



    validateStep4 = ()=>{
        const errors: any = {};
        if (this.state.otp.some((value) => value === ""))
        {
            errors.otp = "Please enter the code.";
        }

        return errors;

    }


    handleNextClick = () => {
        const errors = this.validateStep4();
        this.setState({
            formError: errors,
        });
        if (Object.keys(errors).length === 0) {
                this.doEmailSignUpVerifyOtp();
        }
      
    }

    resendOTP=(e:any)=>{
        e.preventDefault();
        this.doEmailSignUpOtpSend();
    }

    async doEmailSignUpVerifyOtp() {
        const headerData={
            "Content-Type": configJSON.signupEmailApiContentType,
            "Token":this.state.userToken
        }
        const updatedOTP=this.state.otp.join("")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEmailSignupVerificationOtpId = requestMessage.messageId

          requestMessage.addData (
               getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(headerData)
        );
        requestMessage.addData (
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               `${configJSON.SignUPVerifyOtpEndpoint}?otp=${updatedOTP}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.signupVerifyOtpAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    async doEmailSignUpOtpSend() {
        const headerData={
            "Content-Type": configJSON.signupEmailApiContentType,
            "Token":this.state.userToken
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiEmailSignUpOtpSendCallId = requestMessage.messageId
        requestMessage.addData (
            getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(headerData)
     );
     requestMessage.addData (
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SignUPSendOtpEndpoint
     );
     requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.signupSendOtpAPiMethod,
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;
    }

    handlGlobalApiError = (responseJson: any) => {
        if ( responseJson && (responseJson.error || responseJson.errors)) {
            const errMsg = responseJson?.errors[0]?.otp || "Something went wrong!";
            this.setState({ formGlobalError: { [this.state.currentStep]: errMsg } });
            return true;
        }
    }

    apicallresponseData = (responseJson: any) => {
        this.handlGlobalApiError(responseJson);
         if(!(responseJson.error || responseJson.errors)) {
            this.props.handleSuccessResponse(responseJson)
        }
    }

    apicallresponseDataOnResend = (responseJson: any) => {
        this.handlGlobalApiError(responseJson);
    }

    async receive(from: string, message: Message) {
        const messageType = getName(MessageEnum.RestAPIResponceMessage);
        
        if (messageType === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
            const idToFunctionMapping = {
                [this.apiEmailSignUpOtpSendCallId] : this.apicallresponseDataOnResend,
                [this.apiEmailSignupVerificationOtpId] : this.apicallresponseData ,
            };
    
            if (apiRequestCallId != null && idToFunctionMapping.hasOwnProperty(apiRequestCallId)) {
                const correspondingFunction = idToFunctionMapping[apiRequestCallId];
                correspondingFunction.call(this, responseJson);
            }
        }
    }

      
}
// Customizable Area End
