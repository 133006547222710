// Customizable Area Start
import React from "react"
import HomeController from "./HomeController.web";

class Home extends HomeController {
  render() {

    return (
      <div />
    );
  }
}

export default Home

// Customizable Area End
