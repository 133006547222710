import React from "react";

// Customizable Area Start
import { Container, Grid, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End
// Customizable Area Start
import BusinessEmail from "./BusinessEmail.web";
import BusinessSideBar from "./BusinessSideBar.web";
import PasswordCreate from "./PasswordCreate.web";
import InformationSignup from "./InformationSignup.web";
import BusinessPhoneVerification from "./BusinessPhoneVerification.web";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

import EmailAccountLoginErrorBlock from "./EmailAccountRegistrationErrorBlock.web";
import PlanPayment from "./Planpayment.web";
import HeaderStepInfo from "./HeaderStepInfo.web";
import PaymentDetails from "./PaymentDetail.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  mainContainerForm: {
    width: "100%",
    height: "100%",
  },
  container: {
    height: "100%",
    paddingLeft: "0",
    paddingRight: "0"
  },
  fromGrid: {
    display: "flex",
    width: "100%",
    padding: "0 0 20px 0",
    alignItems: "center",
  },
  nextHomePage: {
    backgroundColor: "#FFC629",
    marginTop: "37px",
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "56px",
    fontSize: "16px",
    fontWeight: 700,
  },
  EmailSubtitle: {
    marginTop: "10px",
    color: "#334155",
    fontWeight: 700,
    fontSize: "16px",
  },
  BackHomePage: {
    color: "#fff",
    marginTop: "25px",
    backgroundColor: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "45px",
  },
  CommunityText: {
    marginRight: "5px",
  },
  fontWeight: {
    fontWeight: 700,
    marginBottom: "30px",
    fontSize: "24px",
  },
  BelowCommunityFlex: {
    display: "flex",
    marginTop: "25px",
    alignItems: "center",
  },
  formContainer: {
    display: "flex",
    maxWidth: "360px",
    marginTop: "15%",
  },
  protectCommunity: {
    lineHeight: "25px",
  },
  InputHeight: {
    height: "50px",
    "&:input": {
      height: "50px",
    },
  },
  errorblock: {
    textAlign: "center" as "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  mainContainerFormPhone: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
};

// Customizable Area End
export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  render() {
    const {
      formGlobalError,
      paymentPlanData,
      platformManagementPaymentPlans,
      emailAddress,
      currentStep,
      sideBarContent,
      paymentPrize,
      attributeName,
      isSelectedPlanWithPlatformManagement,
      selectedPricing,
    } = this.state;
    return (
      // Required for all blocks

      <ThemeProvider theme={theme}>
        {[1, 2, 3, 4, 6].includes(currentStep) && (
          <Container style={webStyle.container} maxWidth="xl">
            <Grid container style={webStyle.mainContainerForm}>
              <Grid md={6} direction="column" item style={webStyle.fromGrid}>
                {currentStep === 1 && (
                  <BusinessEmail
                    data-test-id="businessemail"
                    navigation={undefined}
                    id={""}
                    emailAddress={emailAddress}
                    handleSuccessResponse={this.handleSuccessResponse.bind(
                      this
                    )}
                  />
                )}

                {currentStep === 2 && (
                  <PasswordCreate
                    navigation={undefined}
                    id={""}
                    handleSuccessResponse={this.handleSuccessResponse.bind(
                      this
                    )}
                    data-test-id="emailpassword"
                    handleGoBackClick={this.handleGoBackClick}
                  />
                )}

                {currentStep === 3 && (
                  <InformationSignup
                    navigation={undefined}
                    id={""}
                    handleSuccessResponse={this.handleSuccessResponse.bind(
                      this
                    )}
                    data-test-id="informationsignup"
                    handleGoBackClick={this.handleGoBackClick}
                  />
                )}

                {currentStep === 4 && (
                  <Box style={webStyle.mainContainerFormPhone}>
                    <BusinessPhoneVerification
                      navigation={undefined}
                      id={""}
                      handleSuccessResponse={this.handleSuccessResponse.bind(
                        this
                      )}
                      handleGoBackClick={this.handleGoBackClick}
                      data-test-id="businessphoneverification"
                    />
                  </Box>
                )}
                {currentStep === 6 && (
                  <PaymentDetails
                    stripePaymentData={this.state.stripePaymentData}
                    paymentPrize={paymentPrize}
                    platformManagementPaymentPlans={platformManagementPaymentPlans}
                    selectedPricing={selectedPricing}
                    attributeName={attributeName}
                    handleGoBackClick={this.handleGoToPlanSelection}
                    platformManagementChecked={isSelectedPlanWithPlatformManagement}
                  />
                )}
              </Grid>
              <Grid
                item
                container
                md={6}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
              >
                <BusinessSideBar
                  data={sideBarContent[currentStep]}
                  currentStep={currentStep}
                />
              </Grid>
            </Grid>
          </Container>
        )}

        {currentStep === 5 && (
          <>
            <HeaderStepInfo />
            <div style={webStyle.errorblock}>
              {formGlobalError[currentStep] && (
                <EmailAccountLoginErrorBlock
                  errorMsg={formGlobalError[currentStep]}
                />
              )}
            </div>
            <PlanPayment
              data-test-id="planPayment"
              navigation={undefined}
              id={""}
              setSelectedPlan={this.setSelectedPlan}
              paymentPlanData={paymentPlanData}
              platformManagementPaymentPlans={platformManagementPaymentPlans}
              setPlatformManagement={this.setPlatformManagement}
              setSelectedPricing={this.setSelectedPricing}
              selectedPricing={selectedPricing}
            />
          </>
        )}
      </ThemeProvider>

      // Customizable Area End
    );
  }
}
