// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography } from "@material-ui/core";
import AnalyticsChartController from "./AnalyticsChartController.web";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export class AnalyticsChart extends AnalyticsChartController {
  render() {
    const { classes, chartTitle, startDate, endDate, chartData } = this.props;

    return (
      <Box className={classes.chartContainer}>
        <Box className={classes.chartDescription}>
          <Typography className={classes.chartTitle}>{chartTitle}</Typography>
          <Typography className={classes.chartInterval}>
            {`${startDate.format("MMM D")} - ${endDate.format("MMM D")}`}
          </Typography>
        </Box>

        <Box className={classes.chart}>
          <ResponsiveContainer width={"100%"} height={280}>
            <BarChart
              data={chartData}
              margin={{
                top: 5,
                right: 5,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="10 0" />
              <XAxis dataKey="name" />
              <YAxis orientation="right"/>
              <Tooltip />
              <Bar dataKey="value" fill="#FFC629" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    chartContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "29px",
      width: "100%",
    },

    chartDescription: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: "10px",
    },

    chartTitle: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26px",
      textTransform: "capitalize",
    },

    chartInterval: {
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "24px",
    },
  });

export default withStyles(styles)(AnalyticsChart);
// Customizable Area End
