export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const firststepimg1 = require("../assets/firststepimg1.png");
export const firststepimgwomen = require("../assets/image_woman_step1.png");
export const groupStarbar = require("../assets/group_Starbar.png");
export const groupStarbar2 = require("../assets/step2.png");
export const groupStarbar3 = require("../assets/step3.png");
export const groupStarbar4 = require("../assets/step4.png");
export const titleImg1 = require("../assets/textlabel_Step 1.png");
export const titleImg2 = require("../assets/textlabel_Step 02.png");
export const titleImg3 = require("../assets/textLabel_step3.png");
export const titleImg4 = require("../assets/textlabel_Step 4.png");
export const flags = require("../assets/Flags.png");
export const payment_1 = require("../assets/payment_1.png");
export const payment_3 = require("../assets/payment_3.png");
export const payment_start = require("../assets/payment_start.png");
export const PaymentStripe = require("../assets/PaymentStripe.png");
export const image_3_People = require("../assets/image_3_People 1.png");
export const unknownBrandImg = require("../assets/unknown.png");
export const visaImg = require("../assets/Visa.jpg");
export const unionpayImg = require("../assets/unionpay.png");
export const mastercardImg = require("../assets/Mastercard.png");
export const jsbImg = require("../assets/jsb.png");
export const discoverImg = require("../assets/discover.png");
export const dinersImg = require("../assets/diners.png");
export const amexImg = require("../assets/Amex.png");
export const creditCard = require("../assets/credit_card.svg");
export const backArrow = require("../assets/Arro_Back.png");
export const lockIcon = require("../assets/lock.png");







