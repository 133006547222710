// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import * as yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";



export const configJSON = require("./config");

export const isValidPhoneNumber = (mobile_number: string): boolean => {
  try {
    // Assuming `parsePhoneNumber` is a function available in your code
    const parsedPhoneNumber = parsePhoneNumber(`+${mobile_number}`);

    return !!parsedPhoneNumber && parsedPhoneNumber.isValid();
  } catch (error) {
    return false;
  }
}

export const stripePromise = loadStripe(configJSON.stripeKey);

export interface Props {
  navigation: any;
 location?:any
  id: string;
  contactId?: any;
  createId: number;
  classes: any;
  subscriptionListData?: any;
  waitlListFoldersName?: string;
  addFolderModalFunction?: any;
  
}

interface S {
  websites: any;
  activePopoverId: any;
  anchorEl: any;
  loading: boolean;
  createWebModal: boolean;
  websiteName: string;
  websiteUrl: string;
  websiteNameErr: string;
  websiteUrlErr: string;
  isShowAlert: boolean;
  alertMessage: string;
  endWaitlistModal: boolean;
  updateWebsiteId: string;
  openSubscripttionDialogue: boolean;
  addNotesField: boolean;
  setToken: string;
  folders: any;
  modalData: number;
  waitListContactsData: any;
  date: any;
  contactsInFoder: any;
  removeContactId: any;
  showSucess: string;
  errorAlertMessage: boolean;
  selectedContacts: any,
  normalizedContacts: any,
  checked: boolean,
  confirmModal: boolean
  addFolder: boolean,
  pricingRadioButton: boolean,
  automationTemplateData: any,
  customizableSubscriptionData: any
 
  folderName: string,
  openPaymentModal: boolean,
  clientSecretData: any,
  secretKey: any,
  bulkUploadContactsData: any,
  currentContactsModal: boolean,
  isCurrentContactSelected:boolean,
  currentContactListData:any,
  selectedCurrentContacts:any,
  selectedContactId: any,
  searchQuery:string,
  sendId:any,
  perticularContactDetail:any,
 actions:any,
 selectedContactName:string,
}
interface SS {
  id: any;
}
export default class WaitListController extends BlockComponent<Props, S, SS> {
  getwebsitesAPIID: any = "";
  createWebsiteAPIID: any = "";
  deleteWebsiteAPIID: any = "";
  updateWebsiteAPIID: any = "";
  getWaitListFoldersId: string = "";
  getWaitListContactsId: string = "";
  createWaitlistContactId: string = "";
  getContactsOfWaitlistFolderId: string = "";
  removeContactId: string = "";
  confirmAddContactToWaitListId: string = "";
  createAutomationTemplateId: string = "";
  getAutomationTemplateId: string = "";
  getCustomizableSubscriptionId: string = "";
  getCurrentBuPlanAPIID: string = "";
  getSubscriptionSecretKeyId: string = "";
  createWaitListFolderId: any = "";
  getdraftsContactsId: string = "";
  getCurrentContactListId: string="";
  getPerticularContactId:string="";
  removeBulkContactToWaitListId:string=""


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.SessionResponseMessage),
    getName(MessageEnum.ConnectTOSidebar),
   
    ];

    this.state = {
      websites: [],
      activePopoverId: null,
      anchorEl: null,
      loading: false,
      createWebModal: false,
      websiteName: "",
      websiteUrl: "",
      websiteNameErr: "",
      websiteUrlErr: "",
      isShowAlert: false,
      alertMessage: "",
      endWaitlistModal: false,
      updateWebsiteId: "",
      openSubscripttionDialogue: false,
      addNotesField: false,
      setToken: "",
      folders: [],
      modalData: 0,
      waitListContactsData: [],
      date: "",
      contactsInFoder: [],
      removeContactId: 0,
      showSucess: "",
      errorAlertMessage: false,
      selectedContacts: [],
      normalizedContacts: [],
      checked: false,
      confirmModal: false,
      addFolder: false,
      pricingRadioButton: false,
      automationTemplateData: [],
      customizableSubscriptionData: [],
     
      folderName: "",
      openPaymentModal: false,
      clientSecretData: {},
      secretKey: null,
      bulkUploadContactsData: [],
      currentContactsModal: false,
      isCurrentContactSelected:false,
      currentContactListData:[],
      selectedCurrentContacts:-1,
      selectedContactId: null,
      searchQuery:"",
      sendId:null,
      perticularContactDetail:{},
     actions:null,
     selectedContactName: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  isShowCell = () => {
    setTimeout(() => this.setState({ isShowAlert: false }), 3000);
  }


  closeModal = () => {
    this.setState({ createWebModal: false,automationTemplateData:{} });
  };

  currentContactSelected = (index:number,id:any) => {
   
    if (this.state.selectedCurrentContacts === index) {
    
      this.setState({ selectedCurrentContacts: -1 ,selectedContactId: null});
  } else {
   
      this.setState({ selectedCurrentContacts: index,selectedContactId: id });
  }
};

  pricingChangeFunction = () => {
    this.setState({ pricingRadioButton: !this.state.pricingRadioButton });
  };
  openCurrentContactsModal = (id:any) => {

    this.addFolderModal()
    this.getCurrentContactsApi(id)
    this.setState({ currentContactsModal: !this.state.currentContactsModal });
  };

  componentDidUpdate(prevProps:any, prevState:any) {
    // Check if the modal has been reopened
    if (prevState.currentContactsModal !== this.state.currentContactsModal && this.state.currentContactsModal === true) {
        // Reset the selected contact state
        this.setState({ selectedCurrentContacts: -1, selectedContactId: null });
    }
    if (prevState.waitListContactsData !== this.state.waitListContactsData && this.state.waitListContactsData.length > 0) {
      const firstContact = this.state.waitListContactsData[0];
      const selectedContactName = `${firstContact.attributes.first_name} ${firstContact.attributes.last_name}`;
      this.setState({ selectedContactName });
  }
}

 

  openModal = async (id:any) => {
   
      
   
      this.setState({ createWebModal:true,sendId:id });
      
    
    this.getAutomationTemplate(id)
  };


  paymentModal = async () => {
    this.crateWaitlistSubscriptionSecretKey()

    this.setState({ openPaymentModal: !this.state.openPaymentModal });
  };

  handleClickOpen = async (id: any, name: string) => {
    id && await this.getWaitListContactsList(id);
    this.setState({ openSubscripttionDialogue: !this.state.openSubscripttionDialogue, modalData: id, folderName: name })

  };


  sendInfoToMainLayout = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOSidebar))
    msg.addData(getName(MessageEnum.ShouldUpdate), data)
    this.send(msg)
  }

  
  addFolderModal = async () => {
    this.getCustomizableSubscription()
    this.setState({ addFolder: !this.state.addFolder })

  };
  handleClick = (event: any) => {

    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  openaddNotesField = () => {
    this.setState({ addNotesField: !this.state.addNotesField });
  };

  openConfirmModal = (id: any) => {

    this.setState({ confirmModal: true, removeContactId: id });
  };
  closeConfirmModal = () => {

    this.setState({ confirmModal: false });
  };
  openEndWaitListModal = () => {

    this.setState({ endWaitlistModal: true });
  };
  closeEndWaitListModal = () => {

    this.setState({ endWaitlistModal: false });
  };
  onDateChange = (date: any, setFieldValue: any) => {
    this.setState({date: date })
    setFieldValue('appointment_date',date)

  };

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);
    if (message.id == getName(MessageEnum.ConnectTOSidebar)) {
      let data = message.getData(getName(MessageEnum.ShouldUpdate))
      if (data && data.refresh) {
        this.getWaitListContactsList(this.state.modalData)
        this.getWaitListFolders()
        this.getDraftsContactsinWaitList()
      }
    }
    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        
        case this.getWaitListFoldersId:
          if (responseJson.data) {
            this.setState({ folders: responseJson.data })
          }
          break;

        case this.getWaitListContactsId:
          this.getWaitlistContactResponse(responseJson)
          break;


        case this.createWaitlistContactId:
          if (responseJson.error) {

            this.setState({ isShowAlert: true, showSucess: responseJson.error[0], errorAlertMessage: true }, () => {
              this.isShowCell()
            })
          }
          else {
            this.state.actions.resetForm()
            this.setState({ modalData: responseJson.data.attributes.waitlist_id }, async () => {

              this.sendInfoToMainLayout({ refresh: true })

              this.setState({ isShowAlert: true, showSucess: "Contact Created Successfully", errorAlertMessage: false,perticularContactDetail:{},date:""}, () => {
                this.isShowCell()
                this.getDraftsContactsinWaitList()
              })

            })
          }
          break;


        case this.confirmAddContactToWaitListId:
          this.conirmContactToWaitlistResponse(responseJson)
          break;

        case this.getAutomationTemplateId:
          this.getAutmationTemplateResponse(responseJson)
          break;

        case this.createAutomationTemplateId:
          this.createAutomationTemplateResponse(responseJson)
          break;

        case this.getCustomizableSubscriptionId:
          this.getCustomizableSubscriptionListResponse(responseJson)
          break;

        case this.getSubscriptionSecretKeyId:
          this.getClientSecretKeyResponse(responseJson)
          break;

          case this.getdraftsContactsId:
          this.getBulkUploadContactsResponse(responseJson)
          break;

        case this.removeContactId:
          this.getRemoveContactFromWaitlistResponse(responseJson)
          break;
         
          case this.getCurrentContactListId:
          this.getCurrentContactResponse(responseJson)
          break;
        
      case this.getPerticularContactId:
            this.getPerticularContactRespone(responseJson)
            break;

          case this.removeBulkContactToWaitListId:
             this.removeBulkContactToWaitlistResponse(responseJson)
             break;


      }








    }
  }

  handleSubmit = async (

    stripe: any,
    elements: any
  ) => {

    const submitResult = await elements.submit();

    if (submitResult.error) {
      console.error(submitResult.error.message);
      // Handle error appropriately
      return;
    }

    const matchResult = window.location.href.match(/^(https?:\/\/[a-z0-9\:\-.]+)\//);
    const dynamicBaseUrl = matchResult ? matchResult[1] : '';
    const returnUrl = `${dynamicBaseUrl}/waitList`;

    const result = await stripe.confirmPayment({
      elements,
      clientSecret: this.state.secretKey,
      confirmParams: {
        return_url: returnUrl
      },
      redirect: "if_required"
    });

    

    this.setState({
      isShowAlert: true,
      showSucess: "Waitlist Subscription Is Done Successfully"
    },  () => {
      this.isShowCell();
      this.paymentModal()
      this.props.addFolderModalFunction()
      this.createWaitListFolderApi()
    });


    if (result.error) {
      console.log(result.error.message);
    }
 
   

  };

  getCurrentContactResponse = (responseJson: any) => {

    if (responseJson.data) {
      this.setState({ currentContactListData: responseJson.data})

    }
  }
  getRemoveContactFromWaitlistResponse = (responseJson: any) => {
    if (responseJson.success) {
      this.sendInfoToMainLayout({ refresh: true })
      this.setState({ isShowAlert: true, showSucess: "Contact Is Removed Successfully" }, () => {
        this.isShowCell()
      })
    }
  }

  getPerticularContactRespone = (responseJson: any) => {

    if (responseJson.data) {
      this.setState({ perticularContactDetail: responseJson.data})

    }
  }


  getClientSecretKeyResponse = (responseJson: any) => {

    if (responseJson.data) {
      this.setState({ clientSecretData: responseJson.data, secretKey: responseJson.data.attributes.client_secret })

    }
  }


  getCustomizableSubscriptionListResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ customizableSubscriptionData: responseJson.data })
    }

  }


  getBulkUploadContactsResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ bulkUploadContactsData: responseJson.data })
    }
  }

  getAutmationTemplateResponse = (responseJson: any) => {

    if (responseJson) {
      this.setState({ automationTemplateData: responseJson.data })
    }
  }
  getWaitlistContactResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ waitListContactsData: responseJson.data })
    }
  }
  conirmContactToWaitlistResponse = (responseJson: any) => {
    if (responseJson) {

      this.sendInfoToMainLayout({ refresh: true })

      this.setState({ isShowAlert: true, showSucess: responseJson.message }, () => {
        this.isShowCell()
      })
    }
  }


  removeBulkContactToWaitlistResponse = (responseJson: any) => {
    if (responseJson) {

      this.sendInfoToMainLayout({ refresh: true })

      this.setState({ isShowAlert: true, showSucess: responseJson.message,selectedContacts:[] }, () => {
        this.isShowCell()
      })
    }
  }





  createAutomationTemplateResponse = (responseJson: any) => {
    if (responseJson.message === "automation not updated") {
      this.setState({ isShowAlert: true, errorAlertMessage: true, showSucess: responseJson.message },()=>{
        this.isShowCell()
        
      });
  } else {
      this.setState({ isShowAlert: true, errorAlertMessage: false, showSucess: responseJson.message },()=>{
        this.isShowCell()
        this.closeModal()
      });
  }
  }



  async componentDidMount(): Promise<void> {
   
    let token = await getStorageData("authToken");
    this.setState({ setToken: token }, () => {
      this.getWaitListFolders()
      
      this.getCurrentBuPlan();
      this.getDraftsContactsinWaitList();
      const id= this.props.navigation.getParam('id')
     
      
      this.getWaitListContactsList(id)
      .then(waitListContactsData => {
        // Check if waitListContactsData is an array and contains elements
        if (Array.isArray(waitListContactsData) && waitListContactsData.length > 0) {
            // Access the first element of the array and its attributes property
            const firstContact = waitListContactsData[0];
            // Ensure that the object has the attributes property
            if (firstContact && typeof firstContact === 'object' && firstContact.attributes) {
                // Now you can safely access the attributes property
                const selectedContactName = `${firstContact.attributes.first_name} ${firstContact.attributes.last_name}`;
                this.setState({ waitListContactsData, selectedContactName });
            }
        }
    })
    .catch(error => {
        // Handle error if any
        console.error("Error fetching waitlist contacts data:", error);
    });
      this.getWaitListFolders()
     
      
     
    
    
    });
   
   
  }


  
  validationSchema = yup.object().shape({
    first_name: yup.string().required(configJSON.firstNameErrorMessage),
    last_name: yup.string().required(configJSON.lastNameErrorMessage),
    full_phone_number: yup.string().required(configJSON.phoneNumberErrorMessage).
      test('phoneno', "Enter a valid phone number", function (value: any) {
        return value && isValidPhoneNumber(value);
      }),
      appointment_date: yup.date().required('Date is requied')

  
  });


  handleCheckboxChange = (contactId: any) => {
    
    const { selectedContacts } = this.state;

    // Toggle the selected state
    if (selectedContacts.includes(contactId)) {
      this.setState({
        selectedContacts: selectedContacts.filter((id: any) => id != contactId),
      });
    } else {
      this.setState({
        selectedContacts: [...selectedContacts, contactId],
      });
    }
  };




  handleConfirmRemoval = () => {

   

    this.removeconfirmContactsOfWaitlistApi(this.state.removeContactId)
    this.getDraftsContactsinWaitList()
    // Close the modal
    this.closeConfirmModal();
  };

 
  handleDoneButtonClick = async () => {

    const contactIds = this.state.bulkUploadContactsData.map((item: any) => item.id);
   
    const requestBody = {

      contact_ids: contactIds,
    };

    this.confirmContactsOfWaitlistApi(requestBody)

  };


  chatMemberChange = (selectedContactName:string) => {
    this.setState({ selectedContactName });
};
  async apiFrame(data: any) {
    const { contentType, method, endPoint, body, type } = data
    const apiHeader = {
      "Content-Type": contentType,
      token: this.state.setToken,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeader)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getWaitListFolders = async () => {
    this.getWaitListFoldersId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getWaitListFoldersEndPoint1
    });
  };
 
    
    
  
  getWaitListContactsList = async (id: number) => {
    this.setState({ modalData: id })
    this.getWaitListContactsId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getWaitListFoldersEndPoint2 + `/${id}/contacts`
    });
    return this.getWaitListContactsId
  };

  createContactApi = async (values: any, id: number, actions:any) => {
    let requestBody = {
      contact: {
        first_name: values.first_name,
        last_name: values.last_name,
        full_phone_number: `+${values.full_phone_number}`,
        appointment_date: moment(values.appointment_date).format("MM-DD-YYYY"),
        note: values.note,
        is_draft_contact: true
      },
    };

   this.setState({ actions: actions})

    this.createWaitlistContactId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.getWaitListFoldersEndPoint3 + `/${id}/contacts`,
      body: requestBody
    });

  };


  confirmContactsOfWaitlistApi = async (values: any) => {

    this.confirmAddContactToWaitListId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.addBulkContactsInWaitList,
      body: values
    });

  };


  removeBulkContactsOfWaitlistApi = async () => {
    const reqBody={
      "waitlist_id" : this.props.createId,
      "contact_ids" : this.state.selectedContacts
    }

    this.removeBulkContactToWaitListId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.removeBulkContactsInWaitLIst,
      body: reqBody
    });

  };


  removeconfirmContactsOfWaitlistApi = async (id:any) => {

    this.removeContactId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.removeWaitListContactEndPoint+`/${id}`,
      
    });

  };




  createAutomationTemplateApi = async (values: any) => {
  
    const requestBody = {
      attributes: {
        
        name: values.name,
        business_name: values.business_name,
        reminder_note: values.reminder_note,
        message_template: values.message_template
      },
    };
    this.createAutomationTemplateId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.putApiMethod,
      endPoint: configJSON.setUpAutomationEndPoint + `/${this.state.sendId}/update_waitlist_automation`,
      body: requestBody
    });

  };
  getAutomationTemplate = async (id:number) => {
    this.getAutomationTemplateId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getAutomationTemplateEndPoint+ `/${id}`
    });
  };

  getCustomizableSubscription = async () => {
    this.getCustomizableSubscriptionId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.getCustomizableSubscriptionEndPoint}?name=waitlist`
    });
  };

  getCurrentBuPlan = async () => {
    this.getCurrentBuPlanAPIID = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCurrentBuPlanEndPoint
    });
  };





  crateWaitlistSubscriptionSecretKey() {

    const selectedPlanIndex = this.state.pricingRadioButton ? 0 : 1;

    const formData = new FormData();
    formData.append('subscription[stripe_price_id]', this.props.subscriptionListData[0].attributes.plans[selectedPlanIndex].stripe_plan_id);
    formData.append('subscription[plan_id]', this.props.subscriptionListData[0].attributes.plans[selectedPlanIndex].id);
    formData.append('subscription[premium_waitlist]', "true");

    const headerData = {
      contentType: configJSON.contentType,
      Token: localStorage.getItem("authToken"),
    };


    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionSecretKeyId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSecretKeyForSubscription
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  createWaitListFolderApi = async () => {
    
    const requestBody = {
      "waitlist": {
        "name": "loius"
      }
    }
    this.createWaitListFolderId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.getWaitListFoldersEndPoint4,
      body: requestBody
    });
    setTimeout(() => {
      this.sendInfoToMainLayout({ refresh: true })
    }, 2000);
  };




  getDraftsContactsinWaitList = async () => {
    this.getdraftsContactsId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.addBulkContactsInWaitList
    });
  };
 
  getCurrentContactsApi = async (id:any) => {
    console.log(id,"idForContact")
    this.getCurrentContactListId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCurrentContactsEndPointForAutoFill+`/${id}`
    });
  };

  getAutoFillPerticularContactApi = async () => {
    this.getPerticularContactId = await this.apiFrame({
      contentType: configJSON.contentType,
      method: configJSON.getApiMethod,
      endPoint:configJSON.contactsInWaitListEndPoint+`/${this.state.selectedContactId}`
    });
  };

}
// Customizable Area End
