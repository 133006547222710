export const edit = require("../assets/edit.png");
export const backArrow = require("../assets/Arro_Back.png");
export const Scooter = require('../assets/scooter.jpg');
export const Woman = require('../assets/everyreel_woman.png');
export const FunnelWoman = require('../assets/funnel_woman.jpg');
export const message = require('../assets/icon_msg.svg');
export const socialMedia = require('../assets/social_media.svg');
export const everyBrandLogo = require('../assets/everybrand_logo.svg');
export const everyBrandLogoFilled = require('../assets/everybrand_logo_filled.svg');
export const heartIcon = require('../assets/heart_icon.svg');
export const phoneStatusIcons = require('../assets/phone_status.svg');
export const previewPlay = require('../assets/preview_play_button.svg');
export const avatarExample = require('../assets/avatar_example.jpg');
export const arrowBack = require('../assets/arrow_back.svg');
export const cloud = require('../assets/cloud.svg');
export const speechIcon = require('../assets/speech_icon.svg');
export const InstagramIcon = require("../assets/instagram.svg")
