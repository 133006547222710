// Customizable Area Start
import React, { CSSProperties } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  withStyles,
  TextareaAutosize
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import NewPostCoverController from "./NewPostCoverController.web";
import NewPostSettings from "./NewPostSettings.web";
import { NewPostCreationSteps } from "./enums";
import { arrowBack } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      display: "flex",
      flexBasis: "809px",
      maxWidth: "809px",
      padding: "29px 44px 40px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "22px",
      flexShrink: 0,
      borderRadius: "8px 8px 32px 8px",
      background: "#FFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
      "& *": {
        fontFamily: "Inter, 'sans-serif'",
      }
    },

    closeButton: {
      position: "absolute",
      top: "15px",
      right: "12px",
    },

    backButton: {
      position: "absolute",
      top: "32px",
      left: "45px",
      zIndex: 1,
      minWidth: "unset",
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      padding: 0,
      paddingRight: "1px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },

    settingsModal: {
      position: "fixed",
      zIndex: 2,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: "rgba(0, 0, 0, 0.38)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    modalTitle: {
      paddingLeft: "50px",
      alignSelf: "flex-start",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
      letterSpacing: "-0.12px",
    },
    previewContainer: {
      display: "flex",
      flexWrap: "nowrap",
      width: "100%",
      paddingBottom: "40px",
      gap: "70px",
      borderBottom: "1px solid #AEAEB2",
      paddingTop: "25px",
      borderTop: "1px solid #AEAEB2",
    },
    caption: {
      alignSelf: "flex-start",
      flexGrow: 1,
      fontSize: "20px",
      lineHeight: "30px",
      color: "#6F6F70",
      fontWeight: 400,
      resize: "none",
      border: "none",
      "&:focus": {
        outline: "none"
      }
    },
    imagePreviewContainer: {
      position: "relative",
      maxWidth: "118px",
      height: "187px",
      borderRadius: "5px",
      overflow: "hidden",
      background: "#F2F2F2",
      cursor: "pointer",
      "& > video": {
        width: "100%",
        objectFit: "cover",
        height: "100%"
      },
    },
    imagePreview: {
      display: "flex",
      maxWidth: "100%",
      height: "100%",
      objectFit: "cover",
      "& > img": {
        objectFit: "cover",
      },
    },

    coverContainer: {
      display: "flex",
      width: "94px",
      height: "100%",
      borderRadius: "5px",
      overflow: "hidden",
      background: "#F2F2F2",
      "& > img": {
        objectFit: "cover"
      }
    },
    postPreviewText: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      textAlign: "center",
      color: "#FFF",
      fontSize: "16px",
      fontWeight: 700,
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
    covers: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "0 10px",
      alignItems: "center",
      paddingBottom: "27px",
      borderBottom: "1px solid #AEAEB2",
    },
    coversTitle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignSelf: "start",
      color: "#000",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
    },
    coversOuterContainer: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
    },
    coversInnerContainer: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "scroll",
      alignItems: "flex-end",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      height: "148px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    frameContainer: {
      position: "relative",
    },
    frame: {
      width: "94px",
    },
    frameShade: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
    loadingText: {
      alignSelf: "center",
      justifySelf: "center",
      fontSize: "18px",
      fontWeight: 700,
    },
    footer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 10px",
      width: "100%",
    },
    advanceSettingsLink: {
      fontSize: "15px",
      color: "#6F6F70",
      cursor: "pointer",
      fontWeight: 400,
      padding: "2px 10px",
      borderRadius: "12px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.04)"
      },
    },
    buttonsContainer: {
      display: "flex",
      gap: "10px",
    },
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "132px",
      minWidth: "132px",
      height: "44px",
      borderRadius: "8px",
      backgroundColor: "#FFF",
      border: "1px solid #FFC629",
      color: "#000",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      "&:last-child": {
        backgroundColor: "#FFC629",
        "&:hover": {
          backgroundColor: "rgba(255, 198, 41, 0.80)",
          color: "#000",
        },
      },

      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        color: "#FFF",
      },
    },
    discardButton: {
      "&:hover": {
        border: "none",
      }
    }
  });


class NewPostCover extends NewPostCoverController {
  render() {
    const {
      files,
      classes,
      cover,
      isCommentsAllowed,
      allowComments,
      setStep,
      closeModal,
      sendPost,
      setCover,
    } = this.props;
    const { frames, showSettings } = this.state;

    return (
      <>
        {showSettings && (
          <Container
            id="advancedSettingsModalBackground"
            maxWidth={false}
            disableGutters
            className={classes.settingsModal}
            onClick={(e) => {
              e.stopPropagation();
              this.showSettings();
            }}
          >
            <NewPostSettings
              isCommentsAllowed={isCommentsAllowed}
              allowComments={allowComments}
              closeModal={this.closeSettings}
            />
          </Container>
        )}

        <Container
          id="confirmFileContainer"
          maxWidth={false}
          className={classes.modal}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            id="backButton"
            className={classes.backButton}
            onClick={() => setStep(NewPostCreationSteps.ADD_CAPTION)}
          >
            <img src={arrowBack} alt="back button" />
          </Button>
          <IconButton
            id="closeModalButton"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.modalTitle}>New Post</Typography>
          
          <Box className={classes.previewContainer}>
            <TextareaAutosize
              maxRows={4}
              className={classes.caption}
              placeholder="Introducing our new sound bar. Enjoy your pizza in anything besides boring silence."
            />
            <Box
              id="postPreview"
              className={classes.imagePreviewContainer}
              onClick={() => setStep(NewPostCreationSteps.POST_PREVIEW)}
            >
              {cover || files[0]?.type.includes("image") ? (
                <img
                  className={classes.imagePreview}
                  src={cover || files[0]?.preview}
                  alt=""
                />
              ) : (
                <video controls={false}>
                  <source src={files[0]?.preview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              <Typography className={classes.postPreviewText}>
                Preview post
              </Typography>
            </Box>
          </Box>
          <Box className={classes.covers}>
            <Typography className={classes.coversTitle}>
              Select cover
            </Typography>
            <Box className={classes.coversOuterContainer}>
              <Box className={classes.coversInnerContainer}>
                {files[0]?.type.includes("video") &&
                  (Boolean(frames.length) ? (
                    frames.map((frameURL, i) => (
                      <Box
                        key={`cover-${i}`}
                        id={`cover-${i}`}
                        className={classes.frameContainer}
                        onClick={() => setCover(frameURL)}
                      >
                        <img
                          className={classes.frame}
                          style={{
                            height: frameURL === cover ? "100%" : "85%",
                            borderRadius: frameURL === cover ? "5px" : "none",
                          }}
                          src={frameURL}
                          alt="video cover sample"
                        />

                        {frameURL !== cover && (
                          <Box className={classes.frameShade}></Box>
                        )}
                      </Box>
                    ))
                  ) : (
                    <Typography className={classes.loadingText}>
                      Processing...
                    </Typography>
                  ))}
                {files[0]?.type.includes("image") && (
                  <Box className={classes.coverContainer}>
                    <img
                      src={files[0].preview}
                      className={classes.frame}
                      alt="image preview"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Typography
              id="advancedSettingsButton"
              className={classes.advanceSettingsLink}
              onClick={this.showSettings}
            >
              Advanced Settings
            </Typography>
            <Box className={classes.buttonsContainer}>
              <CustomButton
                id="discardButton"
                btnType="primary-outlined"
                onClick={closeModal}
              >
                Discard
              </CustomButton>
              <CustomButton
                id="postButton"
                data-test-id="postVideo"
                onClick={() => {
                  sendPost();
                  this.uploadPost()
                }}
                disabled={this.state.isPostadded}

              >
                Post
              </CustomButton>
            </Box>
          </Box>
        </Container>
      </>
    );
  }
}

export { NewPostCover };
export default withStyles(styles)(NewPostCover);
// Customizable Area End
