// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

const steps = [
  {
    id: 1,
    active: true,
    completed: false,
    unread: false,
  },
  {
    id: 2,
    active: false,
    completed: false,
    unread: false,
  },
  {
    id: 3,
    active: false,
    completed: false,
    unread: false,
  },
  {
    id: 4,
    active: false,
    completed: false,
    unread: false,
  },
  {
    id: 5,
    active: false,
    completed: false,
    unread: false,
  },
  {
    id: 6,
    active: false,
    completed: false,
    unread: false,
  },
  {
    id: 7,
    active: false,
    completed: false,
    unread: false,
  },
  {
    id: 8,
    active: false,
    completed: false,
    unread: false,
  },
];

const stepTwoPlans = [
  {
    id: 1,
    title: "Low Volume Standard",
    includes: [
      "EIN required for registration",
      "More than 1 Phone Number permitted",
      "Upto 6000 segments per day",
    ],
    isSelected: true,
    isRecomended: true,
  },
  {
    id: 2,
    title: "High Volume Standard",
    includes: [
      "EIN required for registration",
      "More than 1 Phone Number permitted",
      "Upto 6000 segments per day",
    ],
    isSelected: false,
    isRecomended: false,
  },
];
export interface Props {
  navigation: any;
  id: string;
}



interface S {
  formModal: boolean;
  steps: any;
  stepTwoPlans: any;
  activeStep: number;
  phone: string;
  socialPhoneNumberAPIData: any;
  business_tx_id: string;
  loader: boolean;
  legal_business_name:string
  first_name: string
  last_name: string
  email: string
  phoneNumber: string
  streetAddress: string
  city: string
  zipCode: string
  country: string
  region: string
  brandName: string
  businessIndustry: string
  mobileForOtp: string
  campaignUseCase: string
  campaignUseCaseDescription: string
  sampleMessageOne:string
  sampleMessageTwo:string
  endUserCnsent: string
  otpInKeywords: string
  otpInMessages:string,
  emailError:string
}
interface SS {
  id: any;
}
export default class SocialPhoneNumberController extends BlockComponent<
  Props,
  S,
  SS
> {
  createSoicalAPIID: any = "";
  formTopRef: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.formTopRef = React.createRef();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      formModal: false,
      steps: steps,
      stepTwoPlans: stepTwoPlans,
      activeStep: 1,
      phone: "",
      business_tx_id: "yes",
      loader: false,
      legal_business_name: "",
      first_name: "",
      last_name: "",
      email: "",
      phoneNumber: "",
      streetAddress: "",
      city: "",
      zipCode: "",
      country: "",
      region: "",
      brandName: "",
      businessIndustry: "",
      mobileForOtp: "",
      campaignUseCase: "",
      campaignUseCaseDescription: "",
      sampleMessageOne:"",
      sampleMessageTwo:"",
      endUserCnsent: "",
      otpInKeywords: "",
      otpInMessages:"",
      emailError:"",
      socialPhoneNumberAPIData: {},
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);
 
    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.createSoicalAPIID){
        console.log(responseJson)
      }
    }
  }

  closeFormModal = () => {
    this.setState({ formModal: false });
  };

  hanleStepOnRadio = (e: any) => {
    this.setState({ business_tx_id: e.target.value });
  };

  openFormModal = () => {
    this.setState({ formModal: true });
  };

  handleStepTwoRadioIcon = (item: any) => {
    let newdata: any = this.state.stepTwoPlans.map((x: any) => {
      if (x.isSelected === item.isSelected) {
        x.isSelected = !x.isSelected;
      } else {
        x.isSelected = false;
      }
      return x;
    });
    this.setState({ stepTwoPlans: newdata });
  };


  handleStepThree(){
    if(this.state.email===""){
      this.setState({emailError : "Email is mandatory"})
    }else{
      this.setState({emailError : ""})
      this.handleNextStep(3)
    }
  }


  handleNextStep(currStepId: number) {
    if (this.formTopRef.current) {
      this.formTopRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    this.setState((prevState) => {
      const updatedSteps = prevState.steps.map((step: any) => {
        if (step.id === currStepId) {
          // Current step: set completed to true and active to false
          return { ...step, completed: true, active: false };
        } else if (step.id === currStepId + 1) {
          // Next step: set active to true
          return { ...step, active: true };
        }
        return step;
      });

      return { steps: updatedSteps, activeStep: currStepId + 1 };
    });
  }
  
  getBrand(title:string){
    let brand = 0
    if(title !== "Low Volume Standard"){
      brand = 1
    }
    return brand
  }

  callSocialPostAPI(plan: any) {
    let selectedBrand = this.state.stepTwoPlans.filter((e:any)=> e.isSelected)[0]
    let brand = this.getBrand(selectedBrand.title)
   
    const tempData = {
      "verification_status":true,
      "bunsiness_tax_id": plan.business_tx_id,
        "brand_plan":brand,
        "street_address":plan.streetAddress,
        "city":plan.city,
        "postal_zip_code":plan.zipCode,
        "country":plan.country,
        "state": plan.region,
        "brand_name":plan.brandName,
        "brand_industry":plan.businessIndustry,
        "mobile_number":plan.phone,
        "mobile_number_for_otp":plan.mobileForOtp,
        "campaign_use_case":plan.campaignUseCase,
        "campaign_use_case_description":plan.campaignUseCaseDescription,
        "sample_message_1":plan.sampleMessageOne,
        "sample_message_2":plan.sampleMessageTwo,
        "end_user_concent_message":plan.endUserCnsent,
        "otp_in_keywords":plan.otpInKeywords,
        "otp_in_message":plan.otpInMessages
    }
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSoicalAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSocialPhoneNumberEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(tempData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleBackStep(currStepId: number) {
    this.setState((prevState) => {
      const updatedSteps = prevState.steps.map((step: any) => {
        if (step.id === currStepId) {
          // Current step: set it inactive
          return { ...step, active: false };
        } else if (step.id === currStepId - 1) {
          // Previous step: set it active and not completed
          return { ...step, active: true, completed: false };
        }
        return step;
      });

      return { steps: updatedSteps, activeStep: currStepId - 1 };
    });
  }
}
// Customizable Area End
