// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { File, FileFromLibrary } from "./types";
import { getToken } from "../../../components/src/AuthService";
import { getMediafileType } from "../../utilities/src/GetMediafileType";

export const configJSON = require("./config");

export type Props = {
  id?: string;
  classes?: any;
  navigation?: any;
  goBack: () => void;
  setFiles: (selectedFiles: File[]) => void;
};

interface S {
  filesFromLibrary: FileFromLibrary[];
  selectedFiles: FileFromLibrary[];
  error: string;
}

interface SS {
  navigation: any;
}

export default class EverybrandLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMediaFilesApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      filesFromLibrary: [],
      selectedFiles: [],
      error: "",
    };
  }

  async componentDidMount() {
    this.getMediaFiles();
  }

  selectFile = (fileIndex: number) => {
    const selectedFile = this.state.filesFromLibrary[fileIndex];

    this.setState((prevState) => {
      let selectedFiles: FileFromLibrary[] = prevState.selectedFiles;

      if (
        !prevState.selectedFiles.find((file) => file.id === selectedFile.id)
      ) {
        selectedFiles.push(selectedFile);
      } else {
        selectedFiles = prevState.selectedFiles.filter(
          (file) => file.id !== selectedFile.id
        );
      }

      return {
        selectedFiles,
      };
    });
  };

  acceptFiles = () => {
    this.props.setFiles(
      this.state.selectedFiles.map((file) => ({
        filePreview: file.attributes.files,
        preview: file.attributes.files,
        name: "",
        type: getMediafileType(file.attributes.files) || "",
      }))
    );
  };

  getMediaFiles = () => {
    const headerData = {
      "Content-Type": configJSON.libraryContentType,
      token: getToken(),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMediaFilesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMediaFilesEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.getMediaFilesApiCallId) {
      this.handleGetMediaFilesAPIResponse(message);
    }
  }

  handleGetMediaFilesAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.error || responseJson?.errors?.length) {
      this.setState({
        error:
          responseJson?.error ||
          responseJson?.errors[0] ||
          "Something went wrong",
      });
      return;
    }

    if (responseJson?.data) {
      this.setState({
        filesFromLibrary: responseJson?.data.reverse(),
      });
    }
  };
}

// Customizable Area End
