// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CaptionViews, NewPostCreationSteps } from "./enums";
import { File, Poll } from "./types";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  files: File[];
  poll: Poll;
  caption: string;
  view: CaptionViews;
  setStep: (step: NewPostCreationSteps) => void;
  closeModal: () => void;
  setCaption: (caption: string) => void;
  setPollQuestion: (question: string) => void;
  setPollAnswer1: (answer: string) => void;
  setPollAnswer2: (answer: string) => void;
  setCaptionView: (view: CaptionViews) => void;
};

interface S {
  currentFile: number;
}

interface SS {
  navigation: any;
  stepType: NewPostCreationSteps;
}

export default class NewPostAddCaptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  videoPreviewRef: React.RefObject<HTMLVideoElement>;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.videoPreviewRef = React.createRef();

    this.state = {
      currentFile: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  setCurrentFile = (fileIndex: number) => {
    if (fileIndex !== this.state.currentFile) {
      this.setState({
        currentFile: fileIndex,
      });

      this.videoPreviewRef.current?.load();
    }
  };
}
// Customizable Area End
