import React from "react";
import NavigationMenuEUWeb from "../../../components/src/NavigationMenuEU.web";
import { Box, Typography } from "@material-ui/core";
import { HomeIcon } from "./assets";
import TopActiveUsers from "./TopActiverUsers.web";
import SuggestedBrands from "../../../components/src/SuggestedBrands";
import LikeButton from "../../../components/src/LikeButton";
import EuDashboardController from "./EuDashboardController.web";
import CommentButton from "../../../components/src/CommentButton";
import ImageButton from "../../../components/src/ImageProfile";
import PostInfo from "../../../components/src/PostInfo";
import SettingsButton from "../../../components/src/SettingsButton";
import CustomLoader from "../../../components/src/CustomLoader.web";

export default class EuDashboard extends EuDashboardController {
  handleFileRender (postFile: {
    link: string
  }, index: number) {
    return (
      this.checkIsVideo(postFile) ? (
        <video
          ref={this.state.videoRefsHome[index]}
          src={postFile.link}
          style={styles.image}
          controls
        />
      ) : (
        <img
          src={postFile.link}
          alt=""
          style={styles.image}
        />
      )
    )
  }

  render() {
    const { navigation } = this.props
    const { posts, suggestedBrandsHome, top6 } = this.state;

    return (
      <div style={styles.root} data-test-id="eu-home">
        <NavigationMenuEUWeb brands={this.state.brandsIFollowHome} />
        <Box style={styles.mainContainer} data-test-id="eu-home-wrapper">
          <Box style={styles.contentContainer}>
            <Box style={styles.imagesContainer} data-test-id="eu-home-post-wrapper" onScroll={this.handleScrollHomePost}>
              <Typography variant="h6" style={styles.heading}>
                <img src={HomeIcon} alt="" style={styles.title} />
                HOME
              </Typography>
              {posts &&
                posts.map((post, index) => {
                  const postFile = post.attributes.files
                    ? post.attributes.files[0]
                    : null;
                  const amountLike = post.attributes.likes_count
                    ? post.attributes.likes_count
                    : 0;
                  const isLiked = post.attributes.is_liked;
                  return (
                    postFile && (
                      <Box key={post.id} style={styles.imageContainer}>
                        {postFile ? this.handleFileRender(postFile, index) : (
                      ""
                    )}
                        
                        <PostInfo 
                          title={"Title Post Example"} 
                          description={post.attributes.description ? post.attributes.description : ''} 
                        />
                        {post.attributes.account_profile && 
                          <ImageButton
                            imageUrl={post.attributes.account_profile.url}
                          />
                        }
                        <LikeButton
                          amount={amountLike}
                          isLiked={isLiked}
                          handleLikes={() => this.handlePostLikes(post.id)}
                        />
                        <CommentButton amount={post.attributes.comments?.data.length} />
                        <SettingsButton />
                      </Box>
                    )
                  );
                })}
                <CustomLoader loaderSize={24} isLoading={this.state.isEUHomePostLoading} />
            </Box>
            <Box style={styles.sideContainer}>
              <Box>
                <TopActiveUsers
                  navigation={navigation}
                  top6={top6}
                />
              </Box>
              <Box>
                <SuggestedBrands
                  brands={suggestedBrandsHome}
                  followBrand={this.followBrandApi}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

const styles = {
  root: {
    backgroundColor: "#f1f4f9",
    minHeight: "100vh"
  } as React.CSSProperties,
  title: { marginRight: "15px" },
  mainContainer: {
    width: "100vw",
    maxWidth: "1240px",
    margin: "15px auto"
  } as React.CSSProperties,
  contentContainer: {
    padding: "0px 20px",
    display: "flex", 
    gap: "15px", 
    justifyContent: "center"
  },
  imagesContainer: {
    padding:"10px 200px",
    width: "70%",
    backgroundColor: "#FFFFFF",
    maxHeight: "900px",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column"
  } as React.CSSProperties,
  sideContainer: {
    width: "30%",
  } as React.CSSProperties,
  heading: {
    margin: "30px 0 20px 45px",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700
  },
  imageContainer: {
    position: "relative",
    // margin: "10px 24%",
    // width: "522px",
    margin:"10px",
    display: "block"
  } as React.CSSProperties,
  image: {
    borderRadius: "10px",
    width: "100%",
    height: "calc(100vh - 200px)"
  }
};
