const numberToCount = (value) => {
  const thresholds = [
    { value: 1_000_000_000_000, suffix: "T" },
    { value: 1_000_000_000, suffix: "B" },
    { value: 1_000_000, suffix: "M" },
    { value: 1_000, suffix: "K" },
  ];

  for (const { value: threshold, suffix } of thresholds) {
    if (value >= threshold) {
      return (value / threshold).toFixed(1).replace(/\.0$/, "") + suffix;
    }
  }

  return value.toString();
};

const timeSince = (date) => {
  const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);

  if (seconds >= 31536000) {
    const years = Math.floor(seconds / 31536000);
    return years === 1 ? `${years} year` : `${years} years`;
  }
  if (seconds >= 2592000) {
    const months = Math.floor(seconds / 2592000);
    return months === 1 ? `${months} month` : `${months} months`;
  }
  if (seconds >= 86400) {
    const days = Math.floor(seconds / 86400);
    return days === 1 ? `${days} day` : `${days} days`;
  }
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    return hours === 1 ? `${hours} h` : `${hours} hour`;
  }
  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return minutes === 1 ? `${minutes} min` : `${minutes} mins`;
  }
  return `${seconds} sec`;
}

export {
  timeSince,
  numberToCount
}
