// Customizable Area Start
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { settingsMenuOptions } from './constants';
import { SettingsIcon } from './assets';
import { Settings } from './enums';

interface Props {
  selectedMenu: Settings;
  onMenuSelect: (selectedMenu: Settings) => void;
  isUpdateSubscriptionDisabled:boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '0 8px 20px 0',
      boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
      padding: theme.spacing(1.6),
      height: "calc(100vh - 64px)",
    overflowX: "hidden",
      [theme.breakpoints.up('sm')]: {
        width: '353px',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      margin: theme.spacing(5, 0, 2, 1),
      '& p': {
        color: '#0F172A',
        textTransform: 'uppercase',
        fontWeight: 700,
      },
    },
    listItem: {
      height: '48px',
      padding: theme.spacing(2.5, 2, 2.5, 3),
      borderRadius: theme.spacing(1),
      backgroundColor: '#F1F5F9',
      '&:hover': {
        backgroundColor: '#FFC629',
      },
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
      '&.Mui-selected': {
        backgroundColor: '#FFC629',
        '&:hover': {
          backgroundColor: '#FFC629',
        },
      },
      [theme.breakpoints.up('sm')]: {
        height: '68px',
      },
    },
    listItemText: {
      color: '#0F172A',
      fontSize: '14px',
      fontWeight: 700,
    },
  })
);

function SettingsMenu({ selectedMenu, onMenuSelect,isUpdateSubscriptionDisabled }: Props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <img src={SettingsIcon} />
        <Typography>Settings</Typography>
      </div>
      <List>
        {settingsMenuOptions.map((menu) => (
        
          <ListItem
            key={menu.key}
            button
            className={classes.listItem}
            selected={menu.key === selectedMenu}
            onClick={() => onMenuSelect(menu.key)}
            disabled={!isUpdateSubscriptionDisabled && menu.option !== 'Update subscription'}
          >
            
            <ListItemIcon>
              <img src={menu.icon} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={menu.option}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default SettingsMenu;
// Customizable Area End
