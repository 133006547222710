// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef, ChangeEvent } from 'react';
import { titleImg1, titleImg2, titleImg3, titleImg4, groupStarbar2, groupStarbar, groupStarbar3, groupStarbar4 } from "./assets";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    emailAddress: string;
    emailError: string;
    formError: any;
    formGlobalError: any;
    showPassword: boolean;
    businessPassword: string;
    businessConfirmPassword: string;
    showConfirmPassword: boolean;
    passwordsMatch: boolean;
    hasCapitalLetter: boolean,
    hasLowerCaseLetter: boolean,
    hasNumber: boolean,
    hasMinLength: boolean,
    currentStep: number,
    sideBarContent: {
        [step: number]: {
            imagetitle: string;
            image: string;
        };
    },
    firstName: string,
    lastName: string,
    businessWebsite: string,
    zipcode: any,
    mobileNumber: any,
    otp: string[];
    emailValidationResult: any;
    EmailPasswordID: number;
    userToken:string;

}

interface ApiProps {
    method: string;
    endPoint: string;
    body?: any;
    header:any;
}
interface SS {

    id: any;

}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {

    apiEmailLoginCallId: string = "";
    apiEmailSignupCallId: any = "";
    apiEmailSignupVerificationId: any = "";
    apiEmailSignupVerificationOtpId: any = "";
    apiEmailSignUpOtpSendCallId: any = "";
    apimailSignUpDetailsCallId: any = "";
    validationApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            emailAddress: "",
            emailError: "",
            formError: {},
            formGlobalError: {},
            businessPassword: '',
            businessConfirmPassword: '',
            showPassword: false,
            showConfirmPassword: false,
            passwordsMatch: true,
            hasCapitalLetter: false,
            hasLowerCaseLetter: false,
            hasNumber: false,
            hasMinLength: false,
            currentStep: 1,
            sideBarContent: {
                1: {
                    imagetitle: titleImg1,
                    image: groupStarbar,
                },
                2: {
                    imagetitle: titleImg2,
                    image: groupStarbar2,
                },
                3: {
                    imagetitle: titleImg3,
                    image: groupStarbar3,
                },
                4: {
                    imagetitle: titleImg4,
                    image: groupStarbar4,
                },
            },
            firstName: "",
            lastName: "",
            businessWebsite: "",
            zipcode: "",
            mobileNumber: "",
            otp: Array(6).fill(''),
            emailValidationResult: "q@q.com",
            EmailPasswordID:0,
            userToken:""
        }


        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    inputRefs = Array(6).fill(null).map(() => createRef<HTMLInputElement>());

    handleInputChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        event.preventDefault();
        const value = event.target.value;
        const updatedOtp = [...this.state.otp];
        updatedOtp[index] = value;
        const otpDetail = updatedOtp.join('');
        this.setState({ otp: updatedOtp });
        if (value && index < this.inputRefs.length - 1) {
            this.inputRefs[index + 1].current?.focus();
        }
        else if (!value && index > 0) {
            this.inputRefs[index - 1].current?.focus();
        }
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace') {
            event.preventDefault();

            if (index < this.inputRefs.length) {
                const updatedOtp = [...this.state.otp];
                updatedOtp[index] = '';
                this.setState({ otp: updatedOtp });
                if (index !== 0) {
                    this.inputRefs[index-1].current?.focus();
                } else {
                    this.inputRefs[index].current?.focus();
                }
            }

        }
        
         else if (event.key === 'Delete') {

            event.preventDefault();
            if (index <= this.inputRefs.length - 1) {
                const updatedOtp = [...this.state.otp];
                updatedOtp[index] = '';
                this.setState({ otp: updatedOtp });
                if (index !== this.inputRefs.length - 1) {
                    this.inputRefs[index + 1].current?.focus();
                } else {
                    this.inputRefs[index].current?.focus();
                }
            }
        }
    };


    handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value }: { name: string; value: string } = e.target;
        this.setState({
            [name]: value,
        } as unknown as Pick<S, keyof S>)

        if (this.state.businessPassword) {
            this.handlePasswordChange(e.target.value);
        }
    };



    validateStep1 = () => {
        const errors: any = {};
        if (this.state.emailAddress === "") {
            errors.emailAddress = "Email address field is required";
        }
        else if (!this.state.emailAddress || !this.state.emailAddress.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            errors.emailAddress = "Invalid email address";
        }
        return errors;
    }

    validateStep2 = () => {
        const errors: any = {};
        const { businessPassword, businessConfirmPassword, passwordsMatch, hasCapitalLetter, hasLowerCaseLetter, hasNumber, hasMinLength } = this.state
        if (businessPassword === "") {
            errors.businessPassword = "Password Field is required";
        }
        if (businessConfirmPassword === "") {
            errors.businessConfirmPassword = "Confirm password Field is required";
        }
        else if (!businessPassword || businessPassword !== businessConfirmPassword) {
            errors.businessConfirmPassword = "Password and confirm password should be the same";
        }
        else if (!passwordsMatch || !hasCapitalLetter || !hasLowerCaseLetter || !hasNumber || !hasMinLength) {
            errors.businessPassword = "Please ensure that the password criteria are met";
            errors.businessConfirmPassword = "Please ensure that the password criteria are met";
        }
        return errors;
    }

    validateStep3 = () => {
        const errors: any = {};
        if (!this.state.firstName) {
            errors.firstName = "First Name field is required";
        }
        if (!this.state.lastName) {
            errors.lastName = "Last Name field is required";
        }
        if (!this.state.businessWebsite) {
            errors.businessWebsite = "Business Website field is required";
        }
        if (!this.state.zipcode) {
            errors.zipcode = "Zipcode field is required";
        }
        if (!this.state.mobileNumber) {
            errors.mobileNumber = "Mobile Number field is required";
        }
        if (!this.state.mobileNumber.match(/^[0789]\d{9}$/)) {
            errors.mobileNumber = "Mobile Number is not valid";
        }
        if (this.state.zipcode && !this.state.zipcode.match(/^\d+$/)) {
            errors.zipcode = "Zipcode must be a numeric value";
        }
        return errors;
    }

    validateStep4 = ()=>{
        const errors: any = {};

        if (this.state.otp.some((value) => value === "")) {
            errors.otp = "Please enter the code.";
        }
        return errors;

    }

    validateFieldsForStep = (step: number, values: any) => {
        switch (step) {
            case 1:
                return this.validateStep1()
            case 2:
                return this.validateStep2()
            case 3:
                return this.validateStep3()
            case 4:
                return this.validateStep4();
           
        }
    };


    handleNextClick = () => {
        const { currentStep, ...fields } = this.state;
        const errors = this.validateFieldsForStep(currentStep, fields);
        this.setState({
            formError: errors,
        });
        if (Object.keys(errors).length === 0) {

            if (this.state.currentStep === 1) {
                this.doEmailSignUpVerification();
            }
            if (this.state.currentStep === 2) {
                this.doEmailSignUp();
            }
            if (this.state.currentStep === 3) {
                this.doEmailSignUpDetails();
            }
            if (this.state.currentStep === 4) {
                this.doEmailSignUpVerifyOtp();
                return;
            }
        }
    };

    handleGoBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState((prevState) => ({
            currentStep: prevState.currentStep - 1,
        }));
    };

    toggleConfirmPasswordVisibility = () => {
        this.setState((prevstate) => ({
            showConfirmPassword: !prevstate.showConfirmPassword
        }))
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    handlePasswordChange = (text: string) => {
        const password = text;
        this.setState({
            hasCapitalLetter: /[A-Z]/.test(password),
            hasLowerCaseLetter: /[a-z]/.test(password),
            hasNumber: /\d/.test(password),
            hasMinLength: password.length >= 8,
        });
    };
    
    getCheckCircleIconStyle = (condition:any, webStyle:any) => {
        const style = { ...webStyle };

        if (condition) {
          style.color = 'green';
        } else {
          style.color = '#94A3B8';
        }
      
        return style;
    };

    resendOTP=(e:React.MouseEvent<HTMLButtonElement>)=>{
        e.preventDefault();
        this.doEmailSignUpOtpSend();
    }

    apiCall = (apiData: ApiProps) => {
        const { method, endPoint, body ,header } = apiData;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    async doEmailSignUp() {
        const attrs = {
            first_name: "dummy",
            email: this.state.emailAddress,
            password: this.state.businessPassword,
            password_confirmation: this.state.businessConfirmPassword,
            role_name: "business_user"
        };

        const data = {
            type: "email_account",
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };
        
        const headerData= {
            "Content-Type": configJSON.loginApiContentType,
        }

        this.apiEmailSignupCallId = await this.apiCall({
            header:headerData,
            method: configJSON.signupAPiMethod,
            endPoint: configJSON.SignUpEmailEndpoint,
            body: httpBody,
            
        });

        return true;
    }

    async doEmailSignUpVerification() {
        const headerData= {
            "Content-Type": configJSON.loginApiContentType
        }
        if (this.state.emailValidationResult !== this.state.emailAddress) {
            this.apiEmailSignupVerificationId = await this.apiCall({
                header:headerData,
                method: configJSON.signupVerificationAPiMethod,
                endPoint: `${configJSON.signupEmailVerificationEndpoint}?email=${this.state.emailAddress}`,
                body: "",
            });
        }
        else {
            this.setState((prevState) => ({
                currentStep: prevState.currentStep + 1,
            }));
        }
    }

    async doEmailSignUpVerifyOtp() {
        const headerData={
            "Content-Type": configJSON.loginApiContentType,
            "Token":this.state.userToken
        }
        const updatedOTP=this.state.otp.join("")
        this.apiEmailSignupVerificationOtpId = await this.apiCall({
            header:headerData,
            method: configJSON.signupVerifyOtpAPiMethod,
            endPoint: `${configJSON.SignUPVerifyOtpEndpoint}?otp=${updatedOTP}`,
        });
        return true;
    }

    async doEmailSignUpOtpSend() {
        const attrs = {
            mobileNumber: this.state.mobileNumber,
            role_name: "business_user"
        };

        const data = {
            type: "email_account",
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };

        const headerData={
            "Content-Type": configJSON.loginApiContentType,
            "Token":this.state.userToken
        }

        this.apiEmailSignUpOtpSendCallId = await this.apiCall({
            header:headerData,
            method: configJSON.signupSendOtpAPiMethod,
            endPoint: configJSON.SignUPSendOtpEndpoint,
        });

        return true;
    }

    async doEmailSignUpDetails() {
        const attrs = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            full_phone_number: `+91 ${this.state.mobileNumber}`,
            sign_up_step:this.state.currentStep,
            country_code: this.state.zipcode,
        };
       
        const httpBody = {
            data: attrs,
        };
        
        const headerData={
            "Content-Type": configJSON.loginApiContentType,
            "Token":this.state.userToken
        }

        this.apimailSignUpDetailsCallId = await this.apiCall({
            method: configJSON.signupAPiDetailMethod,
            endPoint: `${configJSON.SignUpEmailEndpoint}/${this.state.EmailPasswordID}`,
            body: httpBody,
            header:headerData
        });

        return true;
    }

    apicallresponseData = (responseJson: any) => {
        if (responseJson && (responseJson.error || responseJson.errors)) {
            const errMsg = "Something went wrong!";
            this.setState({ formGlobalError: { [this.state.currentStep]: errMsg } });
        }
       
        else if (responseJson && !responseJson.error) {
            if (responseJson?.valid === false) {
                const errMsg = "Email is already exists.";
                this.setState({ formGlobalError: { [this.state.currentStep]: errMsg } });
            } 
            else {
                // if(this.state.currentStep === 3) {
                //     this.doEmailSignUpOtpSend()
                // }
               if(responseJson.data){
                this.setState({
                    EmailPasswordID:responseJson?.data.id,
                    userToken:responseJson?.meta.token
                })
               }
                this.setState((prevState) => ({
                    currentStep: prevState.currentStep + 1,
                    emailValidationResult: this.state.emailAddress

                }));
            }
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );


            if (apiRequestCallId != null && apiRequestCallId === this.apiEmailSignupVerificationId) {
                this.apicallresponseData(responseJson);
            }

            if (apiRequestCallId != null && apiRequestCallId === this.apiEmailSignupCallId) {
                this.apicallresponseData(responseJson);
            }

            if (apiRequestCallId != null && apiRequestCallId === this.apimailSignUpDetailsCallId) {
                this.apicallresponseData(responseJson);
            }
            if (apiRequestCallId != null && apiRequestCallId === this.apiEmailSignUpOtpSendCallId) {
                this.apicallresponseData(responseJson);
            }
            if (apiRequestCallId != null && apiRequestCallId === this.apiEmailSignupVerificationOtpId) {
                this.apicallresponseData(responseJson);
            }

        }

    }

}
// Customizable Area Start
