export const EverybrandIcon = require('../assets/everybrand_icon.svg');
export const folderIcon = require("../assets/folder_icon.svg")
export const chatIcon = require("../assets/image_Chats_ICON.svg")
export const textIcon = require('../assets/Text_ICON1.svg')
export const directPostIcon = require("../assets/message_icons.svg")
export const textCircle = require ("../assets/image_Ellipse1_ICON.svg")
export const chatCircle = require ("../assets/image_Ellipse2_ICON.svg")
export const directPostCircle = require ("../assets/image_ellipse3_ICON.svg")
export const closeIcon = require("../assets/close_icon.svg")
export const aIcon = require("../assets/group_variant_ICON.svg")
export const whiteFolder = require("../assets/folder_icon_white.svg")
export const camera = require("../assets/camera.svg")
export const document = require("../assets/group_document.svg")
export const search_icon = require("../assets/search_icon.svg")
export const allMessagesIcon = require("../assets/image_Ellipse_bLACK.svg")
export const group_plus = require("../assets/group_plus.svg")
export const icon_info = require("../assets/icon_info.svg")
export const chatWhite = require("../assets/chat_icon.svg")
export const directWhite = require("../assets/message_icon.svg")
export const textWhite = require("../assets/text_icon.svg")