// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography } from "@material-ui/core";
import { fallbackAvatar } from "./assets";
import AnalyticsTextConversationsController from "./AnalyticsTextConversationsController.web";

export class AnalyticsTextConversations extends AnalyticsTextConversationsController {
  render() {
    const { classes, mostActiveUsers } = this.props;    

    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            Text Conversations With
          </Typography>
        </Box>
        <Box className={classes.usersList}>
          {Boolean(mostActiveUsers.length) && mostActiveUsers.map(user => (
            <Box key={user.userId} className={classes.userItem}>
              <Box className={classes.userPicture}>
                <img src={user.profilePicture || fallbackAvatar} alt="user picture" />
              </Box>
              <Typography className={classes.userFullName}>{`${user.firstName} ${user.lastName}`}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      padding: "40px 20px 40px",
      backgroundColor: "#FFF",
      borderRadius: "20px",
      gap: "20px",

      "& *": {
        fontFamily: "Inter",
      },
    },

    titleContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "14px",
      alignItems: "center",
      alignSelf: "flex-start",

      "& > img": {
        width: "24px",
        height: "24px",
      },
    },

    title: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      padding: "0 15px"
    },

    usersList: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "100%",
    },

    userItem: {
      display: "flex",
      flexWrap: "nowrap",
      height: "88px",
      width: "100%",
      padding: "15px 25px",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "20px",
      backgroundColor: "rgba(241, 245, 249, 0.2)",
    },

    userPicture: {
      width: "44px",
      height: "44px",
      borderRadius: "50%",

      "& > img": {
        objectFit: "cover"
      }
    },

    userFullName: {
      fontSize: "16px",
      fontWeight: 700,
    }
  });

export default withStyles(styles)(AnalyticsTextConversations);
// Customizable Area End
