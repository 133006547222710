// Customizable Area Start
import { Radio, RadioProps, makeStyles } from "@material-ui/core";
import React from "react";

const useRadioStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    border: "1px solid #000",
    "$root.Mui-focusVisible &": {
      outline: "2px auto #000",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#fff"
    }
  },
  checkedIcon: {
    backgroundColor: "#000",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: "''"
    },
    "input:hover ~ &": {
      backgroundColor: "#000"
    }
  }
});

function StyledRadio(props: RadioProps) {
  const classes = useRadioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon}`} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default StyledRadio;
// Customizable Area End