// Customizable Area Start
import React, { useEffect } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  IconButton,
  withStyles,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FileDropzone from "./FileDropzone.web";
import { File } from "./types";
import { NewPostCreationSteps } from "./enums";
import { InstagramIcon } from "./assets"
import CustomButton from "../../../components/src/CustomButton.web";

export type Props = {
  classes: any;
  errorMessage?: string
  closeModal: () => void;
  files: File[];
  setFiles: (files: File[]) => void;
  openLibrary: () => void;
  setStep: (step: any) => void;
  postType?: string;
};

const styles = (_theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      display: "flex",
      padding: "35px 30px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "22px",
      flexShrink: 0,
      background: "#FFF",
      "& *": {
        fontFamily: "Inter, 'sans-serif'"
      }
    },

    closeButton: {
      position: "absolute",
      top: "20px",
      right: "11px",
    },

    modalTitle: {
      alignSelf: "flex-start",
      paddingLeft: "18px",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "32px",
      fontFamily: "Inter",
      letterSpacing: "-0.12px",
    },

    libraryButton: {
      width: "132px",
      height: "44px",
      alignSelf: "flex-end",
      fontSize: "10px",
    },
    buttonIcon: {
      height: "16.5px",
      width: "16.5px"
    },
    errorMessage: {
      fontSize: "12px",
      color: "#ff0000"
    }
  });

export const FileDropzoneModal: React.FC<Props> = ({
  classes,
  postType,
  errorMessage,
  closeModal,
  files,
  setStep,
  setFiles,
  openLibrary,
}) => {

  useEffect(() => {
    setFiles([])
  }, [])

  return (
    <Container
      id="selectFileContainer"
      maxWidth={false}
      className={classes.modal}
      onClick={(e) => e.stopPropagation()}
    >
      <IconButton
        id="closeModalButton"
        className={classes.closeButton}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>
      <Typography className={classes.modalTitle}>{postType === "post" ? "New Post" : "New EveryReel"}</Typography>
      <FileDropzone postType={postType} files={files} setFiles={setFiles} />
      <Box hidden={!errorMessage}>
        <Typography data-test-id="media-error" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      </Box>
      <Box display="flex" width="100%" gridGap="10px" justifyContent="flex-end">
      <CustomButton
        disabled
        id="openInstagramLibrary"
        className={classes.libraryButton}
        btnType="secondary-outlined"
        hidden={postType !== "post"}
        startIcon={<img className={classes.buttonIcon} src={InstagramIcon} alt="Instagram" />}
        onClick={() => setStep(NewPostCreationSteps.INSTAGRAM_LIBRARY)}
      >
        Instagram
      </CustomButton>
      <CustomButton
        disabled
        id="openTikTokLibrary"
        className={classes.libraryButton}
        btnType="secondary-outlined"
        hidden={postType !== "post"}
      >
        Tik Tok
      </CustomButton>
      <CustomButton
        id="openLibraryButton"
        className={classes.libraryButton}
        btnType="secondary-outlined"
        onClick={openLibrary}
      >
        Everyreel Library
      </CustomButton>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(FileDropzoneModal);
// Customizable Area End
