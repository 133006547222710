export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// Customizable Area Start
export const AutomationTemplatesIcon = require('../assets/automation_templates.svg');
export const BillingInfoIcon = require('../assets/billing_info.svg');
export const BusinessHoursIcon = require('../assets/business_hours.svg');
export const ContactInfoIcon = require('../assets/contact_info.svg');
export const EveryreelIcon = require('../assets/everyreel.svg');
export const GettingStartedIcon = require('../assets/getting_started.svg');
export const PostFeedIcon = require('../assets/post_feed.svg');
export const SocialIntegrationIcon = require('../assets/social_integration.svg');
export const SubscriptionIcon = require('../assets/subscription.svg');
export const TeamMatesIcon = require('../assets/teammates.svg');
export const WebsiteIcon = require('../assets/websites.svg');
export const TextboxCheckIcon = require('../assets/textbox_check.svg');
export const SettingsIcon = require('../assets/settings.svg');
export const EverybrandIcon = require('../assets/everybrand_logo.svg');
export const EngageIcon = require('../assets/engage.svg');
export const HomeIcon = require('../assets/home.svg');
export const WebIcon = require('../assets/web.svg');
export const PortraitIcon = require('../assets/portrait.svg');
export const PublicIcon = require('../assets/public.svg');
export const NotificationsNoneIcon = require('../assets/notifications_none.svg');
export const ImportantDevicesIcon = require('../assets/important_devices.svg');
export const CloseIcon = require('../assets/close_icon.svg');
export const ThreeDotIcon = require('../assets/three_dot_icon.svg');
export const ListImg = require('../assets/list_img.svg');
export const DeleteIcon = require('../assets/delete_icon.svg');
export const UpgradeIcon = require('../assets/upgrade_icon.svg');
export const firststepimg1 = require("../assets/firststepimg1.png");
export const stepImageFirst = require("../assets/step_Image_First.svg")
export const calendarIcon = require("../assets/calendar.svg")
export const addPlusIcon = require("../assets/plus.svg")
export const socailPhoneIcon = require("../assets/socialPhoneIcons.svg")
export const greyCheckIcon = require("../assets/greyCheck.svg")
export const socialPhoneDocIcons = require("../assets/socialphoneDocIcon.svg")
export const infoIcon = require("../assets/infoIconYellow.svg")
export const womanImage = require("../assets/woman.png")
export const filledOpenSpot = require("../assets/filled_open_spot_icon.svg")
export const removeIcon = require("../assets/remove_from_waitlist.svg")
export const sendIcon = require("../assets/send_icon_vector.svg")
export const IconAvtar = require("../assets/icon_avatar.svg")
export const folderIcon = require("../assets/folder_icon.svg")
export const radioButtonOn = require("../assets/radiobutton_ON.svg")
export const radioButtonOff = require("../assets/radiobutton_OFF.svg")
export const connectWebsiteIcon = require("../assets/skip_website.svg")
export const copyCode = require("../assets/image_code.svg")
export const tutorialIcaon = require("../assets/group_everyreel.svg")
export const commentIcon = require("../assets/comment.svg")
export const heartIcon = require("../assets/image_vectorheart.svg")
export const starIcon = require("../assets/review.svg")
export const notificationPreferencesIcon = require("../assets/notification.svg")
export const checkIcon = require("../assets/check_icon.svg")
export const messagIcon = require("../assets/messages_icon.svg")
export const trashIcon = require("../assets/trash_icon.svg")
export const resendIcon = require("../assets/resend_icon.svg")





// Customizable Area End
