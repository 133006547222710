// Customizable Area Start
import { Box} from "@material-ui/core";
import React, { CSSProperties } from "react";
import { firststepimg1 ,payment_1,payment_start} from "./assets";

const webStyles: Record<string, CSSProperties> = {
  headerStepInfo: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    backgroundColor: "#FFC629",
    borderRadius: "0px 0px 0px 64px",
    padding: "30px 127px 10px 87px"
  },

  titleText: {
    fontWeight: 700,
    fontSize: "36px"
  },

  logoImg: {
    width: "56px",
    height: "56px",
    marginRight: "28px"
  },

  progressBarBlock: {
    marginLeft: "auto"
  },

  progressBarImg: {
    width: "340px",
    height: "72px"
  }
};

const HeaderStepInfo = () => {
  return (
    <Box style={webStyles.headerStepInfo}>
      <Box style={webStyles.logoImg}>
        <img src={firststepimg1} alt="" />
      </Box>
      <Box>
       <img src={payment_1} alt="" />
      </Box>
      <Box style={webStyles.progressBarBlock}>
          <img
            style={webStyles.progressBarImg}
            src={payment_start}
            alt=""
            width="40%"
          />
        {/* )} */}
      </Box>
    </Box>
  );
};

export default HeaderStepInfo;
// Customizable Area End
