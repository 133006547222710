import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { configJSON } from "./Settings2Controller.web";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { InputLabel, Snackbar, Theme, createStyles, withStyles } from "@material-ui/core";
import BilingInformationController, { Props, stripePromise } from "./BillingInformationController.web";
import StripeForm from "./StripeForm.web";
import { Elements } from "@stripe/react-stripe-js";
import clsx from 'clsx';
import MuiAlert from "@material-ui/lab/Alert"
import { EverybrandIcon } from "./assets";
// Customizable Area End

const useStyles = ((theme: Theme) =>
  createStyles({

    Heading: {
      fontWeight: 700,
      color: "#000",
    
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
    
      lineHeight: "32px", /* 133.333% */
      letterSpacing: "-0.12px",
    },
   
    BillingFormLabel: {
      display: 'block',
      color: "#334155",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px"
    },

    marginBottom_20: {
      marginBottom: theme.spacing(2.5),
    },
    marginBottom_48: {
      marginBottom: "52px",
    },
    BillingInformationvalues: {
      color:" #64748B",

      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
  })
);
class BillingInformation extends BilingInformationController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
  const {classes}=this.props
    return (

      <StyledContainer>
        <Snackbar
            open={this.state.isShowAlert}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={"success"}
            >
              {this.state.showSucess}
  
            </MuiAlert>
          </Snackbar>
          <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1.5}
        mb={4.5}
      >
          <Typography
            className={clsx(classes.Heading, classes.marginBottom_48)}
            variant="h5"
          >
            {configJSON.currentBillingInformation}
          </Typography>
          <img style={{marginTop:"-54px"}}src={EverybrandIcon} />
          </Box>
        <Box mt={12} style={{marginTop:"59px"}}>
        
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Box mb={4}>
                <Typography className={clsx(classes.BillingFormLabel, classes.marginBottom_20)}>
                  {configJSON.cardName}
                </Typography>
                <Typography className={classes.BillingInformationvalues}>
                 {this.state.getPaymentData[0]?.attributes?.billing_details?.name  ? this.state.getPaymentData[0].attributes.billing_details?.name :"-"}
                </Typography>
              </Box>
              <Box>
                <Typography className={clsx(classes.BillingFormLabel, classes.marginBottom_20)}>
                  {configJSON.cardExpDate}
                </Typography>
                <Typography className={classes.BillingInformationvalues}>
                {this.state.getPaymentData[0]?.attributes?.card?.exp_month + "/" + this.state.getPaymentData[0]?.attributes?.card?.exp_year ? this.state.getPaymentData[0]?.attributes?.card?.exp_month + "/" + this.state.getPaymentData[0]?.attributes?.card?.exp_year : "-"}
                
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={4}>
                <Typography className={clsx(classes.BillingFormLabel, classes.marginBottom_20)}>
                  {configJSON.cardNumber}
                </Typography>
                <Typography className={classes.BillingInformationvalues}>
                {this.state.getPaymentData[0]?.attributes?.card?.last4 ? this.state.getPaymentData[0]?.attributes?.card?.last4 : "-"}
                 
                </Typography>
              </Box>
            
            </Grid>
          </Grid>
        </Box>
        <Box display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "20px",marginTop:"44px" }}
          mt={1.5}
          mb={4.5}>
          <Title
            gutterBottom>{configJSON.billingHeading}</Title>

        </Box>
        
         <Elements key={this.state.elementsKey}  stripe={stripePromise} options={ {mode: 'setup',currency: 'usd',paymentMethodTypes:['card']}}>
          
            <StripeForm 
            data-test-id="stripeFormmmm"
              handleSubmit={this.handleSubmit}
             
            />
          </Elements>
          
      </StyledContainer>
    )
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const StyledContainer = styled(Box)({
  margin: "82px 52px 519px 47px"
})

const StyledInputLabel = styled(InputLabel)({
  color: "#334155",

  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",

})

const StyledButton = styled(Button)({
  color: "#0F172A",
  backgroundColor: "#FFC629",
  fontWeight: 700,
  textTransform: "none",
  paddingTop: "16px",
  paddingBottom: "16px",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",

  lineHeight: "24px",

  "&:hover": {
    backgroundColor: "#FFC629",
  },
})

const StyledTextField = styled(TextField)({

  marginTop: "5px",
  width: "100%",
  "& .Mui-focused fieldset": {
    borderColor: "#FFCA29 !important",
  },
  "& .MuiInputBase-root input": {
    backgroundColor: "red !important",
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'lightblue !important'
  },
  "& input::placeholder": {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },

})

const Title = styled(Typography)({
  fontWeight: 700,
  color: "#000",

  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",

  lineHeight: "32px", /* 133.333% */
  letterSpacing: "-0.12px",
})
export default withStyles(useStyles)(BillingInformation)
export { BillingInformation }
// Customizable Area End
