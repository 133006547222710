// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { NewPostCreationSteps } from "./enums";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  allowComments: () => void;
  isCommentsAllowed: boolean;
  closeModal: () => void;
};

interface S {}

interface SS {
  navigation: any;
  stepType: NewPostCreationSteps;
}

export default class NewPostSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

}
// Customizable Area End
