// Customizable Area Start
import React, { CSSProperties } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  withStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import NewPostAddCaptionController, {
  Props,
} from "./NewPostAddCaptionController.web";
import { CaptionViews, NewPostCreationSteps } from "./enums";
import CustomButton from "../../../components/src/CustomButton.web";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "30px 44px 40px",
      gap: "22px",
      background: "#FFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
    },
    closeButton: {
      position: "absolute",
      top: "15px",
      right: "10px",
    },

    modalTitle: {
      alignSelf: "flex-start",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.13px",
    },

    previewContainer: {
      position: "relative",
    },

    imagePreviewContainer: {
      display: "flex",
      width: "320px",
      height: "506px",
      overflow: "hidden",
      borderRadius: "20px",
      backgroundColor: "#F2F2F2",

      "& > img": {
        objectFit: "cover",
      },

      "& > video": {
        width: "100%",
        objectFit: "cover",
      },
    },

    captionButtonsContainer: {
      position: "absolute",
      top: "40px",
      right: "21px",
      display: "flex",
      flexDirection: "column",
      gap: "14px",
    },

    captionButton: {
      width: "32px",
      minWidth: "32px",
      height: "32px",
      borderRadius: "50%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      border: "1px solid #FFF",
      color: "#FFF",
      fontSize: "10px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Inter, 'sans-serif'",
    },

    pollContainer: {
      position: "absolute",
      bottom: "121px",
      left: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "12px",
    },

    pollQuestion: {
      width: "90%",

      "& > div": {
        height: "100%",
      },

      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& input": {
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 700,
        color: "#FFF",
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.60)",
        fontFamily: "Inter, 'sans-serif'",

      "&::placeholder": {
        fontSize: "14px",
        fontWeight: 500,
        textAlign: "center",
        color: "#fff",
        opacity: 1
      },
      "&:focus::placeholder": {
        opacity: 0
      }
      },

      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    pollAnswer: {
      minWidth: "160px",
      minHeight: "31px",
      borderRadius: "10px",
      backgroundColor: "#FFF",

      "& > div": {
        height: "100%",
      },

      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& textarea": {
        textAlign: "center",
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: "Inter, 'sans-serif'",

        "&::placeholder": {
        fontSize: "12px",
        fontWeight: 700,
        textAlign: "center",
        color: "#000",
        opacity: 1
      },
      "&:focus::placeholder": {
        opacity: 0
      }
      },

      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    caption: {
      position: "absolute",
      top: "60%",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: "90%",
      height: "80px",
      padding: "6px 10px",
      overflow: "hidden",
      resize: "none",
      borderRadius: "5px 5px 20px 5px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "28px",
      fontWeight: 500,
      fontFamily: "Inter, 'sans-serif'",
      border: "none",
      "&:focus-visible": {
        outline: "none",
      },
    },

    dotsContainer: {
      position: "absolute",
      bottom: "108px",
      left: "50%",
      transform: "translate(-50%, 0)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "7px",
    },

    filesChoiceDots: {
      width: "7px",
      height: "7px",
      borderRadius: "50%",
      cursor: "pointer",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },

    stepButtonsContainer: {
      position: "absolute",
      bottom: "29px",
      left: "50%",
      transform: "translate(-50%, 0)",
      display: "flex",
      gap: "10px",
    },

    stepButton: {
      height: "44px",
      "&:hover" : {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        border: "1px solid #fff !important",
      }
    },
  });

const webStyles: Record<string, CSSProperties> = {};

class NewPostAddCaption extends NewPostAddCaptionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      files,
      classes,
      poll,
      view,
      caption,
      setStep,
      closeModal,
      setPollQuestion,
      setPollAnswer1,
      setPollAnswer2,
      setCaptionView,
      setCaption,
    } = this.props;

    const { currentFile } = this.state;

    return (
      <Container
        id="confirmFileContainer"
        maxWidth={false}
        className={classes.modal}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          id="closeModalButton"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.modalTitle}>New post</Typography>
        <Box className={classes.previewContainer}>
          <Box className={classes.imagePreviewContainer}>
            {files[currentFile]?.type.includes("image") ? (
              <img src={files[currentFile]?.preview} alt="image preview" />
            ) : (
              <video controls={false} ref={this.videoPreviewRef}>
                <source src={files[currentFile]?.preview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Box>

          <Box className={classes.captionButtonsContainer}>
            <Button
              id="setPollButton"
              data-test-id="pollButton"
              className={classes.captionButton}
              onClick={() => setCaptionView(CaptionViews.POLL)}
            >
              Poll
            </Button>
            <Button
              id="setCaptionButton"
              data-test-id="captionAddBtn"
              className={classes.captionButton}
              onClick={() => setCaptionView(CaptionViews.CAPTION)}
            >
              Aa
            </Button>
          </Box>

          {view === CaptionViews.POLL && (
            <Box className={classes.pollContainer}>
              <TextField
                id="pollQuestionInput"
                value={poll.question}
                placeholder="Ask a question"
                onChange={(e) => {
                  setPollQuestion(e.target.value);
                }}
                className={classes.pollQuestion}
              />
              <TextField
                id="answer1Input"
                value={poll.answer1}
                placeholder="Answer 1"
                onChange={(e) => {
                  setPollAnswer1(e.target.value);
                }}
                className={classes.pollAnswer}
                multiline
                maxRows={3}
                inputProps={{ style: { wordWrap: 'break-word' } }}
              />
              <TextField
                id="answer2Input"
                placeholder="Answer 2"
                value={poll.answer2}
                onChange={(e) => {
                  setPollAnswer2(e.target.value);
                }}
                className={classes.pollAnswer}
                multiline
                maxRows={3}
                inputProps={{ style: { wordWrap: 'break-word' } }}
              />
            </Box>
          )}

          {view === CaptionViews.CAPTION && (
            <textarea
              id="captionInput"
              value={caption}
              maxLength={100}
              onChange={(e) => {
                setCaption(e.target.value);
              }}
              className={classes.caption}
            />
          )}

          {view === CaptionViews.NONE && (
            <Box className={classes.dotsContainer}>
              {Boolean(files?.length) &&
                files.map((_, i) => {
                  return (
                    <Box
                      key={`dot-${i}`}
                      id={`dot-${i}`}
                      className={classes.filesChoiceDots}
                      onClick={() => this.setCurrentFile(i)}
                      style={{
                        backgroundColor:
                          currentFile === i
                            ? "#FFF"
                            : "rgba(255, 255, 255, 0.50)",
                      }}
                    />
                  );
                })}
            </Box>
          )}

          <Box className={classes.stepButtonsContainer}>
            <CustomButton
              id="rejectFilesButton"
              data-test-id="discard-button"
              className={classes.stepButton}
              btnType="secondary"
              onClick={() => {
                setCaption("")
                setPollQuestion("Ask a question")
                setPollAnswer1("Answer 1")
                setPollAnswer2("Answer 2")
                setCaptionView(CaptionViews.NONE)
                setStep(NewPostCreationSteps.SELECT_FILE);
              }}
            >
              Discard
            </CustomButton>
            <CustomButton
              id="confirmFilesButton"
              className={classes.stepButton}
              onClick={() => setStep(NewPostCreationSteps.COVER_SELECTION)}
            >
              Next
            </CustomButton>
          </Box>
        </Box>
      </Container>
    );
  }
}

export { NewPostAddCaption };
export default withStyles(styles)(NewPostAddCaption);
// Customizable Area End
