// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Comment, PostType } from "../../dashboard/src/interfaces";
import { getToken } from "../../../components/src/AuthService";
import React from "react";
import { Color } from "@material-ui/lab/Alert";
const helper = require("../../../components/src/helper.js");
export const configJSON = require("./config");

export enum CommentOrderEnum {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

interface ILikePayload {
  like: {
    likeable_id: string;
    likeable_type: string;
  };
}

interface IPostPayload {
  postId: string;
  commentId: string;
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  token: any;
  setToken: string;
  isLoading: boolean;
  isShowAlert: boolean;
  showSuccess: string;
  comment: string;
  commentImage: File | null
  isAddingComment: boolean;
  postPayload: IPostPayload | null;
  postData: PostType | null;
  isHighlightComment: boolean;
  repliesOpenCommentId: string;
  replyingToId: string;
  replyingToName: string;
  commentsOrder: CommentOrderEnum;
  isSortingComments: boolean;
  postComments: Comment[];
  infoType: Color | undefined
  isAllCommentClicked: boolean
}

interface SS {
  id: any;
}

export default class OpenNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  highlightTimer: NodeJS.Timeout | null = null;
  commentFieldRef: React.RefObject<HTMLInputElement> = React.createRef();
  imageCommentFieldRef: React.RefObject<HTMLInputElement> = React.createRef();
  commentsWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  postCommentCallId: string = "";
  getPostDataApiCallId: string = "";
  getCommentsApiCallId: string = "";
  likeApiCallId: string = "";
  undoLikeApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      setToken: "",
      isShowAlert: false,
      isLoading: false,
      showSuccess: "",
      comment: "",
      commentImage: null,
      isAddingComment: false,
      postPayload: null,
      postData: null,
      isHighlightComment: false,
      repliesOpenCommentId: "",
      replyingToId: "",
      replyingToName: "",
      commentsOrder: CommentOrderEnum.ASCENDING,
      isSortingComments: false,
      postComments: [],
      infoType: "success",
      isAllCommentClicked: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.handleGetPostData();
  }

  async componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): Promise<void> {
    const { postPayload, postData, commentsOrder } = this.state;
    if (prevState.postPayload?.postId !== postPayload?.postId) {
      this.handleGetPostData();
    }

    if (prevState.postData?.id !== postData?.id) {
      this.handleCommentHighlight();
    }

    if(prevState.commentsOrder !== commentsOrder && postData) {
      this.setState({ isSortingComments: true })
      this.getPostCommentsApiCall(postData.id)
    }
  }

  async componentWillUnmount(): Promise<void> {
    if (this.highlightTimer) {
      clearTimeout(this.highlightTimer);
    }
  }

  toggleCommentSortChange = () => {
    const updatedOrder =
      this.state.commentsOrder === CommentOrderEnum.ASCENDING
        ? CommentOrderEnum.DESCENDING
        : CommentOrderEnum.ASCENDING;
    this.setState({ commentsOrder: updatedOrder });
  };

  getFieldPlaceholder = () => {
    const { replyingToName } = this.state;
    if (replyingToName) {
      return `Replying to ${replyingToName}`;
    }
    return "Add a comment";
  };

  handleReplyClick = (commentDetails: Comment, commentId?: string) => {
    const {
      id,
      attributes: {
        account: {
          data: {
            attributes: { first_name, last_name },
          },
        },
      },
    } = commentDetails;
    this.setState({
      replyingToId: commentId || id,
      replyingToName: `${first_name} ${last_name}`,
    });
    if (this.commentFieldRef.current) {
      this.commentFieldRef.current.focus();
    }
  };

  handleCommentFieldBlur = () => {
    !this.state.comment &&
      this.setState({ replyingToId: "", replyingToName: "" });
  };

  ternary = <T, U>(condition: boolean, param1: T, param2: U) => {
    return condition ? param1 : param2;
  };

  orCondition = <T, U>(param1: T, param2: U) => {
    return param1 || param2;
  };

  getReplyText = (repliesLength: number) => {
    const repliesNumber = helper.numberToCount(repliesLength);
    return repliesLength > 1
      ? `View ${repliesNumber} replies`
      : `View ${repliesNumber} reply`;
  };

  toggleReplies = (commentId: string) => {
    const updatedId =
      this.state.repliesOpenCommentId === commentId ? "" : commentId;
    this.setState({ repliesOpenCommentId: updatedId });
  };

  handleGetPostData = () => {
    const { postPayload } = this.state;
    if (postPayload) {
      const { postId } = postPayload;
      this.getPostDataApi(postId);
    } else {
      const storagePayload = localStorage.getItem(
        configJSON.SinglePostPayloadKey
      );
      if (storagePayload) {
        this.setState({ postPayload: JSON.parse(storagePayload) });
      }
    }
  };

  handleCommentHighlight = () => {
    const { postPayload } = this.state;
    if (postPayload) {
      const { commentId } = postPayload;
      this.highlightComment(commentId, true)
    }
  };

  highlightComment = (commentId: string, isHighlight?: boolean) => {
    const hightComment = document.getElementById(`comment-${commentId}`);
    if (hightComment) {
      hightComment.scrollIntoView({
        behavior: "smooth",
      });
      if(isHighlight) {
        this.setState({ isHighlightComment: true });
        this.highlightTimer = setTimeout(() => {
          this.setState({ isHighlightComment: false });
        }, 4000);
      }
    }
  }

  handleCommentsWrapperInView = () => {
    if(this.commentsWrapperRef.current) {
      this.commentsWrapperRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth"
      })
    }
  }

  isToHighlight = (commentId: string) => {
    const { postPayload, isHighlightComment } = this.state;
    if (postPayload) {
      return postPayload.commentId === commentId && isHighlightComment;
    }
    return false;
  };

  async receive(_from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NotificationToPostPayloadMessage)
      );
      this.setState({ postPayload: data, isAllCommentClicked: !data.commentId });
      localStorage.setItem(
        configJSON.SinglePostPayloadKey,
        JSON.stringify(data)
      );
    }

    const messageType = getName(MessageEnum.RestAPIResponceMessage);
    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.error && !responseJson.errors) {
        this.handleApiSuccessResponse(apiRequestCallId, responseJson);
      } else {
        this.handleApiErrorResponse(apiRequestCallId, responseJson);
      }
    }
  }

  isShowCell = () => {
    setTimeout(() => this.setState({ isShowAlert: false, showSuccess: "", infoType: "success"  }), 3000);
  };

  handleRedirection = (pageName: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleImageCommentClick = () => {
    if(this.imageCommentFieldRef.current) {
      this.imageCommentFieldRef.current.click()
    }
  }

  handleImageCommentRemove = () => {
    this.setState({ commentImage: null })
  }

  handleImageCommentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files: imageFiles } = event.currentTarget;
    if (imageFiles && imageFiles.length > 0) {
      const currentFile = imageFiles[0];
      
      const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      
      if (validImageTypes.includes(currentFile.type)) {
        this.setState({commentImage: currentFile })
      } else {
        this.setState({
          isShowAlert: true,
          infoType: "error",
          showSuccess: "Invalid file type. Please upload a jpeg, jpg, or png image.",
        })
        this.isShowCell()
      }
    }
  }

  handlePostComment = () => {
    const { postData, replyingToId } = this.state;
    if (replyingToId) {
      this.initiateComment(replyingToId, configJSON.CommentClass);
    } else if (postData) {
      this.initiateComment(postData.id, configJSON.PostClass);
    }
  };

  initiateComment = (commentableId: string, commentType: string) => {
    const { comment, commentImage } = this.state;
    const formData = new FormData();
    formData.append("comment[commentable_id]", commentableId);
    formData.append("comment[comment]", comment);
    formData.append("comment[commentable_type]", commentType);
    commentImage && formData.append("comment[files][]", commentImage);
    this.postCommentApi(formData);
  };

  postCommentApi = (formData: FormData) => {
    this.setState({ isAddingComment: true });
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postCommentCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommentEndPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getPostDataApi = (postId: string) => {
    this.setState({ isLoading: true });
    const endPoint = `${configJSON.getPostDataEndPoint}/${postId}`;
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getPostDataApiCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: getToken(),
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getPostCommentsApiCall = (postId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCommentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCommentEndPoint}?commentable_id=${postId}&commentable_type=${configJSON.PostClass}&sort=${this.state.commentsOrder}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePostLikeUnlike = () => {
    const { postData } = this.state;
    if (postData) {
      const {
        id,
        type,
        attributes,
        attributes: { is_liked, likes_count },
      } = postData;
      const updatedCount = is_liked ? likes_count - 1 : likes_count + 1;
      const updatedPost = {
        id,
        type,
        attributes: {
          ...attributes,
          is_liked: !is_liked,
          likes_count: updatedCount,
        },
      };
      this.setState({ postData: updatedPost });
      is_liked
        ? this.undoLikeApiCall(id, configJSON.PostClass)
        : this.handleLike(id, configJSON.PostClass);
    }
  };

  handleCommentLikeUnlike = (commentId: string, isLiked: boolean) => {
    const { postComments } = this.state;
      const updatedComments = [...postComments].map((commentData) => {
        const { id, type, attributes } = commentData;
        if (id === commentId) {
          return {
            id,
            type,
            attributes: {
              ...attributes,
              already_liked: !isLiked,
            },
          };
        }
        return commentData;
      });
      this.setState({ postComments: updatedComments });
      isLiked
        ? this.undoLikeApiCall(commentId, configJSON.CommentClass)
        : this.handleLike(commentId, configJSON.CommentClass);
  };

  handleCommentReplyLikeUnlike = (
    commentId: string,
    commentReplyId: string,
    isLiked: boolean
  ) => {
    const { postComments } = this.state;
      const updatedComments = [...postComments].map((commentData) => {
        const {
          id,
          type,
          attributes,
          attributes: { replies },
        } = commentData;
        if (id === commentId) {
          const updatedReplies = [...replies.data].map((replyData) => {
            const { attributes, ...rest } = replyData;
            if (replyData.id === commentReplyId) {
              return {
                ...rest,
                attributes: {
                  ...attributes,
                  already_liked: !isLiked,
                },
              };
            }
            return replyData;
          });
          return {
            id,
            type,
            attributes: {
              ...attributes,
              replies: {
                data: updatedReplies,
              },
            },
          };
        }
        return commentData;
      });
      this.setState({ postComments: updatedComments });
      isLiked
        ? this.undoLikeApiCall(commentReplyId, configJSON.CommentClass)
        : this.handleLike(commentReplyId, configJSON.CommentClass);
  };

  handleLike = (likeableId: string, type: string) => {
    const payload = {
      like: {
        likeable_id: likeableId,
        likeable_type: type,
      },
    };
    this.likeApiCall(payload);
  };

  likeApiCall = (httpBody: ILikePayload) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  undoLikeApiCall = (likeableId: string, likeType: string) => {
    const endPoint = `${configJSON.undoLikeApiEndpoint}?likeable_id=${likeableId}&likeable_type=${likeType}`;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.undoLikeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  postCommentApiResponse = (responseJson: any) => {
    if (responseJson) {
      const { replyingToId, postComments } = this.state;
      let updatedComments = [];
      if (replyingToId) {
        updatedComments = [...postComments].map((commentData) => {
          const {
            id,
            type,
            attributes: commentAttr,
            attributes: {
              replies: { data },
            },
          } = commentData;
          if (id === replyingToId) {
            return {
              id,
              type,
              attributes: {
                ...commentAttr,
                replies: {
                  data: [...data, responseJson.data],
                },
              },
            };
          }
          return commentData;
        });
      } else {
        updatedComments = [...postComments, responseJson.data];
      }
      this.setState(
        {
          isShowAlert: true,
          showSuccess: responseJson.meta.message,
          postComments: updatedComments,
          isAddingComment: false,
          comment: "",
          commentImage: null,
          replyingToId: "",
          replyingToName: "",
        },
        () => {
          this.isShowCell();
          this.highlightComment(responseJson.data.id)
        }
      );
    }
  };

  showAllComments = () => {
    const { postData } = this.state
    if(postData) {
      this.setState({ isAllCommentClicked: true })
      this.getPostCommentsApiCall(postData.id)
    }
  }

  handleCommentsApiResponse = (responseJson: { data: Comment[] }) => {
    const { isAllCommentClicked, postPayload } = this.state
    let comments = responseJson.data
    if(!isAllCommentClicked && postPayload) {
      const { commentId } = postPayload
      comments = responseJson.data.filter((commentData: Comment) => commentData.id === commentId)
    }
    this.setState({ postComments: comments, isSortingComments: false }, () => this.handleCommentsWrapperInView())
  }

  handlePostDataApiResponse = (responseJson: any) => {
    const { isAllCommentClicked, postPayload } = this.state
    const {
      data,
      data: {
        attributes: {
          comments: { data: comments },
        },
      },
    } = responseJson;
    let showComments = comments
    if(!isAllCommentClicked && postPayload) {
      const { commentId } = postPayload
      showComments = comments.filter((commentData: Comment) => commentData.id === commentId)
    }
    this.setState({
      postData: data,
      postComments: showComments,
      isLoading: false,
    }, () => {
      postPayload?.commentId && this.handleCommentsWrapperInView()
    });
  }

  handleApiSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.postCommentCallId:
        this.postCommentApiResponse(responseJson);
        break;
      case this.getPostDataApiCallId:
        this.handlePostDataApiResponse(responseJson)
        break;
      case this.getCommentsApiCallId:
        this.handleCommentsApiResponse(responseJson)
        break;
    }
  };

  handleApiErrorResponse = (apiRequestCallId: string, _responseJson: any) => {
    if (apiRequestCallId === this.getPostDataApiCallId) {
      this.handleRedirection("Notifications");
    }
  };
}
// Customizable Area End
