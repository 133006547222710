import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity here (0.5 for 50% opacity)
      zIndex: 9999, // Ensure it's on top of other elements
    },
    loader: {
     color: '#000',
     borderRadius: '2px',

    },
  })
);

export default function MobileLoader({loading}:{loading : boolean}) {
  const classes = useStyles();

  return loading ? (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
    </div>
  ): (
    <div />
  )
}
