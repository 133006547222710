Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.commentsContentType = "application/json";
exports.sendNewMessageContentType = "multipart/form-data"
exports.newMessageContentType = "application/json";
exports.commentsApiMethodType = "GET";
exports.commentPOSTAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Comments";
exports.labelBodyText = "Comments Body";
exports.hintCountryCode = "Select Country";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.commentEndPoint = "/comments/comments"
exports.likeCommentEndPoint = "/comments/like_comment"
exports.userTextsEndpoint = "account_block/sms_contacts"
exports.getFoldersEndPoint = "account_block/chat_folders"
exports.createFoldersEndpoint = "account_block/chat_folders"
exports.contactsSearchEndPoint = "/account_block/contacts/search?query="
exports.directPostEndPoint = "bx_block_posts/direct_post_contacts"
exports.singleDirectPostEndPoint = "bx_block_posts/direct_post_contacts/"
exports.sendMessageEndPoint ="account_block/messages"
exports.createConversationEndPoint = "account_block/sms_contacts"
exports.userChatsEndPoint = "account_block/contacts/get_chat_contacts"
exports.delteFolderEndPoint = "account_block/chat_folders/"
exports.teamMatesEndPoint = "bx_block_teammates/teammates"
exports.updateFolderEndPoint = "account_block/chat_folders/"
exports.dashboardGetUrl = "/bx_block_dashboard/dashboards";
exports.assignConversationEndPoint = "/account_block/sms_contacts/assign_conversation"
// Customizable Area End
