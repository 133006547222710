// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { MostEngagedPost } from "./interfaces";

export const configJSON = require("./config");

const config = require('../../../framework/src/config.js')

export type Props = {
  classes: any;
  posts: MostEngagedPost[];
};

interface S {}

interface SS {
  navigation: any;
}

export default class AnalyticsEngagedPostsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  baseURL = config.baseURL;
}
// Customizable Area End
