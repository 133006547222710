// Customizable Area Start
/* eslint-disable */
import React, {ChangeEvent} from "react";
import * as yup from "yup";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { everybrandLogo, everybrandIcon, womanPicture } from "./assets";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import PhoneLoginController, { Props } from "./PhoneLoginController.web";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const validationSchema = yup.object().shape({
  phone: yup.string().required("Phone is required.")
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0
    },
    container: {
      flex: 1,
      maxWidth: "440px"
    },
    logo: {
      marginBottom: theme.spacing(5),
      "& img": {
        width: "225px",
        height: "75px"
      }
    },
    fontWeightBold: {
      fontWeight: 700
    },
    formControlLabel: {
      display: "block",
      color: "#334155",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      marginBottom: theme.spacing(0.5)
    },
    textButton: {
      padding: 0,
      textTransform: "none",
      fontSize: "14px",
      fontWeight: 700,
      color: "#0F172A"
    },
    loginButton: {
      color: "#fff",
      backgroundColor: "#0F172A",
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      textTransform: "none",
      padding: theme.spacing(2, 0),
      "&:hover": {
        backgroundColor: "#0F172A"
      }
    },
    fullHeight: {
      height: "100vh"
    },
    sidebar: {
      gap: theme.spacing(5),
      backgroundColor: "#FFC629",
      borderBottomLeftRadius: theme.spacing(8)
    },
    heading: {
      fontSize: theme.spacing(4.5),
      fontWeight: 700,
      lineHeight: "44px",
      textAlign: "center",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(4)
    },
    subHeading: {
      fontSize: theme.spacing(2.25),
      fontWeight: 600,
      lineHeight: "26px",
      color: "#FFFFFF",
      textAlign: "center",
      padding: theme.spacing(1)
    },
    signUpBtnBlock: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1)
    },
    formErrorBlock: {
      padding: theme.spacing(1.5, 2),
      margin: theme.spacing(5, 0),
      borderLeft: "4px solid #DC2626",
      borderRadius: theme.spacing(0.5),
      backgroundColor: "#FEE2E2",
      "& p": {
        fontSize: "12px",
        color: "#DC2626"
      }
    },
    passwordVisibilityIcon: {
      color: "#94A3B8"
    },
    checkboxLabel: {
      fontSize: "14px",
      color: "#0F172A"
    },
    fontSizeNormal: {
      fontSize: "16px"
    },
    sidebarText: {
      maxWidth: "423px",
      textAlign: "center"
    },
    phoneInputError: {
      border: "1px solid #f44336 !important",

      "&:focus": {
        boxShadow: "0 0 0 1px #f44336 !important"
      }
    },
    inputError: {
      color: "#f44336",
      lineHeight: "1.66 !important",
      fontSize: "0.75rem !important",
      letterSpacing: "0.033em !important",
      margin: "3px 14px 14px 0"
    }
  });

const webStyles = {
  fullWidth: {
    width: "100%"
  }
};

class PhoneLogin extends PhoneLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { phoneLoginError } = this.state;

    return (
      <Container maxWidth="xl" className={classes.root}>
        <Grid container className={classes.fullHeight}>
          <Grid
            item
            container
            md={6}
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.container}>
              <Typography align="center" className={classes.logo}>
                <img src={everybrandLogo} alt="everybrand logo" />
              </Typography>
              <Box p={5}>
                <Formik
                  data-test-id="phoneForm"
                  initialValues={{
                    phone: ""
                  }}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  onSubmit={(values, actions) => {
                    this.doPhoneLogIn(values);
                    actions.setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={5}>
                        <Typography
                          variant="h5"
                          className={classes.fontWeightBold}
                        >
                          Phone log in
                        </Typography>
                      </Box>
                      {phoneLoginError && (
                        <div
                          className={classes.formErrorBlock}
                          data-test-id="error"
                        >
                          <Typography>{phoneLoginError}</Typography>
                        </div>
                      )}
                      <Box mb={2}>
                        <label
                          className={classes.formControlLabel}
                          htmlFor="phone"
                        >
                          Phone
                        </label>
                        <PhoneInput
                          country={"us"}
                          value={values.phone}
                          placeholder="Enter mobile phone"
                          specialLabel={""}
                          onChange={(value: string, _country: string, event: ChangeEvent<HTMLInputElement>) => {
                            const changedEvent = {
                              target: {
                                id: event.target?.id,
                                name: event.target?.name,
                                value: `+${value}`
                              }
                            };
                            handleChange(changedEvent);
                          }}
                          onBlur={handleBlur}
                          inputProps={{
                            id: "phone",
                            name: "phone",
                            "data-test-id": "phoneInput"
                          }}
                          inputClass={
                            touched.phone &&
                            Boolean(errors.phone) &&
                            classes.phoneInputError
                          }
                          inputStyle={webStyles.fullWidth}
                        />
                        {touched.phone && errors.phone && (
                          <Typography
                            className={classes.inputError}
                            color="error"
                            data-test-id="phoneError"
                          >
                            {errors.phone}
                          </Typography>
                        )}
                      </Box>
                      <Box my={5}>
                        <Button
                          data-test-id="submit"
                          variant="contained"
                          className={classes.loginButton}
                          fullWidth
                          disableElevation
                          type="submit"
                        >
                          Continue
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            container
            md={6}
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.sidebar}
          >
            <Grid item className={classes.sidebarText}>
              <Typography align="center">
                <img src={everybrandIcon} alt="everybrand icon" />
              </Typography>
              <Typography variant="h4" className={classes.heading}>
                Welcome Back! Sign In With Your Number.
              </Typography>
              <Typography className={classes.subHeading}>
                We protect our community by making sure everyone is real.
              </Typography>
            </Grid>
            <Grid item>
              <img src={womanPicture} alt="woman picture" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export { PhoneLogin };
export default withStyles(styles)(withRouter(PhoneLogin));
// Customizable Area End
