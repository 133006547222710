// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React from "react";
import { ProductData } from "./EmailAccountRegistrationController.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  setSelectedPlan: (plan: any) => void;
  paymentPlanData: ProductData[];
  platformManagementPaymentPlans: ProductData[];
  setPlatformManagement: (planId?: string) => void;
  setSelectedPricing: (interval: PlanPricing) => void;
  selectedPricing: PlanPricing;
  clsses?: any;
}

export enum PlanPricing {
  Annual = "year",
  Month = "month",
}

interface S {
  currentStep: number;
  paymentError?: string;
  paymentPlanData: any;
  paymentPrize: number;
  activeBoxIndex: number;
}
interface SS {
  id: any;
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      paymentPrize: 0,
      currentStep: 1,
      paymentError: undefined,
      activeBoxIndex: 1,
      paymentPlanData: this.props.paymentPlanData,
    };
  }

  async componentDidMount() {
    this.props.setPlatformManagement()
  }

  addPlatformManagement = configJSON.addPlatformManagement;
  platformManagementRegularTooltip =
    configJSON.platformManagementRegularTooltip;
  platformManagementBoldTooltip = configJSON.platformManagementBoldTooltip;

  getPaymentPlan = (paymentPlan: any) => {
    if (paymentPlan?.attributes?.service?.permissions.length) {
      const paymentDescription = paymentPlan.attributes.service.permissions;
      return paymentDescription.map((description: string) =>
        description.replace("_", " ")
      );
    } else {
      return ["online reviews", "website chat"];
    }
  };

  planPriceVal = (
    paymentPlan: any,
    interval: PlanPricing = this.props.selectedPricing
  ) => {
    let planData = null;

    if (paymentPlan.attributes && paymentPlan.attributes?.plans.length > 0) {
      planData = paymentPlan.attributes?.plans.find((item: any) => {
        return item.interval === interval;
      });
    }

    return planData;
  };

  getPriceValue = (paymentPlan: any) => {
    const planPrice: any = this.planPriceVal(paymentPlan);
    if (planPrice) {
      return {
        price: planPrice.price,
        currency: planPrice.currency,
        interval: planPrice.interval,
        stripe_price_id: planPrice.stripe_plan_id,
        plan_id: planPrice.id,
        name: planPrice.name,
      };
    } else {
      return {
        price: "",
        currency: "",
        interval: "",
        stripe_price_id: "No Data Available",
        plan_id: "No Data Available",
        name: "No Data Available",
      };
    }
  };

  setActiveBoxIndex = (step: number) => {
    this.setState({ activeBoxIndex: step });
  };
}
// Customizable Area End
