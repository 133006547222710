// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export type Props = {
  id?: string;
  classes?: any;
  navigation?: any;
  modalData?: any;
  closeModal: (e: React.SyntheticEvent) => void;
};

interface S {}

interface SS {
  navigation: any;
}

export default class BaseModal extends BlockComponent<Props, S, SS> {
  sendProfileImageApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}
// Customizable Area End
