const getToken = () => {
  return window.localStorage.getItem('authToken') || '';
};

const setToken = (token: string) => {
  window.localStorage.setItem('authToken', token);
};

const removeToken = () => {
  window.localStorage.removeItem('authToken');
};

const isAuthenticated = () => {
  return !['', null].includes(getToken());
};

export { getToken, setToken, removeToken, isAuthenticated };
