// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  websites: any;
  activePopoverId: any;
  anchorEl: any;
  loading: boolean;
  createWebModal: boolean;
  websiteName: string;
  websiteUrl: string;
  websiteNameErr: string;
  websiteUrlErr: string;
  isShowAlert: boolean;
  alertMessage: string;
  updateModal: boolean;
  updateWebsiteId: string;
}
interface SS {
  id: any;
}
export default class WebsiteController extends BlockComponent<Props, S, SS> {
  getwebsitesAPIID: any = "";
  createWebsiteAPIID: any = "";
  deleteWebsiteAPIID: any = "";
  updateWebsiteAPIID: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      websites: [],
      activePopoverId: null,
      anchorEl: null,
      loading: true,
      createWebModal: false,
      websiteName: "",
      websiteUrl: "",
      websiteNameErr: "",
      websiteUrlErr: "",
      isShowAlert: false,
      alertMessage: "",
      updateModal: false,
      updateWebsiteId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleClick = (event: any, id: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      activePopoverId: id,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      activePopoverId: null,
    });
  };

  closeModal = () => {
    this.setState({ createWebModal: false });
  };

  openModal = () => {
    this.setState({ createWebModal: true });
  };

  closeModalUpdate = () => {
    this.setState({ updateModal: false });
  };

  openModalUpdate = (data: any) => {
    this.setState({
      websiteName: data.attributes.name,
      websiteUrl: data.attributes.url,
      updateWebsiteId: data.id,
      updateModal: true,
    });
  };

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.getwebsitesAPIID) {
          this.setState({ websites: responseJson.data });
        }

        if (apiRequestCallId === this.deleteWebsiteAPIID) {
          this.getWebsites();
          this.setState({
            isShowAlert:true,
            alertMessage: 'Website Deleted Successfully!'
          }, () => this.isShowCell());
        }

        if (apiRequestCallId === this.updateWebsiteAPIID) {
          this.getWebsites();
          this.setState({
            updateModal: false,
            anchorEl: null,
            activePopoverId: null,
            isShowAlert:true,
            alertMessage: 'Website Updated Successfully!'
          }, () => this.isShowCell());
        }

        if (apiRequestCallId === this.createWebsiteAPIID) {
          this.getWebsites();
          this.setState({
            createWebModal: false,
            alertMessage: "Website created successfully!",
            isShowAlert:true,
          }, () => this.isShowCell());
        }
      }
    }
  }

  async componentDidMount(): Promise<void> {
    this.getWebsites();
  }

  isShowCell = () => {
    setTimeout(() => this.setState({isShowAlert:false }), 2000);
  }

  onCreateWebsite(values: any) {
    const data = {
      website: {
        name: values.webName,
        url: values.webUrl,
      },
    };

    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createWebsiteAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.websitesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  onUpdateWebsite(values: any) {
    console.log(values);
    const data = {
      website: {
        name: values.webName,
        url: values.webUrl,
      },
    };

    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateWebsiteAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.websitesEndPoint}/${this.state.updateWebsiteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  deteleWebsite(value: any) {
    const headerData = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteWebsiteAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.websitesEndPoint}/${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getWebsites() {
    const headerData = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getwebsitesAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.websitesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}
// Customizable Area End
