Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.getPaymentDetailsEndPoint="bx_block_stripe_integration/payment_methods"
exports.stripeKey="pk_test_51NlZ7kBJz0kOzyGJKm1A1VZUEgqZJ7tez3FPTqPmywuJRsotJoKY1Dm99Z6ceaY7OWGbUJ8ZULD2ZKKqileBglMZ00ym8mSgSj"
exports.btnExampleTitle = "CLICK ME";
exports.contentType = "application/json";
exports.getApiMethod = "GET";
exports.deleteApiMethod = "DELETE";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.createIntentEndPoint ="bx_block_stripe_integration/payment_methods/create_intent"
exports.updateBusinessContactGetEndpoint = "account_block/business_contacts/current_contact";
exports.updateBusinessContactPutEndpoint = "account_block/business_contacts/update_contact";
exports.countryCodesGetEndpoint = "account/accounts/country_code_and_flag";
exports.customizePostingFeedGetEndpoint = "bx_block_settings/settings/posting_feed";
exports.customizePostingFeedPostEndpoint = "bx_block_settings/settings/update_posting_feed";
exports.customizeEveryReelButtonGetEndpoint = "bx_block_settings/settings/everyreel_button";
exports.customizeEveryReelButtonPostEndpoint = "bx_block_settings/settings/update_everyreel_button";
exports.updateBusinessContactHeading = "Update Main Business Contact";
exports.updateBusinessContactSubHeading = "Main point of contact for the business";
exports.billingHeading = "Update Billing Information";
exports.cardName = "Cardholder Name";
exports.cardExpDate = "Expiration date";
exports.cardNumber = "Card Number";
exports.cardCvv = "CVV";
exports.firstName = "First Name";
exports.lastName = "Last Name";
exports.mobileNumber = "Your Mobile Number";
exports.email = "Email";
exports.firstNamePlaceholder = "Your First Name";
exports.lastNamePlaceholder = "Your Last Name";
exports.emailPlaceholder = "email@email.com";
exports.cardNamePlaceholder = "Your First Name";
exports.cardExpDatePlaceholder = "MM/YYYY";
exports.cardNumberPlaceholder = "1111  1111 1111 1111";
exports.cardCvvPlaceholder = "000";
exports.update = "Save";
exports.updateContactButton="Update"
exports.currentBillingInformation = "Current Billing Information";
exports.currentBusinessContact = "Current Business Contact";
exports.businessContactUpdatedSuccess = "Business Contact Updated Successfully!";
exports.firstNameErrorMessage = "First Name is required.";
exports.lastNameErrorMessage = "Last Name is required.";
exports.phoneNumberErrorMessage = "Phone Number is required.";
exports.emailRequiredErrorMessage = "email is required.";
exports.emailInvalidErrorMessage = "Please enter a valid email address.";
exports.phoneRegExp = /^\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,6}$/;
exports.phoneNumberInvalidMessage = "Please enter a valid phone number";
exports.customizePostingFeed = "Customize Posting Feed";
exports.appearance = "Appearance";
exports.postThemeColor1 = "Post Theme Color 1";
exports.postThemeColor2 = "Post Theme Color 2";
exports.postThemeColorPlaceholder = "#FFC629";
exports.postThemeColorSecondPlaceholder="#FFF"
exports.save = "Save";
exports.postThemeColor1Required = "Post Theme Color 1 is required.";
exports.postThemeColor2Required = "Post Theme Color 2 is required.";
exports.switchOne="Allow Audience Posts"
exports.switchTwo="Approve Audience Posts"
exports.switchThree="Allow Audience Reviews"
exports.switchFour="Allow Audience Comments"
exports.switchFive="Allow Audience Photos & Videos"
exports.customizeEveryReelButton = "Customize EveryReel Button";
exports.border = "Border";
exports.caption = "Caption";
exports.addOns = "Add-ons";
exports.textBubble = "Text bubble";
exports.captionPlaceholder = "Let's talk";
exports.switchOn = "On";
exports.switchOff = "Off";
exports.updateSubscriptionText = "Update Subscription"
exports.postingFeedUpdatedSuccess = "Posting Feed Settings Updated Successfully!";
exports.hexColorCodeRegExp = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
exports.postThemeColorInvalid = "Please enter a valid hex color code";
exports.captionRequired = "Caption is required."
exports.everyReelButtonUpdatedSuccess = "EveryReel Button Settings Updated Successfully!";
exports.socialIntegrationsTitle = "Social Integrations";
exports.invitationSentTitle = "Invitation sent";
exports.updateSocialLinkEndPoint = "bx_block_settings/social_integrations/"
exports.blankError = "This field is required"
exports.cardNameError = "Please enter valid card name"
exports.cvvNumberError = "Please enter valid cvv number"
exports.usernameError = "Enter valid username"
exports.holidayNameError = "Please enter valid holiday name"
exports.afterHoursDescription = "We received your text after hours. One of our team members will text you back tomorrow."
exports.receivedDescription = "We received your text after hours."
exports.receivedModalDescription="We received your text."
exports.tomorrowDescription = " One of our team members will text you back as soon as we return from the holiday break."
exports.socialDescription = "Build social integrations to share your Outgrow content pieces in social network apps"
exports.workingHoursEndPoint = "account_block/business_hours/update_working_hours"
exports.addHolidayEndPoint = "account_block/business_hours/add_holiday"
exports.updateHolidayEndPoint = "account_block/business_hours"
exports.allHolidayEndPoint = "account_block/business_hours/all_holidays"
exports.getWorkingHoursEndPoint = "account_block/business_hours/get_working_hours"
exports.getDefaultSocialIntegrationValuesEndPoint="bx_block_settings/social_integrations/get_default_sites"
exports.updateWorkingHoursEndPoint="account_block/business_hours/update_working_hours"
exports.getHolidayHoursEndPoint="account_block/business_hours/get_holiday_hours_status"
exports.setHolidayHoursEndPoint="account_block/business_hours/set_holiday_hours_status"
exports.settingsSubscriptionPlansEndPoint="customisable_user_subscriptions/products"
exports.websitesEndPoint="account_block/websites"
exports.teammatesEndPoint="bx_block_teammates/teammates"
exports.invitationAPIEndPoint="bx_block_teammates/get_invite_automation"
exports.getCurrentBuPlanEndPoint="customisable_user_subscriptions/current_subscription"
exports.updateCurrentBuPlanEndPoint="customisable_user_subscriptions/update_current_subscription"
exports.automationTemplatesApiEndPoint="bx_block_automations/automations"
exports.getWaitListFoldersEndPoint="account_block/waitlists" 
exports.contactsInWaitListEndPoint="account_block/contacts"
exports.addedToWaitListFolderEndPoint="account_block/contacts/waitlist/add_contacts"
exports.removeWaitListContactEndPoint="account_block/contacts/remove_waitlist_contact"
exports.setUpAutomationEndPoint="account_block/waitlists"
exports.getAutomationTemplateEndPoint="account_block/contacts/get_reminder_automation"
exports.createSocialPhoneNumberEndPoint="/bx_block_settings/social_phone_numbers"
exports.getCustomizableSubscriptionEndPoint="customisable_user_subscriptions/waitlist_subscriptions"
exports.getSecretKeyForSubscription="bx_block_stripe_integration/subscriptions"
exports.getNotificationPreferencesEndPoint="bx_block_settings/notification_prefrences"
exports.updateNotificationPreferencesEndPoint="bx_block_settings/notification_prefrences"
exports.getWigetCodeEndPoint="bx_block_settings/settings/widget_code"
exports.getWidgetDataEndPoint="bx_block_settings/settings/website_data" 
exports.getEmbedDataEndPoint="bx_block_dashboard/dashboards/embed_data"
exports.pauseBuAccountEndPoint="customisable_user_subscriptions/pause_subscription"
exports.removeTeammateEndPoint="bx_block_teammates/teammates/"
exports.sendInviteEndPoint="bx_block_teammates/invites/first_login_invite"
exports.updateInvitaitonStatusEndPoint="bx_block_teammates/teammates/update_invitation_status/"
exports.forgtPasswordEndPoint="bx_block_forgot_password/generate_password_link"
exports.bulkInvitesEndPoint="bx_block_teammates/add_teammates"
// Customizable Area End