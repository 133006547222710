// Customizable Area Start
import React, { CSSProperties, ChangeEvent } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  FormHelperText,
  withStyles,
  TextareaAutosize,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import Select from "react-select";
import InviteFriendsModalController, {
  Props,
} from "./InviteFriendsModalController.web";
import { InviteFriendsStepType } from "./enums";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      display: "flex",
      width: "555px",
      padding: "60px 0 40px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "22px",
      flexShrink: 0,
      borderRadius: "8px 8px 32px 8px",
      background: "#FFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
    },

    closeButton: {
      position: "absolute",
      top: "20px",
      right: "11px",
    },

    modalTitle: {
      padding: "0 64px",
      color: "#000",
      textAlign: "center",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },

    modalDescription: {
      padding: "0 64px",
      color: "#64748B",
      textAlign: "center",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },

    linkContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "0 64px",
      width: "100%",
      height: "66px",
      flexShrink: 0,
      backgroundColor: "#FFC629",
    },

    linkText: {
      display: "flex",
      alignItems: "center",
      color: "#0F172A",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      textAlign: "center",
    },

    link: {
      display: "flex",
      alignItems: "center",
      color: "#0F172A",
      textDecoration: "none",
      fontWeight: 700,

      "&:hover": {
        cursor: "pointer",
      },
    },

    form: {
      width: "100%",
      padding: "0 64px",
    },

    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
    },

    inputLabel: {
      color: "#334155",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
    },

    contactSelect: {
      width: "100%",
      height: "44px",
    },

    checkBox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#000",
      },
    },

    textInputContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "4px",
    },

    templateInput: {
      width: "100%",
      flexShrink: 0,
      resize: "vertical",
      padding: "8px",
      border: "1px solid #E2E8F0",
      borderRadius: "8px",
      fontFamily: "inherit",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "26px",
      color: "#475569",

      "&:focus": {
        outline: "none",
        border: "2px solid #FFC629",
      },
    },

    automationsButton: {
      display: "flex",
      padding: "16px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#000000",
      borderRadius: "8px",
      color: "#FFF",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",

      "&:hover": {
        backgroundColor: "#000000",
      },
    },

    linkInput: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
      },
    },

    submitButton: {
      display: "flex",
      padding: "16px 60px",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "none",
      borderRadius: "8px",
      backgroundColor: "#FFC629",
      color: "#0F172A",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",

      "&:hover": {
        backgroundColor: "#FFC629",
      },
    },
  });

const webStyles: Record<string, CSSProperties> = {
  linkInput: {
    display: "flex",
    width: "100%",
    height: "44px",
    padding: "12px 40px 12px 8px",
    alignItems: "center",
    flexShrink: 0,
  },
};

class InviteFriendsModal extends InviteFriendsModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      contacts,
      inviteMessage,
      link,
      classes,
      setStep,
      setInviteMessage,
      setLink,
      closeModal,
      handleSubmitInvite,
      checkContact,
    } = this.props;

    return (
      <Container
        id="inviteFriendsContainer"
        maxWidth={false}
        className={classes.modal}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          id="closeModalButton"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.modalTitle}>
          {this.labelInviteFriendToEverybrand}
        </Typography>
        <Typography className={classes.modalDescription}>
          {this.inviteFriendDescriptionText}
        </Typography>
        <Box className={classes.linkContainer}>
          <Typography className={classes.linkText}>
            {this.bulkInviteProposal}
          </Typography>
          <Typography
            id="bulkInviteLink"
            onClick={() => setStep(InviteFriendsStepType.BULK_INVITE)}
            className={classes.link}
          >
            &nbsp;{this.bulkInviteLink}
          </Typography>
        </Box>
        <Box className={classes.linkContainer}>
          <Typography className={classes.linkText}>
            {this.addContactQuestion}
          </Typography>
          <Typography
            id="addContactLink"
            onClick={() => setStep(InviteFriendsStepType.ADD_CONTACT)}
            className={classes.link}
          >
            &nbsp;{this.addContactLink}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            selectedContacts: [],
            invite_message: inviteMessage,
            link: link,
          }}
          validate={this.validate}
          onSubmit={(_values, actions) => {
            actions.setSubmitting(false);
            handleSubmitInvite();
          }}
        >
          {({ handleChange, handleSubmit, setFieldValue, errors, values }) => {
            const selectedContactsIds = contacts
              .filter((contact) => contact.checked)
              .map((contact) => contact.id);

            if (
              selectedContactsIds?.join("") !==
              values.selectedContacts?.join("")
            ) {
              setFieldValue(
                "selectedContacts",
                contacts
                  .filter((contact) => contact.checked)
                  .map((contact) => contact.id)
              );
            }

            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Box className={classes.formContainer}>
                  <Typography className={classes.inputLabel}>
                    {this.sendTo}
                  </Typography>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.selectedContacts)}
                  >
                    <Select
                      id="selectedContacts"
                      name="selectedContacts"
                      className={classes.contactSelect}
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: "100%",
                          boxShadow: "unset",
                          borderColor: "#CBD5E1",
                          "&:hover": {
                            borderColor: "#CBD5E1 !important",
                            boxShadow: "#FFC629 !important",
                          },
                          "&.is-focused": {
                            borderColor: "#FFC629 !important",
                            boxShadow: "#FFC629 !important",
                          },
                          "& > div:first-child": {
                            overflow: "hidden",
                            height: "100%",
                          },
                        }),
                      }}
                      placeholder="Select contact"
                      isMulti
                      options={contacts.map((contact) => ({
                        value: contact.id,
                        label: contact.fullName,
                      }))}
                      value={contacts
                        .filter((contact) => contact.checked)
                        .map((contact) => ({
                          value: contact.id,
                          label: contact.fullName,
                        }))}
                      onChange={(selectedContacts) => {
                        checkContact(
                          Array.isArray(selectedContacts) && selectedContacts.map((contact) => contact.value) || []
                        );

                        const changedEvent = {
                          target: {
                            name: "selectedContacts",
                            value: contacts
                              .filter((contact) => contact.checked)
                              .map((contact) => contact.id),
                          },
                        };
                        handleChange(changedEvent);
                      }}
                    />
                    <FormHelperText>{errors.selectedContacts}</FormHelperText>
                  </FormControl>

                  <Box className={classes.textInputContainer}>
                    <Typography className={classes.inputLabel}>
                      {this.templatePreview}
                    </Typography>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.invite_message)}
                    >
                      <TextareaAutosize
                        minRows={4}
                        id="invite_message"
                        name="invite_message"
                        className={classes.templateInput}
                        value={values.invite_message}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                          setInviteMessage(event.target.value);
                          handleChange(event);
                        }}
                        style={
                          errors.invite_message
                            ? { borderColor: "#f44336" }
                            : {}
                        }
                      />
                    </FormControl>
                    <FormHelperText
                      style={errors.invite_message ? { color: "#f44336" } : {}}
                    >
                      {errors.invite_message}
                    </FormHelperText>
                  </Box>

                  <Typography className={classes.inputLabel}>
                    {this.tryOtherAnswers}
                  </Typography>
                  <Button
                    className={classes.automationsButton}
                    data-test-id="automationsButton"
                    variant="contained"
                  >
                    {this.automationsTemplates}
                  </Button>

                  <Box className={classes.textInputContainer}>
                    <Typography className={classes.inputLabel}>
                      {this.autolink}
                    </Typography>
                    <TextField
                      id="link"
                      name="link"
                      variant="outlined"
                      className={classes.linkInput}
                      value={values.link}
                      fullWidth
                      error={Boolean(errors.link)}
                      helperText={errors.link}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        setLink(event.target.value);
                        handleChange(event);
                      }}
                      InputProps={{
                        style: webStyles.linkInput,
                      }}
                    />
                  </Box>
                  <Button
                    id="submitInvite"
                    variant="contained"
                    type="submit"
                    className={classes.submitButton}
                  >
                    {this.sendInvite}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

export { InviteFriendsModal };
export default withStyles(styles)(InviteFriendsModal);
// Customizable Area End
