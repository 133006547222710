// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography } from "@material-ui/core";
import AnalyticsTabsController from "./AnalyticsTabsController.web";
import { AnalyticsPages } from "./enums";

export class AnalyticsCommonStats extends AnalyticsTabsController {
  render() {
    const {
      classes,
      currentEngagementView,
      currentPage,
      tabsData,
      handleTabClick,
    } = this.props;
    const tabsFontColors = [
      "#189CBD",
      "#FFC629",
      "#60C289",
      "#FD6C3F",
      "#0EB0A7",
    ];

    return (
      <Box className={classes.mainContainer}>
        {Object.keys(tabsData).map((tabName, i) => (
          <Box
            key={tabName}
            data-test-id={tabName}
            className={classes.commonStatsItem}
            onClick={() => handleTabClick(tabName)}
            style={{
              backgroundColor: this.getStyleValue(
                currentPage === AnalyticsPages.ENGAGEMENT &&
                  currentEngagementView === tabName.toUpperCase(),
                tabsFontColors[i],
                "#FFF"
              ),
              cursor: this.getStyleValue(
                currentPage === AnalyticsPages.ENGAGEMENT,
                "pointer",
                "default"
              ),
            }}
          >
            <Typography
              className={classes.statsItemTitle}
              style={{
                color: this.getStyleValue(
                  currentPage === AnalyticsPages.ENGAGEMENT &&
                    currentEngagementView === tabName.toUpperCase(),
                  "#FFF",
                  "#000"
                ),
              }}
            >
              {tabName.split("_").join(" ")}
            </Typography>
            <Typography
              className={classes.statsItemValue}
              style={{
                color: this.getStyleValue(
                  currentPage === AnalyticsPages.ENGAGEMENT &&
                    currentEngagementView === tabName.toUpperCase(),
                  "#FFF",
                  tabsFontColors[i]
                ),
              }}
            >
              {tabsData[tabName]}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      gap: "23px",
    },

    commonStatsItem: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: "#FFF",
      padding: "15px 15px 30px",
      borderRadius: "15px",
      flexBasis: "212px",
      height: "157px",
    },

    statsItemTitle: {
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "capitalize",
    },

    statsItemValue: {
      fontFamily: "Inter",
      fontSize: "36px",
      lineHeight: "44px",
      fontWeight: 700,
    },
  });

export default withStyles(styles)(AnalyticsCommonStats);
// Customizable Area End
