import React from "react";
import NavigationMenuEUWeb from "../../../components/src/NavigationMenuEU.web";
import { Box, Typography } from "@material-ui/core";
import SuggestedBrands from "../../../components/src/SuggestedBrands";
import TopActiveUsers from "./TopActiverUsers.web";
import DashboardController from "./DashboardController.web";
import CustomLoader from "../../../components/src/CustomLoader.web";

export default class EuTrending extends DashboardController {
  handleTrendingPostRender (trend: {attributes: {files: {link: string}[] }},index : number) {
    return (
      this.isVideo(trend.attributes.files[0]) ? (
        <video
          ref={this.state.videoRefs[index]}
          src={trend.attributes.files[0].link}
          style={webstyle.image}
          controls
        />
      ) : (
        <img
          src={trend.attributes.files[0].link}
          alt=""
          style={webstyle.image}
        />
      )
    )
  }
  render() {
    const { navigation } = this.props;
    const { top6Trending } = this.state;

    return (
      <div style={webstyle.root}>
        <NavigationMenuEUWeb brands={this.state.brandsIFollowTrending} />
        <Box style={webstyle.mainContainer}>
          <Box style={webstyle.mainBox}>
            <Box style={webstyle.trendingBox} onScroll={this.handleScrollTrendingPost} data-test-id="trending-box">
              <Typography variant="h6" style={webstyle.Trendheading}>
                TRENDING
              </Typography>
              {this.state.trendingpost &&
                this.state.trendingpost.map((trend, index) => (
                  <Box key={trend.id} style={webstyle.imageContainer}>
                    {trend.attributes.files && trend.attributes.files[0] ? (
                      this.handleTrendingPostRender(trend, index)
                    ) : (
                      ""
                    )}
                  </Box>
                ))}
                <CustomLoader loaderSize={24} isLoading={this.state.isLoader} />
            </Box>
            <Box style={webstyle.boxBrandTop}>
              <div>
                <TopActiveUsers
                  top6={top6Trending}
                  navigation={navigation}
                />
              </div>
              <div>
                <SuggestedBrands
                  brands={this.state.suggestedBrandsTrending}
                  followBrand={this.followSuggestedBrandApi}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

const webstyle = {
  root: {
    backgroundColor: "#f1f4f9",
    minHeight: "100vh"
  },
  bgColor: {
    backgroundColor: "#f1f4f9"
  },
  mainContainer: {
    width: "100vw",
    maxWidth: "1240px",
    margin: "15px auto"
  },
  Trendheading: {
    margin: "30px 0 20px 45px",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700
  },
  boxBrandTop: { width: "24%", backgroundColor: "#FFFFFF", maxHeight: "700px" },
  videoImg: { borderRadius: "10px", margin: "10px 21%" },
  trendingBox: {
    padding:"10px 200px",
    width: "70%",
    backgroundColor: "#FFFFFF",
    maxHeight: "900px",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column"
  } as React.CSSProperties,
  mainBox: { display: "flex", gap: "15px", justifyContent: "center" },
  imageContainer: {
    position: "relative",
    margin:"10px",
    display: "block"
  } as React.CSSProperties,
  image: {
    borderRadius: "10px",
    width: "100%",
    height: "calc(100vh - 200px)"
  }
};
