// Customizable Area Start
import React from "react";
import MessageOpetionsController from "./MessageOptionsController.web";
import {
  Box,
  Typography,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  priorityIcon,
  messageIcon,
  resendIcon,
  pinIcon,
} from "../../notifications/src/assets";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { handleOrConditions } from "../../../components/src/CommonFunctions";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F172A",
      },
    },
  },
}))(MenuItem);

export class MessageOption extends MessageOpetionsController {
  render() {
    const {
      classes,
      isPopoverVisible,
      closePopover,
      folders,
      selectedMessage,
    }: any = this.props;
    const open = Boolean(isPopoverVisible);
    const id = open ? "simple-popover" : undefined;

    const openAssign = Boolean(this.state.assignPopover);
    const idAssign = openAssign ? "simple-popover" : undefined;
    console.log(selectedMessage.attributes);

    return (
      <Box style={{ position: "relative" }} data-test-id="mainBox">
        <Popover
          id={id}
          open={open}
          anchorEl={isPopoverVisible}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{
            paper: classes.popOverPaper,
          }}
          data-test-id="mainPopover"
        >
          <StyledMenuItem
            data-test-id="reply"
            onClick={undefined}
          >
            <ListItemIcon>
              <img
                src={resendIcon}
                alt="priorityIcon"
                style={{ height: "24px", width: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Reply" />
          </StyledMenuItem>
          <StyledMenuItem
            data-test-id="pinToTop"
            onClick={this.pinConversation}
          >
            <ListItemIcon>
              <img
                src={pinIcon}
                alt="priorityIcon"
                style={{ height: "24px", width: "24px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={handleOrConditions(
                selectedMessage.attributes.pin_conversation,
                "Unpin message",
                "Pin message"
              )}
            />
          </StyledMenuItem>
          <StyledMenuItem data-test-id="MarkAsRead" onClick={this.marksAsRead}>
            <ListItemIcon>
              <img
                src={messageIcon}
                alt="priorityIcon"
                style={{ height: "24px", width: "24px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Mark as read" />
          </StyledMenuItem>
          <StyledMenuItem
            data-test-id="assigneBtn"
            onClick={(e) => this.assignPopoverHandle(e)}
          >
            <ListItemIcon>
              <img
                src={priorityIcon}
                alt="priorityIcon"
                style={{ height: "30px", width: "30px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Assign" />
          </StyledMenuItem>{" "}
          <Popover
            id={idAssign}
            open={openAssign}
            anchorEl={this.state.assignPopover}
            onClose={this.closeAssignPopover}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={{
              paper: classes.popOverPaperAssign,
            }}
          >
            <Box key="assignFOlder" className={classes.assignFolderBox}>
              <Box className={classes.assignFolBox2}>
                <ArrowBackIcon />
                <Typography className={classes.folderText}>Folder</Typography>
              </Box>
              <Divider />
              <RadioGroup
                aria-label="options"
                name="options1"
                key="options"
                value={this.state.selectedFolder}
                onChange={this.handleFolderSelect}
                data-test-id="assignFolderRadios"
              >
                {folders.map((value: any) => (
                  <FormControlLabel
                    data-testid="lableNames"
                    key={value.attributes?.name}
                    value={value.attributes?.name}
                    control={<Radio style={{ color: "#000" }} />}
                    label={value.attributes?.name}
                  />
                ))}
              </RadioGroup>
            </Box>
          </Popover>
        </Popover>
      </Box>
    );
  }
}
// Customizable Area End
