// Customizable Area Start
import { Settings } from './enums';
import {
  AutomationTemplatesIcon,
  BillingInfoIcon,
  BusinessHoursIcon,
  ContactInfoIcon,
  EveryreelIcon,
  GettingStartedIcon,
  PostFeedIcon,
  SocialIntegrationIcon,
  SubscriptionIcon,
  TeamMatesIcon,
  WebsiteIcon,
  TextboxCheckIcon,
  EngageIcon,
  HomeIcon,
  WebIcon,
  PortraitIcon,
  PublicIcon,
  NotificationsNoneIcon,
  ImportantDevicesIcon,
  socailPhoneIcon,
  connectWebsiteIcon,
  notificationPreferencesIcon
} from './assets';

export const settingsMenuOptions = [
  {
    key: Settings.GettingStarted,
    icon: GettingStartedIcon,
    option: 'Getting started',
  },
  {
    key: Settings.BillingInfo,
    icon: BillingInfoIcon,
    option: 'Billing Information',
  },
  {
    key: Settings.UpdateBusinessContactInfo,
    icon: ContactInfoIcon,
    option: 'Update Business Contact',
  },
  {
    key: Settings.CustomizePostingFeed,
    icon: PostFeedIcon,
    option: 'Customize Posting Feed ',
  },
  {
    key: Settings.CustomizeEveryreelBtn,
    icon: EveryreelIcon,
    option: 'Customize EveryReel Button',
  },
  {
    key: Settings.SocialIntegrations,
    icon: SocialIntegrationIcon,
    option: 'Social Integrations',
  },
  {
    key: Settings.ConnectWebsite,
    icon: connectWebsiteIcon,
    option: 'Connect Website',
  },
  {
    key: Settings.UpdateBusinessHours,
    icon: BusinessHoursIcon,
    option: 'Update their business hours',
  },
  {
    key: Settings.NotificationPreferences,
    icon: notificationPreferencesIcon,
    option: 'Notification Preferences',
  },
  {
    key: Settings.Websites,
    icon: WebsiteIcon,
    option: 'Websites',
  },
  {
    key: Settings.SocialPhoneNumber,
    icon: socailPhoneIcon,
    option: "Social Phone Number"
  },
  {
    key: Settings.TeamMates,
    icon: TeamMatesIcon,
    option: 'Teammates',
  },
  {
    key: Settings.UpdateSubscription,
    icon: SubscriptionIcon,
    option: 'Update subscription',
  },
  {
    key: Settings.AutomationTemplates,
    icon: AutomationTemplatesIcon,
    option: 'Automation templates',
  }
];

export const gettingStartedGuidelines = [
  {
    icon: TextboxCheckIcon,
    heading: 'Create Your Account',
    message:
      'Click on the "Sign Up" button and provide your business details. You\'ll need your business name, email, and password to set up your account.',
  },
  {
    icon: HomeIcon,
    heading: 'Explore Your Dashboard',
    message:
      "Once you're logged in, take a tour of your Everybrand dashboard. Get familiar with the layout and options available.",
  },
  {
    icon: WebIcon,
    heading: 'Add Your Websites',
    message:
      'Navigate to the "Websites" section and add the websites you want to manage using Everybrand. This will allow you to streamline your online presence and engage with your audience effectively.',
  },
  {
    icon: PortraitIcon,
    heading: 'Customize Your Profile',
    message:
      'Head to the "Profile" section and personalize your business profile. Upload a profile picture, add a catchy headline, and make sure your contact information is up to date.',
  },
  {
    icon: PublicIcon,
    heading: 'Connect Your Social Media',
    message:
      'In the "Social Integrations" section, connect your social media accounts to expand your reach and easily share your content across platforms.',
  },
  {
    icon: NotificationsNoneIcon,
    heading: 'Set Up Notifications',
    message:
      'Manage your notifications in the "Settings" section. Choose which alerts you want to receive and how often.',
  },
  {
    icon: ImportantDevicesIcon,
    heading: 'Start Posting',
    message:
      'Go to the "Posting Feed" and begin creating and sharing your engaging posts. You can customize colors and interactions to make your content stand out.',
  },
  {
    icon: EngageIcon,
    heading: 'Engage with Your Audience',
    message:
      "Interact with your audience by responding to comments, reviews, and messages. Use Everybrand's features to build meaningful connections.",
  },
];

export const pauseSubscriptionReasons: Array<string> = ["Happy with the amount of reviews I have.", "I will ramp back up later.", "I'm in my non-busy season.", "I want to keep all my current reviews and posts, but want nothing new.", "I received all the value I wanted.", "I want to lose all my reviews, content, and customer conversations by canceling."]
// Customizable Area End
