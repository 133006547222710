// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  hourNotification: boolean;
  newCommentsDesktop: boolean;
  newCommentsEmail: boolean;
  desktop: boolean
  email: boolean
  newReviewsDesktop: boolean;
  newReviewsEmail: boolean;
  allNewMessageDesktop: boolean;
  allNewMessageEmail: boolean;
  onlyNewMessagesAssignedToDesktop: boolean;
  onlyNewMessagesAssignedToEmail: boolean;
  existingMessagesAssignedToDesktop: boolean;
  existingMessagesAssignedToEmail: boolean;
  showSuccessAlert:boolean
}
interface SS {
  id: any;
}
export default class NotificationPreferencesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getNotificationPreferencesId:any=""
  updateNotificationPreferencesId:any=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      hourNotification: false,
      newCommentsDesktop: false,
      newCommentsEmail: false,
      desktop: false, 
      email: false, 
      newReviewsDesktop: false,
      newReviewsEmail: false,
      allNewMessageDesktop: false,
      allNewMessageEmail: false,
      onlyNewMessagesAssignedToDesktop: false,
      onlyNewMessagesAssignedToEmail: false,
      existingMessagesAssignedToDesktop: false,
      existingMessagesAssignedToEmail: false,
      showSuccessAlert:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    this.getNotificationPreferences();
  }
  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getNotificationPreferencesId){
        this.handleGetNotificationResponse(responseJson)
      }
      if(apiRequestCallId === this.updateNotificationPreferencesId){
        this.setState({ showSuccessAlert: true });
        setTimeout(() => {
          this.setState({ showSuccessAlert: false });
        }, 2000)
        this.getNotificationPreferences()
      }
    }
  }

  handleGetNotificationResponse(response:any){
    this.setState({
      hourNotification : response.data.hours_24,
      newCommentsDesktop: response.data.desktop_comment,
      newCommentsEmail: response.data.email_comment,
      desktop: response.data.desktop_follower, 
      email: response.data.email_follower, 
      newReviewsDesktop: response.data.desktop_revies,
      newReviewsEmail: response.data.email_reviews,
      allNewMessageDesktop: response.data.desktop_messages,
      allNewMessageEmail: response.data.email_messages,
      onlyNewMessagesAssignedToDesktop: response.data.desktop_assigned_messages,
      onlyNewMessagesAssignedToEmail: response.data.email_assigned_messages,
      existingMessagesAssignedToDesktop: response.data.desktop_assigned_conversation,
      existingMessagesAssignedToEmail: response.data.email_assigned_conversation,
    })
  }

  getNotificationPreferences() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotificationPreferencesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationPreferencesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  updateNotificaitonPreferences() {
    const headerData = {
      "Content-Type": configJSON.contentType,
      Token: localStorage.getItem("authToken"),
    };

    const httpBody = {
      "notification_preference": {
          "hours_24": this.state.hourNotification,
          "desktop_comment": this.state.newCommentsDesktop,
          "email_comment": this.state.newCommentsEmail,
          "desktop_follower": this.state.desktop,
          "email_follower": this.state.email,
          "desktop_revies": this.state.newReviewsDesktop,
          "email_reviews": this.state.newReviewsEmail,
          "desktop_messages": this.state.allNewMessageDesktop,
          "email_messages": this.state.allNewMessageEmail,
          "desktop_assigned_messages": this.state.onlyNewMessagesAssignedToDesktop,
          "email_assigned_messages": this.state.onlyNewMessagesAssignedToEmail,
          "desktop_assigned_conversation": this.state.existingMessagesAssignedToDesktop,
          "email_assigned_conversation": this.state.existingMessagesAssignedToEmail
      }
  }

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateNotificationPreferencesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateNotificationPreferencesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}

// Customizable Area End
