// Customizable Area Start
import React, { Component } from "react";
import { styled } from "@material-ui/core/styles";
import ImageViewer from "react-simple-image-viewer";
import { IconButton } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const Image = styled("img")({
  height: "60px",
  width: "60px",
  borderRadius: "8px",
});

const CloseButton = styled(IconButton)({
  backgroundColor: "#000",
  zIndex: 1501,
  "&:hover": {
    backgroundColor: "#000",
  },
  "& svg": {
    color: "#fff",
  },
});

interface ICommentImageProps {
  files:
    | {
        url: string;
      }[]
    | null;
}

interface State {
  isImageOpen: boolean;
}

export class CommentImage extends Component<ICommentImageProps, State> {
  constructor(props: ICommentImageProps) {
    super(props);

    this.state = {
      isImageOpen: false,
    };
  }

  openImageFull = () => {
    this.setState({ isImageOpen: true });
  };

  closeImageFull = () => {
    this.setState({ isImageOpen: false });
  };

  render() {
    const { files } = this.props;
    const { isImageOpen } = this.state;

    return (
      <>
        {Array.isArray(files) && files.length > 0 ? (
          <>
            <Image
              src={files[0].url}
              alt="Image"
              onClick={this.openImageFull}
            />
            {isImageOpen && (
              <ImageViewer
                src={[files[0].url]}
                currentIndex={0}
                closeComponent={
                  <CloseButton size="small" onClick={this.closeImageFull}>
                    <CloseRounded />
                  </CloseButton>
                }
                backgroundStyle={{
                  zIndex: 1500,
                  backgroundColor: "rgba(0,0,0,0.45)",
                  borderRadius: 8
                }}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={this.closeImageFull}
              />
            )}
          </>
        ) : null}
      </>
    );
  }
}

export default CommentImage;
// Customizable Area End
