// Customizable Area Start
import React from "react";
import InstagramAuthRedirectController from "./InstagramAuthRedirectController.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import CustomAlert from "../../../components/src/CustomAlert.web";

class InstagramAuthRedirect extends InstagramAuthRedirectController {
  render() {
    const { isLoading, alertMessage, alertType } = this.state;

    return (
      <>
        <CustomAlert alertType={alertType} message={alertMessage} />
        <CustomLoader isLoading={isLoading} wrapperHeight="70vh" />;
      </>
    );
  }
}

export default InstagramAuthRedirect;
// Customizable Area End
