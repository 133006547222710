import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField,
} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const webStyle = {
    EmailSubtitle: {
        marginTop: "10px",
        color: "#334155",
        fontWeight: 900
    },
    nextHomePage: {
        backgroundColor: "#FFC629",
        marginTop: '25px',
        width: "100%"
    },
    BackHomePage: {
        backgroundColor: "#0F172A",
        color: "#fff",
        marginTop: '25px',
        width: "100%"
    },
    CommunityText: {
        marginRight: "5px"
    },
    BelowCommunityFlex: {
        display: "flex",
        marginTop: '25px',
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        maxWidth: "350px",
    },
    fontWeight: {
        fontWeight: 900
    },

};
const BusinessEmail = (props: any) => {
    return (    
        // Required for all blocks

        <Box style={webStyle.formContainer}>
            <Box>
                <Typography variant="h5" gutterBottom style={webStyle.fontWeight} >
                    Enter bussiness email
                </Typography>
                <Typography variant="subtitle2" gutterBottom style={webStyle.EmailSubtitle} >
                    Email
                </Typography>
                <TextField fullWidth placeholder="email@email.com" variant="outlined" size="small" name="emailAddress"
                    onChange={(e) => props.handleFieldChange(e)}
                    value={props.emailAddress}
                    error={Boolean(props.errorMsg?.emailAddress)}
                    helperText={props.errorMsg?.emailAddress}
                    data-test-id="emailInput"
                />
                <Box><Button variant="contained" onClick={props.handleSubmit} data-test-id="nextButton" style={webStyle.nextHomePage}>Next</Button></Box>
                <Box style={webStyle.BelowCommunityFlex}>
                    <LockOutlinedIcon style={webStyle.CommunityText}></LockOutlinedIcon>
                    <Typography variant="body2" gutterBottom>
                        we protect our community by making sure anyone on everybrand is real</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default BusinessEmail;
// Customizable Area End
