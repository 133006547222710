import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button} from '@material-ui/core';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		activeUsersContainer: {
			padding: theme.spacing(2, 2),
			maxHeight: "400px",
			overflowY: "auto",
			marginTop: "25px",
			backgroundColor: "#FFFFFF",
		},
		heading: {
			color: "#0F172A",
			fontFamily: "Inter",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: 700,
			lineHeight: "22px",
		},
		brandsUlContainer:{
			display: "flex",
			flexDirection: "column",
			gap: "15px",
			marginTop: "25px",
		},
		brandsLiContainer: {
			display: "flex",
			justifyContent:'space-between',
			alignItems:"center",
			gap: "10px"
		},
		brandsContentContainer: {
			display: "flex",
			gap: "15px",
			flex: 1,
			alignItems: "center",
			overflow: "hidden"
		},
		brandNameTag: {
			fontSize: "16px",
			fontWeight: 700,
			overflowX: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis"
		},
		brandsImageContainer: {
			width: "48px",
			height: "48px",
			padding: "4px",
			background: "#FFC629",
			borderRadius: "50%",
			"& img" : {
				width: "46px",
				height: "46px",
				borderRadius: "50%",
				border: "1px solid #fff",
				objectFit: "cover"
			}
		},
		brandsFollowBtn: {
			textTransform: "capitalize",
			color: "#3B82F6",
			fontWeight: 600,
			fontSize: "16px",
			lineHeight: "1.5"
		}
	})
);

interface BrandAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_name: string | null;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  is_first_login: boolean;
  profile_pic: {
    url : string
  } | null;
  cover_photo: {
    url : string
  } | null;
  follows: {
      followers: string;
      followings: string;
  };
  subscription_paused: boolean;
}
interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}

interface SuggestedBrandsProps {
	brands: Brand[]
	followBrand: (brandId: string) => void
}

const SuggestedBrands : React.FC<SuggestedBrandsProps> = ({ brands = [], followBrand }) => {
	const classes = useStyles();
	return (
		<>
			<Paper square elevation={0} className={classes.activeUsersContainer}>
				<Typography
					gutterBottom
					variant="subtitle2"
					className={classes.heading}
				>
					Suggested brands for you
				</Typography>
				<Box>
					<div className={classes.brandsUlContainer}>
						{
							brands.map((brandElem) => {
								const { id, attributes: { profile_pic, first_name, last_name } } = brandElem
								const fullName = `${first_name} ${last_name}`

								return (
									<div key={id} className={classes.brandsLiContainer}>
										<Box className={classes.brandsContentContainer}>
											<Link to={`/profile/${id}`}>
											<Box className={classes.brandsImageContainer}>
												<img src={profile_pic?.url} alt={""}  />
											</Box>
											</Link>
											<Typography title={fullName} className={classes.brandNameTag}>
												{fullName}
											</Typography>
										</Box>
										<Button
											className={classes.brandsFollowBtn}
											onClick={() => followBrand(id)}
										>
											Follow
										</Button>
									</div>
								)
							})
						}
					</div>
				</Box>
			</Paper>
		</>

	);
}

export default SuggestedBrands;
