// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { NewEveryreelSteps, PostTypes } from "./enums";
import { File } from "./types";
import React from "react";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  files: File[];
  postType: PostTypes;
  setStep: (step: NewEveryreelSteps) => void;
  closeModal: () => void;
  sendFunnelFiles: () => void;
};

interface S {
  isPlaying: boolean
}

interface SS {
  navigation: any;
  stepType: NewEveryreelSteps;
}

export default class CreateEveryreelConfirmController extends BlockComponent<
  Props,
  S,
  SS
> {
  videoRef: React.RefObject<HTMLVideoElement> = React.createRef()

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isPlaying: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  goodChoice = configJSON.goodChoice;

  handleSendFunnelFiles = () => {
    if(this.props.postType === PostTypes.INTERACTIVE_FUNNEL) {
      this.props.sendFunnelFiles()
    }
  }

  handleVideoToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (this.videoRef.current) {
      if (this.state.isPlaying) {
        this.videoRef.current.pause();
      } else {
        this.videoRef.current.play();
      }
      this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
    }
  }
}
// Customizable Area End
