// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  withStyles,
  Switch,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateEveryreelSettingsController, {
  Props,
} from "./CreateEveryreelSettingsController.web";
import { PostTypes } from "./enums";
import {
  ControlPoint,
  CreateOutlined,
  InsertLinkOutlined,
} from "@material-ui/icons";
import CreateEveryreelSettingsPostPreview from "./CreateEveryreelSettingsPostPreview.web";
import CreateEveryreelSettingsFilesQueue from "./CreateEveryreelSettingsFilesQueue.web";
import CreateEveryreelChoicesList from "./CreateEveryreelChoicesList.web";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      display: "flex",
      padding: "45px 30px 30px 30px",
      justifyContent: "center",
      alignItems: "center",
      background: "#FFF",
      "@media screen and (max-width: 767px)": {
        paddingTop: "50px"
      }
    },
    closeButton: {
      position: "absolute",
      top: "12px",
      right: "8px",
    },
    content: {
      display: "flex",
      gap: "25px",
      justifyContent: "center",
      width: "100%",
      "@media screen and (max-width: 767px)": {
        flexWrap: "wrap"
      }
    },

    postSettings: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      flexBasis: "619px",
    },

    postTitle: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      gap: "5px",
      borderBottom: "1px solid #AEAEB2",
    },

    postTitleInput: {
      flexGrow: 1,

      "& > div": {
        paddingRight: "45px",
      },

      "& input": {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        fontFamily: "Inter, 'sans-serif'"
      },

      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    postCaption: {
      display: "flex",
      borderBottom: "1px solid #AEAEB2",
    },

    socialScrollCaptionInput: {
      flexGrow: 1,
      "& input": {
        fontWeight: 400,
        fontSize: "18px",
        fontFamily: "Inter, 'sans-serif'",
        "&::placeholder": {
          color: "#6F6F70"
        }
      },
      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    funnelCaptionInput: {
      flexGrow: 1,

      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    postChoices: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      alignSelf: "stretch",
      justifySelf: "stretch",
      gap: "18px",
    },

    switcherContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "22px",
    },

    switchLabel: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },

    switch: {
      width: "60px",
      height: "40px",
      padding: "5px",

      "& .MuiSwitch-thumb": {
        width: "26px",
        height: "26px",
        color: "#FFF",
      },

      "& .MuiSwitch-track": {
        border: "1px solid #D1D3D9",
        borderRadius: "14px",
      },

      "& .MuiSwitch-switchBase": {
        padding: "7px",
      },

      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "#FFF",
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#FFC629",
        opacity: 1,
      },
    },

    link: {
      display: "flex",
      flexWrap: "nowrap",
    },

    linkText: {
      display: "flex",
      justifyContent: "space-between",
      gap: "5px",
      borderRight: "1px solid #6F6F70",
      height: "57px",
      alignItems: "center",
      paddingRight: "10px",
      "& > button": {
        "& svg": {
          color: "#1C1B1F"
        }
      }
    },

    linkTextInput: {
      flexGrow: 1,
      "& input": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Inter, 'sans-serif'",
        lineHeight: "24px",
      },

      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    linkURL: {
      display: "flex",
      height: "57px",
      flexGrow: 1,
      alignItems: "center",
      paddingLeft: "16px",
      gap: "18px",
    },

    linkURLInput: {
      flexGrow: 1,

      "& input": {
        color: "#64748B",
        fontWeight: 700,
        fontFamily: "Inter, 'sans-serif'"
      },

      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    funnelSettings: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },

    funnelSettingsTitle: {
      color: "#6F6F70",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "30px",
    },

    setting: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: "380px",
      minWidth: "300px",
      gap: "70px",
    },

    settingTitle: {
      display: "flex",
      alignItems: "center",
      color: "#6F6F70",
      fontSize: "20px",
      lineHeight: "30px",
    },

    inputLabel: {
      color: "#6F6F70",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
    },

    choicesArriveTimeContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },

    arriveTimeInput: {
      width: "25px",

      "& div.MuiInput-underline": {
        color: "#6F6F70",
        fontSize: "20px",
        lineHeight: "30px",
        fontWeight: 700,
      },

      "& > div.MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "& > div.MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
    },

    multipleSelectionContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },

    choicesTitle: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },

    addButton: {
      display: "flex",
      width: "111px",
      height: "40px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "17px",
      color: "#FFF",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "capitalize",

      "& > *:first-child": {
        display: "flex",
        gap: "6px",
      },
    },
  });

export class CreateEveryreelSettings extends CreateEveryreelSettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, closeModal, postType } = this.props;
    const {
      files,
      selectedFile,
      choices,
      allowMultipleSelection,
      choicesArriveTime,
    } = this.state;

    return (
      <Container
        id="newPostSettingsContainer"
        maxWidth={false}
        className={classes.modal}
        onClick={(e) => {
          e.stopPropagation();
          this.closeChoiceMenu();
        }}
      >
        <IconButton
          id="closeModalButton"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>

        <Box className={classes.content}>
          <Box className={classes.postSettings} id="everyreelSettings">
            <Box className={classes.postTitle}>
              <TextField
                id="postTitle"
                variant="standard"
                value={selectedFile.title}
                inputRef={this.videoTitleFieldRef}
                onChange={(e) => this.setPostTitle(e.target.value)}
                className={classes.postTitleInput}
              />
              <IconButton
                size="small"
                data-test-id="title-edit"
                onClick={this.handleTitleFieldEdit}
              >
                <CreateOutlined />
              </IconButton>
            </Box>
            <Box className={classes.postCaption}>
              <TextField
                id="postCaption"
                variant="standard"
                value={selectedFile.caption}
                placeholder={
                  postType === PostTypes.SOCIAL_SCROLL
                    ? "Write a caption"
                    : "Write your caption overlay"
                }
                onChange={(e) => this.setPostCaption(e.target.value)}
                className={
                  postType === PostTypes.SOCIAL_SCROLL
                    ? classes.socialScrollCaptionInput
                    : classes.funnelCaptionInput
                }
              />
            </Box>
            <Box className={classes.postChoices}>
              {postType === PostTypes.INTERACTIVE_FUNNEL && (
                <>
                  <Typography className={classes.choicesTitle}>
                    {this.editChoices}
                  </Typography>
                  <CreateEveryreelChoicesList
                    choices={choices}
                    files={files}
                    changeChoiceName={this.changeChoiceName}
                    showChoiceMenu={this.showChoiceMenu}
                    setChoiceType={this.setChoiceType}
                    closeChoiceMenu={this.closeChoiceMenu}
                  />

                  <Button
                    id="addChoiceButton"
                    className={classes.addButton}
                    onClick={this.addChoice}
                    disabled={choices.length >= 3}
                    style={{
                      backgroundColor:
                        choices.length >= 3 ? "#DADADA" : "#0F172A",
                      color: "#FFF",
                    }}
                  >
                    <ControlPoint />
                    Add
                  </Button>
                </>
              )}
            </Box>
            <Box className={classes.postAdvancedSettings}>
              {postType === PostTypes.SOCIAL_SCROLL ? (
                <Box className={classes.socialScrollSettings}>
                  <Box className={classes.switcherContainer}>
                    <Typography className={classes.switchLabel}>
                      {this.linkButton}
                    </Typography>
                    <Switch
                      id={"allowLinkSwitch"}
                      onClick={this.allowLink}
                      checked={selectedFile.isLinkAllowed}
                      className={classes.switch}
                      focusVisibleClassName=".Mui-focusVisible"
                      disableRipple
                    />
                  </Box>
                  <Box className={classes.link}>
                    <Box className={classes.linkText}>
                      <TextField
                        id="linkText"
                        variant="standard"
                        value={selectedFile.linkText}
                        inputRef={this.linkButtonFieldRef}
                        onChange={(e) => this.setLinkText(e.target.value)}
                        className={classes.linkTextInput}
                      />
                      <IconButton
                        size="small"
                        data-test-id="edit-button-text"
                        onClick={this.handleLinkButtonTextEdit}
                      >
                        <CreateOutlined />
                      </IconButton>
                    </Box>
                    <Box className={classes.linkURL}>
                      <InsertLinkOutlined
                        style={{ color: "#DADADA" }}
                      />
                      <TextField
                        id="linkURL"
                        variant="standard"
                        placeholder="Paste URL"
                        value={selectedFile.link}
                        onChange={(e) => this.setLinkURL(e.target.value)}
                        className={classes.linkURLInput}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.funnelSettings}>
                  <Typography className={classes.funnelSettingsTitle}>
                    Advanced settings
                  </Typography>
                  <Box className={classes.setting}>
                    <Typography className={classes.settingTitle}>
                      Choices Arrive Time
                    </Typography>
                    <Box className={classes.choicesArriveTimeContainer}>
                      <TextField
                        id="choiceArriveTimeInput"
                        className={classes.arriveTimeInput}
                        onChange={(e) =>
                          this.setChoiceArriveTime(Number(e.target.value) || 0)
                        }
                        value={choicesArriveTime}
                      />
                      <Typography className={classes.inputLabel}>
                        {choicesArriveTime === 1 ? "Second" : "Seconds"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.setting}>
                    <Typography className={classes.settingTitle}>
                      Allow multiple selection
                    </Typography>
                    <Box className={classes.multipleSelectionContainer}>
                      <Switch
                        id="allowMulripleSelectionSwitch"
                        onClick={this.setMultipleSelection}
                        checked={allowMultipleSelection}
                        className={classes.switch}
                        focusVisibleClassName=".Mui-focusVisible"
                        disableRipple
                      />
                      <Typography className={classes.inputLabel}>
                        {allowMultipleSelection ? "On" : "Off"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <CreateEveryreelSettingsFilesQueue
              videos={this.videos}
              files={files}
              selectFile={this.selectFile}
              addFiles={this.props.addFiles}
              addFileButtonRef={this.addFileButtonRef}
              upload={this.upload}
            />
          </Box>
          <CreateEveryreelSettingsPostPreview
            closeModal={closeModal}
            postPreview={this.postPreview}
            selectedFile={selectedFile}
            videoPreviewRef={this.videoPreviewRef}
            postType={postType}
            choices={choices}
            postSocialScrollText={this.postSocialScrollText}
            postEveryreelText={this.postEveryreelText}
            runPostFunction={this.runPostFunction}
          />
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles)(CreateEveryreelSettings);
// Customizable Area End
