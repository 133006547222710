// Customizable Area Start
import React, { MouseEvent } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button, makeStyles, Container } from "@material-ui/core";
import { configJSON } from "./EmailAccountRegistrationController.web";

interface Props {
  goToBack: (e: MouseEvent<HTMLButtonElement>) => void;
  handleSubmit: (e: MouseEvent<HTMLButtonElement>, stripe: any, elements: any) => Promise<void> | undefined;
}

const useStyles = makeStyles(() => ({
  cardholderInput: {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "17px 8px",
    color: "#000",
    fontSize: "16px",
    textAlign: "left",

    "&::placeholder": {
      opacity: 1,
      fontSize: "16px",
      color: "#64748B",
    },
  },
  cardInputBlock: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "17px 8px",
  },

  cardInputBlockInvalid: {
    borderColor: "#eb1c26",
  },

  label: {
    color: "#334155",
    fontWeight: 700,
    marginBottom: "4px",
  },

  submitButton: {
    backgroundColor: "#FFC629",
    marginTop: "37px",
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "56",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "black",
    },
    "& a": {
      opacity: 1,
      fontSize: "16px",
      color: "black",
      textDecoration: "none",
    },
  },

  backButton: {
    color: "#fff",
    marginTop: "25px",
    backgroundColor: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "56px",
    "&:hover": {
      backgroundColor: "#0F172A",
      color: "#fff",
    },
  },
  formContainer: {
    display: "flex",
    maxWidth: "360px",
  },
  formContainere: {
    width: "100%",
  },
}));

const StripeForm = ({ goToBack, handleSubmit }: Props) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Container className={classes.formContainere} data-test-id="stripeForm">
      <PaymentElement />
      <Button
        data-test-id="submitButton"
        variant="contained"
        fullWidth
        type="button"
        disabled={!stripe}
        className={classes.submitButton}
        onClick={(e) => handleSubmit(e, stripe, elements)}
      >
        {configJSON.submit}
      </Button>
      <Button
        data-test-id="backButton"
        variant="contained"
        fullWidth
        type="button"
        className={classes.backButton}
        onClick={goToBack}
      >
        {configJSON.back}
      </Button>
    </Container>
  );
};
export default StripeForm;
// Customizable Area End
