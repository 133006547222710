// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}



interface S {
  tutorailModal: boolean
  likesCount: number
  commentCount: number
  reviewsCoutn: number
  profilePic: string
  widgetCode:string

}
interface SS {
  id: any;
}
export default class ConnectWebsiteController extends BlockComponent<
  Props,
  S,
  SS
> {

  getWidgetDataId: any = "";
  getWidgetCodeID: any = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      tutorailModal: false,
      likesCount: 0,
      commentCount: 0,
      reviewsCoutn: 0,
      profilePic: "",
      widgetCode:""

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  closetutorialModal = () => {
    this.setState({ tutorailModal: false });
  };
  opentutorialModal = () => {
    this.setState({ tutorailModal: true });
  };

  async componentDidMount(): Promise<void> {
    this.getEmbedData();
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getWidgetDataId) {
        this.handleEmbedDataResponse(responseJson)
      }
    }
  }

  handleEmbedDataResponse(response: any) {
    this.setState({
      widgetCode:response.widget_code,
      likesCount: response.likes_count,
      commentCount: response.comments_count,
      reviewsCoutn: response.average_review_rating,
      profilePic: response.account.data.attributes.profile_pic?.url || ""
    })
  }

  handleCopy(){
    navigator.clipboard.writeText(this.state.widgetCode)
  }
  getEmbedData() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWidgetDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmbedDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

}
// Customizable Area End
