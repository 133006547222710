// Customizable Area Start
import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { EverybrandIcon } from './assets';
import Box from '@material-ui/core/Box';
import { configJSON } from './Settings2Controller';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CustomSwitch } from '../../../components/src/CustomSwitch.web';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { EveryReelButton } from './interfaces';


export interface Props {
  everyReelButtonInfo: EveryReelButton;
  onUpdateEveryReelButtonInfo: (formData: EveryReelButton) => void;
  isFormSubmitting: boolean;
  classes:any,
  validationSchemaCustomiseEveryReel:object;
}

const useStyles = ((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5, 5, 5, 3),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
      },
      padding: theme.spacing(3),
    },
    pageTitle: {
      color: '#000000',
      fontWeight: 700,
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
      },
    },
    typography: {
      color: '#0F172A',
      fontSize: '14px',
    },

    marginBottom_24: {
      marginBottom: '24px',
    },
    marginBottom_40: {
      marginBottom: '40px',
    },
    spacer: {
      height: '1px',
      backgroundColor: '#DADADA',
      margin: theme.spacing(0, 3, 4, 1),
      border: 'none',
    },
    textInputField: {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '1px solid #DADADA',
        borderRadius: '6px',
      },
      "& .Mui-focused fieldset": {
        borderColor: "#FFCA29 !important",
      }
    },
    formContainer: {
      padding: theme.spacing(0, 3, 0, 1),
    },
    saveButton: {
      color: '#0F172A',
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      backgroundColor: '#FFC629',
      padding: theme.spacing(2, 0),
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#FFC629',
      },
    },
    input: {
      '&::placeholder': {
        color: '#0F172A',
        opacity: '0.50',
      },
    },
    fontWeightBold: {
      fontWeight: 700,
    },
    errorMessage: {
      position: 'absolute',
      bottom: '-24px',
    },
  })
);

class CustomizeEveryReelButton extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={3}
        >
          <Typography className={classes.pageTitle} data-test-id="pageHeading">
            {configJSON.customizeEveryReelButton}
          </Typography>
          <img src={EverybrandIcon} />
        </Box>
        <hr className={classes.spacer} />
        <Formik
          enableReinitialize={true}
          initialValues={{
            hide_button: this.props.everyReelButtonInfo.hide_button,
            border: this.props.everyReelButtonInfo.border || false,
            caption: this.props.everyReelButtonInfo.caption || '',
            text_bubble: this.props.everyReelButtonInfo.text_bubble || false,
          }}
          validateOnChange={false}
          validationSchema={this.props.validationSchemaCustomiseEveryReel}
          onSubmit={(values, { setSubmitting }) => {
            this.props.onUpdateEveryReelButtonInfo(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form data-test-id="formSubmiId" onSubmit={handleSubmit}>
              <Grid
                container
                direction="column"
                className={classes.formContainer}
              >
                <Box mb={3}>
                  <Typography
                    className={clsx(classes.typography, classes.fontWeightBold)}
                  >
                    {configJSON.appearance}
                  </Typography>
                </Box>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.marginBottom_24}
                >
                  <Grid item>
                    <Typography variant="body2" className={classes.typography}>
                      {configJSON.border}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          color="primary"
                          name="border"
                          checked={values.border}
                          onChange={handleChange}
                        />
                      }
                      label={
                        values.border ? configJSON.switchOn : configJSON.switchOff
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.marginBottom_40}
                >
                  <Typography variant="body2" className={classes.typography}>
                    {configJSON.caption}
                  </Typography>
                  <Grid item sm={3}>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="caption"
                      placeholder={configJSON.captionPlaceholder}
                      fullWidth
                      className={classes.textInputField}
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                      value={values.caption}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.caption && Boolean(errors.caption)}
                      helperText={touched.caption && errors.caption}
                      FormHelperTextProps={{
                        className: classes.errorMessage,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <hr className={classes.spacer} />
              <Grid
                container
                direction="column"
                className={classes.formContainer}
              >
                <Box mb={2}>
                  <Typography
                    className={clsx(classes.typography, classes.fontWeightBold)}
                  >
                    {configJSON.addOns}
                  </Typography>
                </Box>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.marginBottom_40}
                >
                  <Grid item>
                    <Typography variant="body2" className={classes.typography}>
                      {configJSON.textBubble}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          color="primary"
                          name="text_bubble"
                          checked={values.text_bubble}
                          onChange={handleChange}
                        />
                      }
                      label={
                        values.text_bubble
                          ? configJSON.switchOn
                          : configJSON.switchOff
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={5} justifyContent="flex-end">
                <Grid item xs={12} sm={6} md={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    type="submit"
                    className={classes.saveButton}
                    data-test-id="saveButton"
                    disabled={this.props.isFormSubmitting}
                  >
                    {configJSON.save}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
export default withStyles(useStyles)(CustomizeEveryReelButton);
export { CustomizeEveryReelButton }
// Customizable Area End
