// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AfterSignupController from "./AfterSignupController.web";
import { AfterSignupModalSteps } from "./enums";
import AfrerSignupWelcome from "./AfterSignupWelcome.web";
import AfterSignupNumber from "./AfterSignupNumber.web";
import AfterSignupUpload from "./AfterSignupUpload.web";
import AfterSignupInvites from "./AfterSignupInvites.web";

export class AfterSignup extends AfterSignupController {
  render() {
    const { classes, socialPhoneNumber, closeModal } = this.props;
    const { currentStep, invitesContacts, file } = this.state;    
    return (
      <Box className={classes.content} id="afterSignupModal" onClick={(e) => e.stopPropagation()}>
        
        {currentStep === AfterSignupModalSteps.WELCOME && (
          <AfrerSignupWelcome setStep={this.setStep} />
        )}

        {currentStep === AfterSignupModalSteps.NUMBER_ACCOUNT && (
          <AfterSignupNumber setStep={this.setStep} socialPhoneNumber={socialPhoneNumber} />
        )}
        {currentStep === AfterSignupModalSteps.PROFILE_PICTURE && (
          <AfterSignupUpload
            file={file}
            sendProfilePic={this.sendProfilePic}
            setStep={this.setStep}
            setPicture={this.setPicture}
            closeModal={closeModal}
          />
        )}
        {currentStep === AfterSignupModalSteps.INVITES && (
          <AfterSignupInvites
            invitesContacts={invitesContacts}
            setInvitesContacts={this.setInvitesContacts}
            sendInvites={this.sendInvites}
            closeModal={closeModal}
          />
        )}
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    content: {
      position: "relative",
      display: "flex",
      maxHeight: "95%",
      overflow: "hidden",
      padding: "80px 70px 70px 70px",
      backgroundColor: "#FFF",
      borderRadius: "8px 8px 32px 8px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      width: '65%',
    },

    closeButton: {
      position: "absolute",
      top: "20px",
      right: "11px",
    },
  });

export default withStyles(styles)(AfterSignup);

// Customizable Area End
