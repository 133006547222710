// Customizable Area Start
/* eslint-disable */
import { ChangeEvent, KeyboardEvent, createRef } from "react";
import * as yup from "yup";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
export const configJSON = require("./config.js");

export type Props = RouteComponentProps & {
  navigation?: any;
  id?: string;
  classes?: any;
};
interface S {
  phoneLoginError: string;
  otpError: string;
  otp: string[];
}
interface SS {
  id: any;
}

export default class PhoneLoginController extends BlockComponent<Props, S, SS> {
  phoneLoginApiCallId: string = "";
  otpApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      phoneLoginError: "",
      otpError: "",
      otp: Array(6).fill("")
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  validationSchema = {
    phone: yup.string().required("Phone is required.")
  };

  inputRefs = Array(6)
    .fill(null)
    .map(() => createRef<HTMLInputElement>());

  handleOtpInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const value = event.target.value;
    const updatedOtp = [...this.state.otp];
    updatedOtp[index] = value;
    this.setState({ otp: updatedOtp });
    if (value && index < this.inputRefs.length - 1) {
      this.inputRefs[index + 1].current?.focus();
    } else if (!value && index > 0) {
      this.inputRefs[index - 1].current?.focus();
    }
  };

  handleOtpKeyDown = (event: KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === "Backspace") {
      if (index < this.inputRefs.length) {
        const updatedOtp = [...this.state.otp];
        updatedOtp[index] = "";

        this.setState({ otp: updatedOtp });
        if (index !== 0) {
          this.inputRefs[index - 1].current?.focus();
        } else {
          this.inputRefs[index].current?.focus();
        }
      }
    } else if (event.key === "Delete") {
      if (index <= this.inputRefs.length - 1) {
        const updatedOtp = [...this.state.otp];

        updatedOtp[index] = "";

        this.setState({ otp: updatedOtp });
        if (index !== this.inputRefs.length - 1) {
          this.inputRefs[index + 1].current?.focus();
        } else {
          this.inputRefs[index].current?.focus();
        }
      }
    }
  };

  getPhoneNumber = () => {
    return sessionStorage.getItem("phone-login") ?? "";
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.phoneLoginApiCallId) {
      this.handlePhoneLoginAPIResponse(message);
    }

    if (apiRequestCallId === this.otpApiCallId) {
      this.handleOTPConfirmAPIResponse(message);
    }
  }

  doPhoneLogIn(formData: { phone: string }): boolean {
    const requestHeaders = {
      "Content-Type": configJSON.loginApiContentType
    };

    const requestBody = {
      data: {
        attributes: {
          full_phone_number: formData.phone
        }
      }
    };

    sessionStorage.setItem("phone-login", formData.phone);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.phoneLoginApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneLoginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeaders)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  otpPhoneConfirm(): boolean {
    const requestHeaders = {
      "Content-Type": configJSON.loginApiContentType,
      token: localStorage.getItem("token")
    };

    const otp = this.state.otp.join("");

    const requestBody = {
      pin: otp
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeaders)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handlePhoneLoginAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.errors) {
      this.setState({
        phoneLoginError: responseJson.errors[0]?.full_phone_number
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      localStorage.setItem("token", responseJson.meta.token);
      this.navigate("/phone-login-otp");
    }
  };

  handleOTPConfirmAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.errors) {
      this.setState({
        otpError: responseJson.errors[0]?.pin
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      localStorage.setItem("token", responseJson.meta.token);
      this.navigate("/dashboard");
    }
  };

  navigate = (path: string) => {
    this.props.history.push(path);
  };
}
// Customizable Area End
