// Customizable Area Start
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 'calc(100vh - 64px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

function UnderDevelopmentMessage() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h5">
        Page under development. Check back soon!
      </Typography>
    </div>
  );
}

export default UnderDevelopmentMessage;
// Customizable Area End
