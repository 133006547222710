import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

interface LikeButtonProps {
  amount: number;
  handleLikes: () => void;
  isLiked: boolean;
}

const LikeButton: React.FC<LikeButtonProps> = ({ amount, handleLikes, isLiked }) => {
  const [liked, setLiked] = useState(isLiked);
  const [amountLike, setAmountLikes] = useState(amount)

  const handleClick = () => {
      handleLikes();
      setLiked(prev => !prev);
      setAmountLikes(prev => liked ? prev - 1 : prev + 1);
  };

  return (
    <div style={styles.button} >
      <IconButton onClick={handleClick} style={{ color: '#FFFFFF' }} data-test-id="likeBtnWrapper">
        {liked ? <FavoriteIcon style={{ color: 'red' }} /> : <FavoriteBorderIcon />}
      </IconButton>
      <Typography variant="body2" style={{ color: '#FFFFFF', fontFamily: 'Inter' }}>{amountLike}</Typography>
    </div>
  );
};

const styles = {
  button: {
    display: 'flex', flexDirection: 'column', alignItems: 'center',
    position: "absolute",
    bottom: 130,
    right: 16,
    zIndex: 5
  } as React.CSSProperties
}

export default LikeButton;
