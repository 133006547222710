export enum InviteFriendsStepType {
  INVITE_FRIEND = "INVITE_FRIEND",
  ADD_CONTACT = "ADD_CONTACT",
  BULK_INVITE = "BULK_INVITE"
}

export enum AfterSignupModalSteps {
  WELCOME = "WELCOME",
  NUMBER_ACCOUNT = "NUMBER_ACCOUNT",
  PROFILE_PICTURE = "PROFILE_PICTURE",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  INVITES = "INVITES",
}

export enum AppLayoutModals {
  AFTER_SIGNUP_WELCOME = "AFTER_SIGNUP_WELCOME",
  INVITE_FRIENDS = "INVITE_FRIENDS",
  CREATE_EVERYREEL = "CREATE_EVERYREEL",
  EDIT_CONTACT = "EDIT_CONTACT",
}

export enum ContactsListViews {
  LIST = "LIST",
  ADD_CONTACT = "ADD_CONTACT",
  EDIT_CONTACT = "EDIT_CONTACT",
}

export enum AnalyticsPages {
  OVERVIEW = "OVERVIEW",
  LEADS = "LEADS",
  ENGAGEMENT = "ENGAGEMENT",
  WAITLIST = "WAITLIST",
  EVERYREEL = "EVERYREEL",
  REVIEWS = "REVIEWS"
}

export enum EngagementViews {
  TRENDING_POSTS = "TRENDING_POSTS",
  EVERYBRAND_POLLS = "EVERYBRAND_POLLS",
  FEED_CLICKS = "POST_FEED_CLICKS",
  TEXT_CONVERSATIONS = "TEXT_CONVERSATIONS",
  CONNECTED_PLATFORMS = "CONNECTED_PLATFORMS"
}