import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  alertMsg: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "4px",
    boxShadow: "none",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    backgroundColor: "#FEE2E2",
    display: "flex",
    padding: "6px 16px",
    color: "#DC2626",
    borderLeft: "3px solid #DC2626",
    marginTop:"30px",
    width:"330px"
}

 
};
  const EmailAccountLoginErrorBlock = (props: any) => {
    return (
      // Required for all blocks
      // Customizable Area start
      <ThemeProvider theme={theme}>
            <div style={webStyle.alertMsg} role="alert">
              <div className="MuiAlert-message">{props.errorMsg}</div>
            </div>
      </ThemeProvider>
      // Customizable Area End
    );
}
export default EmailAccountLoginErrorBlock;