export function capitalizeFirstLetter(str: any) {
  return str[0].toUpperCase() + str.slice(1);
}

export function getErrorMessage(obj: any, isKeyNeed: boolean = false) {
  let err = obj;
  let str = "";
  if (Object.keys(obj).length) {
    if (!isKeyNeed) {
      for (let k in err) {
        if (err.hasOwnProperty(k)) {
          str = err[k] ? str + err[k] : str
        }
      }
      console.log(str);
      return str;
    } else {
      for (const [p, val] of Object.entries(obj)) {
        str += `${capitalizeFirstLetter(p)} ${val}\n`;
      }
      return str;
    }
  } else {
    return obj;
  }
}

export const handleAndConditions = (
  primary: any,
  secondary: any,
  result: any
) => {
  return primary && secondary ? result : null;
};

export const handleOrConditions = (
  condition: any,
  primary: any,
  secondary: any
) => {
  return condition ? primary : secondary;
};
