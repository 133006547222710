// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import {
  titleImg1,
  titleImg2,
  titleImg3,
  payment_3,
  PaymentStripe,
  titleImg4,
  groupStarbar2,
  groupStarbar,
  groupStarbar3,
  groupStarbar4,
} from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  emailAddress: string;
  emailError: string;
  formError: any;
  currentStep: number;
  hasLowerCaseLetter: boolean;
  hasNumber: boolean;
  hasMinLength: boolean;
  sideBarContent: {
    [step: number]: {
      imagetitle: string;
      image: string;
    };
  };
  businessWebsite: string;
  zipcode: any;
  mobileNumber: any;
  otp: string[];
  emailValidationResult: any;
  paymentError?: string;
  EmailPasswordID: number;
  userToken: string;
  selectedPricing: PlanPricing;
  showSuccessMessage: boolean;
  paymentPlanData: ProductData[];
  platformManagementPaymentPlans: ProductData[];
  stripePaymentData: any;
  formGlobalError: any;
  showPassword: boolean;
  businessPassword: string;
  businessConfirmPassword: string;
  showConfirmPassword: boolean;
  passwordsMatch: boolean;
  hasCapitalLetter: boolean;
  passWordInformation: string;
  attributeName: string;
  paymentPrize: number;
  activeBoxIndex: number;
  isSelectedPlanWithPlatformManagement: boolean;
}

export interface Plan {
  id: any;
  price: any | undefined;
  created_at: string;
  updated_at: string;
  product_id: number;
  currency: string | undefined;
  interval: string | undefined;
  stripe_plan_id: string;
  stripe_prod_id: string;
}

export interface ProductData {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface SS {
  id: any;
}

export interface ProductAttributes {
  id: any;
  name: string;
  description: string;
  stripe_prod_id: string;
  plans: Plan[];
  service?: Service | null;
  platformManagement?: boolean;
}

export interface PaymentPlan {
  id?: number;
  type: string;
  name: string;
  description: string;
  plans: Plan[];
  data: ProductData[];
  bestFor: string;
  pricePerMonth: number;
  discountPricePerMonth: number;
  advantages: string[];
  attributes: ProductAttributes;
}
export interface PaymentPlanData {
  data: ProductData[];
}

interface Service {
  id: any;
  product_id: number;
  max_members_count: string;
  permissions: any;
  created_at: string;
  updated_at: string;
  max_contacts_count: string;
}

export enum PlanPricing {
  Annual = "year",
  Month = "month",
}

export enum PlanType {
  Essentials = "Essentials",
  Standard = "Standard",
  Proffecional = "Proffecional",
}
export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiStripeProductDetailsId: any = "";
  apiUserRegistrationId: any = "";
  apiSubcriptionSelectionId: any = "";
  apiStripeSubscriptionCancelId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      emailAddress: "",
      showPassword: false,
      showConfirmPassword: false,
      passwordsMatch: true,
      sideBarContent: {
        1: {
          imagetitle: titleImg1,
          image: groupStarbar,
        },
        2: {
          imagetitle: titleImg2,
          image: groupStarbar2,
        },
        3: {
          imagetitle: titleImg3,
          image: groupStarbar3,
        },
        4: {
          imagetitle: titleImg4,
          image: groupStarbar4,
        },
        6: {
          imagetitle: payment_3,
          image: PaymentStripe,
        },
      },
      zipcode: "",
      mobileNumber: "",
      hasCapitalLetter: false,
      hasLowerCaseLetter: false,
      hasNumber: false,
      hasMinLength: false,
      currentStep: 1,
      formError: {},
      formGlobalError: {},
      passWordInformation: "",
      emailError: "",
      businessPassword: "",
      businessConfirmPassword: "",
      attributeName: "",
      paymentPrize: 0,
      paymentError: undefined,
      activeBoxIndex: 1,
      selectedPricing: PlanPricing.Annual,
      businessWebsite: "",
      showSuccessMessage: false,
      paymentPlanData: [],
      platformManagementPaymentPlans: [],
      otp: Array(6).fill(""),
      emailValidationResult: "q@q.com",
      EmailPasswordID: 0,
      userToken: "",
      stripePaymentData: "",
      isSelectedPlanWithPlatformManagement: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.clearSession();
  }

  clearSession() {
    sessionStorage.clear();
  }

  setSelectedPricing = (pricing: PlanPricing) => {
    this.setState({ selectedPricing: pricing})
  }

  getPriceValue = (paymentPlan: any) => {    
    const planPrice =
      paymentPlan.attributes &&
      paymentPlan.attributes?.plans.length > 0 &&
      paymentPlan.attributes?.plans.find((item: any) => {
        return item.interval === this.state.selectedPricing;
      });
    if (planPrice) {
      return {
        productId: paymentPlan.id,
        price: planPrice.price,
        currency: planPrice.currency,
        interval: planPrice.interval,
        stripe_price_id: planPrice.stripe_plan_id,
        plan_id: planPrice.id,
        name: paymentPlan.attributes.name,
        subscription_id: paymentPlan.attributes.id,
      };
    } else {
      return {
        productId: "",
        price: "",
        currency: "",
        interval: "",
        stripe_price_id: "No Data Available",
        plan_id: "No Data Available",
        name: "No Data Available",
        subscription_id: ""
      };
    }
  };

  handleGoBackClick = () => {
    sessionStorage.setItem("valuesBackStep", "true");
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }));
  };

  handleGoToPlanSelection = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }));

    this.doSubcriptionCancel();
  };

  setPlatformManagement = (planId?: string) => {    
    this.setState({
      paymentPlanData: this.state.paymentPlanData.map((plan) => {
        if (plan.id === planId) {
          plan.attributes.platformManagement = !plan.attributes
            .platformManagement;
        } else {
          plan.attributes.platformManagement = false;
        }

        return plan;
      }),
    });
  };

  setSelectedPlan = (plan: ProductData) => {
    const apiData = plan && this.getPriceValue(plan);
    this.setState({ attributeName: apiData.name, paymentPrize: apiData?.price });    
    this.doSubcriptionSelection(apiData);
  };

  async doSubcriptionSelection(selectedPlan: any) {
    const platformManagementStatus =
      this.state.paymentPlanData.find(
        (plan) => plan.id === selectedPlan.productId
      )?.attributes.platformManagement || false;

    this.setState({
      isSelectedPlanWithPlatformManagement: platformManagementStatus,
    });

    const attrs = {
      stripe_price_id: selectedPlan?.stripe_price_id,
      plan_id: selectedPlan?.plan_id,
      platform_management: platformManagementStatus,
      subscription_id: selectedPlan?.subscription_id
    };

    const httpBody = {
      subscription: attrs,
    };    

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      Token: this.state.userToken,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUserRegistrationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StripesubScriptionsDetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  stripeProductDetailsAPI() {
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStripeProductDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StripeProductDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.StripeProductDetailsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async doSubcriptionCancel() {
    const httpBody = {
      stripe_subscription_id: this.state.stripePaymentData.id,
    };

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      Token: this.state.userToken,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiStripeSubscriptionCancelId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StripeCancelSubscription
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlGlobalApiError = (responseJson: any) => {
    if (responseJson && (responseJson.error || responseJson.errors)) {
      const errMsg = "Something went wrong!";
      this.setState({ formGlobalError: { [this.state.currentStep]: errMsg } });
      return true;
    }
  };

  apicallresponseData = (responseJson: any) => {
    this.handlGlobalApiError(responseJson);
    if (!(responseJson.error || responseJson.errors)) {
      this.handleSuccessResponse(responseJson);
    }
  };
  handleSuccessResponse(responseJson: any) {
    const emailAddressFromLocalStorage = sessionStorage.getItem("emailAddress");
    const { data, meta } = responseJson;

    if (responseJson.data) {
      this.setState({
        EmailPasswordID: data.id,
        stripePaymentData: data,
        passWordInformation: this.state.businessPassword,
        formGlobalError: { [this.state.currentStep]: "" },
      });
      sessionStorage.setItem("EmailPasswordID", data.id);

      if (meta?.token) {
        this.setState({
          userToken: meta.token,
        });
        sessionStorage.setItem("userToken", meta.token);
      }
    }

    const newStep = this.state.currentStep;

    this.setState({
      currentStep: newStep + 1,
      emailValidationResult: emailAddressFromLocalStorage,
    });

    if (newStep === 1) {
      this.stripeProductDetailsAPI();
    }
  }

  subscriptionDetailsApiResponse = (responseJson: any) => {
    this.handlGlobalApiError(responseJson);
    if (this.state.currentStep === 2) {
      this.setState({
        paymentPlanData: responseJson.data
          .filter((plan: ProductData) => ["Essentials", "Standard", "Professional"]
          .includes(plan.attributes.name))
          .map((plan: ProductData) => ({
            ...plan,
            attributes: {
              ...plan.attributes,
              platformManagement: false,
            },
          })
        ),
        platformManagementPaymentPlans: responseJson.data.filter((plan: ProductData) => plan.attributes.name === "Platform  Management")
      });
    }
  };

  subscriptionCancelApiResponse = (responseJson: any) => {
    this.handlGlobalApiError(responseJson);
    if (!(responseJson.error || responseJson.errors)) {
      this.handleSubscriptionCancelResponse(responseJson);
    }
  };

  handleSubscriptionCancelResponse(responseJson: any) {
    const { data } = responseJson;

    if (data.attributes) {
      const prevStripePaymentData = this.state.stripePaymentData;
      const prevAttributes = prevStripePaymentData.attributes;

      const stripePaymentData = {
        ...prevStripePaymentData,
        attributes: {
          ...prevAttributes,
          status: data.attributes.status,
        },
      };

      this.setState({
        stripePaymentData,
      });
    }
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiUserRegistrationId
      ) {
        this.apicallresponseData(responseJson);
      }
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiStripeProductDetailsId
      ) {
        this.subscriptionDetailsApiResponse(responseJson);
      }
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apiStripeSubscriptionCancelId
      ) {
        this.subscriptionCancelApiResponse(responseJson);
      }
    }
  }
}
// Customizable Area End 
