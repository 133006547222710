// Customizable Area Start
import React from "react";

import {
  Box,
} from "@material-ui/core";
import { firststepimgwomen, firststepimg1 } from "./assets";

const webStyle = {
  imageFirstTab: {
    backgroundColor: "#FFC629",
    borderRadius: "0px 0px 0px 64px",
    width: "100%",
    height: "100%",
    textAlign: "center" as "center",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between"
  },
  imagestepsDetail: {
    width: "70%",
    display: "inline-block",
    fontWeight: 900
  },
  stepDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center"
  },
  fontWeight: {
    fontWeight: 900
  },

};
const BusinessSideBar = (props: any) => {
    return (
      // Required for all blocks
      <Box style={webStyle.imageFirstTab}>
        <div style={webStyle.stepDiv}>
          <img src={firststepimg1} />
          <Box style={webStyle.imagestepsDetail}>
            <img src={props.data?.imagetitle} alt="" width="80%" style={{ margin: "20px 0px" }} />

            <img src={props.data?.image} alt="" width="80%" style={{ marginTop: "20px" }} />
          </Box>
        </div>
        <div>
          <img src={firststepimgwomen} width="40%" />
        </div>
      </Box>
    );
  }
export default BusinessSideBar;
// Customizable Area End