// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Moment } from "moment";

export const configJSON = require("./config");

const config = require('../../../framework/src/config.js')

export type Props = {
  classes: any;
  chartTitle: string;
  startDate: Moment;
  endDate: Moment;
  chartData: Array<{
    name: string;
    value: number;
  }>
};

interface S {}

interface SS {
  navigation: any;
}

export default class AnalyticsChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

}
// Customizable Area End
