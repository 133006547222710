// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getErrorMessage } from "../../../components/src/CommonFunctions";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  userOtp: string;
  userPhone: string;
  userName: string;
  otpError: string;
  isSignUp: boolean;
  thoughts: string;
  themeSwitch: boolean;
}
interface SS {
  id: any;
}
export default class EndUserOtpAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  phoneBasedSignupAPI: any = "";
  verifyUserOtpAPI: any = "";
  verifyUserOtpForLoginID: any = "";
  resendOtpAPI: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.EndUserOtpValidationMessage),
    ];

    this.state = {
      userOtp: "",
      loading: false,
      userPhone: "",
      userName: "",
      otpError: "",
      isSignUp: false,
      thoughts: "",
      themeSwitch:
        localStorage.getItem("yellowTheme") === "true" ? true : false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentWillUnmount() {
    let keysToRemove = ["countdownTime", "isUserLogin"];
    keysToRemove.forEach((k) => window.localStorage.removeItem(k));
  }

  navigateToEndU = () => {
    if(!this.state.thoughts) {
      this.naviageToPhoneNumber()
      return
    }
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EndUserEmailSignUp"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const params = {
      stepOne: false,
      stepTwo: false,
      stepThree: true,
      phone: this.state.userPhone,
      name: this.state.userName,
      signUp: true,
      thoughts: this.state.thoughts,
    };
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.EndUserSignupMessage),
      params
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  naviageToPhoneNumber = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EndUserSignIn"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const params = {
      stepOne: false,
      stepTwo: false,
      stepThree: true,
      thoughts: this.state.thoughts,
    };
    const raiseMessage: Message = new Message(
      getName(MessageEnum.ChangePhoneNumberMessage)
    );
    raiseMessage.addData(getName(MessageEnum.ChangePhoneNumberMessage), params);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (getName(MessageEnum.EndUserOtpValidationMessage) === message.id) {
      const params = message.getData(
        getName(MessageEnum.EndUserOtpValidationMessage)
      );
      this.setState({
        userPhone: params.phone,
        userName: params.name,
        isSignUp: params?.signUp,
        thoughts: params.thoughts,
      });
    }

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.meta) {
        this.setState({ loading: false });
        this.handleApiResponse(apiRequestCallId, responseJson);
      } else if (responseJson.errors || responseJson.error) {
          this.setState({
            otpError: getErrorMessage(responseJson.errors ? responseJson.errors[0] : responseJson.error),
            loading: false,
          });
        setTimeout(() => {
          this.setState({otpError: ""})
        }, 3000)
      }
    }
  }

  handleApiResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.verifyUserOtpAPI) {
      window.localStorage.setItem("EndUserAuthToken", responseJson.meta.token);
      window.localStorage.setItem("EUAuthToken", responseJson.meta.token);
      this.phoneBasedSignup(responseJson.meta.token);
    }

    if (
      apiRequestCallId === this.phoneBasedSignupAPI ||
      apiRequestCallId === this.verifyUserOtpForLoginID
    ) {
      window.localStorage.setItem("EndUserAuthToken", responseJson.meta.token);
      window.localStorage.setItem("EUAuthToken", responseJson.meta.token);
      if (this.state.isSignUp) {
        this.props.navigation.navigate("EnduserFeed");
      }
    }

    if (apiRequestCallId === this.resendOtpAPI) {
      window.localStorage.setItem("EndUserAuthToken", responseJson.meta.token);
      window.localStorage.setItem("EUAuthToken", responseJson.meta.token);
    }
  }

  checkRedirections(pin: string) {
    let isUserLogin = window.localStorage.getItem("isUserLogin");
    if (isUserLogin === "true") {
      this.verifyUserOtpForLogin(pin);
    } else {
      this.verifyUserOtp(pin);
    }
  }

  verifyUserOtp(otp: string) {
    this.setState({
      loading: true,
      userOtp: otp,
      otpError: "",
    });
    const httpBody = {
      pin: otp,
    };
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: window.localStorage.getItem("EndUserAuthToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyUserOtpAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyUserOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  verifyUserOtpForLogin(otp: string) {
    this.setState({
      loading: true,
      userOtp: otp,
    });
    const data = {
      attributes: {
        full_phone_number: `+${this.state.userPhone}`,
        pin: Number(otp),
      },
      type: "sms_account",
    };
    const httpBody = {
      data: data,
    };
    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: window.localStorage.getItem("EndUserAuthToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyUserOtpForLoginID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endUserLoginOtpConformationEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  phoneBasedSignup(userAuth: string) {
    const data = {
      attributes: {
        full_phone_number: `+${this.state.userPhone}`,
        full_name: this.state.userName,
      },
      type: "sms_account",
    };

    const httpBody = {
      data: data,
    };

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: window.window.localStorage.getItem("EndUserAuthToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.phoneBasedSignupAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  resendOtp = () => {
    this.setState({
      loading: true,
    });
    const data = {
      attributes: {
        full_phone_number: `+${this.state.userPhone}`,
      },
    };

    const httpBody = {
      data: data,
    };

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpToEndUserEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
}
// Customizable Area End
