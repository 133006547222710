import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button, makeStyles, Container } from "@material-ui/core";
import { configJSON } from "./BillingInformationController.web";

interface Props {
  handleSubmit: (stripe: any, elements: any) => Promise<void> | undefined;
}

const useStyles = makeStyles(() => ({
  submitButton: {
    backgroundColor: "#FFC629",
    marginTop: "37px",
    width: "100%",
    maxWidth: "358px",
    height: "56px",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "black",
    },
    "& a": {
      opacity: 1,
      fontSize: "16px",
      color: "black",
      textDecoration: "none",
    },
  },
  formContainer: {
    display: "flex",
    maxWidth: "360px",
  },
  formContainere: {
    width: "100%",
    marginLeft: "-21px",
  },
}));

const StripeForm = ({ handleSubmit }: Props) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
 
  return (
    <Container className={classes.formContainere} data-test-id="billingInformationForm">
  
        <PaymentElement id="card" />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            data-test-id="submitButton"
            variant="contained"
            fullWidth
            type="button"
            onClick={()=>handleSubmit(stripe, elements)}
            style={{ textTransform: "none" }}
            disabled={!stripe}
            className={classes.submitButton}
          >
            {configJSON.updateContactButton}
          </Button>
        </div>
      
    </Container>
  );
};

export default StripeForm;
