// Customizable Area Start

import React from "react";
import { Box } from "@material-ui/core";
import AnalyticsMainStats from "./AnalyticsMainStats.web";
import AnalyticsTabs from "./AnalyticsTabs.web";
import AnalyticsEngagedPosts from "./AnalyticsEngagedPosts.web";
import AnalyticsReels from "./AnalyticsReels.web";
import VisualAnalyticsController from "./AnalyticsController.web";
import AnalyticsChart from "./AnalyticsChart.web";
import AnalyticsTextConversations from "./AnalyticsTextConversations.web";
import AnalyticsPlatforms from "./AnalyticsPlatforms.web";

export class VisualAnalytics extends VisualAnalyticsController {
  render() {
    const {
      currentPage,
      engagementData,
      chartData,
      dataRange,
      tabsData,
      everyreelData,
      currentEngagementView,
      trendingPosts,
      postFeedClicks,
      mostActiveUsers,
      connectedPlatforms,
    } = this.state;    
    
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: "30px",
          backgroundColor: "#F1F5F9",
          gap: "30px",
        }}
        data-test-id="analyticsContainer"
      >
        <AnalyticsMainStats
          currentPage={currentPage}
          engagements={engagementData}
          chartData={
            Boolean(engagementData.length)
              ? chartData[engagementData.find((data) => data.checked)!.type]
              : []
          }
          dataRange={dataRange}
          changeEngagementCheck={this.changeEngagementCheck}
          setCurrentPage={this.setCurrentPage}
          setDataRange={this.setDataRange}
        />

        {this.showAnalyticsTabs() && (
          <AnalyticsTabs
            tabsData={tabsData}
            currentEngagementView={currentEngagementView}
            currentPage={currentPage}
            handleTabClick={this.handleTabClick}
          />
        )}

        {this.showReelsStats() && <AnalyticsReels videos={everyreelData} />}

        {this.showTrendingPosts() && (
          <AnalyticsEngagedPosts posts={trendingPosts} />
        )}

        {this.showFeedClicks() && (
          <Box
            style={{
              display: "flex",
              width: "100%",
              padding: "44px 20px 20px 40px",
              borderRadius: "20px",
              backgroundColor: "#FFF",
            }}
          >
            <AnalyticsChart
              chartData={postFeedClicks}
              chartTitle={this.getChartTitle(currentEngagementView)}
              startDate={dataRange.startDate}
              endDate={dataRange.endDate}
            />
          </Box>
        )}

        {this.showTextConversations() && (
          <AnalyticsTextConversations mostActiveUsers={mostActiveUsers} />
        )}

        {this.showConnectedPlatforms() && (
          <AnalyticsPlatforms
            connectedPlatforms={connectedPlatforms}
            setSelectedPlatform={this.setSelectedPlatform}
          />
        )}
      </Box>
    );
  }
}

export default VisualAnalytics;

// Customizable Area End
