// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ConnectedPlatform } from "./interfaces";

export const configJSON = require("./config");

const config = require("../../../framework/src/config.js");

export type Props = {
  classes: any;
  connectedPlatforms: ConnectedPlatform[];
  setSelectedPlatform: (platformName: string) => void;
};

interface S {
  selectedPlatform?: ConnectedPlatform;
}

interface SS {
  navigation: any;
}

export default class AnalyticsPlatformsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedPlatform: undefined,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  baseURL = config.baseURL;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (prevProps.connectedPlatforms !== this.props.connectedPlatforms) {
      this.setState({
        selectedPlatform: this.props.connectedPlatforms.find(
          (platform) => platform.selected
        ),
      });
    }
  }
}
// Customizable Area End
