// Customizable Area Start
import React, {CSSProperties,useState} from "react";
import { useDropzone } from "react-dropzone";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography, Button, Snackbar, Modal, styled } from "@material-ui/core";
import { cloudIcon } from "./assets";
import { CloseIcon } from "../../settings2/src/assets";
import Alert from "@material-ui/lab/Alert";

const configJSON = require("./config.js");

type Props = {
  classes: any;
  setStep: () => void;
  setContactsFiles: (file: any) => void;
  sendContactsFile: () => void;
  contactFile: any;
  hideBackBtn?: boolean;
  acceptModal: boolean;
  handleModalClose: () => void
};

const ResetModalTitle = styled(Typography)({
  paddingBottom: "21px",
  marginBottom: "38px",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  textAlign: "center",
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px" /* 133.333% */,
  letterSpacing: "-0.12px",
});

const DeleteModalFooter = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
});
const CancelModalButton = styled("button")({
  padding: "16px 0",
  backgroundColor: "#ffca29",
  textTransform: "capitalize",
  color: "black",
  display: "block",
  border: "none",
  borderRadius: "12px",
  width: "100%",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
});

const DeleteModalButton = styled("button")({
  backgroundColor: "black",
  borderRadius: "12px",
  padding: "16px 0",
  textTransform: "capitalize",
  width: "100%",
  color: "white",
  border: "none",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
});
const CloseImageIcon = styled("img")({
  right: "-71px",
  position: "absolute",
  cursor: "pointer",
  height: "26px",
  bottom: "81px",
  padding: "4px",
  width: "24px",
  borderRadius: "80px",
  justifyContent: "space-between",
  alignItems: "center",
  border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
  background: "#FFF",
  boxShadow:
    " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});

export const ContactsListAddContactsFile = ({ classes, setContactsFiles, sendContactsFile, setStep, contactFile, acceptModal, handleModalClose }: Props) => {
  const [fileupload , setFileupload] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/*": [".csv", ".xlsx"],
    },
    maxSize: 1000000000,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFileupload(true);
      setContactsFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleNextStep = () => {
    sendContactsFile()
    setStep()
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>You have a CSV file?</Typography>
      {
        contactFile && contactFile.length > 0 ? (
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} id="fileInput" />
            <img
              src={cloudIcon}
              style={{ width: 72, color: "#E0E0E0" }}
            />

            <Box className={classes.fileDescriptionContainer}>
              <Typography className={classes.fileName}>
                {contactFile[0].path}
              </Typography>
              <Typography className={classes.fileSize}>
                {Math.ceil(contactFile[0].size / (1024 * 1024))} MB
              </Typography>
            </Box>

            <Button className={classes.acceptButton} style={{ ...webStyles.button }}>
              Select File
            </Button>
          </div>
        ) : (
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} id="fileInput" />
            <img
              src={cloudIcon}
              style={{ width: 72, color: "#E0E0E0" }}
            />
            <Box className={classes.dropzoneInnerContainer}>
              <Typography className={classes.dropzoneTitle}>
                Select file to upload
              </Typography>
              <Typography className={classes.dropzoneText}>
                or drag and drop file
              </Typography>
            </Box>

            <Box className={classes.fileDescriptionContainer}>
              <Typography className={classes.limitsText}>CSV file or .XMLS file</Typography>
              <Typography className={classes.limitsText}>
                Less than 1 GB
              </Typography>
            </Box>

            <Button className={classes.downloadButton} style={{ ...webStyles.button }} onClick={(event) => {
              event.stopPropagation()
            }}>
              Download CSV Template
            </Button>
            <Button className={classes.acceptButton} style={{ ...webStyles.button }}>
              Select File
            </Button>
          </div>
        )
      }
       <Snackbar
        open={Boolean(fileupload)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={"success"}
        >
          {"File uploaded succesfully"}
        </Alert>
      </Snackbar>
      {contactFile && contactFile.length > 0 && 
      // <Button id="acceptFileButton" className={classes.acceptButton} style={{ ...webStyles.button }} onClick={handleNextStep}>Accept</Button>
      <Button className={classes.downloadButton} style={{ ...webStyles.button }} onClick={(event) => {
        event.stopPropagation()
      }}>
        Download CSV Template
      </Button>
      }
      <Modal
            open={acceptModal}
            onClose={handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            data-test-id="templateEditModal"
          >
            <Box className={classes.EndWaitlistmodalBox}>
              <div>
                <ResetModalTitle>
                  Are you sure you want to send CSV?
                  <CloseImageIcon
                    data-test-id="closeEndModal"
                    src={CloseIcon}
                    alt=""
                    onClick={handleModalClose}
                  />
                </ResetModalTitle>
              </div>
              <DeleteModalFooter>
                <DeleteModalButton
                  data-test-id="resetButtonCall"
                  type="button"
                  onClick={handleModalClose}
                >
                  Cancel
                </DeleteModalButton>
                <CancelModalButton onClick={handleNextStep}>
                  Confirm
                </CancelModalButton>
              </DeleteModalFooter>
            </Box>
          </Modal>
    </Box>
  );
};

const webStyles: Record<string, CSSProperties> = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "360px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
  },
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "auto",
      gap: "17px",

      "&::-webkit-scrollbar": {
        width: "10px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    title: {
      alignSelf: "flex-start",
      paddingLeft: "14px",
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px"
    },

    dropzone: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "stretch",
      alignItems: "center",
      padding: "18px 25px 25px",
      gap: "20px",
      borderRadius: "10px",
      border: "2px dashed #ABB0BC",
    },

    dropzoneInnerContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "4px",
    },

    fileDescriptionContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "10px",
      paddingBottom: "22px"
    },
    fileName: {
      fontFamily: "inter",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "center",
      letterSpacing: "-0.11999999731779099px"
    },
    fileSize: {
      fontFamily: "inter",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "13px",
      textAlign: "center",
      color: "#000000",
      paddingTop: "11px"
    },

    dropzoneTitle: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },

    dropzoneText: {
      fontSize: "14px",
      lineHeight: "20px",
    },

    limitsText: {
      fontSize: "14px",
      lineHeight: "25px",
      fontWeight: 700,
      fontFamily: "Inter",
      color: "#000000"

    },

    acceptButton: {
      backgroundColor: "#FFC629",
      color: "#0F172A",

      "&:hover": {
        backgroundColor: "#FFC629",
      }
    },

    downloadButton: {
      backgroundColor: "#189CBD",
      color: "#fff",

      "&:hover": {
        backgroundColor: "#189CBD",
      }
    },
  
    backButton: {
      backgroundColor: "#000",
      color: "#FFF",

      "&:hover": {
        backgroundColor: "#000",
      },
    },
    EndWaitlistmodalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "85px 106px 31px 72px",
      width: "100%",
      maxWidth: "636px",
      borderRadius: "0px 0px 40px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
    },
  });

export default withStyles(styles)(ContactsListAddContactsFile);
// Customizable Area End
