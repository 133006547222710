Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.httpPostMethod = "POST"
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.getuserdetailsEndPoint = "account_block/accounts/user_profile";
exports.sendPostEndPoint = "/bx_block_posts/posts";

// Create Everyreel
exports.getSocialScrollEndPoint = "bx_block_posts/social_scrolls/"
exports.createSocialScrollEndPoint = "bx_block_posts/social_scrolls"
exports.uploadFunnelFilesEndPoint = "bx_block_posts/funnels/upload_funnel_video"
exports.createFunnelEndPoint = "bx_block_posts/funnels"
exports.createPost = "bx_block_posts/posts"
exports.instagramMediaApiEndPoint = ""
exports.instagramCodeApiEndPoint = "account_block/business_contacts/get_access_token"

exports.createNewEveryreel = "Create New EveryReel"
exports.socialScroll = "Social Scroll"
exports.socialScrollText = "Connect multiple videos into a single scrollable feed to build brand loyalty"
exports.interactiveFunnel = "Interactive Funnel"
exports.interactiveFunnelText = "Connect separate video parts into a workflow to create a personalized experience for your user"
exports.newEveryreel = "New EveryReel"
exports.selectVideoToUpload = "Select video to upload"
exports.dragNdropImage = "or drag and drop a image file"
exports.imageType = "JPG or PNG"
exports.resolution = "720X1280 resolution or higher"
exports.videoDuration = "Up to 1 minute"
exports.videoSize = "Less than 2 GB"
exports.selectFile = "Select File"
exports.everybrandLibrary = "Everybrand Library"
exports.goodChoice = "Good choice! Is this correct?"
exports.postPreview = "Post Preview"
exports.postSocialScroll = "Post New Social Scroll"
exports.postEveryreelText = "Post New EveryReel"
exports.upload = "Upload"
exports.linkButton = "Link Button"
exports.videos = "Videos"
exports.editChoices = "Edit Choices"
exports.acceptLabel = "Accept"
exports.cancelLabel = "Cancel"
exports.selectInstagramPostTitle = "Select videos from your Instagram Library"
exports.instagramAccountNotConnectedMessage = "Your Instagram account isn't connected. Hang tight, we're redirecting you to connect now..."
exports.instagramAccountConnectedMessage = "Instagram account successfully connected"

//Everybrand library
exports.getMediaFilesEndpoint = "/bx_block_posts/everyreel_library"
exports.libraryContentType = "application/json";
exports.httpGetMethod = "GET";

exports.endPointApiGetEUCreateBrands = "bx_block_dashboard/dashboards/end_user_data";
exports.followBrandApiEndpoint = "bx_block_followers/follows/create_follower"
exports.createBrandsOptionsApiEndpoint = "bx_block_followers/follows/top_6_brands"
exports.contentTypeApiGetEUCreateBrands = "application/json";
exports.methodTypeApiGetEUCreateBrands = "GET";
exports.EU_AUTH_TOKEN = "EUAuthToken"

exports.INSTAGRAM_APP_ID = "1876551739537774"
exports.INSTAGRAM_AUTH_CODE_PARAM = "code"
exports.INSTAGRAM_AUTH_ERROR_PARAM = "error_description"
// Customizable Area End