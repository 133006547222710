// Customizable Area Start
import React, { Component } from "react";

interface ICustomConditionalRender {
  isRender: boolean;
  children: React.ReactNode;
}

export class CustomConditionalRender extends Component<
  ICustomConditionalRender
> {
  render() {
    const { isRender, children } = this.props;

    return isRender ? children : null;
  }
}

export default CustomConditionalRender;
// Customizable Area End
