import React from 'react';

interface ImageButtonProps {
  imageUrl: string;
}

const ImageButton: React.FC<ImageButtonProps> = ({ imageUrl }) => {
  return (
    <div style={styles.button}>
        <img src={imageUrl} alt="button" style={styles.image} />
    </div>
  );
};

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "absolute",
    bottom: 200, 
    right: 22,
    zIndex: 5
  } as React.CSSProperties,
  image: {
    width: '35px', 
    height: '35px', 
    borderRadius: '50%',
  } as React.CSSProperties
};

export default ImageButton;
