// Customizable Area Start
import React from "react";

import { Box, Typography } from "@material-ui/core";
import { firststepimgwomen, firststepimg1, image_3_People } from "./assets";
const webStyle = {
  imageFirstTab: {
    backgroundColor: "#FFC629",
    borderRadius: "0px 0px 0px 64px",
    width: "100%",
    height: "100%",
    textAlign: "center" as "center",
    display: "flex",
    justifyContent: "center",
    padding: "60px 0 0 0",
  },
  imagestepsDetail: {
    //  display: "inline-block",
    display: "flex",
    flexDirection: "column" as "column",
    fontWeight: 900,
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    "& img": {
      marginTop: "300px",
    },
  },
  stepDiv: {
    display: "flex",
    flexDirection: "column" as "column",
    // alignItems: "center" as "center",
    // position: "relative" as "relative",
    justifyContent: "space-between",
  },

  fontWeight: {
    fontWeight: 900,
  },
  imagestepsDetaillast: {
    marginTop: "30px",
    "@media and (max-width:'960px')": {
      position: "inherit",
    },
  },
  imgstarbar: {
    fontSize: "18px",
    color: "#fff",
    fontWeight: 600,
    marginTop: "17px",
  },

  phoneVerificationTextContainer: {
    width: "470px",
  },

  phoneVerificationText: {
    color: "#000",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    letterSpacing: "-0.36px",
  }
};
const BusinessSideBar = (props: any) => {
  console.log('current step', props.currentStep);
  
  return (
    <>
      <Box style={webStyle.imageFirstTab}>
        <Box style={webStyle.stepDiv}>
          <Box style={webStyle.imagestepsDetail}>
            <img
              src={firststepimg1}
              style={{ marginBottom: "35px", alignSelf: "center" }}
            />
            {props.currentStep === 4 ? (
              <Box style={webStyle.phoneVerificationTextContainer} data-test-id="phoneVerificationText">
                <Typography style={webStyle.phoneVerificationText}>
                  For one reason only: to make your life easier, and more fun (I guess that's two reasons 😉 ).
                </Typography>
              </Box>
            ) : (
              <img src={props.data?.imagetitle} alt="img" data-test-id="imageTitle" />
            )}

            {props.currentStep === 6 && (
              <>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={webStyle.imgstarbar}
                  data-test-id="starBar"
                >
                  You’re in the home stretch!
                </Typography>
              </>
            )}
            <img
              src={props.data?.image}
              alt="img"
              style={{ marginTop: "40px", alignSelf: "center" }}
            />
          </Box>

          {props.currentStep === 6 && (
            <div data-test-id="image3People">
              <img src={image_3_People} />
            </div>
          )}
          {props.currentStep !== 6 && (
            <div style={webStyle.imagestepsDetaillast} data-test-id="imageFirstStepWoman">
              <img src={firststepimgwomen} width="100%" />
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};
export default BusinessSideBar;
// Customizable Area End
