// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { AnalyticsPages } from "./enums";
import { Moment } from "moment";
import { EngagementData } from "./interfaces";

export const configJSON = require("./config");

export type Props = {
  classes?: any;
  currentPage: AnalyticsPages;
  engagements: EngagementData[];
  chartData?: Array<{
    name: string;
    value: number;
  }>;
  dataRange: {
    startDate: Moment;
    endDate: Moment;
  };
  setCurrentPage: (currentPage: AnalyticsPages) => void;
  setDataRange: (dataRange: number) => void;
  changeEngagementCheck: (id: number) => void;
};

interface S {
  dateMenuAnchorEl: Element | null;
}

interface SS {
  navigation: any;
}

export default class AnalyticsMainStatsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      dateMenuAnchorEl: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  setMenuAnchor = (anchor: Element | null) => {
    this.setState({ dateMenuAnchorEl: anchor });
  };

  getStyleValue = (
    condition: boolean,
    truthlyStyle: string,
    falsyStyle: string
  ) => {
    return condition ? truthlyStyle : falsyStyle;
  };

  getChartTitle = () => {
    return (
      this.props.engagements
        .find((engagement) => engagement.checked)
        ?.type.split("_")
        .join(" ") || ""
    );
  };

  isAllTimeSelected = () => {
    const { dataRange } = this.props
    return dataRange.endDate.diff(dataRange.startDate, "days") === 10000
  }
}
// Customizable Area End
