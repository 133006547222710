import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/Comment';

interface CommentButtonProps {
  amount?: number;
}

const CommentButton: React.FC<CommentButtonProps> = ({ amount = 0 }) => {
  const [commentCount, setCommentCount] = useState(amount);

  const handleClick = () => {
    setCommentCount(commentCount + 1);
  };

  return (
    <div style={styles.button}>
      <IconButton style={{ color: '#FFFFFF' }}>
        <ChatBubbleOutlineIcon />
      </IconButton>
      <Typography variant="body2" style={{ color: '#FFFFFF', fontFamily: 'Inter' }}>{commentCount}</Typography>
    </div>
  );
};

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "absolute",
    bottom: 60,
    right: 16,
    zIndex: 5
  } as React.CSSProperties
};

export default CommentButton;


