import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { loadStripe } from "@stripe/stripe-js";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");
export const stripePromise = loadStripe(configJSON.stripeKey);


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  cardName: string;
  expiryDate: string;
  cardNumber: string;
  cvv: string;
  cardNameErr: string;
  expiryDateErr: string;
  cardNumberErr: string;
  cvvErr: string;
  isToken: string;
  getPaymentData: any;
  createIntent: any;
  stripeForm: any;
  elementsKey: any, 
  isShowAlert:boolean,
  showSucess:string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BillingInformationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPaymentsDetailsId: string = "";
  createIntentId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      cardName: "",
      expiryDate: "",
      cardNumber: "",
      cvv: "",
      cardNameErr: "",
      expiryDateErr: "",
      cardNumberErr: "",
      cvvErr: "",
      isToken: "",
      getPaymentData: [],
      createIntent: "",
      stripeForm: {},
      elementsKey: 0, 
      isShowAlert:false,
      showSucess:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = await getStorageData("authToken");

    this.setState({ isToken:token }, () => {
      this.getPaymentDetails(this.state.isToken)
    });
    
    
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    console.log("runnn222")
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getPaymentsDetailsId && responseJson) {
      this.setState({ getPaymentData: responseJson?.data })
    }
    if (apiRequestCallId === this.createIntentId && responseJson) {
      
      const matchResult = window.location.href.match(/^(https?:\/\/[a-z0-9\:\-.]+)\//);
      const dynamicBaseUrl = matchResult ? matchResult[1] : '';
      const returnUrl = `${dynamicBaseUrl}/settings`;

this.setState({ createIntent: responseJson.client_secret }, async () => {
      
        const result = await this.state.stripeForm.stripe.confirmSetup({
          elements: this.state.stripeForm.elements,
          clientSecret: this.state.createIntent,
          confirmParams: {
            return_url:returnUrl,
          },
          redirect:"if_required"
          
        },);
       
       
        this.setState({ isShowAlert: true,showSucess:"Billing Information Updated Successfully"}, () => {
          this.isShowCell()
          this.handlePaymentSuccess()
          this.getPaymentDetails(this.state.isToken)
        }) 
         
        
    
        
        
      });
      // Customizable Area End
    }
  }

  // Customizable Area Start
  isShowCell = () => {
    setTimeout(() => this.setState({ isShowAlert: false }), 3000);
  }

  handlePaymentSuccess = () => {
    // Logic for successful payment
  
    // Reset the form by changing the key
    this.setState((prevState) => ({
      elementsKey: prevState.elementsKey + 1,
      stripeForm: { ...prevState.stripeForm, elements: null }, // Reset elements
    }));
  };
  
  handleSubmit = async (
    
    stripe: any,
    elements: any,
    
  ) => {
   
    
    try {
     
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // Show error to your customer
        console.log(submitError.message, "ERRRR!!!!!!!!!!!!");
        return;
      }
     
      this.setState({
        stripeForm: { stripe: stripe, elements: elements }
      },  () => {
         this.createIntentApiCall(this.state.isToken);

       
  
      });
   
    } catch (apiError) {
      // Handle API call error
      console.error(apiError, "API CALL ERROR");
    }
  };
  
  



  getPaymentDetails(token: string) {
    console.log("hhhhh")
    const headerData = {
      "Content-Type": configJSON.contentType,
      token: token
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentsDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPaymentDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  createIntentApiCall(token: string) {
    
      const headerData = {
        token: token
      };
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createIntentId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createIntentEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     
    

  }
  // Customizable Area End

}