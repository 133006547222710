// Customizable Area Start
/* eslint-disable */
import React, { CSSProperties } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {
  createStyles,
  createTheme,
  Theme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { everybrandLogo } from "./assets";
import { withRouter } from "react-router-dom";
import PhoneLoginController, { Props } from "./PhoneLoginController.web";
import { CssBaseline } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFC629",
      contrastText: "#0F172A"
    },
    background: {
      default: "#FFC629"
    }
  }
});

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 0"
    },
    everybrandLogo: {
      height: "75px",
      marginBottom: "48px"
    },
    paper: {
      backgroundColor: "#FFFFFF",
      width: "440px",
      borderRadius: "0 0 24px 0",
      padding: "40px"
    },
    title: {
      fontSize: "24px",
      fontWeight: 700,
      marginBottom: "40px"
    },
    subTitle: {
      fontSize: "18px",
      fontWeight: 400,
      marginBottom: "8px"
    },
    submitButton: {
      color: "#FFFFFF",
      backgroundColor: "#0F172A",
      borderRadius: "8px",
      textTransform: "none",
      height: "52px",
      fontWeight: 700,
      marginBottom: "40px"
    },
    textButton: {
      textTransform: "none"
    },
    otpContainer: {
      display: "flex",
      marginBottom: "40px"
    },
    otpInput: {
      "&:not(:last-child)": {
        marginRight: "8px"
      }
    },
    formErrorBlock: {
      padding: theme.spacing(1.5, 2),
      margin: theme.spacing(5, 0),
      borderLeft: "4px solid #DC2626",
      borderRadius: theme.spacing(0.5),
      backgroundColor: "#FEE2E2",
      "& p": {
        fontSize: "12px",
        color: "#DC2626"
      }
    }
  });

const webStyles: Record<string, CSSProperties> = {
  alignCenter: {
    textAlign: "center"
  }
};

class PhoneLoginOTP extends PhoneLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className={classes.container}>
          <img
            className={classes.everybrandLogo}
            src={everybrandLogo}
            alt="everybrand logo"
          />
          <Box className={classes.paper}>
            <Typography className={classes.title}>
              Phone verification
            </Typography>
            {this.state.otpError && (
              <div
                className={classes.formErrorBlock}
                data-test-id="error"
              >
                <Typography>{this.state.otpError}</Typography>
              </div>
            )}
            <Typography className={classes.subTitle}>
              A 6-digit verification code was just sent to
            </Typography>
            <Typography className={classes.subTitle}>
              {this.getPhoneNumber()}
            </Typography>
            <Box className={classes.otpContainer}>
              {this.inputRefs.map((inputRef: any, index: number) => (
                <TextField
                  key={index}
                  data-test-id={`codeInput-${index}`}
                  autoFocus={index === 0}
                  inputProps={{
                    maxLength: 1,
                    // style: webStyles.alignCenter
                  }}
                  className={classes.otpInput}
                  variant="outlined"
                  value={this.state.otp[index] || ""}
                  onChange={(e) => this.handleOtpInputChange(e, index)}
                  inputRef={inputRef}
                  onKeyDown={(e) => this.handleOtpKeyDown(e, index)}
                />
              ))}
            </Box>
            <Button
              className={classes.submitButton}
              fullWidth
              variant="contained"
              onClick={() => this.otpPhoneConfirm()}
              data-test-id="nextButton"
            >
              Next
            </Button>
            <Button variant="text" className={classes.textButton} data-test-id="resendButton">
              <Typography>
                Didn't get code? <b>Resend it</b>
              </Typography>
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export { PhoneLoginOTP };
export default withStyles(styles)(withRouter(PhoneLoginOTP));
// Customizable Area End
