import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getToken } from '../../../components/src/AuthService';
import * as yup from 'yup'
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  isShowAlert: boolean;
  showSucess:string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CustomizePostingFeedController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  updateCustomizePostingID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

this.state = {
  dashboardData: {},
  errorMsg: "",
  token: "",
  loading: false,
  isShowAlert: false,
  showSucess:""
};
// Customizable Area End
runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = getToken();
    this.setState({ token: token })
    this.getDashboardData()
    // Customizable Area End
  }
 
  getDashboardData() {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.contentType,
      token: getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customizePostingFeedGetEndpoint
    );

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getApiMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
// Customizable Area End
   
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = await message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiDashboardItemCallId && responseJson ) {
       this.setState({dashboardData:responseJson.data})
        }
      if(apiRequestCallId===this.updateCustomizePostingID && responseJson){
        this.setState({ isShowAlert: true,showSucess:"Posting Feed Settings Updated"}, () => {
          this.isShowCell();
          this.getDashboardData()
         
        })
       
      
      }
    }
    // Customizable Area End
    }

  // Customizable Area Start
  isShowCell = () => {
    setTimeout(() => this.setState({ isShowAlert: false }), 3000);
  }
  
  handleSubmit=(values:any)=>{
   const formValues={
    "posting_feed":{
      "color_option_1" : values.color_option_1,
      "comments_enabled":values.comments_enabled,
      "color_option_2":values.color_option_2,
      "post_enabled":values.post_enabled,
      "review_enabled":values.review_enabled,
      "approve_audience_post_enabled":values.approve_audience_post_enabled,
      "photo_video_enabled":values.photo_video_enabled
    }
   }
 
   this.updateCustomizePostingFeed(formValues)
  }
  handleSwitchChange = (name: string, checked: boolean) => {
    // Update the local state
    this.setState((prevState) => ({
      dashboardData: {
        ...prevState.dashboardData,
        [name]: checked,
      },
    }));
  
    // Make the API call
    const values = {
      posting_feed: {
        ...this.state.dashboardData,
        [name]: checked,
      },
    };
    this.updateCustomizePostingFeed(values);
  };
  updateCustomizePostingFeed(values:any){
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.contentType,
      token: getToken()
    };

   
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCustomizePostingID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customizePostingFeedPostEndpoint
    );

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(values)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.postApiMethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  validationSchemaCustomisePostingFeed = yup.object().shape({
    color_option_1: yup
      .string().nullable()
      .matches(configJSON.hexColorCodeRegExp, {
        message: configJSON.postThemeColorInvalid,
      })
      .required(configJSON.postThemeColor1Required),
    color_option_2: yup
      .string().nullable()
      .matches(configJSON.hexColorCodeRegExp, {
        message: configJSON.postThemeColorInvalid,
      })
      .required(configJSON.postThemeColor2Required),
  });
  // Customizable Area End

}