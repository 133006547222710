Object.defineProperty(exports, "__esModule", {
    value: true
  });

// Customizable Area Start
//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";
exports.httpPutMethod = 'PUT';
exports.httpDeleteMethod = 'DELETE';

exports.forgotPasswordApiContentType = 'application/json';
exports.forgotPasswordEndPoint = 'bx_block_forgot_password/generate_password_link';

exports.resetPasswordApiContentType = 'application/json';
exports.resetPasswordEndPoint = 'bx_block_forgot_password/create_password';

exports.labelEverybrand = 'Everybrand';
exports.labelForgotPassword = 'Forgot password?';
exports.labelNoWorries = "No worries, we'll send a recovery link to your email.";
exports.labelEmailPlaceholder = 'Your email';
exports.labelSendRecoveryLink = "Send a recovery link";
exports.labelBackTo = 'Back to';
exports.labelLogIn = 'log in';
exports.labelPasswordRecovery = 'Password recovery';
exports.labelCheckYourEmail = 'Check your email';
exports.labelResetLink = 'We have sent a password reset link to'
exports.labelResendEmail = 'Resend e-mail';
exports.labelSuccessResend = 'Your email has resend succesfully!'
exports.labelConfirmEmailText = 'We sent you an email link to confirm your new email. Please open this link to verify the change'
exports.labelOpenMail = 'Open mail';
exports.labelNewPassword = 'New Password'
exports.labelEnterNewPasswordFor = 'Enter a new password for'
exports.labelNewPasswordPlaceholder = 'Your new password'
exports.labelConfirmNewPassword = 'Confirm New Password';
exports.labelConfirmNewPasswordPlaceholder = 'Confirm your new password';
exports.labelYourPasswordMustContain = 'Your password must contain';
exports.labelAtLeastOneCapitalLetter = 'At least one capital letter';
exports.labelAtLeastOneLoweraseLetter = 'At least one lowercase letter';
exports.labelAtLeastOneNumber = 'At least one number';
exports.labelMinLengt = 'Minimum character length is 8 characters';
exports.labelSetNewPassword = 'Set New Password';
exports.labelPasswordIsRequired = 'Password is required';
exports.labelPasswordIsInvalid = 'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'
exports.labelPasswordMustMatch = 'Password must match';
exports.labelConfirmPasswordIsRequired = 'Confirm password is required';
exports.labelResetPasswordTokenError = 'User token not provided';


exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";

exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.labelNewPassword = 'New Password';
exports.labelConfirmNewPassword = 'Confirm New Password';
exports.labelLogIn = 'Log in';
// Customizable Area End