// Customizable Area Start
import React, { Component } from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { configJSON } from './Settings2Controller.web';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Formik } from 'formik';
import { BusinessContactInfo } from './interfaces';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css'
import { EverybrandIcon } from './assets';



export interface Props {
  busninessContactInfo: BusinessContactInfo;
  countryCodesWithFlags: Array<{ label: string; value: string }>;
  onUpdateBusinessContactInfo: (
    formData: BusinessContactInfo,
    resetForm: () => void
  ) => void;
  isFormSubmitting: boolean;
  classes: any;
  validationSchema: any;
}

const useStyles = ((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      height: "calc(100vh - 64px)",
      overflowX: "hidden",
     
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.up('md')]: {
        padding:"59px 39px 40px 55px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "yellow !important",
      }
    },
    input: {
      '& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FFC629' },
    },
    sectionHeading: {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px"
    },
    sectionSubHeading: {
      color: "#0F172A",

      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px"
    },
    formLabel: {
      display: 'block',
      color: " #334155",

      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      marginBottom: theme.spacing(0.5),
    },
    formField: {
      backgroundColor: '#FFFFFF',
      borderRadius: theme.spacing(1),
      "& .Mui-focused fieldset": {
        borderColor: "#FFCA29 !important",
      }
    },
    formHelperText: {
      position: 'absolute',
      bottom: '-24px',
    },
    updateButtonWrapper: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    updateButton: {
      

fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",

lineHeight: "24px", 
      color: '#0F172A',
      backgroundColor: '#FFC629',
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      textTransform: 'none',
      padding: theme.spacing(2, 0),
      '&:hover': {
        backgroundColor: '#FFC629',
      },
    },
    mb_20: {
      marginBottom: theme.spacing(2.5),
    },
    mb_48: {
      marginBottom: theme.spacing(6),
    },
    lightText: {
      color: '#64748B',
    },
    countryCodesDropdown: {
      fontSize: '24px',
      maxWidth: '32px',
      border: 'none',
      background: 'none',
      '-moz-appearance': 'none',
      '-webkit-appearance': 'none',
      '& option': {
        fontSize: '16px',
        maxWidth: '250px',
      },
    },
    countryCode: {
      color: '#64748B',
      marginLeft: theme.spacing(1),
    },
    emailAddress: {
      color: '#0F172A',
      fontSize: '16px',
    },
    phoneInputClass: {
      background: "white !important",
      backgroundColor: "white !important",

      "&:focus": {
        boxShadow: "0 0 0 1px #FFC629 !important",
        borderColor: "#FFC629 !important",

      }

    },

    phoneInputButton: {
      "& .arrow": {
        display: "none !important",
      }
    },
    customPlaceholder: {
      "&::placeholder": {
          color: " #64748B",

          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
          opacity: 1
      }
  },
  })
);
class UpdateBusinessContact extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  FormField = (touched: any, error: any) => {

    return `2px solid ${error && touched ? "red" : "#CBD5E1"}`
  }

  FormFieldError = (errors: any, touched: any) => {

    return errors.phone_number && touched.phone_number && <div data-testid="phone-error" style={{ color: "red", marginTop: "5px" }}>
      {errors.phone_number}
    </div>
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root} >
        
        <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1.5}
        mb={4.5}
      >
          <Typography
            className={clsx(classes.sectionHeading, classes.mb_48)}
            variant="h5"
          >
            {configJSON.currentBusinessContact}
          </Typography>
          <img style={{marginTop:"-54px"}}src={EverybrandIcon} />
          </Box>
          <Box mt={3}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Box mb={4}>
                <Typography className={clsx(classes.formLabel, classes.mb_20)}>
                  {configJSON.firstName}
                </Typography>
                <Typography className={classes.lightText}>
                  {this.props.busninessContactInfo.first_name || '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className={clsx(classes.formLabel, classes.mb_20)}>
                  {configJSON.lastName}
                </Typography>
                <Typography className={classes.lightText}>
                  {this.props.busninessContactInfo.last_name || '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={4}>
                <Typography className={clsx(classes.formLabel, classes.mb_20)}>
                  {configJSON.mobileNumber}
                </Typography>
                <Typography className={classes.lightText}>
                  {this.props.busninessContactInfo.phone_number
                    ? `+${this.props.busninessContactInfo.country_code} ${this.props.busninessContactInfo.phone_number}`
                    : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className={clsx(classes.formLabel, classes.mb_20)}>
                  {configJSON.email}
                </Typography>
                {this.props.busninessContactInfo.email ? (
                  <a
                    href={`mailto:${this.props.busninessContactInfo.email}`}
                    className={classes.emailAddress}
                  >
                    {this.props.busninessContactInfo.email}
                  </a>
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
            </Grid>
          </Grid>




        </Box>


        <Box mt={12}>
          <Typography
            className={classes.sectionHeading}
            variant="h5"
            gutterBottom
            data-test-id="sectionHeading"
          >
            {configJSON.updateBusinessContactHeading}
          </Typography>
          <Typography variant="body2" className={classes.sectionSubHeading}>
            {configJSON.updateBusinessContactSubHeading}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            country_code: '1',
            phone_number: '',
          }}
          validationSchema={this.props.validationSchema}
          validateOnChange={false}

          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            setFieldValue('phone_number', '');
            setFieldValue('country_code', '+1');

            this.props.onUpdateBusinessContactInfo(values, resetForm);
            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box mt={5} mb={2.5}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={10} md={6}>
                    <Box mb={4}>
                      <FormLabel
                        htmlFor="firstName"
                        className={classes.formLabel}
                      >
                        {configJSON.firstName}
                      </FormLabel>
                      <TextField
                        id="firstName"
                        name="first_name"
                        variant="outlined"
                        placeholder={configJSON.firstNamePlaceholder}
                        fullWidth
                        className={classes.formField}
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        InputProps={{
                          classes: { input: classes.input },
                          style: {

                            display: "flex",
                            height: "56px",
                            padding: "17px 8px",
                            justifyContent: "center",
                            alignItems: "center",


                            borderRadius: "8px",
                            border: "1px solid #CBD5E1",

                            background: "#FFF"
                          }
                        }}
                        inputProps={{
                          className: classes.customPlaceholder,
                        

                      }}
                        FormHelperTextProps={{
                          className: classes.formHelperText,
                        }}
                      />
                    </Box>
                    <Box>
                      <FormLabel htmlFor="lastName" className={classes.formLabel}>
                        {configJSON.lastName}
                      </FormLabel>
                      <TextField
                        id="lastName"
                        name="last_name"
                        variant="outlined"
                        placeholder={configJSON.lastNamePlaceholder}
                        fullWidth
                        className={classes.formField}
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        InputProps={{
                          classes: { input: classes.input },
                          style: {
                            display: "flex",
                            height: "56px",
                            padding: "17px 8px",
                            justifyContent: "center",
                            alignItems: "center",


                            borderRadius: "8px",
                            border: "1px solid #CBD5E1",

                            background: "#FFF"
                          }
                        }}
                        inputProps={{
                          className: classes.customPlaceholder,
                      }}
                        FormHelperTextProps={{
                          className: classes.formHelperText,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={10} md={6}>
                    <Box mb={4}>
                      <FormLabel
                        htmlFor="mobileNumber"
                        className={classes.formLabel}
                      >
                        {configJSON.mobileNumber}
                      </FormLabel>
                      <PhoneInput

                        country={values.phone_number ? "" : "us"}
                        data-test-id="phoneinput"
                        inputClass={classes.phoneInputClass}
                        buttonClass={classes.phoneInputButton}
                        value={values.phone_number}
                        buttonStyle={{
                          border: "none",
                          borderBottom: this.FormField(touched.phone_number, errors.phone_number),
                          borderLeft: this.FormField(touched.phone_number, errors.phone_number),
                          borderTop: this.FormField(touched.phone_number, errors.phone_number),
                          background: "transparent"


                        }}
                        onChange={(phone: any, data: any, event) => {
                          if (data) {
                            // Destructure dialCode from data
                            const { dialCode } = data;

                            // Update the form values
                            setFieldValue('phone_number', phone);
                            setFieldValue('country_code', dialCode);
                          }
                        }}
                        inputProps={{
                          id: "mobileNumber",
                          name: "phone_number",
                        }}
                        inputStyle={{
                          height: "56px",

                          
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexShrink: 0,
                          borderRadius: "8px",
                          border: this.FormField(touched.phone_number, errors.phone_number),
                          background: "white !important",
                          color: "#64748B",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "22px"
                        }}
                      />

                      {this.FormFieldError(errors, touched)}
                    </Box>
                    <Box>
                      <FormLabel htmlFor="email" className={classes.formLabel}>
                        {configJSON.email}
                      </FormLabel>
                      <TextField
                        id="email"
                        name="email"
                        variant="outlined"
                        placeholder={configJSON.emailPlaceholder}
                        fullWidth
                        className={classes.formField}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        inputProps={{
                          className: classes.customPlaceholder,
                      }}
                        InputProps={{
                          classes: { input: classes.input },
                          style: {
                            display: "flex",
                            height: "56px",
                            padding: "17px 8px",
                            justifyContent: "center",
                            alignItems: "center",


                            borderRadius: "8px",
                            border: "1px solid #CBD5E1",

                            background: "#FFF"
                          }
                        }}
                        FormHelperTextProps={{
                          className: classes.formHelperText,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Grid container spacing={5} className={classes.updateButtonWrapper}>
                <Grid item xs={12} sm={10} md={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    type="submit"
                    className={classes.updateButton}
                    data-test-id="updateButton"
                    disabled={this.props.isFormSubmitting}
                  >
                   {configJSON.updateContactButton}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>

      </div>
    );
  }
}

export default withStyles(useStyles)(UpdateBusinessContact);
export {UpdateBusinessContact}
// Customizable Area End
