export const imgBell = require("../assets/bell.png");
export const likeIcon = require("../assets/1_like_icon.svg");
export const priorityIcon = require("../assets/1_priority_icon.svg");
export const messageIcon = require("../assets/messages_icon.svg");
export const deleteIcon = require("../assets/trash_icon.svg");
export const pinIcon = require("../assets/pin_icon.svg");
export const resendIcon = require("../assets/resend_icon.svg");
export const reportPost = require("../assets/1_reported_post_icon.svg");
export const reviewIcon = require("../assets/1_review_icon.svg");
export const commentIcon = require("../assets/1_Comment_icon.svg");
export const selectedCommentIcon = require("../assets/coments_icon.svg");
export const selectedLikeIcon = require("../assets/like_icon_selected.svg");
export const selectedPriorityIcon = require("../assets/priority_icon.svg");
export const selectedReportIcon = require("../assets/reported_post_icon.svg");
export const selectedReviewIcon = require("../assets/review_icon.svg");
export const whiteCommentIcon = require("../assets/COMENT_ICON.svg");
export const sendIcon = require("../assets/SEND_MESSAGE_ICON.svg")
export const RedLikeIcon = require("../assets/red-heart.svg")
export const UploadIcon = require("../assets/upload_icon.svg")



