// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ContactsListViews } from "./enums";

export const configJSON = require("./config");

export type Props = {
  id?: string;
  classes?: any;
  navigation: any;
};

interface S {
  showPolicy: boolean;
}

interface SS {
  navigation: any;
}

export default class ContactsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    const view = this.props.navigation.getParam("view");
    if (view === "add") {
      localStorage.setItem("addContact", `${ContactsListViews.ADD_CONTACT}`);
    }

    this.state = {
      showPolicy: true,
    };
  }

  template = configJSON.template;

  closeModal = () => {
    this.setState({
      showPolicy: false,
    });
  };  
}
// Customizable Area End
