// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { AnalyticsPages } from "./enums";
import { TabsData } from "./interfaces";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  currentEngagementView: string;
  currentPage: AnalyticsPages;
  tabsData: TabsData;
  handleTabClick: (tabDataName: string) => void;
};

interface S {}

interface SS {
  navigation: any;
}

export default class AnalyticsTabsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getStyleValue = (
    condition: boolean,
    truthlyStyle: string,
    falsyStyle: string
  ) => {
    return condition ? truthlyStyle : falsyStyle;
  };
}
// Customizable Area End
