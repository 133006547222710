import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getToken } from "../../../components/src/AuthService";
import moment from "moment";
export const configJSON = require("./config");
interface AddHolidayResponse {
    data: {
        message: string;
        status: string;
    };
}

interface AllHolidayHour {
    id: number;
    account_id: number;
    holiday_name: string;
    start_date: string | null;
    end_date: string | null;
    created_at: string;
    updated_at: string;
    date: string;
    start_time: string;
    end_time: string;
}

interface AllHolidayHoursData {
    data: {
        holiday_hours: Array<AllHolidayHour>;
        after_hours_response: [];
    };
}

interface GetWorkingHour {
    id: number;
    account_id: number;
    day: number;
    active: boolean;
    start_time: string | null;
    end_time: string | null;
    created_at: string;
    updated_at: string;
    day_name: string;
}

interface GetWorkingHoursDataResponse {
    data: {
        working_hours: Array<GetWorkingHour>
        after_hours_response: [];
    };
}
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    switch: boolean;
    holidayModal: boolean;
    setToken: string;
    addHolidayData: AddHolidayResponse;
    allHolidayData: Array<AllHolidayHour>;
    getWorkingHoursData: Array<GetWorkingHour>;
    afterHoursResponse: any,
    holidayName: string;
    holidayDate: any;
    holidayStartTime: any;
    holidayEndTime: any;
    holidayDateErr: string;
    holidayEndTimeErr: string;
    holidayNameErr: string;
    holidayStartTimeErr: string;
    update_working_hours:
    any;
    isLoading: boolean;
    getHolidayHoursToggleData: boolean;
    isShowAlert: boolean;
    showSucess: string;
    openEditModal: boolean;
    anchorEl: any;
    endWaitlistModal: boolean;
    getHolidayHoursDetails: any
    holidayId: number;
    isEditMode:boolean;
    openConfirmationModal:boolean;
    disabledTimes:any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UpdateBusinessHoursController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    addHolidayCallId: string = "";
    allHolidayCallId: string = "";
    getWorkingHoursCallId: string = "";
    updateWorkingHoursId: string = "";
    getHolidayHoursId: string = "";
    setHolidayHoursId: string = "";
    getPerticularHolidayID: string = "";
    deleteHolidayId: string = "";
    updateHolidayCallId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];
        // Customizable Area End

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            showSucess: "",
            isShowAlert: false,
            getHolidayHoursToggleData: false,
            holidayModal: false,
            switch: true,
            addHolidayData: {
                data: {
                    message: "",
                    status: ""
                }
            },
            allHolidayData: [],
            getWorkingHoursData: [],
            afterHoursResponse: [],
            setToken: "",
            holidayName: "",
            holidayDate: null,
            holidayStartTime: new Date(),
            holidayEndTime:new Date(),
            disabledTimes:[],
            holidayDateErr: "",
            holidayEndTimeErr: "",
            holidayNameErr: "",
            holidayStartTimeErr: "",
            update_working_hours: [
                {
                    active: false,
                    start_time: "",
                    end_time: ""
                }
            ],
            isLoading: false,
            openEditModal: false,
            anchorEl: null,
            endWaitlistModal: false,
            getHolidayHoursDetails: {},
            holidayId: 0,
            isEditMode:false,
            openConfirmationModal:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        runEngine.debugLog("API Message Recived", message);
        switch (apiRequestCallId) {
        
            case this.addHolidayCallId:
             
               this.addHiolidaySuccessCallBack(responseJson)
              
              break;
              
              case this.allHolidayCallId:
              
               this.allHolidaySuccessCallBack(responseJson)
              
              break;
              
              case this.getWorkingHoursCallId:
              this.getWorkingHoursSuccessCallBack(responseJson)
              break;
              
               case this.updateWorkingHoursId:
              this.setState({ isShowAlert: true, showSucess: "Working Hours Updated Successfully" }, () => {
                        this.isShowCell();
                        this.getWorkingHours()
                    })
              break;
              
              case this.getHolidayHoursId:
             this.holidayHoursResponse(responseJson)
              break;
              
              case this.setHolidayHoursId:
            this.setState({ isShowAlert: true, showSucess: responseJson.success }, () => {
                        this.isShowCell();
                        this.holidayHours()
                    })
              break;
             
              case this.getPerticularHolidayID:
              this.getHolidayHoursResponse(responseJson)
              break;
              
               case this.deleteHolidayId:
               this.deleteHolidayResponse(responseJson)
              break;
              
               case this.updateHolidayCallId:
               this.updateHolidayResponse(responseJson)
              break;
             
                        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        let token = getToken();
        this.setState({ setToken: token }, () => {
            this.getWorkingHours()
            this.allHolidayList()
            this.holidayHours()
        })

    }

    openEndWaitListModal = () => {

        this.setState({ endWaitlistModal: !this.state.endWaitlistModal });
    };
    confirmationModal = () => {

        this.setState({ openConfirmationModal: !this.state.openConfirmationModal });
    };
    apiCallFunction = async (data: any) => {
        const { contentType, method, endPoint, body, type } = data
        const header = {
            "Content-Type": contentType,
            token: this.state.setToken,
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body && type != "formData" ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    addHoliday = async () => {
        let addHolidayBody = {
            holiday: {
                holiday_name: this.state.holidayName,
                date: this.state.holidayDate,
                start_time: new Date(this.state.holidayStartTime).toISOString(),
                end_time: new Date(this.state.holidayEndTime).toISOString()
            }
        }
        this.addHolidayCallId = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.postApiMethod,
            endPoint: configJSON.addHolidayEndPoint,
            body: addHolidayBody
        });
    };


    getDetailsOfHoliday = async () => {
        this.getPerticularHolidayID = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.getApiMethod,
            endPoint: configJSON.updateHolidayEndPoint + `/${this.state.holidayId}/get_holiday`
        });
    };


    deleteHlidayApi = async () => {
      
        this.deleteHolidayId = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.deleteApiMethod,
            endPoint: configJSON.updateHolidayEndPoint + `/${this.state.holidayId}/delete_holiday`
        });
    };

    deleteHolidayResponse = async (responseJson: any) => {
        if (responseJson) {
            this.setState({

                isShowAlert: true,
                showSucess: responseJson.message
            }, () => {
                this.isShowCell();
                this.openEndWaitListModal()
                this.allHolidayList()
            })
        }
    };

    updateHoliday = async () => {
        let addHolidayBody = {
            holiday: {
                holiday_name: this.state.holidayName,
                date: moment(this.state.holidayDate).format('YYYY-MM-DD'),
                start_time: new Date(this.state.holidayStartTime).toISOString(),
                end_time: new Date(this.state.holidayEndTime).toISOString()
            }
        }
        this.updateHolidayCallId = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.putApiMethod,
            endPoint:configJSON.updateHolidayEndPoint + `/${this.state.holidayId}/update_holiday`,
            body: addHolidayBody
        });
    };
    updateHolidayResponse = async (responseJson: any) => {
        if (responseJson) {
            this.setState({

                isShowAlert: true,
                showSucess: responseJson.message,
                
            }, () => {
                this.isShowCell();
                this.confirmationModal();
                this.closeHolidayModal()
                this.allHolidayList()
            })
        }
    };

    getHolidayHoursResponse = async (responseJson: any) => {
        if (responseJson) {
            this.setState({holidayName: responseJson.holiday.holiday_name, 
                holidayDate: responseJson.holiday.date, 
                holidayStartTime: responseJson.holiday.start_time, 
                holidayEndTime:responseJson.holiday.end_time})
        }

    }


    addHiolidaySuccessCallBack = (responseJson: AddHolidayResponse) => {
        this.setState({
            addHolidayData: responseJson, holidayModal: false, holidayDate: "",
            holidayEndTime: "",
            holidayName: "",
            holidayStartTime: "",
            holidayDateErr: "",
            holidayEndTimeErr: "",
            holidayNameErr: "",
            holidayStartTimeErr: "",
            isShowAlert: true,
            showSucess: responseJson.data.message
        }, () => {
            this.isShowCell();
            this.allHolidayList()
        })
    };

    allHolidayList = async () => {
        this.allHolidayCallId = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.getApiMethod,
            endPoint: configJSON.allHolidayEndPoint
        });
    };

    allHolidaySuccessCallBack = (responseJson: AllHolidayHoursData) => {
        this.setState({ allHolidayData: responseJson.data.holiday_hours })
    };

    holidayHours = async () => {
        this.getHolidayHoursId = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.getApiMethod,
            endPoint: configJSON.getHolidayHoursEndPoint
        });
    };

    holidayHoursResponse = (responseJson: any) => {
        this.setState({ getHolidayHoursToggleData: responseJson.holiday_hours_status })
    };

    setHolidayHours = async () => {
        let setHolidayHpursBody = {
            business_hour: {
                holiday_hours_status: this.state.getHolidayHoursToggleData,

            }
        }
        this.setHolidayHoursId = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.postApiMethod,
            endPoint: configJSON.setHolidayHoursEndPoint,
            body: setHolidayHpursBody
        });
    };

    getWorkingHours = async () => {
        this.getWorkingHoursCallId = await this.apiCallFunction({
            contentType: configJSON.contentType,
            method: configJSON.getApiMethod,
            endPoint: configJSON.getWorkingHoursEndPoint
        });
    };

    getWorkingHoursSuccessCallBack = (responseJson: GetWorkingHoursDataResponse) => {
        this.setState({
            getWorkingHoursData: responseJson.data.working_hours,
            afterHoursResponse: responseJson.data.after_hours_response,
            isLoading: false
        })


    };

    onHolidaySubmit = () => {
        
        const holidayNameRegex = /^[A-Za-z]+( [A-Za-z]+)*$/
        if (this.state.holidayName.trim().length === 0) {
            this.setState({ holidayNameErr: configJSON.blankError });
        } else if (!holidayNameRegex.test(this.state.holidayName)) {
            this.setState({ holidayNameErr: configJSON.holidayNameError });
        } else if (this.state.holidayDate.length === 0) {
            this.setState({ holidayDateErr: configJSON.blankError });
        } else {
            this.addHoliday();
        }
    };

    onHolidayNameChange = (event: any) => {
        
        this.setState({
            holidayName: event.target.value

        }, () => {

            const holidayNameRegex = /^[A-Za-z]+( [A-Za-z]+)*$/
            if (holidayNameRegex.test(this.state.holidayName)) {
                this.setState({
                    holidayNameErr: ""
                })
            } else {
                this.setState({
                    holidayNameErr: this.state.holidayName ? configJSON.holidayNameError : configJSON.blankError,
                })
            }
        })
    };

    onHolidayDateChange = (value: any) => {
        this.setState({ holidayDate: value, holidayDateErr: "" })
    };

    onStartTimeChange = (date: any) => {
      

        if (!(date instanceof Date)) {
            // Handle error, log it, or throw an exception
            console.error('Invalid date object passed.');
            return;
        }
    
        this.setState({
            holidayStartTime: date,
        });
    
        const options = [];
        const currentHour = date.getHours();
        const currentMinute = date.getMinutes();
        for (let hour = 0; hour <= currentHour; hour++) {
            const maxMinute = (hour === currentHour) ? currentMinute : 59;
            for (let minute = 0; minute <= maxMinute; minute++) {
                options.push(new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute));
            }
        }
    
        this.setState({ holidayEndTime: null, disabledTimes: options });
    };

    onEndTimeChange = (date: any) => {
                this.setState({
            holidayEndTime: date
        })
    };

    closeHolidayModal = () => {
        this.setState({ holidayModal: false })
    };

    holidayModalOpen = () => {
        this.setState({ holidayModal: true })
    };
    handleClick = (event: any, id: number) => {

        this.setState({ anchorEl: event.currentTarget, holidayId: id }, () => {
            this.getDetailsOfHoliday()
        });
    };


    handleClose = () => {
        this.setState({ anchorEl: null });
    };
   
    isShowCell = () => {
        setTimeout(() => this.setState({ isShowAlert: false }), 3000);
    }

    handleEditClick = (id: number) => {

    }

    handleChangeHolidayHoursToggle = () => {
        this.setState({ getHolidayHoursToggleData: !this.state.getHolidayHoursToggleData }, () => {
            this.setHolidayHours()
        });
        // You can perform additional actions here based on the new state
    };

    handleChange = (newData: any, type: any) => {
        this.setState(
            (prevState) => {
                const updatedWorkingHoursData = prevState.getWorkingHoursData.map((hour) => {
                    if (hour.id === newData.id) {
                        return {
                            ...hour,
                            active: type === 'switch' ? !hour.active : hour.active,
                            start_time: type === 'start' ? newData.time : hour.start_time,
                            end_time: type === 'end' ? newData.time : hour.end_time,
                        };
                    }
                    return hour;
                });

                return {
                    getWorkingHoursData: updatedWorkingHoursData,
                };
            },
            () => {
                const workingHours = this.state.getWorkingHoursData.reduce((result: any, item: any, index: number) => {
                    // Extract necessary properties
                    const { active, start_time, end_time } = item;

                    // Create the new object structure
                    result[index + 1] = {
                        active: active || false, // Set default value to false if not provided
                        start_time: start_time ? moment(start_time) : null,
                        end_time: end_time ? moment(end_time) : null,
                    };

                    return result;
                }, {});
                this.updateWorkingHours(workingHours)
            }
        );

    };
    updateWorkingHours = async (workingHours: any) => {
        this.setState({ isLoading: true })
        const header = {
            'Content-Type': configJSON.contentType,
            token: this.state.setToken,
        }
        const body = {
            working_hours: workingHours
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.updateWorkingHoursId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateWorkingHoursEndPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }
    // Customizable Area End
}
