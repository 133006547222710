import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FormControl from "@material-ui/core/FormControl";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import PasswordCreateController from "./PasswordCreateController.web";
import EmailAccountLoginErrorBlock from "./EmailAccountRegistrationErrorBlock.web";
import { withStyles } from "@material-ui/core/styles";
import { configJSON } from "./BusinessPhoneVerificationController.web";
interface Props {
  navigation: any;
  id: string;
  handleSuccessResponse: (data: any) => void;
  handleGoBackClick: () => void;
}
const styles = (theme: any) => ({
  nextHomePage: {
    backgroundColor: "#FFC629",
    marginTop: "37px",
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "56",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "black",
    },
  },
  passwordCretireia: {
    fontSize: "16px",
    fontWeight: 400,
  },
  checkCircleIcon: {
    color: "rgb(148, 163, 184)",
    fontSize: "15px",
    marginRight: "4px",
  },
  EmailSubtitle: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "16px",
    margin: "33px 0px",
  },
  BackHomePage: {
    color: "#fff",
    marginTop: "45px",
    backgroundColor: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "56px",
    "&:hover": {
      backgroundColor: "#0F172A",
      color: "#fff",
    },
  },
  passwordTitle: {
    fontSize: "24px",
    fontWeight: 700,
  },
  ulItems: {
    margin: "0",
    listStyleType: "none",
    padding: "0",
  },
  liItems: {
    display: "flex",
    margin: "0px",
    padding: "0px",
  },
  marginCommon: {
    marginBottom: "7px",
  },
  passwordField: {
    width: "360px",

    "& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFC629",
    },
  },
  passwordVisibilityIcon: {
    color: "#94A3B8",
  },
  marginTextField: {
    marginTop: "7px",
    fontWeight: 700,
    color: "#334155",
    fontSize: "16px",
  },
  formContainerSecond: {
    display: "flex",
    maxWidth: "360px",
    marginTop: "80px",
    "& *": {
      fontFamily: "Inter, 'sans-serif'"
    }
  },
});
const webStyle = {
  checkCircleIcon: {
    color: "#94A3B8",
    fontSize: "15px",
    marginRight: "4px",
  },
};
class PasswordCreate extends PasswordCreateController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes }: any = this.props;
    const {
      businessPassword,
      businessConfirmPassword,
      showPassword,
      showConfirmPassword,
      hasCapitalLetter,
      hasLowerCaseLetter,
      hasNumber,
      hasMinLength,
      formError,
      formGlobalError,
      currentStep,
    } = this.state;
    return (
      <Box className={classes.formContainerSecond} id="passwordCreateContainer">
        <Box className={classes.passwordField}>
          {formGlobalError[currentStep] && (
            <EmailAccountLoginErrorBlock
              errorMsg={formGlobalError[currentStep]}
            />
          )}
          <Typography
            variant="h5"
            gutterBottom
            className={classes.passwordTitle}
          >
            {configJSON.createPassword}
          </Typography>

          <Typography
            variant="subtitle2"
            className={classes.EmailSubtitle}
            gutterBottom
          >
            {configJSON.enterPasswordFor}
            {this.emailAddressValue}
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.marginTextField}
          >
            {configJSON.newPassword}
          </Typography>
          <FormControl size="small" className={classes.marginCommon}>
            <TextField
              placeholder={configJSON.placeholderNewPw}
              name="businessPassword"
              size="small"
              className={classes.passwordField}
              value={businessPassword}
              id="firstPasswordInput"
              error={Boolean(formError["businessPassword"])}
              variant="outlined"
              inputProps={{ maxLength: 12 }}
              helperText={formError["businessPassword"]}
              type={showPassword ? "text" : "password"}
              onChange={(e) => this.handleFieldChange(e)}
              data-test-id="inputPassword"
              InputProps={{
                style: {
                  height: "56px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={this.togglePasswordVisibility}
                      data-test-id="tooglebtn"
                      id="toggleFirstPasswordVisibility"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          className={classes.passwordVisibilityIcon}
                          data-test-id="tooglebtn"
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          className={classes.passwordVisibilityIcon}
                          data-test-id="tooglebtn"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Typography
            variant="subtitle2"
            className={classes.marginTextField}
            gutterBottom
          >
            {configJSON.newconpasswprd}
          </Typography>
          <FormControl className={classes.marginCommon} size="small">
            <TextField
              size="small"
              error={Boolean(formError["businessConfirmPassword"])}
              id="secondPasswordInput"
              name="businessConfirmPassword"
              variant="outlined"
              className={classes.passwordField}
              value={businessConfirmPassword}
              inputProps={{ maxLength: 12 }}
              helperText={formError["businessConfirmPassword"]}
              onChange={(e) => this.handleFieldChange(e)}
              placeholder={configJSON.placeholderCofirmPw}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                style: {
                  height: "56px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle confirm password visibility"
                      onClick={this.toggleConfirmPasswordVisibility}
                      data-test-id="tooglebtn2"
                      id="toggleSecondPasswordVisibility"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOutlinedIcon
                          className={classes.passwordVisibilityIcon}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          className={classes.passwordVisibilityIcon}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              data-test-id="inputConfirmpassword"
            />
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.passwordCretireia}
            >
              Your password must contain
            </Typography>
            <ul className={classes.ulItems}>
              <li className={classes.liItems}>
                <CheckCircleIcon
                  style={this.getCheckCircleIconStyle(
                    hasCapitalLetter,
                    webStyle.checkCircleIcon
                  )}
                />{" "}
                <Typography variant="caption" display="block" gutterBottom>
                  {configJSON.oneCapitalLetter}
                </Typography>
              </li>
              <li className={classes.liItems}>
                <CheckCircleIcon
                  style={this.getCheckCircleIconStyle(
                    hasLowerCaseLetter,
                    webStyle.checkCircleIcon
                  )}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  {configJSON.lowercaseletter}
                </Typography>
              </li>
              <li className={classes.liItems}>
                <CheckCircleIcon
                  style={this.getCheckCircleIconStyle(
                    hasNumber,
                    webStyle.checkCircleIcon
                  )}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  {configJSON.oneNumber}
                </Typography>
              </li>
              <li className={classes.liItems}>
                <CheckCircleIcon
                  style={this.getCheckCircleIconStyle(
                    hasMinLength,
                    webStyle.checkCircleIcon
                  )}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  {configJSON.minCharacter}
                </Typography>
              </li>
            </ul>
          </Box>

          <Box>
            <Button
              variant="contained"
              onClick={this.handleNextClick}
              className={classes.nextHomePage}
              id="nextButton"
              data-test-id="nextButton"
            >
              {configJSON.next}
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={this.props.handleGoBackClick}
              id="backButton"
              data-test-id="goToBack"
              className={classes.BackHomePage}
            >
              {configJSON.back}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}
export { PasswordCreate };
export default withStyles(styles)(PasswordCreate);
// Customizable Area End
