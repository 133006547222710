// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { Message } from '../../../framework/src/Message';
import { runEngine } from '../../../framework/src/RunEngine';
import { setToken } from '../../../components/src/AuthService';

export const configJSON = require('./config.js');

export type Props = {
  navigation?: any;
  id?: string;
  classes?: any;
};
interface S {
  showPassword: boolean;
  loginError: string;
  rememberMe: boolean;
}
interface SS {
  id: any;
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  emailLoginApiCallId: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      showPassword: false,
      loginError: '',
      rememberMe: true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    localStorage.setItem("rememberMe", "true")
  }

  handleRememberMe = () => {
    this.setState((prevState) => {
      if(!this.state.rememberMe) {
        localStorage.setItem("rememberMe", "true")
      } else {
        localStorage.removeItem("rememberMe")
      }

      return {rememberMe: !prevState.rememberMe}
    })        
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.emailLoginApiCallId) {
      this.handleLoginAPIResponse(message);
    }
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  doEmailLogIn(formData: { email: string; password: string }): boolean {
    const requestHeaders = {
      'Content-Type': configJSON.loginApiContentType,
    };

    const requestBody = {
      data: {
        type: 'email_account',
        attributes: {
          email: formData.email,
          password: formData.password,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailLoginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userLoginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeaders)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleLoginAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.errors) {
      this.setState({ loginError: responseJson.errors[0].failed_login || responseJson.errors[0].account })
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      setToken(responseJson.meta.token);
      document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: true }));
    history.pushState("","","/Dashboard")
      
    }
  };

  navigate = (path: string) => {
    if(this.props.navigation) {
      this.props.navigation.navigate(path);
    }   
  };
}
// Customizable Area End
