import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button, makeStyles, Container } from "@material-ui/core";


interface Props {
  handleSubmit: (stripe: any, elements: any) => Promise<void> | undefined;
  handleBackButton: () => Promise<void> | undefined;
 
}

const useStyles = makeStyles(() => ({
  submitButton: {
    backgroundColor: "#FFC629",
    marginTop: "37px",
    width: "100%",
    maxWidth: "358px",
    height: "56px",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#FFC629",
      color: "black",
    },
    "& a": {
      opacity: 1,
      fontSize: "16px",
      color: "black",
      textDecoration: "none",
    },
  },

  backButton: {
    backgroundColor: "#000",

     color:"#fff",
    marginTop: "37px",
    width: "100%",
    maxWidth: "358px",
    height: "56px",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
    "& a": {
      opacity: 1,
      fontSize: "16px",
      color: "#fff",
      textDecoration: "none",
    },
  },
  formContainer: {
    display: "flex",
    maxWidth: "360px",
  },
  formContainere: {
    width: "100%",
    marginLeft: "-21px",
  },
}));

const StripeFoeWaitListPayment = ({ handleSubmit,handleBackButton }: Props) => {
  const stripeFormStyles = useStyles();
  const stripeForm = useStripe();
  const stripelElements = useElements();
 
  return (
    <Container className={stripeFormStyles.formContainere} data-test-id="waitListPayemntForm">
  
        <PaymentElement id="card" />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            data-test-id="submitButton"
            variant="contained"
            fullWidth
            type="button"
            onClick={()=>handleSubmit(stripeForm, stripelElements)}
            style={{ textTransform: "none" }}
            disabled={!stripeForm}
            className={stripeFormStyles.submitButton}
          >
            Submit
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            data-test-id="submitButton"
            variant="contained"
            fullWidth
            type="button"
            onClick={()=>handleBackButton()}
            style={{ textTransform: "none" }}
            disabled={!stripeForm}
            className={stripeFormStyles.backButton}
          >
            Back
          </Button>
        </div>
      
    </Container>
  );
};

export default StripeFoeWaitListPayment;
