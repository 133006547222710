import React from 'react';
import NavigationMenuEUWeb from '../../../components/src/NavigationMenuEU.web';
import { Box, Button, Snackbar, Typography } from '@material-ui/core';
import TopActiveUsers from '../../dashboard/src/TopActiverUsers.web';
import SuggestedBrands from '../../../components/src/SuggestedBrands';
import NotificationsController from './NotificationsController';
import { likeIcon, commentIcon } from './assets';
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import { CheckOutlined, MoreVertRounded } from "@material-ui/icons";
import { IconAvtar } from "../../contactlist/src/assets";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MuiAlert from "@material-ui/lab/Alert";


export default class EuNotifications extends NotificationsController {
  render() {
    const { navigation } = this.props

    return (
      <div style={webStyle.root}>
        <NavigationMenuEUWeb brands={this.state.brandsIFollowNotifiacation}/>
        <Snackbar
          open={this.state.euisShowAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert elevation={6} variant="filled" severity={"success"}>
            {this.state.eushowSuccess}
          </MuiAlert>
        </Snackbar>

        <Box style={webStyle.mainContainer}>
          <Box style={webStyle.contentContainer}>
            <Box style={webStyle.notificationContainer}>
              <Box style={{ borderBottom: "1px solid rgb(226, 232, 240)", paddingBottom: "16px" }}>
                {
                  this.state.EuFilters.map((notify: any) => (
                    <>
                      <Button style={webStyle.notifyButton}
                        data-test-id="notificationcall"
                        onClick={() => this.EUnotificationApiCall(notify.block)}>
                        {notify.name === "Like" && (
                          <img
                            src={likeIcon}
                            alt="priorityIcon"
                            style={{
                              width: "21.23px",
                              height: "18px",
                            }}
                          />
                        )}
                        {notify.name === "Comments" && (
                          <img
                            src={commentIcon}
                            alt="priorityIcon"
                            style={{
                              width: "21.23px",
                              height: "18px",
                            }}
                          />
                        )}
                        {notify.name === "Followers" && (
                          <NotificationsOutlinedIcon />
                        )}
                        {notify.name === "Mark all as read" && (
                          <Box
                          data-test-id="markasreadapi" 
                          onClick={() => this.EUmarkAllAsReadApiCall()}>
                            <CheckOutlined />
                          </Box>

                        )}

                        <Typography>
                          {notify.name}

                        </Typography>
                      </Button>
                    </>
                  ))
                }
              </Box>
              <Box>
                {this.state.EunotificationData && this.state.EunotificationData.map((e: any, index) => (
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "16px 0 32px 8px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                    backgroundColor: this.GetcolorByIndex(index)
                  }}
                  >
                    <Box style={{ display: "flex", gap: "24px" }}>
                      <img
                        src={
                          e.attributes.created_by?.data?.attributes?.profile_pic?.url || IconAvtar
                        }
                        alt=""
                        style={{
                          borderRadius: "70px",
                          border: "1px solid"
                        }}
                        width={"44px"}
                        height={"44px"} />
                      <Box>
                        <Box style={{ display: "flex", gap: "6px" }}>
                          <Typography style={webStyle.notifheading}>
                            {e.attributes.account.first_name}{" "}{e.attributes.account.last_name}
                          </Typography>
                          <Box>
                            {e.attributes.is_read &&
                              <FiberManualRecordIcon
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "10px",
                                  fontSize: "12px",
                                  margin: "6px 5px 0 0",
                                  color: "#000",
                                  background: "#0F172A",

                                }} />}
                          </Box>
                        </Box>

                        <Typography
                          style={webStyle.notifdescription}>
                          {e.attributes.contents}
                        </Typography>
                      </Box>

                    </Box>
                    <Box>

                      <div style={{
                        cursor: "pointer"
                      }}>
                        <MoreVertRounded />
                      </div>
                      <Typography style={webStyle.timeText}>
                        {this.timeSince(e.attributes.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box style={webStyle.sideContainer}>
              <div>
                <TopActiveUsers
                  navigation={navigation}
                  top6={this.state.top6}
                />
              </div>
              <div>
                <SuggestedBrands
                  brands={this.state.suggestedBrandsNotifiacation}
                  followBrand={this.followBrandNotificationApi}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}


const webStyle = {
  root: {
    backgroundColor: "#f1f4f9",
  },
  mainContainer: {
    width: "100vw",
    maxWidth: "1240px",
    margin: "15px auto",
    backgroundColor: "#f1f4f9",
  },
  contentContainer: {
    display: "flex",
    gap: "15px",
    justifyContent: "center"
  },
  notificationContainer: {
    width: "70%",
    backgroundColor: "#FFFFFF",
    overflowy: "auto",
    padding: "32px 17px",
  },
  sideContainer: {
    width: "30%",
    backgroundColor: "#FFFFFF",
    maxHeight: "700px"
  },
  notifyButton: {
    height: "32px",
    borderRadius: "26px",
    padding: "6px 12px 6px 8px",
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "capitalize",
    background: "#F8FAFC",
    margin: "5px"

  } as React.CSSProperties,
  timeText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#64748B",
    marginTop: "12px"
  },
  notifheading: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    marginBottom: "4px",
  },
  notifdescription: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  }

}
