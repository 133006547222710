import React from 'react';
// Customizable Area Start
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DashboardController, { Props } from './DashboardController.web';
import { Button, Paper, Typography } from '@material-ui/core';
import { stepImageFirst } from './assets';
import { Link } from "react-router-dom"

const styles = (theme: Theme) =>
  createStyles({

    root: {
      padding: "28px 19px",
      background: "#FFC629",
      margin: "35px 32px 0 32px",
      opacity: 1
    },
    actions: {

      transform: "translateY(-50%)",

      flexDirection: "column",
      justifyContent: "center"
    },
   



  });

class PausedAccount extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;



    return (
      <>
    {this.state.isPaused &&
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Paper square elevation={0} style={{
              padding: "28px 19px",
              background: "#FFC629",
              margin: "0px 32px 20px 20px",
              opacity: 1,
              maxWidth:"833px",
              width:"100%",
            }}>
              <div
                style={{ display: "flex" }}
                data-test-id="first">
                <div id="fisrtContact" style={{
                  marginRight: "17px",
                  paddingTop: "12px",
                  width: "91px"
                }}>
                  <img
                    src={stepImageFirst}
                    alt="simeimag"
                    style={{ height: "64.84px", width: "64.84px" }}
                  />
                </div>
                <div style={{width:"70%"}}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{
                      fontStyle: "normal",
                      fontFamily: "Inter",
                      fontSize: "30px",
                      fontWeight: 800
                    }}>Your subscription is paused.</Typography>
                    <span className={classes.bullet}></span>
                  </div>
                  <Typography style={{
                     fontStyle: "normal",
                     fontFamily: "Inter",
                     fontSize: "16px",
                     fontWeight: 400,
                     marginTop: "15px"
                  }} >To unlock features, please resume your subscription</Typography>


                </div>
                <div>
                  <div style={{ marginTop: "25px" }}>
                    <Link to={`/settings/${this.state.account?.attributes.subscription_paused}`} style={{ textDecoration: "none" }}>
                      <Button

                        style={{
                          padding: "6px 10px",
                          borderRadius: "16px",
                          background: "#000",
                          color: "#fff",
                          fontWeight: 700,
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontSize: "15px",
                          textTransform: "none",
                          width: "214px",
                          cursor: "pointer"

                        }}>
                        Update my subscription
                      </Button>
                    </Link>
                  </div>

                </div>

              </div>
            </Paper>

          </Grid>
                  </Grid>
}
        </>
    );
  }
}

export { PausedAccount };
export default withStyles(styles)(PausedAccount);
// Customizable Area End
