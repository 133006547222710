// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormControl from '@material-ui/core/FormControl';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// Customizable Area End

// Customizable Area Start
const webStyle = {
  EmailSubtitle: {
    margin: "15px 0px",
    color: "#0F172A",
  },
  nextHomePage: {
    backgroundColor: "#FFC629",
    marginTop: '25px',
    width: "100%"
  },
  BackHomePage: {
    backgroundColor: "#0F172A",
    color: "#fff",
    marginTop: '25px',
    width: "100%"
  },
  fontWeight: {
    fontWeight: 900
  },
  marginCommon: {
    marginBottom: "7px"
  },
  marginTextField: {
    marginTop: "5px"
  },
  ulItems: {
    margin: "0",
    padding: "0",
    listStyleType: "none",

  },
  liItems: {
    display: "flex",
    margin: "0px",
    padding: "0px",
  },
  checkCircleIcon: {
    fontSize: "15px",
    color: "#94A3B8",
    marginRight: "4px"
  },
};
// Customizable Area End

// Customizable Area Start
const PasswordCreate = (props: any) => {
  return (

    <Box >
      <Box>
        <Typography variant="h5" gutterBottom  >
          Create a Password
        </Typography>
       
        <Typography variant="subtitle2" gutterBottom style={webStyle.EmailSubtitle} >
          Enter a  password for {props?.emailAddress}
        </Typography>
        <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>
          New Password
        </Typography>
        <FormControl style={webStyle.marginCommon} size="small">
          <TextField
            id="outlined-adornment-password"
            placeholder="Your new password"
            name="businessPassword"
            size="small"
            value={props.businessPassword}
            error={Boolean(props.errorMsg["businessPassword"])}
            helperText={props.errorMsg["businessPassword"]}
            type={props.showPassword ? 'text' : 'password'}
            onChange={(e) => props.handleFieldChange(e)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={props.togglePasswordVisibility}
                    data-test-id="tooglebtn"
                  >
                    {props.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            data-test-id="inputPassword"
          />
        </FormControl>


        <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>
          Confirm New Password
        </Typography>
        <FormControl style={webStyle.marginCommon} size="small">
          <TextField
            id="outlined-adornment-password"
            placeholder="Confirm your new password"
            type={props.showConfirmPassword ? 'text' : 'password'}
            name="businessConfirmPassword"
            size="small"
            variant="outlined"
            value={props.businessConfirmPassword}
            error={Boolean(props.errorMsg["businessConfirmPassword"])}
            helperText={props.errorMsg["businessConfirmPassword"]}
            onChange={(e) => props.handleFieldChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    edge="end"
                    onClick={props.toggleConfirmPasswordVisibility}
                  >
                    {props.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            data-test-id="inputConfirmpassword"
          />
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" gutterBottom style={webStyle.marginTextField}>
            Your password must contain
          </Typography>
          <ul style={webStyle.ulItems}>
            <li style={webStyle.liItems}><CheckCircleIcon style={props.getCheckCircleIconStyle(props.hasCapitalLetter, webStyle.checkCircleIcon)} />  <Typography variant="caption" display="block" gutterBottom>
              At least one capital letter
            </Typography></li>
            <li style={webStyle.liItems}><CheckCircleIcon style={props.getCheckCircleIconStyle(props.hasLowerCaseLetter, webStyle.checkCircleIcon)} /><Typography variant="caption" display="block" gutterBottom>
              At least one lowercase letter
            </Typography></li>
            <li style={webStyle.liItems}><CheckCircleIcon style={props.getCheckCircleIconStyle(props.hasNumber, webStyle.checkCircleIcon)} /><Typography variant="caption" display="block" gutterBottom>
              At least one number
            </Typography></li>
            <li style={webStyle.liItems}><CheckCircleIcon style={props.getCheckCircleIconStyle(props.hasMinLength, webStyle.checkCircleIcon)} /><Typography variant="caption" display="block" gutterBottom>
              Minimum character length is 8 characters
            </Typography></li>

          </ul>
        </Box>

        <Box><Button variant="contained" onClick={props.handleSubmit} style={webStyle.nextHomePage}>Next</Button></Box>
        <Box><Button variant="contained" onClick={() => props.goToBack(1)} data-test-id="goToBack" style={webStyle.BackHomePage}>Back</Button></Box>

      </Box>
    </Box>
  );
}

export default PasswordCreate;
// Customizable Area End

