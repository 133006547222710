// Customizable Area Start
export enum Settings {
  GettingStarted,
  BillingInfo,
  UpdateBusinessContactInfo,
  CustomizePostingFeed,
  CustomizeEveryreelBtn,
  SocialIntegrations,
  UpdateBusinessHours,
  Websites,
  TeamMates,
  UpdateSubscription,
  AutomationTemplates,
  SocialPhoneNumber,
  NotificationPreferences,
  ConnectWebsite
}
// Customizable Area End
