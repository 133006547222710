Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
//account_block/accounts/check_email
exports.signupEmailApiContentType = "application/json";
exports.signupEmailVerificationEndpoint = "account_block/accounts/check_email";
exports.SignUpEmailEndpoint="account_block/accounts"
exports.SignUPSendOtpEndpoint="account_block/accounts/resent_otp"
exports.SignUPVerifyOtpEndpoint="account_block/accounts/verify_otp"
exports.signupVerificationAPiMethod = "GET";
exports.signupVerifyOtpAPiMethod = "GET";
exports.signupSendOtpAPiMethod = "GET";
exports.signupAPiMethod = "POST";
exports.signupAPiDetailMethod = "PUT";
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.orLabel = "or"
exports.labelTitle = "Log in";
exports.signUpLabel = "Sign Up"
exports.DEFAULT_COUNTRY_NAME = "us"
exports.enterMobileNumber = "Enter Your Mobile Number"
exports.mobileNumberLabel = "Mobile number"
exports.businessLoginLabel = "Business Log in"
exports.checkPhoneNumberError = "Please check your phone number and try again."
exports.doNotHaveAccountMessage = "Don't have an account?"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.userLoginAPIEndPoint = "bx_block_login/logins";
exports.postApiMethod = "POST";

exports.phoneLoginAPIEndPoint = "account_block/accounts/send_otps";
exports.otpApiEndPoint = "account_block/accounts/sms_confirmations";
exports.endUserLoginEndPoint = "/bx_block_login/logins/sms_login"

exports.termsAndConditionsText = "By continuing you agree to our terms of service, privacy policy, and community guidelines"
exports.termsAndConditionsErrorMessage = "You must agree to the Privacy Policy and Terms and Conditions"
// Customizable Area End