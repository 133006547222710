// Customizable Area Start
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { EverybrandIcon } from './assets';
import { gettingStartedGuidelines } from './constants';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
     
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
      },
    },
    sectionHeading: {
      fontWeight: 700,
      color: '#0F172A',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
    },
    sectionIntro: {
      color: '#000000',
      fontWeight: 600,
      lineHeight: '22px',
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(4),
      },
    },
    listItem: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(5),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(4),
      },
    },
    listItemIcon: {
      width: '56px',
      height: '46px',
      borderRadius: theme.spacing(1),
      backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
    },
    typography: {
      fontSize: '14px',
      color: '#000000',
    },
    fontBold: {
      fontWeight: 700,
    },
  })
);

function GettingStarted() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1.5}
        mb={4.5}
      >
        <Typography className={classes.sectionHeading}>
          Getting Started
        </Typography>
        <img style={{marginTop:"-12px"}}src={EverybrandIcon} />
      </Box>
      <Box mb={4.5}>
        <Typography
          className={classes.sectionIntro}
          data-test-id="sectionIntro"
        >
          Welcome to Everybrand! Getting started is easy and we're here to guide
          you through the process step by step.
        </Typography>
      </Box>
      <ul className={classes.list} data-test-id="guidelines">
        {gettingStartedGuidelines.map((guideline, index) => (
          <li key={index} className={classes.listItem}>
            <div className={classes.listItemIcon}>
              <img src={guideline.icon} />
            </div>
            <div>
              <Typography component="span" className={classes.typography}>
                <b>{guideline.heading}:</b>&nbsp;
              </Typography>
              <Typography component="span" className={classes.typography}>
                {guideline.message}
              </Typography>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GettingStarted;
// Customizable Area End
