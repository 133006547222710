// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { InviteFriendsStepType } from "./enums";
import { Contact, NormalizedContact } from "./InviteFriendsController.web";

export const configJSON = require("./config");

export type Props = {
  contacts: NormalizedContact[];
  inviteMessage: string;
  link: string;
  setInviteMessage: (message: string) => void;
  setLink: (link: string) => void;
  setStep: (step: InviteFriendsStepType) => void;
  closeModal: () => void;
  handleSubmitInvite: () => void;
  checkContact: (contactId?: number[]) => void;
  classes?: any;
};

interface S {
  selectedContact: Contact | null;
  error: string;
}

interface SS {
  navigation: any;
  stepType: InviteFriendsStepType;
}

export default class InviteFriendsModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  labelInviteFriendToEverybrand = configJSON.labelInviteFriendToEverybrand;
  inviteFriendDescriptionText = configJSON.inviteFriendDescriptionText;
  bulkInviteProposal = configJSON.bulkInviteProposal;
  bulkInviteLink = configJSON.bulkInviteLink;
  addContactQuestion = configJSON.addContactQuestion;
  addContactLink = configJSON.addContactLink;
  sendTo = configJSON.sendTo;
  sendInvite = configJSON.sendInvite;
  templatePreview = configJSON.templatePreview;
  tryOtherAnswers = configJSON.tryOtherAnswers;
  automationsTemplates = configJSON.automationsTemplates;
  autolink = configJSON.autolink;

  validate = (values: {
    selectedContacts: number[];
    invite_message: string;
    link: string;
  }) => {
    const errors: {
      selectedContacts?: string;
      invite_message?: string;
      link?: string;
    } = {};

    if (!values.selectedContacts || !values.selectedContacts.length) {
      errors.selectedContacts = "Required";
    }

    if (!values.invite_message) {
      errors.invite_message = "Required";
    }

    if (!values.link) {
      errors.link = "Required";
    }

    return errors;
  };
}
// Customizable Area End
