// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Typography,
  Container,
  Switch,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import StyledRadioBtn from "./StyledRadioBtn.web";
import {
  PaymentPlan,
  PlanPricing,
  configJSON,
} from "./EmailAccountRegistrationController.web";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import PlanPaymentController from "./PlanPaymentController.web";
interface Props {
  setSelectedPlan: (plan: any) => void;
}

const webStyles: Record<string, CSSProperties> = {
  mainContainerForm: {
    display: "flex",
    width: "1018px",
    gap: "41px",

    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  fromGrid: {
    width: "100%",
    height: "fit-content",
    marginRight: "15px",
    marginTop: "25px",
  },
  radioGroupBlock: {
    display: "flex",
    justifyContent: "center",
    padding: "16px 0 24px",
  },
  strikethrougnBlock: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginRight: "8px",
    marginBottom: "2px",
    paddingLeft: "7px",
    fontSize: "20px",
    fontWeight: 700,
    color: "#CBD5E1",
  },
  radioButtons: {
    display: "flex",
  },

  planPaymentBlock: {
    display: "flex",
    justifyContent: "center",
  },

  paymentPlan: {
    display: "flex",
    flexDirection: "column",
    width: "312px",
    border: "1px solid #ABB0BC",
    borderRadius: "20px",
    color: "#475569",
    padding: "20px 15px 25px",
    marginBottom: "20px",
  },

  descriptionList: {
    paddingInlineStart: "20px",
    marginBlockStart: 0,
  },

  descriptionText: {
    color: "#475569",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19.5px",
  },

  hideMark: {
    listStyleType: "none",
    paddingInlineStart: "20px",
  },

  standardPlan: {
    border: "6px solid #000000",
  },

  titleText: {
    color: "#0F172A",
    fontWeight: 700,
    marginLeft: "17px",
    marginBottom: "37px",
  },

  priceBlock: {
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: "15px",
    marginBottom: "30px",
    flexWrap: "wrap",
  },

  strikethroughLine: {
    position: "absolute",
    width: "100%",
    top: "45%",
    height: "3px",
    backgroundColor: "#CBD5E1",
  },

  oldPrice: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "25px",
  },

  price: {
    display: "flex",
    alignItems: "flex-end",
    fontSize: "20px",
    fontWeight: 700,
    color: "#000",
    wordBreak: "normal",
  },

  extraSize: {
    fontSize: "36px",
    lineHeight: "36px",
  },

  priceAttributes: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "3px",
  },

  priceCurrency: {
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "14px",
    letterSpacing: "-0.12px",
  },

  priceInterval: {
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: 700,
  },

  advantageList: {
    flexGrow: 1,
    marginBottom: "30px",
  },

  advantageItem: {
    marginBottom: "28px",
  },

  permissionText: {
    marginBottom: "21px",
    color: "#475569",
    fontFamily: "inherit",
    fontSize: "14px",
    fontStyle: "inherit",
    fontWeight: 400,
    lineHeight: "19.5px",
  },

  selectBtn: {
    fontWeight: 700,
    backgroundColor: "#FFC629",
    textTransform: "capitalize",
  },

  divider: {
    width: "1px",
    height: "100%",
    backgroundColor: "#C0C4CD",
    marginRight: "26px",
  },

  discountText: {
    color: "#0A66C2",
  },

  infoButton: {
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center",
    width: "18px",
    height: "18px",
    padding: "2px",
    marginLeft: "auto",
    borderRadius: "100%",
    backgroundColor: "#FFC629",
  },

  infoText: {
    color: "#475569",
    fontSize: "14px",
    fontWeight: 900,
  },

  tooltipRegular: {
    color: "#475569",
    fontSize: "14px",
  },

  tooltipBold: {
    color: "#475569",
    fontSize: "14px",
    fontWeight: 700,
  },
};

const styles = (theme: Theme) =>
  createStyles({
    formLabel: {
      borderRadius: "5px",
      padding: "5px 25px 5px 16px",
    },
    formLabelActive: {
      backgroundColor: "#D9D9D9",
    },

    platformManagementContainer: {
      display: "flex",
      alignItems: "center",
      paddingBottom: "30px",
      gap: "5px",
    },

    infoContainer: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
    },

    tooltip: {
      display: "none",
      position: "absolute",
      bottom: "28px",
      left: "8px",
      width: "236px",
      padding: "15px 12px",
      borderRadius: "20px 20px 20px 0",
      backgroundColor: "#FFC629",
      color: "#475569",
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "14.5px",
    },

    tooltipText: {
      "& + span": {
        fontWeight: 700,
      },
    },

    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "18px",
      height: "18px",
      borderRadius: "50%",
      backgroundColor: "#475569",

      "&:hover + *": {
        display: "block",
      },

      "& > *": {
        width: "22px",
        height: "22px",
      },
    },

    infoIcon: {
      "&.MuiSvgIcon-root": {
        fill: "#FFC629",
        borderRadius: "50%",
      },
    },

    platformManagementDescription: {
      color: "#475569",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "19.5px",
    },

    platformManagementSwitch: {
      width: "60px",
      height: "38px",
      padding: "5px",

      "& .MuiSwitch-thumb": {
        width: "26px",
        height: "26px",
        color: "#FFF",
      },

      "& .MuiSwitch-track": {
        border: "1px solid #D1D3D9",
        borderRadius: "14px",
      },

      "& .MuiSwitch-switchBase": {
        padding: "7px",
      },

      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "#FFF",
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#FFC629",
        opacity: 1,
      },
    },
  });

class PlanPayment extends PlanPaymentController {
  render() {
    const {
      classes,
      platformManagementChecked,
      setPlatformManagement,
      setSelectedPricing,
      selectedPricing,
      paymentPlanData,
      platformManagementPaymentPlans,
    }: any = this.props;
    const { activeBoxIndex } = this.state;

    return (
      <>
        <Box style={webStyles.radioGroupBlock}>
          <RadioGroup
            data-test-id="planpaymentradio"
            row
            style={webStyles.radioButtons}
            name="plan"
            value={selectedPricing}
            onChange={(_, value) => setSelectedPricing(value as PlanPricing)}
          >
            <FormControlLabel
              data-test-id="annualPlan"
              classes={{
                root: `${classes.formLabel} ${selectedPricing ===
                  PlanPricing.Annual && classes.formLabelActive}`,
              }}
              value={PlanPricing.Annual}
              control={<StyledRadioBtn />}
              label={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography style={webStyles.discountText}>
                    <b>{configJSON.tenPercentSave}</b>
                  </Typography>
                  <Typography>
                    <b>{configJSON.annualPricing}</b>
                  </Typography>
                </Box>
              }
            />
            <Box style={webStyles.divider} />
            <FormControlLabel
              data-test-id="monthPlan"
              classes={{
                root: `${classes.formLabel} ${selectedPricing ===
                  PlanPricing.Month && classes.formLabelActive}`,
              }}
              value={PlanPricing.Month}
              control={<StyledRadioBtn />}
              label={
                <Typography>
                  <b>{configJSON.monthlyPricing}</b>
                </Typography>
              }
            />
          </RadioGroup>
        </Box>

        <Box style={webStyles.planPaymentBlock}>
          <Container style={webStyles.mainContainerForm}>
            {paymentPlanData?.map((paymentPlan: PaymentPlan, index: number) => (
              <Box
                data-test-id={paymentPlan.attributes.name}
                key={paymentPlan.attributes.name}
                style={{
                  ...webStyles.paymentPlan,
                  ...(activeBoxIndex === index
                    ? { border: "6px solid #000000" }
                    : {}),
                }}
                onClick={() => this.setActiveBoxIndex(index)}
              >
                <Typography variant="h5" style={webStyles.titleText}>
                  {paymentPlan.attributes.name}
                </Typography>
                <ul style={webStyles.descriptionList}>
                  <li>
                    <Typography style={webStyles.descriptionText}>
                      <b>{configJSON.bestFor}:</b>{" "}
                      {paymentPlan.attributes.description}
                    </Typography>
                  </li>
                </ul>
                {this.getPriceValue(paymentPlan).price ? (
                  <Box style={webStyles.priceBlock}>
                    {selectedPricing === PlanPricing.Annual && (
                      <Box style={webStyles.strikethrougnBlock}>
                        <Typography style={webStyles.oldPrice}>
                          $
                          {paymentPlan.attributes.platformManagement
                            ? Number(
                                this.planPriceVal(
                                  paymentPlan,
                                  PlanPricing.Month
                                ).price
                              ) +
                              Number(
                                this.planPriceVal(
                                  platformManagementPaymentPlans?.[0],
                                  PlanPricing.Month
                                ).price
                              )
                            : this.planPriceVal(paymentPlan, PlanPricing.Month)
                                .price}
                        </Typography>
                        <Box style={webStyles.strikethroughLine}></Box>
                      </Box>
                    )}
                    <Typography
                      data-test-id="planPrice"
                      style={webStyles.price}
                    >
                      $
                      <span style={webStyles.extraSize}>
                        {paymentPlan.attributes.platformManagement
                          ? Number(this.getPriceValue(paymentPlan).price) +
                            Number(
                              this.planPriceVal(
                                platformManagementPaymentPlans?.[0],
                                PlanPricing.Month
                              ).price
                            )
                          : this.getPriceValue(paymentPlan).price}
                      </span>
                      <Box style={webStyles.priceAttributes}>
                        <Typography style={webStyles.priceCurrency}>
                          {this.getPriceValue(paymentPlan).currency}
                        </Typography>
                        <Typography style={webStyles.priceInterval}>
                          /Month
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                ) : (
                  <Box style={webStyles.priceBlock}>
                    <Typography variant="h5">{configJSON.noData}</Typography>{" "}
                  </Box>
                )}

                <ul style={webStyles.hideMark}>
                  <li style={{ marginBottom: "21px" }}>
                    {paymentPlan?.attributes?.service?.max_contacts_count ? (
                      <Typography data-test-id="contactsLimitText">
                        {" "}
                        <b>
                          {paymentPlan?.attributes?.service?.max_contacts_count}{" "}
                          {configJSON.contacts}
                        </b>
                      </Typography>
                    ) : (
                      <Typography>
                        {" "}
                        <b>
                          {configJSON.config1000} {configJSON.contacts}
                        </b>
                      </Typography>
                    )}
                  </li>

                  <li style={{ marginBottom: "31px" }}>
                    {paymentPlan?.attributes?.service?.max_members_count ? (
                      <Typography data-test-id="membersLimitText">
                        {" "}
                        <b>
                          {paymentPlan?.attributes?.service?.max_members_count}{" "}
                          {configJSON.teamMembers}
                        </b>
                      </Typography>
                    ) : (
                      <Typography>
                        {" "}
                        <b>
                          {configJSON.upto3} {configJSON.teamMembers}
                        </b>
                      </Typography>
                    )}
                  </li>
                  <li>
                    <Typography>
                      <b>{configJSON.includes}</b>
                    </Typography>
                  </li>
                </ul>
                <ul style={webStyles.advantageList}>
                  {this.getPaymentPlan(paymentPlan)?.map(
                    (item: any, index: number) => (
                      <li style={webStyles.permissionText}>{item}</li>
                    )
                  )}
                </ul>
                {paymentPlan.attributes.name !== "Essentials" && (
                  <Box className={classes.platformManagementContainer}>
                    <Box className={classes.infoContainer}>
                      <Box className={classes.iconContainer}>
                        <Info className={classes.infoIcon} />
                      </Box>
                      <Box className={classes.tooltip}>
                        <span className={classes.tooltipText}>
                          {this.platformManagementRegularTooltip}
                        </span>
                        <span>{this.platformManagementBoldTooltip}</span>
                      </Box>
                    </Box>
                    <Typography
                      className={classes.platformManagementDescription}
                    >
                      {this.addPlatformManagement}
                    </Typography>
                    <Switch
                      data-test-id={`planManagementSwitch-${paymentPlan.id}`}
                      onClick={() => setPlatformManagement(paymentPlan.id)}
                      checked={platformManagementChecked}
                      className={classes.platformManagementSwitch}
                      focusVisibleClassName=".Mui-focusVisible"
                      disableRipple
                    />
                  </Box>
                )}
                <Button
                  data-test-id="selectButton"
                  variant="contained"
                  fullWidth
                  style={webStyles.selectBtn}
                  onClick={() => this.props.setSelectedPlan(paymentPlan)}
                >
                  {configJSON.select}
                </Button>
              </Box>
            ))}
          </Container>
        </Box>
      </>
    );
  }
}

export { PlanPayment };
export default withStyles(styles)(PlanPayment);
// Customizable Area End
