// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography } from "@material-ui/core";
import { reelIcon } from "./assets";
import AnalyticsPlatformsController from "./AnalyticsPlatformsController.web";

export class AnalyticsPlatforms extends AnalyticsPlatformsController {
  render() {
    const { classes, connectedPlatforms, setSelectedPlatform } = this.props;
    const { selectedPlatform } = this.state;

    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.platformsContainer}>
          <Box className={classes.platformsHeader}>
            <img
              className={classes.reelsIcon}
              src={reelIcon}
              alt="reels icon"
            />
            <Typography className={classes.platformsListTitle}>
              Connected Platforms
            </Typography>
          </Box>

          <Box className={classes.platformsList}>
            {Boolean(connectedPlatforms?.length) &&
              connectedPlatforms.map((platform, i) => (
                <Box
                  data-test-id={`platform-${i}`}
                  key={`${platform.platform_name + i}`}
                  className={classes.platformItem}
                  onClick={() => setSelectedPlatform(platform.platform_name)}
                >
                  <Box className={classes.platformIcon}>
                    <img src={""} alt="platform icon" />
                  </Box>

                  <Typography className={classes.platformTitle}>
                    {platform.platform_name}
                  </Typography>

                  <Typography className={classes.platformStatus}>
                    {platform.connected ? "Connected" : "Disconnected"}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>

        <Box className={classes.platformStatsContainer}>
          <Typography className={classes.platformStatsTitle}>
            Profile Engagements
          </Typography>

          <Box className={classes.platformStatsList}>
            <Box className={classes.statItem}>
              <Typography className={classes.statName}>Likes</Typography>
              <Typography
                className={classes.statValue}
                style={{ color: "#FD6C3F" }}
              >
                {selectedPlatform?.platform_data.likes || 0}
              </Typography>
            </Box>

            <Box className={classes.statItem}>
              <Typography className={classes.statName}>Comments</Typography>
              <Typography
                className={classes.statValue}
                style={{ color: "#60C289" }}
              >
                {selectedPlatform?.platform_data.comments || 0}
              </Typography>
            </Box>

            <Box className={classes.statItem}>
              <Typography className={classes.statName}>Shares</Typography>
              <Typography
                className={classes.statValue}
                style={{ color: "#FFC629" }}
              >
                {selectedPlatform?.platform_data.shares || 0}
              </Typography>
            </Box>

            <Box className={classes.statItem}>
              <Typography className={classes.statName}>Saves</Typography>
              <Typography
                className={classes.statValue}
                style={{ color: "#189CBD" }}
              >
                {selectedPlatform?.platform_data.saves || 0}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      width: "100%",
      alignItems: "stretch",
      justifyContent: "center",
      gap: "27px",

      "& *": {
        fontFamily: "Inter",
      },
    },

    platformsContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexBasis: "440px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      padding: "43px 20px 34px",
      gap: "10px",
    },

    platformsHeader: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "21px",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "20px",
    },

    reelsIcon: {
      width: "43px",
    },

    platformsListTitle: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },

    platformsList: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "49px",
    },

    platformItem: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      height: "76px",
      padding: "16px 8px",
      gap: "20px",
    },

    platformIcon: {
      width: "25px",
      height: "25px",
    },

    platformTitle: {
      display: "flex",
      alignItems: "center",
      flexBasis: "50%",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 700,
      textTransform: "capitalize",
    },

    platformStatus: {
      display: "flex",
      alignItems: "center",
      flexBasis: "30%",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 700,
      color: "#0F172A",
      opacity: 0.5,
    },

    platformStatsContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexBasis: "684px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      padding: "47px 55px 46px",
      gap: "30px",
    },

    platformStatsTitle: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },

    platformStatsList: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
    },

    statItem: {
      display: "flex",
      flexWrap: "nowrap",
      height: "101px",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "30px 60px 20px 40px",

      "&:nth-child(even)": {
        backgroundColor: "rgba(226, 232, 240, 0.37)",
      },
    },

    statName: {
      fontSize: "16px",
      fontWeight: 700,
    },

    statValue: {
      fontSize: "36px",
      fontWeight: 700,
    },
  });

export default withStyles(styles)(AnalyticsPlatforms);
// Customizable Area End
