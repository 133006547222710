// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  IconButton,
  withStyles,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EverybrandLibraryController from "./EverybrandLibraryController.web";
import { getMediafileType } from "../../utilities/src/GetMediafileType";

const styles = (theme: Theme) =>
  createStyles({
    libraryModalContainer: {
      position: "relative",
      display: "flex",
      height: "90%",
      flexBasis: "1171px",
      flexShrink: 0,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "64px 44px 37px",
      gap: "22px",
      borderRadius: "8px 8px 32px 8px",
      background: "#FFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
    },

    closeButton: {
      position: "absolute",
      top: "21px",
      right: "12px",
    },

    libraryModalTitle: {
      alignSelf: "flex-start",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.13px",
    },

    filesContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(230px, 1fr))",
      gridAutoRows: "300px",
      columnGap: "38px",
      rowGap: "41px",
      overflowY: "auto",
      scrollbarWidth: "none",
      msOverflowStyle: "none",

      "&::-webkit-scrollbar": {
        width: "10px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    filePreviewContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "hidden",

      "& > img": {
        objecFit: "cover",
      },

      "& > video": {
        width: "100%",
      },
    },

    filePreviewOverlay: {
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    fileCheckbox: {
      position: "absolute",
      top: "13px",
      right: "13px",
    },

    selectedFileNumber: {
      fontSize: "70px",
      fontWeight: 700,
      color: "#FFC629",
    },

    buttonsContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "15px",
    },

    button: {
      width: "132px",
      height: "44px",
      textTransform: "none",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 700,
    },
  });

export class EverybrandLibrary extends EverybrandLibraryController {
  render() {
    const { classes, goBack } = this.props;
    const { filesFromLibrary, selectedFiles } = this.state;

    return (
      <Box
        id="everybrandLibraryModal"
        className={classes.libraryModalContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton
          id="closeEveryreelModalButton"
          className={classes.closeButton}
          onClick={goBack}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.libraryModalTitle}>
          Select videos from your Everybrand Library
        </Typography>
        <Box className={classes.filesContainer}>
          {filesFromLibrary.map((file, i) => (
            <Box
              id={`file-${i}`}
              className={classes.filePreviewContainer}
              onClick={() => this.selectFile(i)}
            >
              {getMediafileType(file.attributes.files) === "image" ? (
                <img
                  src={file.attributes.files}
                  alt="file from library"
                  className={classes.filePreview}
                />
              ) : (
                <video controls={false}>
                  <source src={file.attributes.files} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}

              <Box
                className={classes.filePreviewOverlay}
                style={{
                  backgroundColor: "#000",
                  opacity: Boolean(
                    selectedFiles.find(
                      (selectedFile) => selectedFile.id === file.id
                    )
                  )
                    ? 0.5
                    : 0,
                }}
              >
                <Checkbox
                  className={classes.fileCheckbox}
                  checked={Boolean(
                    selectedFiles.find(
                      (selectedFile) => selectedFile.id === file.id
                    )
                  )}
                  disabled={selectedFiles.length >= 10}
                />
                {selectedFiles.includes(file) && (
                  <Typography className={classes.selectedFileNumber}>
                    {selectedFiles.indexOf(file) + 1}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={classes.buttonsContainer}>
          <Button
            id="discardLibraryButton"
            className={classes.button}
            style={{
              backgroundColor: "#000",
              border: "1px solid #FFC629",
              color: "#FFF",
            }}
            onClick={goBack}
          >
            Discard
          </Button>
          <Button
            id="confirmLibraryFilesButton"
            className={classes.button}
            style={{ backgroundColor: "#FFC629", color: "#000" }}
            onClick={this.acceptFiles}
            disabled={!selectedFiles.length}
          >
            Accept
          </Button>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(EverybrandLibrary);

// Customizable Area End
