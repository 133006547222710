Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.stripeKey = "pk_test_51NlZ7kBJz0kOzyGJKm1A1VZUEgqZJ7tez3FPTqPmywuJRsotJoKY1Dm99Z6ceaY7OWGbUJ8ZULD2ZKKqileBglMZ00ym8mSgSj"

exports.signupEmailApiContentType = "application/json";
exports.signupEmailVerificationEndpoint = "account_block/accounts/check_email";
exports.SignUpEmailEndpoint = "account_block/accounts";
exports.SignUPSendOtpEndpoint = "account_block/accounts/resent_otp";
exports.SignUPVerifyOtpEndpoint = "account_block/accounts/verify_otp";
exports.StripeProductDetails = "customisable_user_subscriptions/products";
exports.StripeCancelSubscription = "bx_block_stripe_integration/subscriptions/cancel"
exports.StripesubScriptionsDetails = "bx_block_stripe_integration/subscriptions";
exports.StripeProductDetailsAPiMethod = "GET";
exports.signupVerificationAPiMethod = "GET";
exports.signupVerifyOtpAPiMethod = "GET";
exports.signupSendOtpAPiMethod = "GET";
exports.signupAPiMethod = "POST";
exports.signupAPiDetailMethod = "PUT";
exports.placeHolderEmail = "Email";
exports.step = "Step";
exports.bestFor = "Best for";
exports.unlimited = "Unlimited";
exports.advantage1 = "Online reviews";
exports.advantage2 = "Website chat";
exports.advantage3 = "Text marketing";
exports.upTo3 = "Up to 3";
exports.contacts = "contacts";
exports.teamMembers = "team members";
exports.includes = "Includes";
exports.everythingInEssential = "Everything in Essential, plus:";
exports.everythingInStandard = "Everything in Standard, plus:"
exports.addPlatformManagement = "Add platform management";
exports.platformManagementRegularTooltip = "Your platform will be managed by an Everybrand specialist around the clock, saving you time to focus on the increased leads coming in.";
exports.platformManagementBoldTooltip = "Additional investment of $1000/mo.";
exports.select = "Select";
exports.verification = "Phone Verification";
exports.dataPrivacy = "Message/data rates may apply.";
exports.tappingIcon = 'By tapping "Next",you agree to our';
exports.privacyPlicy = "Privacy Policy and Terms of Service"
exports.digitCode = " A 6-digit verification code was just sent to"
exports.validCode = "the code is valid for 5 minutes"
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.EnterBusiness="Enter Business Email";
exports.Email="Email";
exports.informationData ="Complete Your Information";
exports.firstName ="First Name";
exports.lastName ="Last Name";
exports.businesswebsite ="Business website";
exports.zipcode ="Zip code";
exports.newPassword = "New Password";
exports.newconpasswprd = "Confirm New Password";
exports.oneNumber = "At least one number";
exports.homeStretch = "You’re in the home stretch!"
exports.applyTime = "Message/data rates may apply.";
exports.minCharacter = " Minimum character length is 8 characters"
exports.createPassword = "Create a Password";
exports.mobileNumber ="Your  Mobile Number";
exports.next = "Next";
exports.back = "Back";
exports.resendOtp = "Resend it";
exports.pleaceholderZip = "Your Zip Code";
exports.noData = "No Data Available";
exports.$249 = "$249";
exports.$ = "$";
exports.plan ="plan ";
exports.newCard="New Card";
exports.MyCard = "My Card";
exports.config1000 = "1000";
exports.authorization = " .and authorize Everybrand to send text messages to the mobile number provided."
exports.upto3 = "upto 3"
exports.sixDigitCode = "A 6-digit verification code was just sent to"
exports.placeholderFirstName= "Your First Name";
exports.placeholderCofirmPw="Confirm your new password";
exports.placeholderNewPw = "Your new password";
exports.enterPasswordFor = "Enter a  password for "
exports.businessWebsites = "Your Business Website";
exports.placeholderLastName = "Your Last Name"
exports.changeNumber = "Change number";
exports.otpCode = "Didn’t get code?";
exports.oneCapitalLetter = "At least one capital letter";
exports.lowercaseletter = "At least one lowercase letter"
exports.publicProfiles = `We'll verify your number to make sure you are you. We never share this
with anyone and it won't be on your public profile.`
exports.mailPlaceholder = "email@email.com";
exports.communty =" We protect our community by making sure everyone on Everybrand is real.";
exports.protectCommunityMessage = "We protect our community by making sure everyone is real."
exports.securityMessage = "This information is kept private and will be used to verify you are you."
exports.addPersonalTouchMessage = "Great start! Let's add a personal touch to your post."
exports.alreadyHaveAnAccountMessage = "Already have an account?"
exports.labelRePassword = "Re-Type Password";
exports.signInLabel = "Sign In"
exports.errorCountryCodeNotSelected = "Please select country code";
exports.phoneNumberErrorMessage = "Please check your phone number and try again."
exports.errorBlankField = "can't be blank";
exports.step2Title = "Pick your plan and Payment";
exports.step3Title = "Enter payment information";
exports.step3SubTitle = "You're in the home stretch";
exports.cardholderLabel = "Cardholder Name";
exports.cardNumberLabel = "Card number";
exports.cardExpirationDate = "Expiration Date";
exports.annualPricing = "Annual Pricing";
exports.monthlyPricing = "Monthly Pricing";
exports.cardCvv = "CVV";
exports.tenPercentSave = "Save 10%";
exports.whatIsFullNameLabel = "What's your first and last name?"
exports.continueLabel = "Continue"
exports.cardNumberPlaceholder = "1111 1111 1111 1111";
exports.cardExpirationPlaceholder = "MM/YYYY";
exports.cardCvvPlaceholder = "000";
exports.submit = "Submit";
exports.back = "back";
exports.successMessage = "Transaction Succesful";
exports.paymentDetails = "Payment Details";
exports.plan = "Plan";
exports.perMonth = "/Month";
exports.requiredCardholder = "Card holder name is required";
exports.invalidCardholder = "Invalid card holder name";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.enterThoughtsPlaceholder = `Tap to share your thoughts \n or ask a question...`
exports.termsConditionsMessage = "By continuing, you agree to our privacy policy and terms of service and authorize (business user's company name) to send text messages with offers & other information to the mobile number you provided. Message/data rates apply."
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.sendOtpToEndUserEndpoint="account_block/accounts/send_otps"
exports.verifyUserOtpEndPoint="account_block/accounts/sms_confirmations"
exports.endUserLoginOtpConformationEndPoint="bx_block_login/logins"
exports.createUserEndPoint="account_block/accounts"
const countryCodesList = require('./json/CountryCodes.json')
exports.countryCodes = countryCodesList
// Customizable Area End
