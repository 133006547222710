// Customizable Area Start
import React, { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography, Button } from "@material-ui/core";
import { cloudIcon } from "./assets";
import { AfterSignupModalSteps } from "./enums";

type Props = {
  classes: any;
  file: any;
  setStep: (step: AfterSignupModalSteps) => void;
  setPicture: (file: any) => void;
  sendProfilePic: () => void;
  closeModal: (e: React.SyntheticEvent) => void;
};

export const AfterSignupUpload = ({
  classes,
  file,
  setPicture,
  sendProfilePic,
  setStep,
}: Props) => {
  const editorRef = useRef<any>(null);
  const [imageScale, setImageScale] = useState(1);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    maxSize: 2000000000,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setPicture(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleNextStep = async () => {
    if (editorRef.current) {
      const dataUrl = editorRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPicture([blob]);
    }
    sendProfilePic();
    setStep(AfterSignupModalSteps.INVITES);
  };

  const handleSkip = () => {
    setStep(AfterSignupModalSteps.INVITES);
  };

  return (
    <Box className={classes.container} id="uploadImageScreen">
      <Typography className={classes.title}>Upload a Profile Pic</Typography>
      <Typography className={classes.description}>
        Let's make your profile a little more on brand
      </Typography>

      {file ? (
        <Box className={classes.imagePreviewContainer}>
          <AvatarEditor
            ref={editorRef}
            image={file[0]?.preview}
            width={390}
            height={390}
            border={25}
            borderRadius={390}
            color={[0, 0, 0, 0.6]}
            scale={imageScale}
            rotate={0}
          />

          <Box className={classes.scaleContainer}>
            Scale
            <input
              className={classes.scaleInput}
              type="range"
              name="Scale"
              id="scale"
              min="1"
              max="5"
              step="0.1"
              value={imageScale}
              onChange={(e) => {
                setImageScale(Number(e.target.value));
              }}
            />
          </Box>
        </Box>
      ) : (
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} id="fileInput" />
          <img src={cloudIcon} alt="cloud icon" className={classes.cloudIcon} />
          <Box className={classes.dropzoneInnerContainer}>
            <Typography className={classes.dropzoneTitle}>
              Select image to upload
            </Typography>
            <Typography className={classes.dropzoneText}>
              or drag and drop an image file
            </Typography>
          </Box>
        </div>
      )}

      <Box className={classes.buttonsContainer}>
        <Typography
          id="skipUploadingButton"
          className={classes.buttonSkip}
          onClick={handleSkip}
        >
          Skip for now
        </Typography>
        <Button
          id="goToInvitesScreen"
          className={classes.buttonNext}
          onClick={handleNextStep}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      paddingRight: "20px",
      gap: "40px",
      color: "#0F172A",
      overflow: "auto",

      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: "39px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    title: {
      alignSelf: "flex-start",
      fontSize: "32px",
      fontWeight: 700,
    },

    description: {
      fontSize: "24px",
      fontWeight: 700,
    },

    dropzone: {
      position: "relative",
      display: "flex",
      width: "390px",
      height: "390px",
      minHeight: "390px",
      borderRadius: "50%",
      flexDirection: "column",
      border: "2px dashed #DADADA",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },

    dropzoneInnerContainer: {
      position: "absolute",
      top: "180px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },

    dropzoneTitle: {
      fontSize: "24px",
      fontWeight: 700,
    },

    dropzoneText: {
      fontSize: "20px",
    },

    cloudIcon: {
      position: "absolute",
      top: "75px",
      width: "79px",
    },

    imagePreviewContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",

      "& > canvas": {
        selfAlign: "center",
      },
    },

    scaleContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: 700,

      "& input[type='range']": {
        "-webkit-appearance": "none",
        appearance: "none",
        background: "transparent",
        cursor: "pointer",
        width: "20rem",
      },

      "& input[type='range']::-webkit-slider-runnable-track": {
        background: "#FFC629",
        height: "11px",
        borderRadius: "21px"
      },
      
      "& input[type='range']::-moz-range-track": {
        background: "#FFC629",
        height: "11px",
        borderRadius: "21px"
      },

      "& input[type='range']::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        "appearance": "none",
        marginTop: "-8px",
        backgroundColor: "#000",
        height: "26px",
        width: "26px",
        borderRadius: "50%",
     }
    },

    scaleInput: {
      width: "50%",
    },

    buttonsContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },

    buttonSkip: {
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
    },

    buttonNext: {
      alignSelf: "flex-end",
      width: "310px",
      height: "44px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#FFC629",
      fontWeight: 700,
      fontSize: "16px",
    },
  });

export default withStyles(styles)(AfterSignupUpload);
// Customizable Area End
