export const routes = {
  basePath: "/",
  signup: "/signup",
  login: "/login",
  phoneLogin: "/phone-login",
  phoneLoginOtp: "/phone-login-otp",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  dashboard: "/dashboard",
  settings: "/settings",
  messages: "/messages",
  contacts: "/contacts",
  automations: "/automations",
  userProfile: "/userprofile",
  endUserSignup: "/endusersignup",
  endUserOtpAuth: "/enduserotpauth",
  endUserLogin: "/enduserlogin",
  inviteFriends: "/invite-friends",
  eudashboard: "/EuDashboard",
  eutrending: "/EuTredning",
  eucreate: "/EuCreatepost",
  euprofile: "/EuProifle",
  eunotification:"/EuNotification"
};
