
export const ThreeDotIcon = require('../assets/three_dot_icon.svg');
export const firststepimg1 = require("../assets/firststepimg1.png");
export const stepImageFirst = require("../assets/step_Image_First.svg")
export const calendarIcon = require("../assets/calendar.svg")
export const womanImage = require("../assets/woman.png")
export const addPlusIcon = require("../assets/plus.svg")
export const IconAvtar = require("../assets/icon_avatar.svg")
export const sendIcon = require("../assets/send_icon_vector.svg")
export const infoIcon = require("../assets/info_icon.svg")
export const filledOpenSpot = require("../assets/filled_open_spot_icon.svg")
export const removeIcon = require("../assets/remove_from_waitlist.svg")
export const folderIcon = require("../assets/folder_icon.svg")
export const radioButtonOn = require("../assets/radiobutton_ON.svg")
export const radioButtonOff = require("../assets/radiobutton_OFF.svg")
export const CloseIcon = require('../assets/close_icon.svg');
export const currentContact = require('../assets/current_contact.svg');
export const checkMarkWhite = require('../assets/checkmark.svg');
export const trashIcon = require('../assets/trash.svg');

